import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faCheck,
  faFloppyDisk,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stringHelper from '../../../services/Core/Helpers/string-helper';
import AllowableMisaligmentComponent from './allowable-misaligment-componet';
import FormFloatingComponent from '../../../components/Forms/from-floating-component';
import pumpExtensions from '../../../services/Pumps/PumpExtensions';
import pumpService from '../../../services/Pumps/PumpService';
import TextFloatingBox from '../../../components/text-floating-box';
import PumpCouplingAlignmentSheet from '../../../services/Pumps/Models/PumpCouplingAlignmentSheet';
import LogoComponent from '../../../components/app/LogoComponent';
import { useChanges } from '../../../hooks/useChanges';

type Props = {
  employeeId: string;
  externalFileId: string;
  alignmentSheet: PumpCouplingAlignmentSheet;
  index: number;
  loadSheets: Function;
};

export default function PumpCouplingAlignmentSheetPage(props: Props) {
  const { t } = useTranslation();

  const { employeeId, externalFileId, alignmentSheet, index, loadSheets } =
    props;

  const [sheet, setSheet] = useState<PumpCouplingAlignmentSheet>(
    new PumpCouplingAlignmentSheet(),
  );
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [serialNumberError, setSerialNumberError] = useState<
    string | undefined
  >();
  const [changes, setChanges] = useState<boolean>(false);
  useChanges([index, sheet], []);

  // useCallBack(handleSave, 10 * 1000);

  useEffect(() => {
    if (alignmentSheet) {
      setSheet(alignmentSheet);
      setSerialNumber(alignmentSheet.serialNumber);
    }
  }, [employeeId, externalFileId, alignmentSheet, index]);

  const created = useMemo(() => {
    if (sheet?.created) {
      return stringHelper.toDateString(new Date(sheet.created));
    }
    return stringHelper.toDateString(new Date(Date.now()));
  }, [index, sheet.created]);

  const isReadOnly = useMemo(() => {
    return sheet.finished && sheet.finished === true;
  }, [sheet]);

  return (
    <div id="pump-coupling-alignment-sheet-page" className="container">
      <div className="card">
        <div className="card-title title-container">
          <LogoComponent />
          <div className="text-center">
            <h2>FLO FAB PUMP COUPLING ALIGNMENT - ({index + 1})</h2>
          </div>
          <div>
            <table className="table">
              <tbody>
                <tr>
                  <th>{t('common.quote')}</th>
                  <td>:</td>
                  <td>{sheet?.couplingAlignmentSheet?.quoteId}</td>
                </tr>
                <tr>
                  <th>{t('common.date')}</th>
                  <td>:</td>
                  <td>{created}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-body">
          <section id="quote-informations-section" className="info-grid">
            <TextFloatingBox
              id={'client'}
              text={sheet?.couplingAlignmentSheet?.client}
              title="Client"
              size="sm"
            />
            <TextFloatingBox
              id={'job'}
              text={sheet?.couplingAlignmentSheet?.job ?? ''}
              title={t('pumps.common.job')}
              size="sm"
            />
            <TextFloatingBox
              id={'employee'}
              text={sheet?.employee}
              title={t('common.employee')}
              size="sm"
            />
            <TextFloatingBox
              id={'approved-by'}
              text={sheet?.approvedBy}
              title={t('pumps.common.approved-by')}
              size="sm"
            />
          </section>
          {/* <hr /> */}
          <h6>Pump Info</h6>
          <div className="pump-info pump-info-grid mb-1">
            <div className="bordered">
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Model:
                </span>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={sheet?.couplingAlignmentSheet?.pumpModel}
                  readOnly
                />
              </div>
              <div className="input-group input-group-sm">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Serial number:
                </span>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={serialNumber}
                  onChange={(ev) => onChangeSerialNumber(ev.target.value)}
                  readOnly={isReadOnly}
                />
              </div>
              {serialNumberError && (
                <label className="error">{serialNumberError}</label>
              )}
            </div>
            <div className="text-center bordered">
              <h6>Pressure</h6>
              <div className="alignment-grid">
                <label>
                  {sheet?.couplingAlignmentSheet?.pressureTDH} TDH(FT)
                </label>
                <label>
                  {sheet?.couplingAlignmentSheet?.pressurePSI} (PSI)
                </label>
              </div>
            </div>
            <div className="text-center bordered">
              <h6>Flow</h6>
              <label>{sheet?.couplingAlignmentSheet?.flow} (USGPM)</label>
            </div>
            <div className="text-center bordered">
              <h6>IMP. DIA. </h6>
              <label>{sheet?.couplingAlignmentSheet?.diam} (IN)</label>
            </div>
            <div className="text-center bordered">
              <h6>TAG</h6>
              <label>{sheet?.couplingAlignmentSheet?.tag}</label>
            </div>
          </div>
          <h6>Motor Info</h6>
          <div className="row mb-1">
            <div className="col-3">
              <TextFloatingBox
                title="HP"
                id="hp"
                text={sheet?.couplingAlignmentSheet?.hp}
              />
            </div>
            <div className="col-3">
              <TextFloatingBox
                title="RPM"
                id="rpm"
                text={sheet?.couplingAlignmentSheet?.rpm}
              />
            </div>
            <div className="col-3">
              <TextFloatingBox
                title="Volt/PH/Hz :"
                id="volt"
                text={sheet?.couplingAlignmentSheet?.phase}
              />
            </div>
            <div className="col-3 align-middle text-center">
              <FormFloatingComponent
                title="Frame"
                id="frame"
                defaultValue={sheet?.frame}
                onchange={(ev: any) => setValue('frame', ev.target.value)}
                type="text"
                readonly={isReadOnly}
              />
            </div>
          </div>

          <div className="alignment-grid">
            <div className="text-center">
              <AllowableMisaligmentComponent />
            </div>

            <div>
              <table className="table-bordered table text-center">
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <FormFloatingComponent
                        title="Measurement: Sleeve Size:"
                        id="sleeveSize"
                        defaultValue={sheet.sleeveSize}
                        onchange={(ev: any) =>
                          setValue('sleeveSize', ev.target.valueAsNumber)
                        }
                        type="number"
                        readonly={isReadOnly}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th className="align-middle">ANGLE</th>
                    <td className="align-middle">
                      <FormFloatingComponent
                        title="Parallel"
                        id="parallel"
                        defaultValue={sheet?.parallel}
                        onchange={(ev: any) =>
                          setValue('parallel', ev.target.valueAsNumber)
                        }
                        type="number"
                        readonly={isReadOnly}
                      />
                      <span>(USE NO-GO GAGE)</span>
                    </td>
                    <td>
                      <FormFloatingComponent
                        title="Angular:"
                        id="angular"
                        defaultValue={sheet?.angular}
                        onchange={(ev: any) =>
                          setValue('angular', ev.target.valueAsNumber)
                        }
                        type="number"
                        readonly={isReadOnly}
                      />
                      <span className="text-center">(USE CALIPER)</span>
                      <FormFloatingComponent
                        title="MAX DIFF."
                        defaultValue={sheet?.maxDiff}
                        id="maxDiff"
                        onchange={(ev: any) =>
                          setValue('maxDiff', ev.target.valueAsNumber)
                        }
                        type="number"
                        readonly={isReadOnly}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>0</th>
                    <td>
                      <input
                        className="form-control"
                        id="0P"
                        value={sheet?.coup0P}
                        onChange={(ev) =>
                          setValue('coup0P', ev.target.valueAsNumber)
                        }
                        readOnly={isReadOnly}
                        type="number"
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        id="0A"
                        value={sheet?.coup0A}
                        onChange={(ev) =>
                          setValue('coup0A', ev.target.valueAsNumber)
                        }
                        readOnly={isReadOnly}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>90</th>
                    <td>
                      <input
                        className="form-control"
                        id="90P"
                        value={sheet?.coup90P}
                        onChange={(ev) =>
                          setValue('coup90P', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        id="90A"
                        value={sheet?.coup90A}
                        onChange={(ev) =>
                          setValue('coup90A', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>180</th>
                    <td>
                      <input
                        className="form-control"
                        id="180P"
                        value={sheet?.coup180P}
                        onChange={(ev) =>
                          setValue('coup180P', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        id="180A"
                        value={sheet?.coup180A}
                        onChange={(ev) =>
                          setValue('coup180A', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>270</th>
                    <td>
                      <input
                        className="form-control"
                        id="270P"
                        value={sheet?.coup270P}
                        onChange={(ev) =>
                          setValue('coup270P', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        id="270A"
                        value={sheet?.coup270A}
                        onChange={(ev) =>
                          setValue('coup270A', ev.target.valueAsNumber)
                        }
                        type="number"
                        readOnly={isReadOnly}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <ul>
                  <li>0.010 (0.010)</li>
                  <li>0.015 (0.010)</li>
                  <li>0.020 (0.020)</li>
                  <li>0.017</li>
                  <li>0.025 (0.025)</li>
                  <li>0.032 (0.020+0.017)</li>
                  <li>0.040 (0.020+0.020)</li>
                  <li>0.045 (0.020+0.025)</li>
                  <li>0.062 (0.010+0.015+0.020+0.017)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer d-flex text-center">
        <div className="flex-fill">
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              const serial = sheet.serialNumber;
              document.title = `Pump Alignment sheet - ${serial}`;
              window.print();
            }}
          >
            <FontAwesomeIcon size="xl" className="me-2" icon={faPrint} />
            {t('common.print')}
          </button>
        </div>
        <div className="flex-fill">
          <button className="btn btn-outline-primary" onClick={onSaveClick}>
            <FontAwesomeIcon size="xl" className="me-2" icon={faFloppyDisk} />
            {t('common.save')}
          </button>
        </div>
        <div className="flex-fill">
          {sheet?.isAdmin && (
            <button
              className="btn btn-outline-success"
              onClick={() => onValidateClick(employeeId, sheet.id)}
            >
              <FontAwesomeIcon size="xl" className="me-2" icon={faCheck} />
              Validate
            </button>
          )}
        </div>
      </div>
    </div>
  );

  // function downloadPdfDocument() {
  //   const input: HTMLElement | null =
  //     document.getElementById("alignment-sheet");
  //   if (input !== null)
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("l", "mm", "a4");
  //       var pdfWidth = pdf.internal.pageSize.getWidth();
  //       var pdfHeight = pdf.internal.pageSize.getHeight();
  //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //       pdf.save(
  //         `PUMP_COUPLING_ALIGNMENT_${sheet.couplingAlignmentSheet.job}_${index}.pdf`
  //       );
  //     });
  // }

  function onChangeSerialNumber(value: string) {
    if (!pumpExtensions.isSerialNumberValid(value)) {
      const error = t('pumps.alignment-sheet-page.serial-number-error');
      setSerialNumberError(error);
    } else {
      setValue('serialNumber', value);
      setSerialNumberError(undefined);
    }
  }

  function onSaveClick() {
    // console.log(`changes '${changes}'`)
    if (changes) {
      save();
    }
  }

  function onValidateClick(employeeId: string, alignmentSheetId: number) {
    pumpService
      .updatePumpAlignmentSheetFinished(employeeId, alignmentSheetId)
      .then((res) => {
        if (res) {
          loadSheets();
          setChanges(false);
        } else {
          toast.error('');
        }
      });
  }

  function save(alignmentSheet: PumpCouplingAlignmentSheet = sheet) {
    if (employeeId) {
      pumpService
        .saveAlignmentSheet(employeeId, alignmentSheet)
        .then((res) => {
          // console.log("update response : ", res);
          if (!res) {
            console.error("'Update' : Response is null");
          } else {
            loadSheets();
            setChanges(false);
            toast.success('Successfully Saved');
          }
        })
        .catch((ex) => {
          console.error(ex);
          toast.error('An error occured while saving');
        });
    }
  }

  function setValue(key: string, value: any) {
    setSheet({ ...sheet, [key]: value });
    setChanges(true);
  }
}
