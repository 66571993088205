import BaseApi from '../Core/REST/BaseApi';
import type { AssemblyControl } from './Models/AssemblyControl';
import type { Client } from '../Users/Client';

class AssemblyControlService extends BaseApi {
  async GetAssemblyControl(
    externalFileId: string,
  ): Promise<AssemblyControl | undefined> {
    const url = `${this.baseUrl}/AssemblyControl/${externalFileId}`;
    const assemblyControl: AssemblyControl | undefined = await this.get(url);
    if (assemblyControl) {
      if (assemblyControl.creationDate)
        assemblyControl.creationDate = new Date(assemblyControl.creationDate);
      if (assemblyControl.testDate)
        assemblyControl.testDate = new Date(assemblyControl.testDate);
    }

    return assemblyControl;
  }
  async GetAllValidWitness(): Promise<Array<Client> | undefined> {
    const url = `${this.baseUrl}/AssemblyControl/Get-All-Valid-Witness`;
    return this.get(url);
  }
  async SaveAssemblyControl(
    assemblyControl: AssemblyControl,
    employeeId: string,
  ) {
    const url = `${this.baseUrl}/AssemblyControl/Save-Assembly-Control/${employeeId}`;
    return this.put(url, assemblyControl);
  }
  async ValidateAssemblyControl(
    assemblyControl: AssemblyControl,
    employeeId: string,
  ) {
    const url = `${this.baseUrl}/AssemblyControl/Validate-Assembly-Control/${employeeId}`;
    return this.put(url, assemblyControl);
  }
}

const assemblyControlService = new AssemblyControlService();
export default assemblyControlService;
