export class Goal {
  id: string;
  goalTypeId: number;
  goalName: string;
  value: number;
  startDate: Date;
  endDate: Date;

  constructor(
    id?: string,
    goalTypeId?: number,
    goalName?: string,
    value?: number,
    startDate?: Date,
    endDate?: Date,
  ) {
    if (id === undefined) throw new ReferenceError('id undefined');
    if (goalTypeId === undefined)
      throw new ReferenceError('goal type id undefined');
    if (value === undefined) throw new ReferenceError('value undefined');
    if (goalName === undefined) throw new ReferenceError('goal name undefined');
    if (startDate === undefined)
      throw new ReferenceError('start date undefined');
    if (endDate === undefined) throw new ReferenceError('end date undefined');

    this.id = id;
    this.goalTypeId = goalTypeId;
    this.goalName = goalName;
    this.value = value;
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
  }
}

export enum GoalTypes {
  CommissionReport = 1,
  IncomeStatement = 2,
}

export class GoalType {
  id: number;
  name: string;

  constructor(id?: number, name?: string) {
    if (id === undefined) throw new ReferenceError('id undefined');
    if (name === undefined) throw new ReferenceError('name undefined');

    this.id = id;
    this.name = name;
  }
}
