import './assigned-reminder-page.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { Client } from '../../services/Users/Client';
import {
  Page,
  PageDetails,
  PageHeader,
} from '../../components/Pages/page-components';
import LoadingComponent from '../../components/Core/Loading';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import ReminderTable from './assigned-reminder-table';
import remindersService from '../../services/Reminder/ReminderService';
import type Reminder from '../../services/Reminder/model/Reminder';

type Props = {
  user: Client;
};
function AssignedReminderPage({ user }: Props) {
  const { t } = useTranslation();
  const [reminderLate, setReminderLate] = useState<Array<Reminder>>();
  const [reminderFinished, setReminderFinished] = useState<Array<Reminder>>();
  const [otherReminder, setOtherReminder] = useState<Array<Reminder>>();
  const [pastReminder, setPastReminder] = useState<Array<Reminder>>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const RecentDelayInMillisecond = 1000 * 60 * 60 * 24 * 7; // 7 days
  const filterReminders = (reminderArray?: Array<Reminder>) => {
    if (reminderArray) {
      const date = new Date();

      const tempReminderLate: Array<Reminder> = [];
      const tempReminderFinished: Array<Reminder> = [];
      const tempOtherReminder: Array<Reminder> = [];
      const tempPastReminder: Array<Reminder> = [];

      reminderArray.forEach((reminder) => {
        if (reminder.finishedDate) {
          if (
            date.getMilliseconds() - reminder.finishedDate.getMilliseconds() <
            RecentDelayInMillisecond
          ) {
            tempReminderFinished.push(reminder);
          } else {
            tempPastReminder.push(reminder);
          }
        } else if (!reminder.date || reminder.date > date) {
          tempOtherReminder.push(reminder);
        } else {
          tempReminderLate.push(reminder);
        }
      });

      setReminderLate(tempReminderLate);
      setReminderFinished(tempReminderFinished);
      setOtherReminder(tempOtherReminder);
      setPastReminder(tempPastReminder);
      setIsLoading(false);
    }
  };
  const refreshReminders = () => {
    remindersService
      .GetRemindersAssignedBy()
      .then(filterReminders)
      .catch(() => {
        toast.error(t('reminder-menu.get-reminder-error'));
        setIsLoading(false);
      });
  };
  useEffect(() => {
    refreshReminders();
  }, []);
  const pageTitle = t('reminder-menu.reminders');
  const breadcrumbItems = [
    { text: 'Project Dashboard', link: `/Project-Dashboard` },
    { text: pageTitle, active: true },
  ];
  if (isLoading) {
    return <LoadingComponent />;
  }
  return (
    <Page id="assigned-reminder-page">
      <PageHeader className="d-block">
        <BreadcrumbComponent items={breadcrumbItems} />
        <div className="title-container">
          <h1 className="h3 mb-2">{pageTitle}</h1>
          <input
            type="search"
            className="form-control rounded search"
            placeholder={t('common.search') || ''}
            aria-label={t('common.search') || ''}
            onChange={(ev) => {
              setGlobalFilter(ev.target.value);
            }}
          />
        </div>
      </PageHeader>
      <PageDetails>
        <div>
          {reminderLate && (
            <div>
              <h4>{t('reminder-menu.reminder-late')}</h4>
              <ReminderTable
                refreshAction={refreshReminders}
                user={user}
                reminderArray={reminderLate}
                initialColumnVisibility={{ finishedDate: false }}
                globalFilter={globalFilter}
              ></ReminderTable>
            </div>
          )}
          {reminderFinished && (
            <div>
              <h4>{t('reminder-menu.reminder-recently-finished')}</h4>
              <ReminderTable
                refreshAction={refreshReminders}
                user={user}
                reminderArray={reminderFinished}
                globalFilter={globalFilter}
              ></ReminderTable>
            </div>
          )}
          {otherReminder && (
            <div>
              <h4>{t('reminder-menu.reminder-other')}</h4>
              <ReminderTable
                refreshAction={refreshReminders}
                user={user}
                reminderArray={otherReminder}
                initialColumnVisibility={{ finishedDate: false }}
                globalFilter={globalFilter}
              ></ReminderTable>
            </div>
          )}
          {pastReminder && (
            <div>
              <h4>{t('reminder-menu.reminder-past')}</h4>
              <ReminderTable
                refreshAction={refreshReminders}
                user={user}
                reminderArray={pastReminder}
                globalFilter={globalFilter}
              ></ReminderTable>
            </div>
          )}
        </div>
      </PageDetails>
    </Page>
  );
}
export default AssignedReminderPage;
