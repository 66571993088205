import BaseApi from '../Core/REST/BaseApi';
import type { Margin } from './Margin';

class MarginQuoteService extends BaseApi {
  async getMarginQuoteData(employeeId: string): Promise<Margin | undefined> {
    const url = `${this.baseUrl}/marginQuote/${employeeId}`;
    return this.get<Margin | undefined>(url);
  }
  async putMarginQuoteData(employeeId: string, editableData: Margin) {
    const url = `${this.baseUrl}/marginQuote/${employeeId}/`;
    return this.put(url, editableData);
  }
}

const marginQuoteService = new MarginQuoteService();
export default marginQuoteService;
