import BaseApi from '../Core/REST/BaseApi';
import type ProductionJob from './Models/ProductionJob';
import type { OrderStatus } from './Models/Production';

class ProductionService extends BaseApi {
  async getJobs(): Promise<Array<ProductionJob> | undefined> {
    const url = `${this.baseUrl}/production/jobs`;
    return this.get<Array<ProductionJob> | undefined>(url);
  }

  async getOrderStatus(type: number): Promise<Array<OrderStatus> | undefined> {
    const url = `${this.baseUrl}/QuoteExternalFile/Order-Status/${type}`;
    return this.get<Array<OrderStatus>>(url);
  }

  async deleteFile(id: number): Promise<void> {
    const url = `${this.baseUrl}/QuoteExternalFile/${id}`;
    return this.delete(url);
  }
}

const productionService = new ProductionService();
export default productionService;
