import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
};
export function SearchBarComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="input-group">
      <input
        type="search"
        className="form-control rounded"
        placeholder={t('common.search') || ''}
        aria-label={t('common.search') || ''}
        value={props.value}
        onChange={(ev) => props.setGlobalFilter(ev.target.value)}
      />
    </div>
  );
}
