import BaseApi from '../Core/REST/BaseApi';
import type { SingleItemQuickNavigationLinkTypes } from './Models/SingleItemQuickNavigationLinkTypes';
import type { SingleItemQuickNavigationLinks } from './Models/SingleItemQuickNavigationLinks';
import type { Item } from '../Core/Items/Item';
import itemExtensions from '../Core/Items/ItemExtensions';

class SingleItemService extends BaseApi {
  async getAllQuickNavigationLinkTypes(): Promise<
    Array<SingleItemQuickNavigationLinkTypes> | undefined
  > {
    const url = `${this.baseUrl}/SingleItem/Get-Types`;
    return this.get<Array<SingleItemQuickNavigationLinkTypes> | undefined>(url);
  }

  async getAllQuickNavigationLinks(
    linkType: number,
  ): Promise<Array<SingleItemQuickNavigationLinks> | undefined> {
    const url = `${this.baseUrl}/SingleItem/Get/${linkType}`;
    return this.get<Array<SingleItemQuickNavigationLinks> | undefined>(url);
  }

  async getAllItemsByOCode(ocode: string): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/SingleItem/search-code/ocode?ocode=${ocode}`;
    return this.get<Array<Item> | undefined>(url);
  }

  async getAllItemsByDescription(
    descr: string,
  ): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/SingleItem/search-code/descr?descr=${descr}`;
    return this.get<Array<Item> | undefined>(url);
  }

  // **************** QuoteSingleItem
  async addItemtoQuote(
    userId: number,
    quoteId: number,
    item: Item,
  ): Promise<number | undefined> {
    const url = `${this.baseUrl}/SingleItem/${userId}/add-to-quote/${quoteId}`;
    return this.post(url, item);
  }

  async getQuoteSingleItem(
    quoteSingleItemId: string | number,
  ): Promise<Item | undefined> {
    const url = `${this.baseUrl}/SingleItem/${quoteSingleItemId}/Quote-single-item`;
    return this.get<Item>(url).then((data) => {
      if (data) {
        return itemExtensions.generateItem(data);
      }
      return undefined;
    });
  }

  async deleteQuoteSingleItem(userId: string, quoteId: string, itemId: number) {
    await this.delete(
      `${this.baseUrl}/SingleItem/${userId}/${quoteId}/Quote-single-item?itemId=${itemId}`,
    );
  }

  async updateQuoteSingleItem(
    itemId: number,
    qty: number,
    quoteId?: string,
    userId?: string,
  ) {
    await this.patch(
      `${this.baseUrl}/SingleItem/${userId}/${quoteId}/Quote-single-item?itemId=${itemId}&qty=${qty}`,
      {},
    );
  }
}
const singleItemService = new SingleItemService();
export default singleItemService;
