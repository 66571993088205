import type { ItemFile } from '../../Core/Items/Item';
import { Item } from '../../Core/Items/Item';
import { ItemDetails } from '../../Quotes/Models/QuoteItem';

export class QuoteSingleItem extends Item {
  id: number;
  quoteId: number;
  listPrice: number;
  netPrice: number;
  overWritten: boolean;
  clicusPrice: number;
  weight: number;
  qty: number;
  // it_code: number;

  constructor(data: any, itemDetails: ItemDetails, files?: Array<ItemFile>) {
    super(data, itemDetails, files);
    this.id = data.id;
    this.quoteId = data.quoteId;
    this.listPrice = data.listPrice;
    this.netPrice = data.netPrice;
    this.overWritten = data.overwritten;
    this.clicusPrice = data.clicusPrice;
    this.weight = data.weight;
    this.it_code = data.it_code;
    this.it_ocode = data.it_ocode;
    this.it_weight = data.weight;
    this.itemDescription = data.itemDescription;
    this.it_soldPrice = data.soldPrice;
    this.qty = data.qty;
    this.itemDetails = new ItemDetails({
      qtyReserved: data.itemDetails.qtyReserved,
      qtyOnOrder: data.itemDetails.qtyOnOrder,
      qtyOnHand: data.itemDetails.qtyReserved,
    });
  }
  public getProfit(): number {
    return ((this.netPrice - this.it_cost) / this.netPrice) * 100;
  }
}
