import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowsRotate,
  faCheckDouble,
  faCopy,
  faFileImport,
  faFilePdf,
  faFloppyDisk,
  faPencil,
  faPlus,
  faPrint,
  faSearch,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MouseEventHandler } from 'react';

type ButtonSize = 'sm' | 'lg' | undefined;
type ButtonProps = {
  title?: string;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  btnSize?: ButtonSize;
  iconSize?: SizeProp;
};

const generateButtonProps = (props: ButtonProps, className?: string) => {
  const { title, onClick, btnSize } = props;
  return {
    className: `btn btn-${btnSize ?? 'sm'} ${props.className}  ${className}`,
    title,
    onClick,
  };
};

// Icon Buttons
export function SearchIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon icon={faSearch} />
    </button>
  );
}

export function RemoveIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline-danger')}>
      <FontAwesomeIcon size={props.iconSize} icon={faX} />
    </button>
  );
}

export function SaveIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon icon={faFloppyDisk} />
    </button>
  );
}

export function EditIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon size={props.iconSize} icon={faPencil} />
    </button>
  );
}

export function RefreshIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline-primary')}>
      <FontAwesomeIcon size={props.iconSize} icon={faArrowsRotate} />
    </button>
  );
}

export function PrintIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon icon={faPrint} />
    </button>
  );
}

export function DeleteIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary red')}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );
}

export function PdfIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon icon={faFilePdf} />
    </button>
  );
}

export function DeleteProductIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline')}>
      <FontAwesomeIcon icon={faTrash} />
      {props.title && <span style={{ marginLeft: '8px' }}>{props.title}</span>}
    </button>
  );
}

export function CopyIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline')}>
      <FontAwesomeIcon size={props.iconSize} icon={faCopy} />
      {props.title && <span style={{ marginLeft: '8px' }}>{props.title}</span>}
    </button>
  );
}

export function CopyToIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline')}>
      <FontAwesomeIcon size={props.iconSize} icon={faFileImport} />
      {props.title && <span style={{ marginLeft: '8px' }}>{props.title}</span>}
    </button>
  );
}

export function SelectAllIconButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-outline')}>
      <FontAwesomeIcon size={props.iconSize} icon={faCheckDouble} />
      {props.title && <span style={{ marginLeft: '8px' }}>{props.title}</span>}
    </button>
  );
}

// Icon and Text Buttons
export function AddButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, 'btn-primary')}>
      <FontAwesomeIcon size={props.iconSize} icon={faPlus} />
      <span> {props.title}</span>
    </button>
  );
}
