import type { ColumnFilter, ColumnFiltersState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

export default function useColumnFilters(
  defaultValue?: ColumnFiltersState,
  onResetFilterFn?: () => void,
) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  useEffect(() => {
    if (defaultValue) {
      setColumnFilters(defaultValue);
    }
  }, [defaultValue]);

  function editFilterMultiselect(columnId: string, list: any[], lastItem: any) {
    const temp: Array<ColumnFilter> = columnFilters.filter(
      (item) => item.id !== columnId,
    );

    if (lastItem !== '' && list.length > 0) {
      const result = list.map((a) => a.value);
      temp.push({ id: columnId, value: result });
    }
    setColumnFilters(temp);
  }

  function editFilterFn(columnId: string, value: any) {
    // console.log("setFilter", columnId, value);
    const temp: Array<ColumnFilter> = columnFilters.filter(
      (item) => item.id !== columnId,
    );

    if (value !== '') {
      temp.push({ id: columnId, value });
    }

    setColumnFilters(temp);
  }

  const resetFiltersFn = () => {
    setColumnFilters(defaultValue ?? []);
    if (onResetFilterFn) onResetFilterFn();
  };

  const getFilterValueFn = (key: string) => {
    const result = columnFilters.find((x) => x.id === key);
    // console.log(result);
    if (result == null) return '';
    return result.value as string;
  };

  return {
    columnFilters,
    editFilterFn,
    setColumnFilters,
    resetFiltersFn,
    getFilterValueFn,
    editFilterMultiselect,
  };
}
