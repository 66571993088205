// eslint-disable-next-line import/no-cycle
import Activity from './Activity';

class ActivityExtensions {
  buildActivities(data: Array<any>): Array<Activity> {
    const activities: Array<Activity> = [];
    if (data) {
      data.forEach((activity) => {
        const activityTemp = new Activity(activity);
        if (activityTemp) {
          activities.push(activityTemp);
        }
      });
    }
    return activities;
  }
}

const activityExtensions = new ActivityExtensions();
export default activityExtensions;
