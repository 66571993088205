class NumberHelper {
  isNumeric(str: string) {
    if (typeof str !== 'string') {
      return false;
    }
    const val: number = parseFloat(str);
    return !Number.isNaN(val) && !Number.isNaN(val);
  }

  toCurrencyFormat(num: number, currency: string = 'CAD') {
    if (currency === 'CAD') {
      return new Intl.NumberFormat('fr-US', {
        style: 'currency',
        currency: 'CAD',
      }).format(num);
    }
    if (currency === 'USD') {
      return new Intl.NumberFormat('fr-UK', {
        style: 'currency',
        currency: 'USD',
      }).format(num);
    }
    return this.toNumberFormat(num, 'en-CA');
  }

  toNumberFormat(num: number, formatType: string = 'en-CA'): string {
    return new Intl.NumberFormat(formatType, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(this.toFixed(num, 2));
  }

  toFixed(num: number, fractionDigits?: number) {
    const temp = num.toFixed(fractionDigits);
    return parseFloat(temp);
  }

  getPercentage(value: number, total: number) {
    return (value / total) * 100;
  }
}

const numberHelper = new NumberHelper();
export default numberHelper;
