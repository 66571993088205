import { useEffect, useState } from 'react';
import './add-booster-system-page.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageSectionComponent } from '../../components/Core/PageSectionComponent/PageSectionComponent';
import bSystemService from '../../services/Systems/BSystemService';
import type { SystemTypes } from '../../services/Systems/BoosterSystem/System/SystemTypes';
import type { SystemTexts } from '../../services/Systems/BoosterSystem/System/SystemTexts';
import { PageHeader, PageTitle } from '../../components/Pages/page-components';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import { SystemPumpComponent } from './system-pumps-component';
import { SystemItemComponent } from './system-items-component';

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export default function AddBoosterSystem() {
  const { t } = useTranslation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    switch (tab) {
      case 1:
        // My Incomplete Systems
        break;
      case 2:
        // General Info
        break;
      case 3:
        // General Drawing
        break;
      case 4:
        // Pumps
        break;
      case 5:
        // Tanks
        break;
      case 6:
        // HEX
        break;
      case 7:
        // Items
        break;
      case 8:
        // Piping
        break;
      default:
        break;
    }
  }, [tab]);

  type TabContainerProp = {
    show?: boolean;
    children?: React.ReactNode;
  };
  function TabContainerComponent(props: TabContainerProp) {
    const { show, children } = props;
    return <>{show && children}</>;
  }

  return (
    <>
      <PageHeader className="d-block">
        <BreadcrumbComponent
          items={[
            { text: 'Quotes', link: `/Quotes/${userId}` },
            {
              text: 'Add System',
              active: true,
            },
          ]}
        />
      </PageHeader>
      <PageTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h1 className="h2 me-3">Add System</h1>
          </div>
        </div>
      </PageTitle>
      <PageSectionComponent
        active={tab}
        onClick={setTab}
        items={[
          { text: t('add-booster-system-page.incomplete-systems'), key: 0 },
          { text: t('add-booster-system-page.general-info'), key: 1 },
          { text: t('add-booster-system-page.general-drawing'), key: 2 },
          { text: t('add-booster-system-page.pumps'), key: 3 },
          { text: t('add-booster-system-page.tanks'), key: 4 },
          { text: t('add-booster-system-page.heatexchanger'), key: 5 },
          { text: t('add-booster-system-page.items'), key: 6 },
          { text: t('add-booster-system-page.piping'), key: 7 },
          { text: t('add-booster-system-page.control-panels'), key: 8 },
          { text: t('add-booster-system-page.entire-package'), key: 9 },
        ]}
      />
      <TabContainerComponent show={tab === 1}>
        <SystemGeneralInfoComponent />
      </TabContainerComponent>
      <TabContainerComponent show={tab === 3}>
        <SystemPumpComponent />
      </TabContainerComponent>
      <TabContainerComponent show={tab === 6}>
        <SystemItemComponent
          quoteId={quoteId}
          userId={userId}
          componentId={1}
        />
      </TabContainerComponent>
      <TabContainerComponent show={tab === 7}>
        <SystemItemComponent
          quoteId={quoteId}
          userId={userId}
          componentId={2}
        />
      </TabContainerComponent>
      <TabContainerComponent show={tab === 8}>
        <SystemItemComponent
          quoteId={quoteId}
          userId={userId}
          componentId={3}
        />
      </TabContainerComponent>
    </>
  );
}

export function SystemGeneralInfoComponent() {
  const { t } = useTranslation();
  const [systemTypes, setSystemTypes] = useState<SystemTypes[]>();
  const [systemModels, setSystemModels] = useState<SystemTexts[]>();

  const [selectedNbPump, setSelectedNbPump] = useState<number>(1);
  const [selectedType, setSelectedType] = useState<SystemTypes>();
  const [selectedModel, setSelectedModel] = useState<SystemTexts>();
  const [selectedLang, setSelectedLang] = useState(1);

  useEffect(() => {
    loadSystemTypes();
  }, [selectedNbPump]);

  useEffect(() => {
    loadSystemModels();
  }, [selectedType]);

  useEffect(() => {}, []);

  const pumpOptions = [
    { label: 'Simplex', value: '1' },
    { label: 'Duplex', value: '2' },
    { label: 'Triplex', value: '3' },
    { label: 'Quadruplex', value: '4' },
  ];

  const handleTypeChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = ev.target.value;
    if (systemTypes) {
      const selected = systemTypes.find((type) => type.desc === selectedType);
      setSelectedType(selected);
    }
  };
  const handleModelChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = ev.target.value;
    if (systemModels) {
      const selected = systemModels.find(
        (model) => model.title === selectedModel,
      );
      setSelectedModel(selected);
    }
  };

  return (
    <div id="general-info-container">
      <p className="warning-text">
        All systems must be reviewed by FLO FAB once the systems is done by the
        rep for price adjustments
      </p>
      <div>
        <label className="form-label" htmlFor="system-selection">
          Number of Pumps:
        </label>
        <select
          id="system-selection"
          className="form-select"
          defaultValue={0}
          onChange={(ev) => setSelectedNbPump(Number(ev.target.value))}
        >
          {pumpOptions.map((option) => (
            <option
              className="form-select"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="form-label" htmlFor="system-type">
          System Type:
        </label>
        <select
          id="system-type"
          className="form-select"
          defaultValue={0}
          onChange={handleTypeChange}
        >
          {systemTypes &&
            systemTypes.map((type) => (
              <option key={type.id} value={type.desc}>
                {type.desc}
              </option>
            ))}
        </select>
      </div>
      <div>
        <label className="form-label" htmlFor="system-model">
          System Model:
        </label>
        <select
          id="system-model"
          className="form-select"
          defaultValue={0}
          onChange={handleModelChange}
        >
          {systemModels &&
            systemModels.map((model) => (
              <option key={model.stid} value={model.title}>
                {model.title}
              </option>
            ))}
        </select>
      </div>
      <div>
        <label className="form-label" htmlFor="system-lang">
          Language:
        </label>
        <select
          id="system-lang"
          className="form-select"
          defaultValue={1}
          onChange={(ev) => setSelectedLang(Number(ev.target.value))}
        >
          <option key={1} value={1}>
            English
          </option>
          <option key={2} value={2}>
            French
          </option>
        </select>
      </div>
      <div>
        <label className="form-label" htmlFor="lb-system-description">
          System Description: (Edit this description to reflect what you want on
          this package. Exemple: Items supplied by others)
        </label>
        <textarea
          id="system-description"
          className="system-description"
          defaultValue={
            selectedLang === 1 ? selectedModel?.descE : selectedModel?.descF
          }
          // onChange={(ev) => handleNoteChange(ev.target.value)}
        />
      </div>
      <SystemRequirements />
    </div>
  );
  function loadSystemTypes() {
    if (selectedNbPump) {
      bSystemService
        .getBoosterSystemTypes(selectedNbPump)
        .then((data) => {
          setSystemTypes(data);
        })
        .catch((error) => {
          toast.error(t('add-booster-system-page.error-get-types'), error);
        });
    }
  }
  function loadSystemModels() {
    if (selectedNbPump && selectedType) {
      bSystemService
        .getBoosterSystemModels(selectedNbPump, selectedType.id)
        .then((data) => {
          console.log(data);
          setSystemModels(data);
        })
        .catch((error) => {
          toast.error(t('add-booster-system-page.error-get-models'), error);
        });
    }
  }
}

export const SystemRequirements = () => {
  return (
    <div className="requirements-container">
      <h2>What we need?</h2>
      <ul>
        <li>1.1 System PID</li>
        <li>1.2 Pumps, Tanks and Heat Exchangers Schedule</li>
        <li>
          1.3 Cut sheets for non Flo Fab parts like
          <ul>
            <li>ex. Boilers, Chillers, etc...</li>
          </ul>
        </li>
        <li>1.4 Skids footprint</li>
        <li>1.5 Mechanical room footprint</li>
        <li>1.6 Details sequence of control</li>
      </ul>
      <p className="warning-text">
        *Please add those informations in the Dropbox
      </p>
    </div>
  );
};
