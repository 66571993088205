import './non-conformity-report-form.scss';
import react from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  faCheck,
  faFloppyDisk,
  faPrint,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import type { Client } from '../../services/Users/Client';
import { Page } from '../../components/Pages/page-components';
import stringHelper from '../../services/Core/Helpers/string-helper';
import LogoComponent from '../../components/app/LogoComponent';
import userService from '../../services/Users/UserService';
import type { NonConformityReport } from '../../services/NonConformity/Models/NonConformityReport';
import nonConformityReportService from '../../services/NonConformity/NonConformityReportService';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import type { QuoteExternalFile } from '../../services/Quotes/Models/QuoteExternalFile';
import Popup from '../../components/Core/Popup';

type IParamTypes = {
  employeeId: string;
  reportId: string;
};

function NonConformityReportForm() {
  const GestionnaireRole = 2;
  const { employeeId, reportId } = useParams<IParamTypes>();
  const [isCreateChildNRCPopupOpen, setIsCreateChildNRCPopupOpen] =
    react.useState<boolean>(false);
  const [employee, setEmployee] = react.useState<Client>();
  const navigate = useNavigate();
  const [nonConformityReport, setNonConformityReport] =
    react.useState<NonConformityReport>();
  const [validated, setValidated] = react.useState(false);

  const { t } = useTranslation();

  const savePhoto = react.useMemo(
    () =>
      async function savePhoto(reportId: string) {
        if (nonConformityReport?.photoFile)
          await nonConformityReportService
            .uploadPhoto(nonConformityReport?.photoFile, reportId)
            .catch(() => {
              toast.error(t('non-conformity-report-page.post-image-error'));
            });
        else if (!nonConformityReport?.photoSrc)
          await nonConformityReportService.deletePhoto(reportId).catch(() => {
            toast.error(t('non-conformity-report-page.post-image-error'));
          });
      },
    [nonConformityReport?.photoFile, nonConformityReport?.photoSrc, t],
  );
  const loadNonConformityReport = react.useMemo(
    () =>
      function loadNonConformityReport() {
        if (reportId)
          nonConformityReportService
            .getNonConformityReport(reportId)
            .then((res) => {
              setNonConformityReport(res);
            })
            .catch(() => {
              toast.error(
                t(
                  'non-conformity-report-page.get-non-conformity-report-page-error',
                ),
              );
            });
      },
    [reportId, t],
  );
  const loadUser = react.useMemo(
    () =>
      function loadUser(employeeId: string) {
        userService
          .getUser(employeeId)
          .then((data) => {
            if (data) {
              setEmployee(data);
            }
          })
          .catch(() => {
            toast.error(t('user-form-page.load-error'));
          });
      },
    [t],
  );
  const ValidateNonConformityReport = async (isValid: boolean) => {
    if (nonConformityReport && employeeId) {
      nonConformityReport.isValid = isValid;
      await nonConformityReportService
        .validateNonConformityReport(nonConformityReport, employeeId)
        .then((res: any) => {
          if (reportId) {
            // updating
            savePhoto(reportId).then(() => loadNonConformityReport());
          } else if (res) {
            // creating
            savePhoto(res);
            navigate(`../non-conformity-report-form/${employeeId}/${res}`);
          }
          toast.success(t('non-conformity-report-page.success-validating'));
        })
        .catch(() => {
          toast.error(t(`non-conformity-report-page.error-validating`));
        });
    }
  };
  const handleSubmit = (event: react.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === true) {
      ValidateNonConformityReport(true);
    } else setValidated(true);
  };
  react.useEffect(() => {
    if (reportId && employeeId) {
      loadNonConformityReport();
    }
  }, [reportId, employeeId, loadNonConformityReport]);

  react.useEffect(() => {
    if (employeeId) {
      loadUser(employeeId);
    }
  }, [employeeId, loadUser]);

  return (
    <Page id="non-conformity-report-form" className="d-flex flex-column">
      <div id="print-title" className="only-print">
        <LogoComponent />
        <h2>
          {nonConformityReport?.isClientComplain
            ? t('non-conformity-report-page.client-complaint')
            : t('non-conformity-report-page.non-conformity-report')}
        </h2>
      </div>
      <CreateChildNRCPopup
        nonConformityReport={nonConformityReport}
        isOpen={isCreateChildNRCPopupOpen}
        closeAction={() => setIsCreateChildNRCPopupOpen(false)}
        employeeId={employeeId}
      />
      <BreadcrumbComponent
        items={[
          {
            text: t(
              'non-conformity-report-page.record-of-non-conformity-reports',
            ),
            link: `/non-conformity-report-table/${employeeId}`,
          },
          {
            text: t('non-conformity-report-page.non-conformity-report'),
            active: true,
          },
        ]}
      />

      <div className="card mt-2">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="card-header">
            <h3>
              {t('non-conformity-report-page.non-conformity-report')}
              {nonConformityReport?.isValid !== undefined &&
                nonConformityReport.isValid !== null &&
                (nonConformityReport.isValid ? (
                  <span style={{ color: 'green', marginInlineStart: 5 }}>
                    {t('non-conformity-report-page.validated')}
                  </span>
                ) : (
                  <span style={{ color: 'red', marginInlineStart: 5 }}>
                    {t('non-conformity-report-page.rejected')}
                    {nonConformityReport.childId ? (
                      <a
                        className="child-report-link"
                        href={`./${nonConformityReport.childId}`}
                      >
                        {`(${t('common.new')}:${nonConformityReport.childId})`}
                      </a>
                    ) : (
                      <></>
                    )}
                  </span>
                ))}
            </h3>
            <Row className="info-container">
              <Col>
                {t('non-conformity-report-page.report-number')} :{' '}
                {nonConformityReport?.id
                  ? nonConformityReport.id
                  : t('non-conformity-report-page.(new-report)')}
              </Col>
              <Col>
                {t('non-conformity-report-page.creation-date')} :{' '}
                {nonConformityReport?.creationDate
                  ? stringHelper.toDateString(nonConformityReport.creationDate)
                  : stringHelper.toDateString(new Date())}
              </Col>
              {nonConformityReport?.quoteId && (
                <Col>Quote Id : {nonConformityReport.quoteId}</Col>
              )}
              {nonConformityReport?.orderId && (
                <Col>
                  Job Number :{' '}
                  {`${nonConformityReport.orderId}-${nonConformityReport.kitId}`}
                </Col>
              )}
              {nonConformityReport?.parentId && (
                <Col>
                  <a href={`./${nonConformityReport.parentId}`}>
                    Parent : {nonConformityReport.parentId}
                  </a>
                </Col>
              )}
            </Row>
          </div>

          <div className="card-body">
            <div className="quoteInformationContainer">
              <Row className="no-print">
                <Col md={12}>
                  <h5 className="d-flex justify-content-center clientComplainInput">
                    <ValidInvalidFormCheck
                      value={!nonConformityReport?.isClientComplain}
                      setValue={(newValue) => {
                        setValue('isClientComplain', !newValue);
                      }}
                      trueLabel={t(
                        'non-conformity-report-page.non-conformity-report',
                      )}
                      falseLabel={t(
                        'non-conformity-report-page.client-complaint',
                      )}
                      id="isClientComplainCheck"
                    ></ValidInvalidFormCheck>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <SelectQuoteExternalFile
                    nonConformityReport={nonConformityReport}
                    setNonConformityReport={setNonConformityReport}
                  ></SelectQuoteExternalFile>
                </Col>
                <Col sm={6}>
                  <InputGroup
                    hasValidation
                    id="Provenance"
                    className="assemblyTypeContainer"
                  >
                    <b>{t('non-conformity-report-page.provenance')}:</b>
                    <div className="check-container">
                      <Form.Check
                        id="isProvenanceClient"
                        reverse
                        checked={nonConformityReport?.isProvenanceClient}
                        label={t('non-conformity-report-page.client')}
                        onChange={() => {
                          setValue(
                            'isProvenanceClient',
                            !nonConformityReport?.isProvenanceClient,
                          );
                        }}
                      />
                      <Form.Check
                        id="isProvenanceInternal"
                        reverse
                        checked={nonConformityReport?.isProvenanceInternal}
                        label={t('non-conformity-report-page.internal')}
                        onChange={() => {
                          setValue(
                            'isProvenanceInternal',
                            !nonConformityReport?.isProvenanceInternal,
                          );
                        }}
                      />
                      <Form.Check
                        id="isProvenanceSupplier"
                        reverse
                        checked={nonConformityReport?.isProvenanceSupplier}
                        label={t('non-conformity-report-page.supplier')}
                        name="assemblyType"
                        onChange={() => {
                          setValue(
                            'isProvenanceSupplier',
                            !nonConformityReport?.isProvenanceSupplier,
                          );
                        }}
                      />
                    </div>
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <InputGroup
                    hasValidation
                    id="Cause"
                    className="assemblyTypeContainer"
                  >
                    <b>{t('non-conformity-report-page.responsibility')}:</b>
                    <span className="check-container">
                      <Form.Check
                        id="isEmployeeResponsibility"
                        reverse
                        checked={nonConformityReport?.isEmployeeResponsibility}
                        label={t('non-conformity-report-page.employee')}
                        onChange={() => {
                          setValue(
                            'isEmployeeResponsibility',
                            !nonConformityReport?.isEmployeeResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isClientResponsibility"
                        reverse
                        checked={nonConformityReport?.isClientResponsibility}
                        label={t('non-conformity-report-page.client')}
                        onChange={() => {
                          setValue(
                            'isClientResponsibility',
                            !nonConformityReport?.isClientResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isOtherResponsibility"
                        reverse
                        checked={nonConformityReport?.isOtherResponsibility}
                        label={t('non-conformity-report-page.other')}
                        onChange={() => {
                          setValue(
                            'isOtherResponsibility',
                            !nonConformityReport?.isOtherResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isAdminResponsibility"
                        reverse
                        checked={nonConformityReport?.isAdminResponsibility}
                        label={t('non-conformity-report-page.admin')}
                        onChange={() => {
                          setValue(
                            'isAdminResponsibility',
                            !nonConformityReport?.isAdminResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isProductionResponsibility"
                        reverse
                        checked={
                          nonConformityReport?.isProductionResponsibility
                        }
                        label={t('non-conformity-report-page.production')}
                        onChange={() => {
                          setValue(
                            'isProductionResponsibility',
                            !nonConformityReport?.isProductionResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isConceptionResponsibility"
                        reverse
                        checked={
                          nonConformityReport?.isConceptionResponsibility
                        }
                        label={t('non-conformity-report-page.conception')}
                        onChange={() => {
                          setValue(
                            'isConceptionResponsibility',
                            !nonConformityReport?.isConceptionResponsibility,
                          );
                        }}
                      />
                      <Form.Check
                        id="isSupplierResponsibility"
                        reverse
                        checked={nonConformityReport?.isSupplierResponsibility}
                        label={t('non-conformity-report-page.supplier')}
                        onChange={() => {
                          setValue(
                            'isSupplierResponsibility',
                            !nonConformityReport?.isSupplierResponsibility,
                          );
                        }}
                      />
                    </span>
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <Row id="generalInformations" className="operationContainer">
              <Col md={12} className="textInputContainer">
                <div className="selectInputContainer">
                  <label htmlFor="textInputContainer">
                    <b>{t('non-conformity-report-page.general-information')}</b>
                  </label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="generalInformation"
                      maxLength={2000}
                      as="textarea"
                      rows={2}
                      required
                      defaultValue={nonConformityReport?.generalInformation}
                      onChange={(ev) => {
                        setValue('generalInformation', ev.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col md={12} className="textInputContainer">
                <div className="selectInputContainer">
                  <label htmlFor="textInputContainer">
                    <b>{t('non-conformity-report-page.description')}</b>
                  </label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="description"
                      maxLength={2000}
                      as="textarea"
                      rows={2}
                      required
                      defaultValue={nonConformityReport?.description}
                      onChange={(ev) => {
                        setValue('description', ev.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
            </Row>

            <div id="cause" className="fieldset-container">
              <fieldset>
                <legend>{t('non-conformity-report-page.cause')}</legend>
                <Row className="operationContainer m-1">
                  <Col md={12}>
                    <InputGroup className="check-container">
                      <Form.Check
                        id="isCauseWorker"
                        reverse
                        checked={nonConformityReport?.isCauseWorker}
                        label={t('non-conformity-report-page.worker')}
                        onChange={() => {
                          setValue(
                            'isCauseWorker',
                            !nonConformityReport?.isCauseWorker,
                          );
                        }}
                      />

                      <Form.Check
                        id="isCauseMethod"
                        reverse
                        checked={nonConformityReport?.isCauseMethod}
                        label={t('non-conformity-report-page.method')}
                        onChange={() => {
                          setValue(
                            'isCauseMethod',
                            !nonConformityReport?.isCauseMethod,
                          );
                        }}
                      />
                      <Form.Check
                        id="isCauseMaterial"
                        reverse
                        checked={nonConformityReport?.isCauseMaterial}
                        label={t('non-conformity-report-page.material')}
                        onChange={() => {
                          setValue(
                            'isCauseMaterial',
                            !nonConformityReport?.isCauseMaterial,
                          );
                        }}
                      />
                      <Form.Check
                        id="isCauseEngine"
                        reverse
                        checked={nonConformityReport?.isCauseEngine}
                        label={t('non-conformity-report-page.engine')}
                        onChange={() => {
                          setValue(
                            'isCauseEngine',
                            !nonConformityReport?.isCauseEngine,
                          );
                        }}
                      />
                      <Form.Check
                        id="isCausePlace"
                        reverse
                        checked={nonConformityReport?.isCausePlace}
                        label={t('non-conformity-report-page.place')}
                        onChange={() => {
                          setValue(
                            'isCausePlace',
                            !nonConformityReport?.isCausePlace,
                          );
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12} className="textInputContainer">
                    <div className="d-flex flex-column align-items-start">
                      <label htmlFor="causeExplication">
                        <b>{t('non-conformity-report-page.explain')}</b>
                      </label>
                      <InputGroup hasValidation>
                        <Form.Control
                          id="causeExplication"
                          as="textarea"
                          rows={2}
                          defaultValue={nonConformityReport?.causeExplication}
                          onChange={(ev) => {
                            setValue('causeExplication', ev.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
              </fieldset>
            </div>

            <div id="immediateTreatment" className="fieldset-container">
              <fieldset>
                <legend>
                  {t('non-conformity-report-page.immediate-treatment')}
                </legend>
                <div className="m-1">
                  <InputGroup
                    hasValidation
                    className="assemblyTypeContainer check-container"
                  >
                    <Form.Check
                      id="immediateTreatmentUseAsIs"
                      required
                      checked={
                        nonConformityReport?.immediateTreatment === 'use as is'
                      }
                      type="radio"
                      name="immediateTreatment"
                      label={t('non-conformity-report-page.use-as-is')}
                      value="use as is"
                      onChange={(ev) => {
                        setValue('immediateTreatment', ev.target.value);
                      }}
                    />
                    <Form.Check
                      id="immediateTreatmentReturnToSupplier"
                      required
                      checked={
                        nonConformityReport?.immediateTreatment ===
                        'return to supplier'
                      }
                      type="radio"
                      name="immediateTreatment"
                      label={t('non-conformity-report-page.return-to-supplier')}
                      value="return to supplier"
                      onChange={(ev) => {
                        setValue('immediateTreatment', ev.target.value);
                      }}
                    />
                    <Form.Check
                      id="immediateTreatmentScrap"
                      required
                      checked={
                        nonConformityReport?.immediateTreatment === 'scrap'
                      }
                      type="radio"
                      name="immediateTreatment"
                      label={t('non-conformity-report-page.scrap')}
                      value="scrap"
                      onChange={(ev) => {
                        setValue('immediateTreatment', ev.target.value);
                      }}
                    />
                    <Form.Check
                      id="immediateTreatmentRework"
                      required
                      checked={
                        nonConformityReport?.immediateTreatment === 'rework'
                      }
                      type="radio"
                      name="immediateTreatment"
                      label={t('non-conformity-report-page.rework')}
                      value="rework"
                      onChange={(ev) => {
                        setValue('immediateTreatment', ev.target.value);
                      }}
                    />
                    <InputGroup className="justify-content-center">
                      <Form.Check
                        id="immediateTreatmentOther"
                        required
                        checked={
                          nonConformityReport?.immediateTreatment === 'other'
                        }
                        type="radio"
                        name="immediateTreatment"
                        label={t('non-conformity-report-page.other')}
                        value="other"
                        onChange={(ev) => {
                          setValue('immediateTreatment', ev.target.value);
                        }}
                      />
                    </InputGroup>
                    <label className="mr-3">
                      <b>{t('non-conformity-report-page.explain')}:</b>
                    </label>
                    <Form.Control
                      id="otherImmediateTreatmentDescription"
                      as="textarea"
                      rows={1}
                      required
                      defaultValue={
                        nonConformityReport?.immediateTreatmentDescription
                      }
                      onChange={(ev) => {
                        setValue(
                          'immediateTreatmentDescription',
                          ev.target.value,
                        );
                      }}
                    />
                  </InputGroup>
                  <Col md={12}>
                    <div className="d-flex justify-content-start">
                      <b className="mr-2">
                        {t(
                          'non-conformity-report-page.verification-of-treatment',
                        )}
                      </b>
                      <span>
                        {t('non-conformity-report-page.(if-required)')}
                      </span>
                    </div>
                    <ValidInvalidFormCheck
                      value={nonConformityReport?.verifyImmediateTreatment}
                      setValue={(newValue) => {
                        setValue('verifyImmediateTreatment', newValue);
                      }}
                      trueLabel={t('non-conformity-report-page.conform')}
                      falseLabel={t('non-conformity-report-page.not-conform')}
                      id="verifyImmediateTreatmentCheck"
                    ></ValidInvalidFormCheck>
                  </Col>
                </div>
              </fieldset>
            </div>
            <div id="photo" className="fieldset-container no-print">
              <fieldset>
                <legend>
                  {t('non-conformity-report-page.photo-of-error')}
                </legend>
                <div className="m-1">
                  <PhotoForm
                    nonConformityReport={nonConformityReport}
                    setNonConformityReport={setNonConformityReport}
                  ></PhotoForm>
                </div>
              </fieldset>
            </div>
            <Row className="mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-start">
                  <b className="mr-2">
                    {t(
                      'non-conformity-report-page.is-effect-real-or-potential',
                    )}
                  </b>
                  <span>
                    {t(
                      'non-conformity-report-page.(indicate-that-a-potential-problem-can-happen-again)',
                    )}
                  </span>
                </div>
                <ValidInvalidFormCheck
                  value={nonConformityReport?.isEffectRealOrPotential}
                  setValue={(newValue) => {
                    setValue('isEffectRealOrPotential', newValue);
                  }}
                  trueLabel={t('common.yes')}
                  falseLabel={t('common.no')}
                  id="isEffectRealOrPotentialCheck"
                ></ValidInvalidFormCheck>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={12}>
                <InputGroup>
                  <label className="mr-2">
                    <b>
                      {t(
                        'non-conformity-report-page.cost-associated-with-the-non-conformity',
                      )}{' '}
                      :
                    </b>
                  </label>
                  <Form.Control
                    id="costAssociatedWithTheComplain"
                    as="textarea"
                    rows={1}
                    required
                    defaultValue={
                      nonConformityReport?.costAssociatedWithTheComplain
                    }
                    onChange={(ev) => {
                      setValue(
                        'costAssociatedWithTheComplain',
                        ev.target.value,
                      );
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <InputGroup>
                  <label>
                    <b>{t('non-conformity-report-page.corrective-action')} :</b>
                  </label>
                  <ValidInvalidFormCheck
                    value={nonConformityReport?.isCorrectiveAction}
                    setValue={(newValue) => {
                      setValue('isCorrectiveAction', newValue);
                    }}
                    trueLabel={t('common.yes')}
                    falseLabel={t('common.no')}
                    id="isCorrectiveActionCheck"
                  ></ValidInvalidFormCheck>
                </InputGroup>
              </Col>
              <Col md={7}>
                <InputGroup>
                  <label className="mr-2">
                    <b>
                      {t('non-conformity-report-page.corrective-action-number')}{' '}
                      :
                    </b>
                  </label>
                  <Form.Control
                    id="correctiveActionNumber"
                    required
                    defaultValue={nonConformityReport?.correctiveActionNumber}
                    onChange={(ev) => {
                      setValue('correctiveActionNumber', ev.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <div className="card mt-2">
              <div className="card-body">
                <Row>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t('non-conformity-report-page.technician')}</label>
                    {(nonConformityReport?.technicianUserName ?? '') +
                      (employee?.nom === nonConformityReport?.technicianUserName
                        ? ''
                        : t(
                            'non-conformity-report-page.save-to-become-tester',
                            {
                              technicianName: employee?.nom,
                            },
                          ))}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t('non-conformity-report-page.supervisor')}</label>
                    {nonConformityReport?.supervisorUserName ??
                      (employee?.roleId && employee?.roleId <= GestionnaireRole
                        ? employee.nom +
                          t(`non-conformity-report-page.close-to-confirm`)
                        : t(
                            'non-conformity-report-page.supervisor-must-validate',
                          ))}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>
                      {t('non-conformity-report-page.creation-date')}
                    </label>
                    <InputGroup hasValidation>
                      <Form.Control
                        id="date"
                        aria-label="Date and time"
                        type="date"
                        className="input"
                        required
                        value={
                          nonConformityReport?.creationDate
                            ? stringHelper.toDateString(
                                nonConformityReport.creationDate,
                              )
                            : ''
                        }
                        size="sm"
                        onChange={(ev) => {
                          setValue(
                            'creationDate',
                            new Date(`${ev.target.value}T00:00:00`),
                          );
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={6} className="d-flex justify-content-between">
                    <label>{t('non-conformity-report-page.date-tested')}</label>
                    <InputGroup hasValidation>
                      <Form.Control
                        id="date"
                        aria-label="Date and time"
                        type="date"
                        className="input"
                        required
                        value={
                          nonConformityReport?.closeDate
                            ? stringHelper.toDateString(
                                nonConformityReport.closeDate,
                              )
                            : ''
                        }
                        size="sm"
                        onChange={(ev) => {
                          setValue(
                            'closeDate',
                            new Date(`${ev.target.value}T00:00:00`),
                          );
                        }}
                      />
                    </InputGroup>
                    {/*

                    {isFinish
                      ? stringHelper.toDateString(
                          nonConformityReport?.closeDate,
                        )
                      : employee?.roleId && employee?.roleId <= GestionnaireRole
                      ? stringHelper.toDateString(new Date()) +
                        t("non-conformity-report-page.close-to-confirm")
                      : t(
                          "non-conformity-report-page.supervisor-must-validate"
                        )}

                        DO NOT DELETE, WILL BE REUSED
                  */}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="no-print">
              <div className="d-flex text-center mt-3">
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => window.print()}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faPrint}
                    />
                    {t('common.print')}
                  </button>
                </div>
                <div className="flex-fill">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (nonConformityReport && employeeId) {
                        nonConformityReportService
                          .saveNonConformityReport(
                            nonConformityReport,
                            employeeId,
                          )
                          .then((res: any) => {
                            const tempReportId = reportId ?? res ?? undefined;
                            if (tempReportId) {
                              // if created return a ID
                              savePhoto(tempReportId);
                              navigate(
                                `../non-conformity-report-form/${employeeId}/${tempReportId}`,
                              );
                            }

                            toast.success(
                              t('non-conformity-report-page.success-saving'),
                            );
                          })
                          .catch(() =>
                            toast.error(
                              t('non-conformity-report-page.error-saving'),
                            ),
                          );
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      className="me-2"
                      icon={faFloppyDisk}
                    />
                    {t('common.save')}
                  </button>
                </div>
                {!nonConformityReport?.supervisorId &&
                  employee?.roleId &&
                  employee?.roleId <= GestionnaireRole && (
                    <>
                      <div className="flex-fill">
                        <button
                          className="btn btn-outline-success"
                          type="submit"
                        >
                          <FontAwesomeIcon
                            size="xl"
                            className="me-2"
                            icon={faCheck}
                          />
                          {t('non-conformity-report-page.validate')}
                        </button>
                      </div>
                      <div className="flex-fill">
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          onClick={() =>
                            ValidateNonConformityReport(false).then(() =>
                              setIsCreateChildNRCPopupOpen(true),
                            )
                          }
                        >
                          <FontAwesomeIcon
                            size="xl"
                            className="me-2"
                            icon={faX}
                          />
                          {t('non-conformity-report-page.reject')}
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="footnote">
        <Row>
          <Col xs={3}>No. : F-09.1</Col>
          <Col xs={3}>{t('non-conformity-report-page.revision')} : 1</Col>
        </Row>
      </div>
      {nonConformityReport?.photoSrc && (
        <img
          title="profil Image"
          className="only-print"
          alt={`default`}
          src={nonConformityReport?.photoSrc}
        ></img>
      )}
    </Page>
  );

  function setValue(key: string, value: any) {
    setNonConformityReport({
      ...nonConformityReport,
      [key]: value,
    } as NonConformityReport);
  }
}
type ValidInvalidFormCheckComponentsProps = {
  value: boolean | undefined;
  setValue: (newValue: boolean | undefined) => void;
  trueLabel: string;
  falseLabel: string;
  id: string;
};
function ValidInvalidFormCheck({
  value,
  setValue,
  trueLabel,
  falseLabel,
  id,
}: ValidInvalidFormCheckComponentsProps) {
  return (
    <div className="validInvalidCheckboxContainer check-container">
      <InputGroup className="check-container">
        <Form.Check
          id={`${id}-true`}
          type="radio"
          className="mx-1"
          label={<label htmlFor={`${id}-true`}>{trueLabel}</label>}
          checked={value === true}
          onChange={() => {
            const newValue = value ? undefined : true;
            setValue(newValue);
          }}
        />

        <Form.Check
          id={`${id}-false`}
          type="radio"
          reverse
          className="mx-1"
          label={<label htmlFor={`${id}-false`}>{falseLabel}</label>}
          checked={value === false}
          onChange={() => {
            const newValue = value === false ? undefined : false;
            setValue(newValue);
          }}
        />
      </InputGroup>
    </div>
  );
}
type Props = {
  nonConformityReport: NonConformityReport | undefined;
  setNonConformityReport: react.Dispatch<
    react.SetStateAction<NonConformityReport | undefined>
  >;
};
function PhotoForm({ nonConformityReport, setNonConformityReport }: Props) {
  const [errorMessage, setErrorMessage] = react.useState('');
  const inputRef = react.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const defaultImageSrc = '/images/Default/default_image.jpg';
  const showPreview = (ev: react.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      const imageFile = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        const img = new Image();

        img.onload = () => {
          const limitSize = 2097152;
          const limitWidthHeight = 400;
          let errorMessage = '';
          if (imageFile.size > limitSize) {
            errorMessage = t('non-conformity-report-page.image-size-too-big');
          }
          if (img.width < limitWidthHeight) {
            errorMessage = t(
              'non-conformity-report-page.image-width-too-small',
            );
          }
          if (img.height < limitWidthHeight) {
            errorMessage = t(
              'non-conformity-report-page.image-height-too-small',
            );
          }
          setErrorMessage(errorMessage);
          if (!errorMessage)
            setNonConformityReport({
              ...nonConformityReport,
              photoFile: imageFile,
              photoSrc: x.target?.result,
            } as NonConformityReport);
        };
        if (typeof x.target?.result === 'string') img.src = x.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  };
  const deleteProfileImage = () => {
    if (nonConformityReport && nonConformityReport.photoSrc)
      setNonConformityReport({
        ...nonConformityReport,
        photoFile: undefined,
        photoSrc: undefined,
      } as NonConformityReport);
  };
  return (
    <Row>
      <Col md={6} className="d-flex justify-content-center">
        <img
          className="avatar-bg"
          title="profil Image"
          alt={`default`}
          src={`${
            nonConformityReport?.photoSrc
              ? nonConformityReport.photoSrc
              : defaultImageSrc
          }`}
        ></img>
        <input
          onChange={showPreview}
          ref={inputRef}
          accept="image/*"
          type="file"
          className="d-none"
          id="getAvatar"
        />
        <span className="mx-3 d-flex flex-column justify-content-evenly">
          <Button
            variant="outline-primary"
            onClick={() => inputRef.current?.click()}
          >
            {t('profile.Upload-Image')}
          </Button>
          {nonConformityReport?.photoSrc && (
            <Button
              onClick={deleteProfileImage}
              id="remove-photo-button"
              variant="outline-danger"
              size="sm"
            >
              {t('profile.remove-image')}
            </Button>
          )}
        </span>
      </Col>
      <Col md={6} id="photo-requirment" className="d-flex">
        <div>
          <h5>{t('profile.image-requirments')}</h5>
          <ol className="mb-0">
            <li>{t('profile.photo-min-dimension')}</li>
            <li>{t('profile.photo-max-size')}</li>
          </ol>
        </div>
      </Col>
      {errorMessage && (
        <div className="alert alert-danger mb-0">{errorMessage}</div>
      )}
    </Row>
  );
}
function SelectQuoteExternalFile({
  nonConformityReport,
  setNonConformityReport,
}: Props) {
  const minOrderId = 9999;
  const [optionKit, setoptionKit] = react.useState<Array<any>>();
  const { t } = useTranslation();

  react.useEffect(() => {
    if (
      nonConformityReport?.orderId &&
      nonConformityReport.orderId > minOrderId
    )
      nonConformityReportService
        .getAllQuoteExternalFileOfOrder(nonConformityReport.orderId)
        .then((listQuoteExternalFile) => {
          if (listQuoteExternalFile !== undefined) {
            setoptionKit(
              listQuoteExternalFile.map(
                (quoteExternalFile: QuoteExternalFile) => ({
                  value: quoteExternalFile,
                  label: quoteExternalFile.kitId,
                }),
              ),
            );
          }
        })
        .catch(() =>
          toast.error(
            t('non-conformity-report-page.get-quote-external-file-error'),
          ),
        );
  }, [nonConformityReport?.orderId, t]);
  return (
    <InputGroup>
      <Col md={8} className="d-flex job-container">
        <label className="mr-2">
          <b>{t('non-conformity-report-page.job')}:</b>
        </label>
        <Form.Control
          id="costAssociatedWithTheComplain"
          type="number"
          defaultValue={nonConformityReport?.orderId}
          onChange={(ev) => {
            setNonConformityReport({
              ...nonConformityReport,
              orderId: parseInt(ev.target.value, 10),
            } as NonConformityReport);
          }}
        />
      </Col>
      <Col md={4} className="d-flex">
        <label className="mx-2">
          <h4>-</h4>
        </label>
        {optionKit?.length ? (
          <Select
            className="react-select-container w-100"
            classNamePrefix={`${nonConformityReport?.quoteExternalFileId ? 'valid' : 'invalid'} react-select`}
            value={optionKit.find(
              (x) => x.value.id === nonConformityReport?.quoteExternalFileId,
            )}
            options={optionKit}
            onChange={(val) => {
              setNonConformityReport({
                ...nonConformityReport,
                quoteExternalFileId: val.value.id,
                orderId: val.value.orderId,
                kitId: val.value.kitId,
                quoteId: val.value.quoteId,
              } as NonConformityReport);
            }}
          />
        ) : (
          <Select isDisabled={true} className="w-100"></Select>
        )}
      </Col>
    </InputGroup>
  );
}
type CreateChildNRCProps = {
  employeeId: string | undefined;
  nonConformityReport: NonConformityReport | undefined;
  isOpen: boolean;
  closeAction: () => void;
};
export function CreateChildNRCPopup({
  employeeId,
  nonConformityReport,
  isOpen,
  closeAction,
}: CreateChildNRCProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createChildNRC = () => {
    const newNonConformityReport = {
      parentId: nonConformityReport?.id,
      quoteExternalFileId: nonConformityReport?.quoteExternalFileId,
    } as NonConformityReport;
    if (employeeId)
      nonConformityReportService
        .saveNonConformityReport(newNonConformityReport, employeeId)
        .then((res: any) => {
          if (res) {
            // if created return a ID
            navigate(`../non-conformity-report-form/${employeeId}/${res}`);
          }

          toast.success(t('non-conformity-report-page.success-saving'));
        })
        .catch(() => toast.error(t('non-conformity-report-page.error-saving')));
  };
  return (
    <Popup
      onClose={closeAction}
      onOk={() => {
        createChildNRC();
        closeAction();
      }}
      title={t('non-conformity-report-page.new-non-conformity-report')}
      show={isOpen}
      okLabel={t('common.create')}
      closeLabel={t('common.no')}
    >
      <p>
        {t(
          'non-conformity-report-page.non-conformity-report-successfuly-rejected',
        )}
      </p>
      <p>
        {t(
          'non-conformity-report-page.create-new-non-conformity-report-description',
        )}
      </p>
    </Popup>
  );
}
export default NonConformityReportForm;
