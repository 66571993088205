import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin } from '@react-oauth/google';
import type { FormEvent } from 'react';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { Page, PageDetails } from '../../components/Pages/page-components';
import {
  createSession,
  createSessionRememberMe,
} from '../../services/Login/loginFunction';
import loginService from '../../services/Login/LoginService';
import type { LoginCredential } from '../../services/Login/LoginService';
import type LoginGmail from '../../services/Login/LoginGmail';

type IParamTypes = {
  logoutMessage: string;
};
export const LoginPage = () => {
  const { logoutMessage } = useParams<IParamTypes>();
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const postLoginAction = (data: unknown) => {
    if (rememberMe) createSessionRememberMe(data as string);
    else createSession(data as string);

    window.location.reload();
  };
  const errorAction = (err: AxiosError) => {
    setValidated(false);
    if (err.response?.status === 401) {
      // console.log(err);
      setErrorMessage(`${t(`login-page.${err.response?.data}`)}`);
    } else toast.error(t(`login-page.login-error`));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const objects = Object.fromEntries(
        new FormData(event.currentTarget).entries(),
      );
      loginService
        .Login(objects as unknown as LoginCredential, rememberMe)
        .then(postLoginAction)
        .catch(errorAction);
    }
    setValidated(true);
  };
  useEffect(() => {
    if (logoutMessage) {
      toast.error(t(logoutMessage));
    }
  }, []);
  return (
    <>
      <Page id="login-page">
        <PageDetails>
          <div className="page-wrapper bg-blue bg-gra-02 font-poppins">
            <div className="wrapper">
              <div className="card card-4">
                <div className="card-body">
                  <h1 className="text-center">
                    {t('login-page.welcome-back')}
                  </h1>
                  <div className="text-center sm-mb--10 mb-5">
                    {t('login-page.login-continue')}
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    className="user-form"
                  >
                    <Form.Group className="mb-2">
                      <Form.Label>{t('user.nom')}</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          name="username"
                          placeholder={`${t('user.nom')}`}
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('login-page.please-enter') + t('user.nom')}.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>{t('user.password')}</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder={`${t('user.password')}`}
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                        <InputGroup.Text
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                          {t('login-page.please-enter') + t('user.password')}.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <Form.Group className="mb-3">
                        <Form.Check
                          name="isExtended"
                          label={t('login-page.remember-me')}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                      </Form.Group>
                      <a href="forgot-password">
                        {t('login-page.forgot-password')}
                      </a>
                    </div>
                    <div className="errorMessage text-center">
                      {errorMessage}
                    </div>
                    <Button
                      className="loginFormButton mb-2"
                      id="loginFormButton"
                      type="submit"
                    >
                      {t('login-page.submit-button')}
                    </Button>
                  </Form>
                  <hr />
                  <div className="text-center mb-2">
                    {t('login-page.or-google')}
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        loginService
                          .LoginGmail(
                            credentialResponse as LoginGmail,
                            rememberMe,
                          )
                          .then(postLoginAction)
                          .catch(errorAction);
                      }}
                      onError={() => {
                        toast.error('login-page.googleLogin-error');
                      }}
                      shape="pill"
                      logo_alignment="center"
                      text="continue_with"
                      size="large"
                      theme="filled_blue"
                    />
                  </div>
                  <hr />
                  <a className="d-flex justify-content-evenly" href="/register">
                    {t('login-page.register')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div />
        </PageDetails>
      </Page>
    </>
  );
};
