export default class PumpCouplingAlignmentSheet {
  // Sheet info
  id: number = 0;
  externalFileId: number = 0;
  serialNumber: string = '';
  sleeveSize: number = 0;
  angular: number = 0;
  parallel: number = 0;
  maxDiff: number = 0;
  frame: string = '';

  coup0P: number = 0;
  coup90P: number = 0;
  coup180P: number = 0;
  coup270P: number = 0;

  coup0A: number = 0;
  coup90A: number = 0;
  coup180A: number = 0;
  coup270A: number = 0;

  approvedBy: string = '';
  approvedById: number = 0;
  employee: string = '';
  employeeId: number = 0;

  updated: Date = new Date();
  created: Date = new Date(Date.now());
  finished: boolean = false;
  isAdmin: boolean = false;

  couplingAlignmentSheet: CouplingAlignmentSheetInfo =
    new CouplingAlignmentSheetInfo();
}

export class CouplingAlignmentSheetInfo {
  // Job Info
  quoteId: number = 0;
  client: string = '';
  job: string = '';

  // Pump Info
  pumpModel: string = '';
  pressureTDH: number = 0;
  pressurePSI: number = 0;
  flow: number = 0;
  diam: number = 0;
  hp: number = 0;
  rpm: number = 0;
  phase: string = '';
  tag: string = '';
}
