export class QuoteDownloadStatus {
  id?: number;
  quoteId: number;
  dlStatusId: number;

  constructor(data: any) {
    this.id = data.id ?? '';
    this.quoteId = data.quoteId;
    this.dlStatusId = data.dlStatusId;
  }
}

export class QuoteDownloadStatusTypes {
  id: number;
  name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}
