export class PumpType {
  id: number;
  idPumpType: number;
  name: string;
  pumpTypeName?: string;
  markup?: number;
  submersible: boolean;
  drawing?: string;
  englishSeriesNote?: string;
  frenchSeriesNote?: string;
  devis?: string;
  disposition?: number;
  frameMount?: boolean;
  showEfficiency: boolean;
  showNpshr: boolean;
  includesMotor: boolean;
  fkMasterPumpSecondType?: number;
  avail_150lbs: boolean;
  avail_250lbs: boolean;
  avail_10pins: boolean;
  avail_16pins: boolean;
  pumpSearch?: boolean;

  constructor(data: any) {
    if (!data.id) throw new ReferenceError('id undefined');
    if (!data.idPumpType) throw new ReferenceError('pump type id undefined');
    if (!data.name) throw new ReferenceError('name undefined');
    if (data.submersible == null)
      throw new ReferenceError('submersible undefined');
    if (data.showEfficiency == null)
      throw new ReferenceError('show Efficiency undefined');
    if (data.showNpshr == null)
      throw new ReferenceError('show Npshr undefined');
    if (data.includesMotor == null)
      throw new ReferenceError('includes motor undefined');
    if (data.avail_150lbs == null)
      throw new ReferenceError('available 150lbs undefined');
    if (data.avail_250lbs == null)
      throw new ReferenceError('available 250lbs undefined');
    if (data.avail_10pins == null)
      throw new ReferenceError('available 10pins undefined');
    if (data.avail_16pins == null)
      throw new ReferenceError('available 16pins undefined');

    this.id = data.id;
    this.idPumpType = data.idPumpType;
    this.name = data.name;
    this.pumpTypeName = data.pumpTypeName;
    this.markup = data.markup;
    this.submersible = data.submersible;
    this.drawing = data.drawing;
    this.englishSeriesNote = data.englishSeriesNote;
    this.frenchSeriesNote = data.frenchSeriesNote;
    this.devis = data.devis;
    this.disposition = data.disposition;
    this.frameMount = data.frameMount;
    this.showEfficiency = data.showEfficiency;
    this.showNpshr = data.showNpshr;
    this.includesMotor = data.includesMotor;
    // eslint-disable-next-line no-underscore-dangle
    this.fkMasterPumpSecondType = data._fkMasterPumpSecondType;
    this.avail_150lbs = data.avail_150lbs;
    this.avail_250lbs = data.avail_250lbs;
    this.avail_10pins = data.avail_10pins;
    this.avail_16pins = data.avail_16pins;
    this.pumpSearch = data.pumpSearch;
  }
}
