import './login.scss';
import type { FormEvent } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Page, PageDetails } from '../../components/Pages/page-components';
import stringHelper from '../../services/Core/Helpers/string-helper';
import loginService from '../../services/Login/LoginService';
import type LoginGmail from '../../services/Login/LoginGmail';
import type { Client } from '../../services/Users/Client';
import { GetProvinceOptions } from '../Users/user-form-components';

function RegisterPage() {
  const [validated, setValidated] = useState(false);
  const [validatedGmail, setValidatedGmail] = useState(false);
  const { t } = useTranslation();
  const [companyGmail, setCompanyGmail] = useState('');
  const [countryGmail, setCountryGmail] = useState('Us');
  const [provinceGmail, setProvinceGmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [country, setCountry] = useState('Us');
  const [province, setProvince] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleAxiosError = (err: AxiosError) => {
    if (err.response?.status === 409) {
      setErrorMessage(`${t(`register-page.${err.response?.data}`)}`);
    } else toast.error(t('register-page.register-error'));
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const objects = Object.fromEntries(
        new FormData(event.currentTarget).entries(),
      );
      loginService
        .CreateAccountRequest(objects as unknown as Client)
        .then(() => {
          navigate('/creation-confirmed');
        })
        .catch(handleAxiosError);
    }
    setValidated(true);
  };

  return (
    <Page id="login-page">
      <PageDetails>
        <div className="page-wrapper bg-blue bg-gra-02 p-t-130 p-b-100 font-poppins">
          <div className="wrapper wrapper--w680">
            <div className="card card-4">
              <div className="card-body">
                <h1 className="text-center">
                  {t('register-page.sign-up-free')}
                </h1>
                <div className="text-center sm-mb--10 mb-5">
                  {t('register-page.approve-first')}
                </div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="user-form"
                >
                  <Row className="mb-2">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.nom')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="nom"
                            placeholder={`${t('user.nom')}`}
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a {t('user.nom')}.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <div className="passwordCriteria">
                        {t('register-page.username-hint')}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.email')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder={'example@mail.com'}
                            aria-describedby="inputGroupPrepend"
                            pattern={stringHelper.RegExpToString(
                              stringHelper.RegExpEmailAdress,
                            )}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('register-page.bad-email-format')}.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label>{t('user.password')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder={`${t('user.password')}`}
                        pattern={stringHelper.RegExpToString(
                          stringHelper.RegExpPassword,
                        )}
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <InputGroup.Text
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {t('register-page.bad-password-format')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <div className="passwordCriteria">
                    {t('register-page.password-criteria')}
                  </div>
                  <Form.Group>
                    <Form.Label>{t('user.nomCompagnie')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        name="nomCompagnie"
                        placeholder={`${t('user.nomCompagnie')}`}
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('register-page.no-compagny')}.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Row className="mb-2">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.country')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            as="select"
                            name="country"
                            aria-describedby="inputGroupPrepend"
                            required
                            onChange={(ev) => setCountry(ev.target.value)}
                          >
                            <option value="Us">Us</option>
                            <option value="Canada">Canada</option>
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.province')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            as="select"
                            name="province"
                            aria-describedby="inputGroupPrepend"
                            isInvalid={province === undefined}
                            onChange={(ev) => setProvince(ev.target.value)}
                            required
                          >
                            <GetProvinceOptions
                              country={country}
                            ></GetProvinceOptions>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {t('register-page.no-province')}.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-2">
                    <Form.Check
                      className="d-flex justify-content-center"
                      name="isExtended"
                      label={
                        <div className="px-2">
                          I agree to the <a href="">Terms of Services</a> &{' '}
                          <a href="">Privacy Policy</a>
                        </div>
                      }
                      required
                    />
                  </Form.Group>
                  <div className="errorMessage text-center">{errorMessage}</div>
                  <Button
                    className="loginFormButton mb-2"
                    id="loginFormButton"
                    type="submit"
                  >
                    {t('register-page.submit-button')}
                  </Button>
                </Form>
                <hr />
                <div className="text-center mb-2">
                  {t('register-page.or-sing-up-google')}
                </div>
                <Form
                  noValidate
                  validated={validatedGmail}
                  onSubmit={handleSubmit}
                >
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>{t('user.nomCompagnie')}</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          name="nomCompagnie"
                          placeholder={`${t('user.nomCompagnie')}`}
                          aria-describedby="inputGroupPrepend"
                          onChange={(ev) => setCompanyGmail(ev.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please choose a {t('user.nomCompagnie')}.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.country')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            as="select"
                            name="country"
                            placeholder={`${t('user.nomCompagnie')}`}
                            aria-describedby="inputGroupPrepend"
                            required
                            onChange={(ev) => setCountryGmail(ev.target.value)}
                          >
                            <option value="Us">Us</option>
                            <option value="Canada">Canada</option>
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>{t('user.province')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            as="select"
                            name="province"
                            placeholder={`${t('user.nomCompagnie')}`}
                            aria-describedby="inputGroupPrepend"
                            onChange={(ev) => setProvinceGmail(ev.target.value)}
                            required
                          >
                            <GetProvinceOptions
                              country={countryGmail}
                            ></GetProvinceOptions>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please choose a {t('user.nomCompagnie')}.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div className="d-flex justify-content-evenly">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      if (
                        companyGmail !== '' &&
                        countryGmail !== '' &&
                        provinceGmail !== ''
                      )
                        loginService
                          .CreateAccountRequestGmail(
                            companyGmail,
                            countryGmail,
                            provinceGmail,
                            credentialResponse as LoginGmail,
                          )
                          .then(() => {
                            navigate('/creation-confirmed');
                          })
                          .catch(handleAxiosError);
                      else {
                        setValidatedGmail(true);
                      }
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    shape="pill"
                    logo_alignment="center"
                    text="signup_with"
                    size="large"
                    theme="filled_blue"
                  />
                </div>
                <hr />
                <a className="d-flex justify-content-evenly" href="/">
                  {t('register-page.login')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div />
      </PageDetails>
    </Page>
  );
}

export default RegisterPage;
