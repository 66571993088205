import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './margin-quote.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import marginQuoteService from '../../services/MarginQuote/margin_quote_service';
import { Margin } from '../../services/MarginQuote/Margin';

type IParamTypes = {
  employeeId: string;
};

type MarginQuoteTableProps = {
  data: Array<Array<string>>;
  title: string;
  onSaveChanges: (newData: Array<Array<string>>) => void;
};

export const MarginQuoteTable: React.FC<MarginQuoteTableProps> = ({
  data,
  title,
  onSaveChanges,
}) => {
  const [editable, setEditable] = useState(false);
  const [editableData, setEditableData] = useState(data);
  const { employeeId } = useParams<IParamTypes>();

  const handleCellEdit = (
    value: string,
    rowIndex: number,
    cellIndex: number,
  ) => {
    const updatedData = editableData.map((row, i) =>
      i === rowIndex
        ? row.map((cell, j) => (j === cellIndex ? value : cell))
        : row,
    );
    setEditableData(updatedData);
  };

  const handleToggleEdit = () => {
    setEditable((prevEditable) => !prevEditable);
    if (editable) {
      onSaveChanges(editableData);
    }
  };
  useEffect(() => {
    setEditableData(data);
  }, [data]);
  return (
    <div id="margin-table">
      <div className="header">
        <h1>{title}</h1>
        {employeeId === '3' && (
          <button
            className="btn btn-outline-primary"
            onClick={handleToggleEdit}
          >
            {editable ? 'Save Changes' : 'Edit'}
          </button>
        )}
      </div>
      <table className="table margin-quote-table">
        <tbody>
          {editableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>
                  {editable ? (
                    <input
                      type="text"
                      className="form-control rounded"
                      defaultValue={cell}
                      onChange={(e) =>
                        handleCellEdit(e.target.value, rowIndex, cellIndex)
                      }
                    />
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const MarginQuotePage = () => {
  const { t } = useTranslation();
  const [dataNew, setDataNew] = useState<Array<Array<string>>>([]);
  const [dataAfter, setDataAfter] = useState<Array<Array<string>>>([]);
  const { employeeId } = useParams<IParamTypes>();

  useEffect(() => {
    loadMarginQuoteNew();
  }, []);

  function loadMarginQuoteNew() {
    if (employeeId) {
      marginQuoteService
        .getMarginQuoteData(employeeId)
        .then((res) => {
          console.log(res);
          if (!res) {
            console.error('Get margin quote response is null');
          } else {
            setDataNew(
              res.newProject.map((row: Array<any>) =>
                row.map((cell) => String(cell)),
              ),
            );
            setDataAfter(
              res.afterMarket.map((row: Array<any>) =>
                row.map((cell) => String(cell)),
              ),
            );
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }

  const handleSaveChangesNew = async (editableData: Array<Array<string>>) => {
    try {
      const updatedData = new Margin({
        newProject: editableData,
        afterMarket: dataAfter,
      });
      await marginQuoteService.putMarginQuoteData(
        employeeId || '',
        updatedData,
      );
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleSaveChangesAfter = async (editableData: Array<Array<string>>) => {
    try {
      const updatedData = new Margin({
        newProject: dataNew,
        afterMarket: editableData,
      });
      await marginQuoteService.putMarginQuoteData(
        employeeId || '',
        updatedData,
      );
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  return (
    <div>
      <MarginQuoteTable
        data={dataNew}
        title={t('margin-quote-page.title-new')}
        onSaveChanges={handleSaveChangesNew}
      />
      <MarginQuoteTable
        data={dataAfter}
        title={t('margin-quote-page.title-after')}
        onSaveChanges={handleSaveChangesAfter}
      />
    </div>
  );
};
