import './nav-tabs-component.scss';
import type { DefaultTFuncReturn } from 'i18next';

type Props = {
  active: string | number;
  navItems: Array<NavItem>;
  additionalClassName?: string;
};

export type NavItem = {
  key: string | number;
  text?: string | DefaultTFuncReturn;
  html?: React.ReactNode;
  onclick: Function;
};

export default function NavTabsComponent(props: Props) {
  return (
    <ul className={`nav nav-tabs ${props.additionalClassName}`}>
      {props.navItems.map((navItem) => {
        return (
          <li key={navItem.key} className="nav-item">
            <button
              className={`nav-link ${
                navItem.key === props.active ? 'active' : ''
              }`}
              aria-current="page"
              onClick={() => navItem.onclick(navItem.key)}
            >
              {navItem.html ? navItem.html : navItem.text}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
