import { useEffect, useState } from 'react';
import DonutChartComponent from '../../components/Charts/DonutChartComponent';
import productionServices from '../../services/Production/ProductionService';

interface Props {
  type: number;
}

const OrderTypeDiagram = (props: Props) => {
  const [data, setData] = useState<[string, number | string][]>([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await productionServices.getOrderStatus(props.type);
      setData([['jobStatusDescription', 'Count']]);
      response?.forEach((item) => {
        setTitle(item.jobTypeDescription);
        setData((prevstate) => [
          ...prevstate,
          [item.jobStatusDescription, item.count],
        ]);
      });
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  const options = { title, is3D: true, pieHole: 0.4 };
  return (
    <DonutChartComponent data={data} options={options}></DonutChartComponent>
  );
};

export default OrderTypeDiagram;
