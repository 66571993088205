import BaseApi from '../Core/REST/BaseApi';
import type { ActivityRequest } from './Activity';
import type Activity from './Activity';

class ActivityService extends BaseApi {
  async getActivities(
    employeeId: string,
    objectId: string | number,
    objectType: string,
    activityType?: number | string,
  ): Promise<Array<Activity> | undefined> {
    const type = activityType ?? '0';
    const url = `${this.baseUrl}/Activities/${employeeId}?objectId=${objectId}&objectType=${objectType}&type=${type}`;
    return this.get<Array<Activity> | undefined>(url);
  }

  async postActivity(employeeId: string, data: ActivityRequest) {
    const url = `${this.baseUrl}/Activities/${employeeId}`;
    return this.post(url, data);
  }

  async patchActivity(
    employeeId: string,
    activityId: string,
    description: string,
  ) {
    const url = `${this.baseUrl}/Activities/${employeeId}/${activityId}`;
    return this.patch(url, { Description: description });
  }

  async deleteActivity(employeeId: string, activityId: string) {
    const url = `${this.baseUrl}/Activities/${employeeId}/${activityId}`;
    return this.delete(url);
  }
}

const activityService = new ActivityService();
export default activityService;
