import type { ProgressBar } from '../../components/Charts/ProgressBarComponent';
import BaseApi from '../Core/REST/BaseApi';
import type { CustOrdersAmount } from './Models/CustOrdersAmount';
import type { OpenOrder, OrderHistoryProgress, WebQuote } from './Models/Order';
import type QuoteTableModel from './Models/QuoteTableModel';

class OrderService extends BaseApi {
  async getAllQuotes(
    employeeId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number,
    withNetPrice: boolean = false,
  ): Promise<Array<QuoteTableModel> | undefined> {
    let url = `${this.baseUrl}/orders/${employeeId}`;
    const params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);
    if (withNetPrice) params.push(`withTotal=${withNetPrice}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += `?${param}`;
      } else {
        url += `&${param}`;
      }
    });

    return this.get<Array<QuoteTableModel>>(url);
  }

  async getOpenOrders(
    employeeId: string,
  ): Promise<Array<OpenOrder> | undefined> {
    const url = `${this.baseUrl}/orders/${employeeId}/Open-Orders`;
    return this.get<Array<OpenOrder>>(url);
  }

  async getWebQuotes(
    employeeId: string,
    date: string,
  ): Promise<Array<WebQuote> | undefined> {
    const url = `${this.baseUrl}/orders/${employeeId}/Web-Quotes/${date}`;
    return this.get<Array<WebQuote>>(url);
  }

  async getQuoteNetPrice(employeeId: string, quoteId: number) {
    const url = `${this.baseUrl}/orders/${employeeId}/net-price/${quoteId}`;
    return this.get<number>(url);
  }

  async getAllUsers(
    employeeId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number,
  ) {
    let url = `${this.baseUrl}/orders/${employeeId}/users`;
    const params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += `?${param}`;
      } else {
        url += `&${param}`;
      }
    });

    return this.get<Array<string>>(url);
  }
  async getOrderHistoryProgressHdr(
    employeeId: string,
    quoteId: string,
  ): Promise<OrderHistoryProgress | undefined> {
    const url = `${this.baseUrl}/orders/${employeeId}/order-history-hdr/${quoteId}`;
    return this.get<OrderHistoryProgress | undefined>(url);
  }

  async getOrderHistoryProgress(
    employeeId: string,
    quoteId: string,
  ): Promise<Array<ProgressBar> | undefined> {
    const url = `${this.baseUrl}/orders/${employeeId}/order-history-progressbar/${quoteId}`;
    return this.get<Array<ProgressBar> | undefined>(url);
  }
  async getCustOrdersAmount(
    employeeId: string,
    startDate?: string,
    endDate?: string,
  ): Promise<Array<CustOrdersAmount> | undefined> {
    const url = `${this.baseUrl}/orders/${employeeId}/cust-orders-amount?startDate=${startDate}&endDate=${endDate}`;
    return this.get<Array<CustOrdersAmount>>(url);
  }
}

const orderService = new OrderService();
export default orderService;
