import BaseApi from '../Core/REST/BaseApi';
import type { Department } from './Department';

class DepartmentService extends BaseApi {
  async getAll(): Promise<Array<Department> | undefined> {
    const url = `${this.baseUrl}/Departments/getAll`;
    return this.get<Array<Department> | undefined>(url);
  }
  async putDepartment(department: Department) {
    const url = `${this.baseUrl}/Departments/Create-Department`;
    return this.put(url, department);
  }
}

const departmentService = new DepartmentService();
export default departmentService;
