import BaseApi from '../Core/REST/BaseApi';
import type { ProjectKit, ToDoAndLateJobs } from './Model/Project';
import type Project from './Model/Project';

class ProjectService extends BaseApi {
  async getProjects(employeeId: string): Promise<Array<Project> | undefined> {
    const url = `${this.baseUrl}/projects/${employeeId}`;
    return this.get<Array<Project> | undefined>(url);
  }

  async getProjectKits(
    employeeId: string,
    projectId: number,
  ): Promise<Array<ProjectKit> | undefined> {
    const url = `${this.baseUrl}/projects/${employeeId}/Project-kit/${projectId}`;
    return this.get<Array<ProjectKit> | undefined>(url);
  }

  async postProjectKitCompleted(
    employeeId: string,
    projectId: number,
    kitId: string,
  ) {
    const url = `${this.baseUrl}/projects/${employeeId}/Project-Kit/${projectId}?kitId=${kitId}`;
    return this.post<any, boolean>(url, {});
  }

  async deleteProjectKitCompleted(employeeId: string, projectKitId: number) {
    const url = `${this.baseUrl}/projects/${employeeId}/Project-Kit/${projectKitId}`;
    return this.delete<boolean>(url);
  }

  async patchProjectAssignedTo(
    employeeId: string,
    projectId: string,
    assignedTo: number | undefined,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/assigned-to/${assignedTo}`;
    return this.patch(url, {});
  }

  async patchProjectState(
    employeeId: string,
    projectId: string,
    state: any,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/state/${state}`;
    return this.patch(url, {});
  }

  async patchProjectNotes(
    employeeId: string,
    projectId: string,
    notes: string,
  ) {
    const url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/notes`;
    return this.patch(url, { notes });
  }
  async getNumberToDoAndLateProject(): Promise<ToDoAndLateJobs | undefined> {
    const url = `${this.baseUrl}/projects/get-number-to-do-late-projects`;
    return this.get(url);
  }
}

const projectService = new ProjectService();
export default projectService;
