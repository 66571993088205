import { useParams } from 'react-router-dom';
import './PumpCouplingAlignmentSheet.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import pumpService from '../../../services/Pumps/PumpService';
import PumpCouplingAlignmentSheetPage from './PumpCouplingAlignmentSheet';
import pumpExtensions from '../../../services/Pumps/PumpExtensions';
import Popup from '../../../components/Core/Popup';
import type PumpCouplingAlignmentSheet from '../../../services/Pumps/Models/PumpCouplingAlignmentSheet';

type IParamTypes = {
  employeeId: string;
  externalFileId: string;
};

export default function PumpCouplingAlignmentSheetsPage() {
  const { t } = useTranslation();

  const { employeeId, externalFileId } = useParams<IParamTypes>();
  const [alignmentSheets, setAlignmentSheets] = useState<
    PumpCouplingAlignmentSheet[]
  >([]);
  const [index, setIndex] = useState<number>(0);
  const [create, setCreate] = useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [serialNumberError, setSerialNumberError] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    async function get() {
      if (employeeId && externalFileId) {
        loadSheets();
        window.addEventListener('beforeunload', async (ev) => {
          ev.preventDefault();
          ev.returnValue = 'Changes you made may not be saved.';
        });
      }
    }

    get();
  }, [externalFileId, employeeId]);

  const previous = () => {
    if (index <= 0) return;
    setIndex(index - 1);
  };

  return (
    <div id="pump-coupling-alignment-sheet-page" className="container">
      <div className="card">
        <div className="card-header">
          <div className="flex-fill">
            <button
              className="btn btn-outline-primary"
              onClick={previous}
              disabled={index === 0}
            >
              {t('common.previous')}
            </button>
          </div>
          <div className="flex-fill">
            <button className="btn btn-success" onClick={() => setCreate(true)}>
              {t('pumps.common.new-sheet')}
            </button>
          </div>
          <div className="flex-fill">
            <button
              className="btn btn-outline-primary"
              onClick={() => setIndex(index + 1)}
              disabled={!(index < alignmentSheets.length - 1)}
            >
              {t('common.next')}
            </button>
          </div>
        </div>
      </div>

      {employeeId && externalFileId && alignmentSheets[index] && (
        <PumpCouplingAlignmentSheetPage
          employeeId={employeeId}
          externalFileId={externalFileId}
          alignmentSheet={alignmentSheets[index]}
          index={index}
          loadSheets={loadSheets}
        ></PumpCouplingAlignmentSheetPage>
      )}

      <Popup
        onClose={closePopup}
        onOk={createSheet}
        title={t('pumps.common.new-sheet')}
        show={create}
      >
        <>
          <label className="form-label">
            {t('pumps.alignment-sheet-page.serial-number')}
          </label>
          <input
            type="text"
            className="form-control"
            value={serialNumber}
            onChange={(ev) => onChangeSerialNumber(ev.target.value)}
          />
          {serialNumberError && (
            <label className="error">{serialNumberError}</label>
          )}
        </>
      </Popup>
    </div>
  );

  function onChangeSerialNumber(value: string) {
    setSerialNumber(value);
    if (!pumpExtensions.isSerialNumberValid(value)) {
      const error = t('pumps.alignment-sheet-page.serial-number-error');
      setSerialNumberError(error);
      return;
    }

    setSerialNumberError(undefined);
  }

  function createSheet() {
    if (!pumpExtensions.isSerialNumberValid(serialNumber)) {
      setSerialNumberError('Invalid Serial Number');
      return;
    }

    if (serialNumber && employeeId && externalFileId && create) {
      pumpService
        .createAlignmentSheet(externalFileId, employeeId, serialNumber)
        .then((res) => {
          if (!res) {
            console.error('Create sheet response is null');
          } else {
            setCreate(false);
            setSerialNumber('');
            loadSheets(true);
          }
        })
        .catch((ex) => {
          console.error(ex);
          toast.error('Error while creating the new sheet');
        });
    }
  }

  function loadSheets(showLast: boolean = false) {
    if (employeeId && externalFileId) {
      pumpService
        .getPumpAlignmentSheets(externalFileId, employeeId)
        .then((res) => {
          console.log(res);
          if (!res) {
            console.error('Get sheets response is null');
          } else if (res.length === 0 || (res.length === 1 && !res[0]?.id)) {
            setCreate(true);
          } else {
            setAlignmentSheets(res);
            // console.log("sheets : ", res);
            if (showLast) {
              setIndex(res.length - 1);
            }
          }
        })
        .catch((ex) => {
          console.error(ex);
          toast.error('Error while fetching the alignment sheets');
        });
    }
  }
  function closePopup() {
    if (alignmentSheets.length > 0 && alignmentSheets[0].id) {
      setCreate(false);
    }
  }
}
