import './pump-search-page.scss';

import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';
import LoadingComponent from '../../../components/Core/Loading';
import PumpSearchCriteria from '../../../services/Pumps/Models/PumpSearchCriteria';
import usePumpTypes from '../../../hooks/Pump/usePumpTypes';
import {
  useAvailFlangeTypes,
  useAvailPumpConstructions,
  useAvailVoltPhHz,
  useConfigPumpSearch,
} from '../../../hooks/Pump/pump-search-hooks';
import CollapseComponent from '../../../components/collapse-component';
import motorService from '../../../services/Motors/motor-service';
import ConfiramtionPopup from '../../../components/Core/Popup-confirmation-Component';
import pumpService from '../../../services/Pumps/PumpService';

import type PumpSearchPump from '../../../services/Pumps/Models/PumpSearchPump';

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export default function PumpSearchPage() {
  const { t } = useTranslation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const navigate = useNavigate();

  const [searchCriteria, setSearchCriteria] = useState<PumpSearchCriteria>(
    new PumpSearchCriteria(),
  );
  const [horsepower, setHorsepower] = useState<string[]>([]);
  const [, setRPMArr] = useState<Map<string, number>>();

  const { pumpTypes, pumpTypesLoading } = usePumpTypes(userId);
  const { phases } = useAvailVoltPhHz(userId);
  const [voltPhHz] = useState('480/3/60');

  const { configPumpSearch } = useConfigPumpSearch(
    userId,
    searchCriteria.pumpType,
  );
  const { constructions } = useAvailPumpConstructions(
    userId,
    searchCriteria.pumpType,
  );
  const { flangeTypes } = useAvailFlangeTypes(userId, searchCriteria.pumpType);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);
  const [, setPumpSearchPump] = useState<PumpSearchPump[] | null>(null);

  useEffect(() => {
    if (searchCriteria.pumpType !== 0 && configPumpSearch) {
      setSearchCriteria((prevSearchCriteria) => ({
        ...prevSearchCriteria,
        motorType: 0,
        flangeType: configPumpSearch.getFlangeType() ?? '0',
        construction: configPumpSearch?.getConstruction() ?? '0',
        voltPhHz: configPumpSearch?.getVolt() ?? '440-460-480/3/60',
      }));
      loadHorsepower();
    }
  }, [configPumpSearch]);

  if (pumpTypesLoading) {
    return <LoadingComponent />;
  }

  return (
    <div id="pump-serach-page">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Pump Search',
            active: true,
          },
        ]}
      />
      {/* <div className="page-title wrapper">
        <h1 className="h3">{t("pump-search-page.title")}</h1>
      </div> */}
      <div className="page-container">
        <div className="card-left">
          <div className="card-title">
            <h5 className="h6">{t('pump-search-page.pump-type')}</h5>
          </div>
          <div className="card-body">
            {pumpTypes && (
              <ul className="list-group">
                {pumpTypes.map((pumpTypeArr, index) => {
                  if (pumpTypeArr.length <= 0) return <></>;

                  const pumpTypeId = pumpTypeArr[0].idPumpType;
                  const collapseId = `collapse-${pumpTypeId}`;
                  const serieName = pumpTypeArr[0].name;

                  return (
                    <li
                      className="list-group-item list-group-item-action list-pump-type"
                      key={index}
                    >
                      <CollapseComponent title={serieName} collapsed>
                        <div className="row" id={collapseId}>
                          {pumpTypeArr.map((pumpType, i) => {
                            const checkboxId = `checkbox-pump_type-${pumpType.id}`;
                            return (
                              <div className="col-12 ms-5" key={i}>
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  checked={
                                    searchCriteria.pumpType === pumpType.id
                                  }
                                  onChange={() =>
                                    setValue('pumpType', pumpType.id)
                                  }
                                  id={checkboxId}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={checkboxId}
                                >
                                  {pumpType.pumpTypeName}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </CollapseComponent>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="card-right">
          <div className="card-title">
            <h5 className="h6">{t('pump-search-page.input-conditions')}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="mb-2 col-lg-6">
                <FormLabel htmlFor="flow-rate" children={'Flow Rate'} />
                <div className="row">
                  <div className="col-lg-4 col-sm-12">
                    <input
                      type="number"
                      id="flow-rate"
                      className="form-control form-control-sm"
                      value={searchCriteria.flowRate}
                      onChange={(ev) =>
                        setValue('flowRate', ev.target.valueAsNumber)
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <select
                      id="flow-rate-unit-measure"
                      className="form-select form-select-sm"
                      defaultValue={searchCriteria.flowRate}
                      onChange={(ev) =>
                        setValue('flowRateMUnit', ev.target.value)
                      }
                    >
                      <option value={0}>{t('unit-measurement.us-gal')}</option>
                      <option value={1}>
                        {t('unit-measurement.m-per-hour')}
                      </option>
                      <option value={2}>
                        {t('unit-measurement.l-per-sec')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-2 col-lg-6">
                <FormLabel htmlFor="head" children={'Head'} />
                <div className="row">
                  <div className="col-lg-4 col-sm-12">
                    <input
                      type="number"
                      id="head"
                      className="form-control form-control-sm"
                      value={searchCriteria.head}
                      onChange={(ev) =>
                        setValue('head', ev.target.valueAsNumber)
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <select
                      id="head-unit-measure"
                      className="form-select form-select-sm"
                      defaultValue={searchCriteria.head}
                      onChange={(ev) => setValue('headMUnit', ev.target.value)}
                    >
                      <option value={0}>{t('unit-measurement.feet')}</option>
                      <option value={1}>{t('unit-measurement.meter')}</option>
                      <option value={2}>
                        {t('unit-measurement.kpa-sign')}
                      </option>
                      <option value={3}>{t('unit-measurement.psi')}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="mb-2">
                  <label htmlFor="construction" className="form-label">
                    {t('pump-search-page.construction')}
                  </label>
                  <select
                    id="construction"
                    className="form-select form-select-sm"
                    defaultValue={searchCriteria?.construction}
                    onChange={(ev) => setValue('construction', ev.target.value)}
                  >
                    {/* <option value={"All Constructions"}>All Constructions</option> */}
                    {constructions?.map((construction) => {
                      return (
                        <option key={construction} value={construction}>
                          {construction}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="mb-1">
                  <label htmlFor="flange-type" className="form-label">
                    {t('pump-search-page.flange-type')}
                  </label>
                  <select
                    id="flange-type"
                    className="form-select form-select-sm"
                    defaultValue={searchCriteria?.flangeType}
                    onChange={(ev) => setValue('flangeType', ev.target.value)}
                  >
                    {flangeTypes?.map((ft) => {
                      return (
                        <option key={ft} value={ft}>
                          {ft}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="mb-2">
                  <label htmlFor="glycol-percent" className="form-label">
                    {t('pump-search-page.glycol')}
                  </label>
                  <input
                    type="number"
                    id="glycol-percent"
                    className="form-control form-control-sm"
                    onChange={(ev) =>
                      setValue('glycolPercent', ev.target.valueAsNumber)
                    }
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card-title">
            <h5 className="h6">{t('pump-search-page.motor-config')}</h5>
          </div>
          <div className="card-body">
            <div className="mb-2">
              <label htmlFor="hp" className="form-label">
                {t('pump-search-page.selection')}
              </label>
              <select
                id="hp"
                className="form-select form-select-sm"
                value={searchCriteria?.hp}
                onChange={(ev) => setValue('hp', ev.target.value)}
              >
                <option key={0} value={0}>
                  Automatic
                </option>
                {horsepower?.map((hp, i) => {
                  return (
                    <option key={i + 1} value={i + 1}>
                      {hp}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mb-2">
              <label htmlFor="motorType" className="form-label">
                {t('pump-search-page.motor-type')}
              </label>
              <select
                id="motorType"
                className="form-select form-select-sm"
                onChange={(ev) => setValue('motorType', ev.target.value)}
                value={searchCriteria?.motorType}
                disabled
              >
                <option value={0}>TEFC</option>
                <option value={1}>ODP</option>
              </select>

              <div className="form-check form-check-inline mt-1">
                <FormCheckInput
                  id="radio_BHP"
                  name="bhp"
                  type="radio"
                  // onClick={() => setValue("bhp", "BHP")}
                  onChange={() => {
                    setValue('bhp', 'BHP');
                    setValue('isNol', false);
                  }}
                  checked={searchCriteria?.isNol === true}
                />
                <FormCheckLabel htmlFor="radio_BHP" children={'BHP'} />
              </div>
              <div className="form-check form-check-inline">
                <FormCheckInput
                  id="radio_NOL"
                  name="bhp"
                  type="radio"
                  // onClick={() => setValue("bhp", "NOL")}
                  onChange={() => {
                    setValue('bhp', 'NOL');
                    setValue('isNol', true);
                  }}
                  checked={searchCriteria?.isNol !== false}
                />
                <FormCheckLabel htmlFor="radio_NOL" children={'NOL'} />
              </div>
            </div>

            <div className="mb-1">
              <label htmlFor="rpm" className="form-label">
                RPM
              </label>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <select
                    id="rpm"
                    className="form-select form-select-sm"
                    onChange={(ev) => setValue('rpm', ev.target.value)}
                    disabled={searchCriteria.isMultiSpeedSelection}
                    defaultValue={1750}
                  >
                    <option value={1150}>1150, 60Hz</option>
                    <option value={1750}>1750, 60Hz</option>
                    <option value={3450}>3450, 60Hz</option>

                    <option value={-1}>All, 60Hz</option>

                    <option value={970}>970, 50Hz</option>
                    <option value={1450}>1450, 50Hz</option>
                    <option value={2900}>2900, 50Hz</option>
                    {/** ************* just the admin should see Other option *************** */}
                    <option value={0}>Other</option>
                  </select>
                </div>
                {searchCriteria.rpm.includes(0) &&
                  !searchCriteria.isMultiSpeedSelection && (
                    <div className="col-lg-6 col-sm-12">
                      <input
                        id="rpm-other"
                        type="number"
                        className="form-control form-control-sm"
                        defaultValue={0}
                        onChange={(ev) => setValue('rpm', ev.target.value)}
                      />
                    </div>
                  )}
              </div>
            </div>

            <div className="mb-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="chbx_multi-selection-speed"
                  onChange={(ev) =>
                    setValue('isMultiSpeedSelection', ev.target.checked)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="chbx_multi-selection-speed"
                >
                  {t('pump-search-page.multi-speed')}
                </label>
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="voltPhHz" className="form-label">
                Volt/PH/Hz
              </label>
              <select
                id="voltPhHz"
                className="form-select form-select-sm"
                defaultValue={voltPhHz}
                onChange={(ev) => setValue('voltPhHz', ev.target.value)}
              >
                {phases.map((phase) => {
                  return (
                    <option key={phase[0]} value={phase[0]}>
                      {phase[1]}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mb-2">
              <div className="form-check form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="chbx_prenium-efficiency-motor-not-north-american"
                  onChange={(ev) =>
                    setValue(
                      'isPremiumEfficiencyMotorNotNorthAmerican',
                      ev.target.checked,
                    )
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="chbx_prenium-efficiency-motor-not-north-american"
                >
                  {t('pump-search-page.eff')}
                </label>
                <span className="error"> {t('pump-search-page.warn-380')}</span>
              </div>
              {/* <div className="form-check form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="metric-ie2"
                  disabled
                />
                <label className="form-check-label" htmlFor="metric-ie2">
                  {t('pump-search-page.metric2')}
                  <span className="error ms-1">
                    {' '}
                    {t('pump-search-page.avail-soon')}
                  </span>
                </label>
              </div>
              <div className="form-check form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="metric-ie3"
                  disabled
                  readOnly
                />
                <label className="form-check-label" htmlFor="metric-ie3">
                  {t('pump-search-page.metric3')}
                  <span className="error ms-1">
                    {' '}
                    {t('pump-search-page.avail-soon')}
                  </span>
                </label>
              </div> */}
            </div>
          </div>

          <div className="card-title">
            <h5 className="h6">{t('pump-search-page.additional-options')}</h5>
          </div>
          <div className="card-body">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchCriteria.isBarePump}
                onChange={(ev) => setValue('isBarePump', ev.target.checked)}
                id="chbx_bare-pump"
              />
              <label className="form-check-label" htmlFor="chbx_bare-pump">
                {t('pump-search-page.bare-pump-option')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchCriteria.isMultifunctionValve}
                onChange={(ev) =>
                  setValue('isMultifunctionValve', ev.target.checked)
                }
                id="chbx_multi-function-valve"
              />
              <label
                className="form-check-label"
                htmlFor="chbx_multi-function-valve"
              >
                {t('pump-search-page.multi-function-valve-option')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchCriteria.isSuctionDeffuser}
                onChange={(ev) =>
                  setValue('isSuctionDeffuser', ev.target.checked)
                }
                id="chbx_suction-deffuser"
              />
              <label
                className="form-check-label"
                htmlFor="chbx_suction-deffuser"
              >
                {t('pump-search-page.suction-deffusers')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchCriteria.isVariableFrequencyDrive}
                onChange={(ev) =>
                  setValue('isVariableFrequencyDrive', ev.target.checked)
                }
                id="chbx_variable-frequency-drive"
              />
              <label
                className="form-check-label"
                htmlFor="chbx_variable-frequency-drive"
              >
                {t('pump-search-page.variable-frequency-drive')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchCriteria.isShowSystemCurve}
                onChange={(ev) =>
                  setValue('isShowSystemCurve', ev.target.checked)
                }
                id="chbx_generate-system-curve"
              />
              <label
                className="form-check-label"
                htmlFor="chbx_generate-system-curve"
              >
                {t('pump-search-page.generate-system-curve')}
              </label>
            </div>
          </div>

          <div className="card-footer text-center">
            <button className="btn btn-primary" onClick={handleFindPump}>
              {t('pump-search-page.find-pump')}
            </button>
          </div>

          {loading && (
            <div className="loading-overlay">
              <LoadingComponent />{' '}
            </div>
          )}

          {showAlert && (
            <ConfiramtionPopup
              show={showAlert}
              title={'Warn!'}
              message={alertMessage}
              onOk={() => setShowAlert(false)}
              onClose={() => setShowAlert(false)}
            ></ConfiramtionPopup>
          )}
        </div>
      </div>
    </div>
  );

  function setValue(key: string, value: any) {
    setSearchCriteria({ ...searchCriteria, [key]: value });
  }

  function handleFindPump(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (searchCriteria.pumpType) {
      if (searchCriteria.rpm.includes(970)) {
        setShowAlert(true);
        setAlertMessage(t('pump-search-page.warn-970-rpm') || 'warn-970-rpm');
      }
      setLoading(true);
      loadPumps();
    } else {
      setShowAlert(true);
      setAlertMessage(
        t('pump-search-page.warn-select-series') || 'select-series',
      );
    }
  }

  function loadHorsepower() {
    if (userId) {
      motorService
        .getAvailableHorsePower(userId)
        .then((data) => {
          if (data) {
            setHorsepower(data);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  function loadRPMList() {
    if (userId) {
      motorService
        .getRPMList(userId)
        .then((data) => {
          if (data) {
            setRPMArr(data);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function loadPumps() {
    if (userId && quoteId) {
      pumpService
        .getPumps(searchCriteria, userId)
        .then((res) => {
          if (res && res.length > 0) {
            setLoading(false);
            setPumpSearchPump(res);
            navigate(`/Pumps/Pump-list/${userId}/${quoteId}`, {
              state: { data: res },
            });
          } else if (res && res.length === 0) {
            setLoading(false);
            setShowAlert(true);
            setAlertMessage('Could not find pumps matching your criteria.');
          }
        })
        .catch(() => {
          setLoading(false);
          setShowAlert(true);
          setAlertMessage('An error occurred while loading pumps.');
        });
    }
  }
}
