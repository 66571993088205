import BaseApi from '../Core/REST/BaseApi';
import type { Permission, PermissionValue } from './Permission';

class PermissionService extends BaseApi {
  async GetAll(): Promise<Array<Permission> | undefined> {
    const url = `${this.baseUrl}/Permission/getAll`;
    return this.get(url);
  }
  async Post(permissionValue: PermissionValue) {
    const url = `${this.baseUrl}/Permission/Post-Department-Value`;
    return this.post(url, permissionValue);
  }
  async Delete(permissionKeyId: number, departmentId: number) {
    const url = `${this.baseUrl}/Permission/${permissionKeyId}/${departmentId}`;
    return this.delete(url);
  }
  async UpdateDescription(permission: Permission) {
    const url = `${this.baseUrl}/Permission/Update-Description`;
    return this.patch(url, permission);
  }
}
const permissionService = new PermissionService();
export default permissionService;
