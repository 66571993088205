import BaseApi from '../Core/REST/BaseApi';
import type { NonConformityReport } from './Models/NonConformityReport';
import type { QuoteExternalFile } from '../Quotes/Models/QuoteExternalFile';

class NonConformityReportService extends BaseApi {
  async getNonConformityReport(
    nonConformityReportId: string,
  ): Promise<NonConformityReport | undefined> {
    const url = `${this.baseUrl}/NonConformityReport/${nonConformityReportId}`;
    const nonConfomityReport: NonConformityReport | undefined =
      await this.get(url);
    if (nonConfomityReport) {
      if (nonConfomityReport.creationDate)
        nonConfomityReport.creationDate = new Date(
          nonConfomityReport.creationDate,
        );
      if (nonConfomityReport.closeDate)
        nonConfomityReport.closeDate = new Date(nonConfomityReport.closeDate);
      if (nonConfomityReport.photoSrc)
        nonConfomityReport.photoSrc = `data:image/jpeg;base64,${nonConfomityReport.photoSrc}`;
    }
    return nonConfomityReport;
  }
  async getAllNonConformityReport(): Promise<
    Array<NonConformityReport> | undefined
  > {
    const url = `${this.baseUrl}/NonConformityReport/getAll`;
    const nonConfomityReportArray: Array<NonConformityReport> | undefined =
      await this.get(url);
    if (nonConfomityReportArray) {
      nonConfomityReportArray.forEach((nonConfomityReport) => {
        if (nonConfomityReport.creationDate)
          nonConfomityReport.creationDate = new Date(
            nonConfomityReport.creationDate,
          );
        if (nonConfomityReport.closeDate)
          nonConfomityReport.closeDate = new Date(nonConfomityReport.closeDate);
      });
    }
    return nonConfomityReportArray;
  }
  async saveNonConformityReport(
    nonConformityReport: NonConformityReport,
    employeeId: string,
  ) {
    const nonConformityReportNoPhoto = {
      ...nonConformityReport,
      photoSrc: undefined,
      photoPath: undefined,
    };
    const url = `${this.baseUrl}/NonConformityReport/Save-Non-Conformity-Report/${employeeId}`;
    return this.post(url, nonConformityReportNoPhoto);
  }
  async validateNonConformityReport(
    nonConformityReport: NonConformityReport,
    employeeId: string,
  ) {
    const nonConformityReportNoPhoto = {
      ...nonConformityReport,
      photoSrc: undefined,
      photoPath: undefined,
    };
    const url = `${this.baseUrl}/NonConformityReport/Validate-Non-Conformity-Report/${employeeId}`;
    return this.post(url, nonConformityReportNoPhoto);
  }
  async uploadPhoto(photo: File, reportId?: string) {
    const url = `${this.baseUrl}/NonConformityReport/Upload-Photo/${reportId}`;
    const formData = new FormData();
    formData.append('photo', photo);
    return this.postFile(url, formData);
  }
  async deletePhoto(reportId?: string) {
    const url = `${this.baseUrl}/NonConformityReport/Delete-Photo/${reportId}`;
    return this.delete(url);
  }
  async getAllQuoteExternalFileOfOrder(orderId: number) {
    const url = `${this.baseUrl}/NonConformityReport/Get-All-Quote-External-File-Of-Order/${orderId}`;
    return this.get<Array<QuoteExternalFile>>(url);
  }
}

const nonConformityReportService = new NonConformityReportService();
export default nonConformityReportService;
