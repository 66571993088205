import type { Column, Header, Row } from '@tanstack/react-table';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  faFileArrowDown,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stringHelper from '../../services/Core/Helpers/string-helper';

type SortOptionProps = {
  header: Header<any, unknown>;
};
export function ReactTableHeaderOptions(props: SortOptionProps) {
  return (
    <>
      {props.header.column.getCanSort() && (
        <>
          {{
            asc: <FontAwesomeIcon className="primary ms-1" icon={faSortUp} />,
            desc: (
              <FontAwesomeIcon className="primary ms-1" icon={faSortDown} />
            ),
            false: <FontAwesomeIcon className="primary ms-1" icon={faSort} />,
          }[props.header.column.getIsSorted() as string] ?? null}
        </>
      )}
    </>
  );
}

type ExportDataBtnProps = {
  data: string | object[] | (() => string | object[]);
  filename?: string;
};
export function TableExportDataButton(props: ExportDataBtnProps) {
  const { t } = useTranslation();
  return (
    <CSVLink
      className="btn btn-sm btn-outline-primary"
      data={props.data}
      filename={props.filename}
    >
      <FontAwesomeIcon className="primary ms-1" icon={faFileArrowDown} />{' '}
      {t('react-table.export-data')}
    </CSVLink>
  );
}

export function getTableHeaderSortProps(column: Column<any>) {
  return {
    role: column.getCanSort() ? 'button' : '',
    onClick: column.getToggleSortingHandler(),
  };
}

export function filterFnDate(
  row: Row<any>,
  columnId: string,
  filterValue: any,
): boolean {
  return stringHelper
    .toDateString(row.getValue(columnId))
    .startsWith(filterValue);
}
