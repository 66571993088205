import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { SortingState } from '@tanstack/react-table';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl } from 'react-bootstrap';
import type PumpSearchPump from '../../../services/Pumps/Models/PumpSearchPump';
import './pump-list-page.scss';
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from '../../../components/react-table/react-table-component';
import pumpService from '../../../services/Pumps/PumpService';
import type PumpSearchOption from '../../../services/Pumps/Models/PumpSerachOption';
import ConfiramtionPopup from '../../../components/Core/Popup-confirmation-Component';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';

type IParamTypes = {
  userId: string;
  quoteId: string;
};
export default function PumpListPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [selectedPump, setSelectedPump] = useState<PumpSearchPump | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<PumpSearchOption[]>(
    [],
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();

  const pumpSearchPump = location.state?.data || [];
  const [options, setOptions] = useState<PumpSearchOption[]>([]);
  const [qty, setQty] = useState<string>('1');
  const [tag, setTag] = useState<string>();
  const [, setShowPumpInfo] = useState<boolean>(false);

  const handlePumpSelection = (row: PumpSearchPump) => {
    setSelectedPump(row);
    loadOptions();
  };
  const handleOptionSelection = (row: PumpSearchOption) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.some((option) => option.id === row.id)) {
        // If the row is already selected, remove it
        return prevSelectedOptions.filter((option) => option.id !== row.id);
      }
      // If the row is not selected, add it
      return [...prevSelectedOptions, row];
    });
  };

  const FloatCellRenderer = ({ cell }: { cell: any }) => {
    const value = parseFloat(cell.getValue());
    return !Number.isNaN(value) ? value.toFixed(2) : cell.getValue();
  };
  const IconCellRender = ({ cell }: { cell: any }) => {
    return cell.getValue() ? (
      <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
    ) : (
      <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} />
    );
  };

  const ImageCellRenderer = ({
    src,
    row,
  }: {
    src: any;
    row: PumpSearchPump;
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const imagePath = `/images/Curves/${src}`;

    const handleMouseEnter = (
      e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    ) => {
      setIsHovered(true);
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (
      e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    ) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div className="image-cell-wrapper">
        <img
          src={imagePath}
          alt="Pump Curve"
          className="image-cell"
          onClick={() => showCurve(row)}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
        {isHovered && (
          <img
            src={imagePath}
            alt="Pump Curve Enlarged"
            className="enlarged-image"
            style={{
              maxWidth: '90vw',
              top:
                mousePosition.y > window.innerHeight / 4
                  ? mousePosition.y - 300
                  : mousePosition.y + 10,
              left: mousePosition.x + 10,
            }}
          />
        )}
      </div>
    );
  };

  const columnsPumps: any = useMemo(() => {
    return [
      {
        header: t('pump-list-page.curve'),
        accessorKey: 'curvePath',
        cell: ImageCellRenderer,
      },
      {
        header: t('pump-list-page.model-name'),
        accessorKey: 'modelName',
      },
      {
        header: t('pump-list-page.series-name'),
        accessorKey: 'seriesName',
      },
      {
        header: t('pump-list-page.rpm'),
        accessorKey: 'rpm',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.diam'),
        accessorKey: 'impellerDiameter',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.eff'),
        accessorKey: 'efficiencyPercent',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.bhp'),
        accessorKey: 'bhp',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.nol'),
        accessorKey: 'nol',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.npshr'),
        accessorKey: 'npshr',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.suct'),
        accessorKey: 'suct',
      },
      {
        header: t('pump-list-page.disc'),
        accessorKey: 'disc',
      },
      {
        header: t('pump-list-page.construction'),
        accessorKey: 'construction',
      },
      {
        header: t('pump-list-page.list-price'),
        accessorKey: 'listPrice',
        cell: FloatCellRenderer,
      },
      {
        header: t('pump-list-page.inventory-status'),
        accessorKey: 'isInventory',
        Cell: IconCellRender,
      },
    ];
  }, [t]);

  const columnsOptions: any = useMemo(() => {
    return [
      {
        header: t('pump-list-page.option'),
        accessorKey: 'name',
      },
      {
        header: t('pump-list-page.price'),
        accessorKey: 'price',
      },
    ];
  }, [t]);

  const tablePumps = useReactTable({
    columns: columnsPumps,
    data: pumpSearchPump,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const tableOptions = useReactTable({
    columns: columnsOptions,
    data: options,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    if (
      selectedPump &&
      userId &&
      selectedPump.quoteId !== null &&
      selectedPump.tag !== null
    ) {
      // console.log(selectedPump);
      pumpService
        .addPumpAndOption(selectedPump, userId, '-1')
        .then((res) => {
          if (res) {
            setShowPumpInfo(false);
            // toast.success(t(`pump-list-page.success-added`));
            if (selectedPump.isMultiFunctionValve)
              navigate(`/Pumps/MFV/${userId}/${quoteId}/${res}`, {
                state: { data: selectedPump },
              });
            else if (selectedPump.isSuctionDiffuser)
              navigate(`/Pumps/SD/${userId}/${quoteId}/${res}`, {
                state: { data: selectedPump },
              });
            else if (selectedPump.isVariableFrequencyDrive)
              navigate(`/Pumps/VFD/${userId}/${quoteId}/${res}`, {
                state: { data: selectedPump },
              });
            else {
              const activeTabIndex = 1;
              sessionStorage.setItem(
                'activeTabIndex',
                activeTabIndex.toString(),
              );
              navigate(`/Quotes/${userId}/${quoteId}`);
            }
          }
        })
        .catch((error) => console.error(error));
    }
    if (selectedPump) {
      loadOptions();
    }
  }, [selectedPump]);

  return (
    <div id="pump-list-page">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Pump Search List',
            active: true,
          },
        ]}
      />
      <div className="page-title wrapper">
        <h1 className="h3">{t('Pump Search')}</h1>
      </div>
      <div className="table-responsive-container">
        <table className="table table-hover">
          <thead>
            {tablePumps.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th></th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {header.column.columnDef.header?.toString()}
                        <ReactTableHeaderOptions header={header} />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {pumpSearchPump.map((row: PumpSearchPump, rowIndex: number) => (
              <tr
                key={rowIndex}
                className={selectedPump === row ? 'selected-row' : ''}
                onClick={() => handlePumpSelection(row)}
              >
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    checked={selectedPump === row}
                    onChange={() => handlePumpSelection(row)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>

                {tablePumps
                  .getRowModel()
                  .rows[rowIndex].getVisibleCells()
                  .map((cell) => (
                    <td className="align-vertical-center" key={cell.id}>
                      {cell.column.id === 'curvePath' ? (
                        <ImageCellRenderer src={cell.getValue()} row={row} />
                      ) : cell.column.id === 'isInventory' ? (
                        <IconCellRender cell={cell} />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <div className="table-overlay">
        <table className="table table-hover">
          <thead>
            {tableOptions.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th></th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {header.column.columnDef.header?.toString()}
                        <ReactTableHeaderOptions header={header} />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {options.map((row: PumpSearchOption, rowIndex: number) => (
              <tr key={rowIndex} onClick={() => handleOptionSelection(row)}>
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    checked={selectedOptions.some(
                      (option) => option.id === row.id,
                    )}
                    onChange={() => handleOptionSelection(row)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>

                {tableOptions
                  .getRowModel()
                  .rows[rowIndex].getVisibleCells()
                  .map((cell, cellIndex, cellsArray) => (
                    <td
                      style={{
                        textAlign:
                          cellIndex === cellsArray.length - 1
                            ? 'center'
                            : 'left',
                        direction: 'ltr',
                      }}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fixed-footer">
        <div className="info-containers">
          <label className="form-label">Enter Tag:</label>
          <FormControl onChange={(e) => setTag(e.target.value)} />
          <label className="form-label">Enter Quantity:</label>
          <FormControl
            type="number"
            placeholder="1"
            onChange={(e) => setQty(e.target.value)}
          />
        </div>
        <div className="button-containers">
          <button className="btn btn-primary" onClick={handleAddToQuote}>
            {t(`add-single-item-page.add-to-quote`)}
          </button>
          <button className="btn btn-primary" onClick={handleBackToQuote}>
            {t(`add-single-item-page.close`)}
          </button>
        </div>
      </div>
      {showAlert && (
        <ConfiramtionPopup
          show={showAlert}
          title={'Warn!'}
          message={alertMessage}
          onOk={() => setShowAlert(false)}
          onClose={() => setShowAlert(false)}
        ></ConfiramtionPopup>
      )}
    </div>
  );
  function handleAddToQuote() {
    if (!selectedPump) {
      setShowAlert(true);
      setAlertMessage(t('pump-list-page.warn-select-pump') || 'select a pump');
    }
    if (!tag) {
      setShowAlert(true);
      setAlertMessage(
        t('pump-list-page.warn-enter-tag') || 'Enter a Tag please!',
      );
    } else if (selectedPump && quoteId && userId) {
      setSelectedPump({
        ...selectedPump,
        quoteId: parseInt(quoteId, 10),
        tag,
        qty: parseInt(qty, 10),
        options: selectedOptions,
      });
    }
  }

  function handleBackToQuote() {
    const activeTabIndex = 1;
    sessionStorage.setItem('activeTabIndex', activeTabIndex.toString());
    window.history.go(-2);
  }

  function loadOptions() {
    if (selectedPump) {
      pumpService
        .getPumpOptions(selectedPump.seriesId, -1)
        .then((res) => {
          if (res) {
            setOptions(res);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function showCurve(selected: PumpSearchPump) {
    navigate(`/Pumps/${userId}/Pump-Curve/?quoteId=${quoteId}`, {
      state: { searchPump: selected },
    });
  }
}
