import type { Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import type {
  IColumnVisibility,
  IColumnVisibilityItem,
} from '../../components/react-table/column-visibility-component';

export default function useColumnVisibility(
  table: Table<any>,
  columnVisibility: any,
) {
  const [data, setData] = useState<IColumnVisibility>([]);
  useEffect(() => {
    if (table) {
      const temp = new Array<IColumnVisibilityItem>();
      temp.push({
        titleTranslationKey: 'react-table.column-visibility-toggle-all',
        isVisible: table.getIsAllColumnsVisible(),
        onClick: table.getToggleAllColumnsVisibilityHandler,
      });

      table.getAllFlatColumns().forEach((column) => {
        const titleTranslationKey = column.columnDef.header?.toString() || '';
        temp.push({
          titleTranslationKey,
          isVisible: column.getIsVisible(),
          onClick: () => column.getToggleVisibilityHandler(),
        });
      });
      setData(temp);
    }
  }, [table, columnVisibility]);

  return data;
}
