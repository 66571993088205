export type SelectProps = {
  defaultValue: any;
  onChange: (value: any) => void;
  options: Array<SelectOption>;
  className?: string;
  disabled?: boolean;
};

export type SelectOption = {
  value: any;
  text: string;
};

export function SelectComponent(props: SelectProps) {
  return (
    <select
      className={`form-select ${props.className && props.className}`}
      value={props.defaultValue}
      onChange={(ev) => {
        props.onChange(ev.target.value);
      }}
      disabled={props.disabled}
      style={{ backgroundColor: '#F2F4F8' }}
    >
      {props.options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        );
      })}
    </select>
  );
}
