export class QuotesRelationship {
  id: number;
  parentQuoteId: number;
  childQuoteId: number;

  constructor(data: any) {
    this.id = data.id;
    this.parentQuoteId = data.parentQuoteId;
    this.childQuoteId = data.childQuoteId;
  }
}
