export class Quarter {
  name: string;
  quarterType: number;
  startDate: Date;
  endDate: Date;

  constructor(
    name?: string,
    startDate?: Date,
    endDate?: Date,
    quarterType?: number,
  ) {
    if (!name) throw new ReferenceError('name undefined');
    if (!startDate) throw new ReferenceError('starting date undefined');
    if (!endDate) throw new ReferenceError('ending date undefined');

    this.name = name;
    this.startDate = new Date(startDate);
    this.endDate = new Date(startDate);
    this.quarterType = quarterType || QuarterType.Normal;
  }
}

export enum QuarterType {
  Normal = 0,
  Fiscal = 1,
}
