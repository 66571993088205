import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Page,
  PageInfo,
  PageHeader,
} from '../../components/Pages/page-components';
import type StatisticContent from '../../services/Stats/StatisticContent';
import LineChartComponent from '../../components/Charts/LineChartComponent';
import stringHelper from '../../services/Core/Helpers/string-helper';
import statsService from '../../services/Stats/stats-service';
import OrderTypeDiagram from './production-ordertype-diagram';
import LoadingComponent from '../../components/Core/Loading';
import NavTabsComponent from '../../components/nav-tabs-component';

import 'react-circular-progressbar/dist/styles.css';
import { ProductionJobType } from '../../services/Production/Models/ProductionJob';

export default function Production() {
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [jobsStatIndex, setJobStatIndex] = useState<number>(7);
  const [jobsStat, setJobStat] = useState<StatisticContent>();

  useEffect(() => {
    const now = new Date(Date.now());
    const end = stringHelper.toDateString(now);
    const start = stringHelper.toDateString(
      new Date(Date.now() - jobsStatIndex * 86400000),
    );
    statsService
      .getJobTypes(start, end)
      .then((data) => {
        if (data) {
          setJobStat(data);
        }
      })
      .catch((error) => {
        toast.error(t('project-dashboard-page.get-production-jobs'));
        setError(`${t('project-dashboard-page.get-production-jobs')}${error}`);
      });
  }, [jobsStatIndex, t]);

  useEffect(() => {
    document.title = t('production-page.title');
  });

  return (
    <Page>
      <PageHeader>
        <h2>{t('production-page.title')}</h2>
      </PageHeader>
      <PageInfo>
        <div className="container">
          <div className="row">
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.Welding}
              ></OrderTypeDiagram>
            </div>
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.Package}
              ></OrderTypeDiagram>
            </div>
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.NoType}
              ></OrderTypeDiagram>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.BuySell}
              ></OrderTypeDiagram>
            </div>
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.Electric}
              ></OrderTypeDiagram>
            </div>
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.Pump}
              ></OrderTypeDiagram>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <OrderTypeDiagram
                type={ProductionJobType.Service}
              ></OrderTypeDiagram>
            </div>
          </div>
        </div>
        <div className="container">
          {
            <div
              id="production-jobs-card"
              className="card clickable double-card"
            >
              <div className="card-title m-2 ms-3">
                <h5>{t('project-dashboard-page.production-jobs')}</h5>
                <NavTabsComponent
                  active={jobsStatIndex}
                  navItems={[
                    { key: 7, text: 'Week', onclick: () => setJobStatIndex(7) },
                    {
                      key: 30,
                      text: 'Month',
                      onclick: () => setJobStatIndex(30),
                    },
                    {
                      key: 365,
                      text: 'Year',
                      onclick: () => setJobStatIndex(365),
                    },
                  ]}
                />
              </div>
              <div className="card-body p-0">
                {jobsStat ? (
                  <LineChartComponent
                    height="215px"
                    content={jobsStat}
                  ></LineChartComponent>
                ) : (
                  (error ?? <LoadingComponent />)
                )}
              </div>
            </div>
          }
        </div>
      </PageInfo>
    </Page>
  );
}
