import './supplier-part-number-page.scss';
import { useState } from 'react';
import { listPartNumber } from './SupplierPartNumberListItem';
import { Page } from '../../components/Pages/page-components';

export default function SupplierPartNumberPageCopy() {
  const [selectedIdArray, setSeletedIdArray] = useState(new Array<number>()); // the key is the code item and the value is the code value Id
  return (
    <Page id="supplier-part-number-page">
      <div className="card mt-2">
        <div className="card-header">
          <h2 className="text-center">Supplier Part Number</h2>
        </div>
        <div className="card-body">
          <h5>Code: {getSupplierPartNumber(selectedIdArray)}</h5>
          <table id="part-table">
            <colgroup>
              <col style={{ width: 50 }} />
              <col style={{ width: 50 }} />
              <col style={{ width: 100 }} />
              <col style={{ width: 200 }} />
            </colgroup>
            <tr>
              <th>Code Item</th>
              <th>Marketing Code Value</th>
              <th>Code Item Description</th>
              <th>Code Option Description</th>
            </tr>
            {listPartNumber.map((partNumberCodeItem) => (
              <tbody>
                {partNumberCodeItem.codeValues.map((codeValue, i, array) => {
                  const isSelected =
                    selectedIdArray[partNumberCodeItem.codeItem] ===
                    codeValue.id;
                  return (
                    <tr
                      className={isSelected ? 'selected' : ''}
                      onClick={() => {
                        if (isSelected)
                          selectedIdArray[partNumberCodeItem.codeItem] = -1;
                        else
                          selectedIdArray[partNumberCodeItem.codeItem] =
                            codeValue.id;
                        setSeletedIdArray([...selectedIdArray]);
                      }}
                    >
                      {i === 0 && (
                        <td className="code-item" rowSpan={array.length}>
                          {partNumberCodeItem.codeItem}
                        </td>
                      )}
                      <td className="marketing-code-value">
                        {codeValue.codeValue}
                      </td>
                      <td className="code-item-description">
                        {partNumberCodeItem.description}
                      </td>
                      <td className="code-option-description">
                        {codeValue.desciption}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ))}
          </table>
          <h5>Code: {getSupplierPartNumber(selectedIdArray)}</h5>
        </div>
      </div>
    </Page>
  );
}
function getSupplierPartNumber(selectedIdArray: Array<number>) {
  let supplierPartNumber = '';

  for (let i = 1; i <= listPartNumber.length; i++) {
    if (
      selectedIdArray[i] !== undefined &&
      selectedIdArray[i] !== null &&
      selectedIdArray[i] !== -1
    ) {
      const letter: string | undefined = listPartNumber[i - 1].codeValues.find(
        (codeValue) => codeValue.id === selectedIdArray[i],
      )?.codeValue;
      console.log(selectedIdArray[i]);
      console.log(listPartNumber[i - 1].codeValues);
      supplierPartNumber += `.${letter}`;
    }
  }
  return supplierPartNumber.slice(1);
}
