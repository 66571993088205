import type { QuoteHeatExchanger } from '../HeatExchangers/Models/QuoteHeatExchanger';
import type { QuoteSingleItem } from '../SingleItem/Models/QuoteSingleItem';
import type { BoosterSystem } from '../Systems/BoosterSystem/BoosterSystem';
import type { QuoteDownloadStatus } from './Models/QuoteDownloadStatus';
import type QuoteKit from './Models/QuoteKit';
import type { QuotesRelationship } from './Models/QuotesRelationship';
import type { QuotesSerialNumber } from './Models/QuotesSerialNumber';

export default class Quote {
  id: number;
  jobName?: string;
  client: number;
  clientListId: number;
  user: number;
  clientContact?: string;
  date: Date;
  status?: number;
  po: string;
  poEmailDate?: Date;
  hasRel: boolean;
  isRMA: boolean;
  remark?: string;
  finalPrice?: number;
  showDiscount: boolean;
  showListPrice: number;

  toOrder: boolean;
  downloadedTimes?: number;
  ownedBy?: string;
  reminderEnable: boolean;
  reminder?: Date;

  // Shipping infos
  shipTo?: string;
  datereq?: string;
  shiptopo?: string;
  shipVia?: string;
  shipAcc?: string;
  broker?: string;
  brokerph?: string;
  shipnote?: string;
  note: string;

  quoteDownloadStatuses: Array<QuoteDownloadStatus>;
  quoteHeatExchangers: Array<QuoteHeatExchanger>;
  quoteSingleItems: Array<QuoteSingleItem>;
  boosterSystems: Array<BoosterSystem>;
  quoteKits: Array<QuoteKit>;
  quotesClients: Array<QuotesClient>;
  quoteRelations: Array<QuotesRelationship>;
  quoteSerialNumbers: Array<QuotesSerialNumber>;
  originalQuote?: QuotesRelationship;

  totalNetPrice: number;

  constructor(
    data: any,
    heatExchangers: Array<QuoteHeatExchanger>,
    systems: Array<BoosterSystem>,
    quoteKits: Array<QuoteKit>,
    quoteSingleItems: Array<QuoteSingleItem>,
    quotesClients: Array<QuotesClient>,
    quoteRelations: Array<QuotesRelationship>,
    quoteSerialNumbers: Array<QuotesSerialNumber>,
    quoteDownloadStatuses: Array<QuoteDownloadStatus>,
  ) {
    // if (data.id == null) throw new ReferenceError("id undefined");
    // if (!data.date) throw new ReferenceError("id undefined");
    // if (data.status == null) throw new ReferenceError("id undefined");

    this.id = data.id;
    this.jobName = data.jobName ?? '';
    this.user = data.user;
    this.client = data.client;
    this.clientListId = data.clientListId;
    this.date = new Date(data.date);
    this.po = data.po ?? '';
    this.poEmailDate = data.poEmailDate
      ? new Date(data.poEmailDate)
      : undefined;
    this.status = data.status;
    this.clientContact = data.clientContact ?? '';
    this.ownedBy = data.ownedBy ?? '';
    this.hasRel = data.hasRel ?? false;
    this.isRMA = data.isRMA ?? false;
    this.remark = data.remark ?? '';
    this.finalPrice = data.finalPrice;
    this.showDiscount = data.showDiscount ?? false;
    this.showListPrice = data.showListPrice;

    this.toOrder = data.toOrder;
    this.downloadedTimes = data.downloadedTimes;
    this.reminderEnable = data.reminderEnable ?? false;
    this.reminder = data.reminder ? new Date(data.reminder) : undefined;

    this.shipTo = data.shipTo ?? '';
    this.datereq = data.datereq;
    this.shiptopo = data.shiptopo;
    this.shipVia = data.shipVia;
    this.shipAcc = data.shipAcc;
    this.broker = data.broker;
    this.brokerph = data.brokerph;
    this.shipnote = data.shipnote;
    this.note = data.note;

    this.quoteDownloadStatuses = quoteDownloadStatuses;
    this.quoteHeatExchangers = heatExchangers;
    this.quoteSingleItems = data.quoteSingleItems;
    this.boosterSystems = systems;
    this.quoteKits = quoteKits;
    this.quoteSingleItems = quoteSingleItems;
    this.quotesClients = quotesClients;
    this.quoteRelations = quoteRelations;
    this.quoteSerialNumbers = quoteSerialNumbers;
    this.originalQuote = data.originalQuote;

    this.totalNetPrice = data.totalNetPrice;
  }
}

export class QuotesClient {
  id: number;
  quoteId: number;
  clientId: number;
  contactId: number;

  name: string;
  tel: string;
  email: string;

  constructor(data: any) {
    this.id = data.id;
    this.quoteId = data.quoteId;
    this.clientId = data.clientId;
    this.contactId = data.contactId;

    this.name = data.name;
    this.tel = data.tel;
    this.email = data.email;
  }
}

export class ClientEmail {
  clientId: number;
  contactId: number;

  name: string;
  tel: string;
  fax: string;
  note: string;
  email: string;

  constructor(data: any) {
    this.clientId = data.clientId;
    this.contactId = data.contactId;

    this.name = data.name;
    this.tel = data.tel;
    this.email = data.email;
    this.fax = data.fax;
    this.note = data.note;
  }
}

export class QuoteCust {
  id: number;
  name: string;
  city: string;

  contacts: Array<ClientEmail>;

  constructor(data: any, contacts: Array<ClientEmail>) {
    this.id = data.id;
    this.name = data.name;
    this.city = data.city;

    this.contacts = contacts;
  }
}
