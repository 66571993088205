import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { FormLabel } from 'react-bootstrap';
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from '../../components/react-table/react-table-component';
import NavTabsComponent from '../../components/nav-tabs-component';
import type { ProjectKit } from '../../services/Projects/Model/Project';
import type Project from '../../services/Projects/Model/Project';
import { ProjectState } from '../../services/Projects/Model/Project';
import stringHelper from '../../services/Core/Helpers/string-helper';
import KitTypeBadgeComponent from '../../components/KitTypeIdComponent';
import projectService from '../../services/Projects/project-service';
import LoadingComponent from '../../components/Core/Loading';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import Popup from '../../components/Core/Popup';
import './projects-table.scss';
import Pagination from '../../components/react-table/Pagination';
import { ActivityManagerComponent } from '../../components/activities/ActivitiesManagerComponent';
import { ProjectKitManager } from './ProjectKit/project-kit_Components';
import { SearchBarComponent } from '../../components/Forms/SearchBarComponent';
import {
  ActivityObjectType,
  useCommentActivities,
} from '../../services/Activities/Activity';
import { Page } from '../../components/Pages/page-components';

type IParamTypes = {
  employeeId: string;
};

export default function ProjectsTablePage() {
  const { t } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const [tab, setTab] = useState<number>(0);
  const [projects, setProjects] = useState<Array<Project>>([]);

  const [project, setProject] = useState<Project | undefined>(undefined);
  const [projectInfo, setProjectInfo] = useState<Array<ProjectKit> | undefined>(
    undefined,
  );
  const { comments, loadComments } = useCommentActivities(
    employeeId,
    project?.id,
    'Project',
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const AssignedToOptions = [
    { value: 0, text: '...' },
    { value: 2418, text: 'Project Manager' },
    { value: 717, text: 'Flofab' },
  ];

  const breadcrumbItems = [
    {
      text: t('production-data-page.title'),
      link: `/Dashboard/Projects/${employeeId}`,
    },
    { text: t('production-data-page.projects'), active: true },
  ];

  const columns: any = [
    {
      header: 'Remarks',
      accessorKey: 'remarks',
      cell: (props: any) => {
        return props
          .getValue()
          .split('\n')
          .map((remark: string, index: number) => {
            return (
              <p className="mb-0" key={index}>
                {remark}
              </p>
            );
          });
      },
    },
    {
      header: 'Date',
      accessorKey: 'date',
      cell: (props: any) =>
        stringHelper.toDateString(new Date(props.getValue())),
    },
    {
      header: 'Client',
      accessorKey: 'client',
    },
    {
      header: 'Type',
      accessorKey: 'description',
      cell: (props: any) => {
        return props.getValue().map((description: string, i: number) => {
          return (
            <span key={i}>
              <KitTypeBadgeComponent key={i} type={description} />
              <br />
            </span>
          );
        });
      },
    },
    {
      header: t('projects-page.assigned-to'),
      accessorKey: 'assignedTo',
      cell: (props: any) => {
        const idAssignedTo = parseInt(props.getValue(), 10);

        if (Number.isNaN(idAssignedTo)) {
          return (
            <>{AssignedToOptions.find((x) => x.value === idAssignedTo)?.text}</>
          );
        }
        return <>{props.getValue()}</>;
      },
    },
    {
      header: 'State',
      accessorKey: 'state',
      enableSorting: false,
      filterFn: 'equals',
      cell: (props: any) => {
        return ProjectState[props.getValue()];
      },
    },
  ];

  const reactTable = useReactTable({
    columns,
    data: projects,
    enableColumnFilters: true,
    state: {
      globalFilter,
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    document.title = 'Projects';
    if (employeeId) {
      setTabFilter(0);
      loadProjects();
    }
  }, [employeeId]);
  useEffect(() => {
    if (project) setProject(projects.find((x) => x.id === project.id));
  }, [projects]);
  useEffect(() => {
    if (project === undefined || employeeId === undefined) return;
    setLoading(true);
    projectService
      .getProjectKits(employeeId, project.id)
      .then((res) => {
        if (!res) {
          console.error('Get project kits response is null');
          return;
        }
        setProjectInfo(res);
        setLoading(false);
      })
      .catch(() => {
        toast.error("'Load Project Kits' : Error while fetching data");
      });
  }, [employeeId, project]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <BreadcrumbComponent items={breadcrumbItems} />
      <h2>Projects</h2>
      <div className="m-3"></div>
      <div className="row">
        <div className="col-lg-6">
          <NavTabsComponent
            active={tab}
            navItems={Object.values(ProjectState)
              .filter((v): v is ProjectState => typeof v === 'number')
              .map((state: ProjectState) => {
                const items = projects.filter((item) => item.state === state);
                const html = (
                  <>
                    {`${t(`project-item-state.${ProjectState[state].toLowerCase()}`)}`}
                    <span className="badge bg-secondary">{items.length}</span>
                  </>
                );
                return {
                  key: state,
                  onclick: () => setTabFilter(state),
                  html,
                };
              })}
          />
        </div>
        <div className="col-lg-6">
          <SearchBarComponent
            value={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>

      <table className="table table-hover">
        <thead>
          {reactTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    <span {...getTableHeaderSortProps(header.column)}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <ReactTableHeaderOptions header={header} />
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {reactTable.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} onClick={() => onTableRowClick(row.original.id)}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="align-vertical-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination reactTable={reactTable} siblingCount={2} />

      <Popup
        onOk={() => setProject(undefined)}
        onClose={() => setProject(undefined)}
        show={project !== undefined}
        title={t('projects-page.edit-notes-title')}
      >
        <>
          {project && (
            <Page className="p-2 page-border">
              <div className="card project-card-popup">
                <div className="card-header">
                  {project.remarks.split('\n').map((remark, i) => {
                    return (
                      <h3 key={i} className="h5">
                        {remark}
                      </h3>
                    );
                  })}
                </div>
                <div className="card-title"></div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <FormLabel htmlFor="project-state">
                        {t('projects-page.state')}
                      </FormLabel>
                      <select
                        id="project-state"
                        className="form-select"
                        defaultValue={project.state}
                        onChange={(ev) =>
                          onStateChange(project.id, ev.target.value)
                        }
                      >
                        {Object.values(ProjectState)
                          .filter(
                            (v): v is ProjectState => typeof v === 'number',
                          )
                          .map((state) => {
                            return (
                              <option key={state} value={state}>
                                {t(
                                  `project-item-state.${ProjectState[state].toLowerCase()}`,
                                )}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <FormLabel htmlFor="project-assignedTo">
                        {t('projects-page.assigned-to')}
                      </FormLabel>
                      <select
                        id="project-assignedTo"
                        className="form-select"
                        defaultValue={getAssignedToDefaultValue(
                          project.assignedTo,
                        )}
                        onChange={(ev) =>
                          onAssignedToChange(ev.target.value, project.id)
                        }
                      >
                        {AssignedToOptions.map((option) => {
                          return (
                            <option key={option.text} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-title">
                  <h4 className="h6">Kits</h4>
                </div>
                <div className="card-body pt-0">
                  {projectInfo?.map((projectKit) => {
                    return (
                      <ProjectKitManager
                        key={projectKit.kitId}
                        employeeId={employeeId}
                        projectKit={projectKit}
                        loadProjects={loadProjects}
                      />
                    );
                  })}
                </div>
              </div>

              <hr />

              <ActivityManagerComponent
                userId={employeeId}
                objectId={project?.id}
                objectType={ActivityObjectType.Project}
                activities={comments}
                loadActivities={loadComments}
              ></ActivityManagerComponent>
            </Page>
          )}
        </>
      </Popup>
    </>
  );

  function loadProjects() {
    if (employeeId) {
      projectService
        .getProjects(employeeId)
        .then((res) => {
          if (!res) {
            console.error('Get projects response is null');
          } else {
            // console.log(res)
            setProjects(res);
          }
          setLoading(false);
        })
        .catch(() => {
          toast.error("'Load Projects' : Error while fetching data");
        });
    }
  }

  function onTableRowClick(projectId: number) {
    const project = projects.find((p) => p.id === projectId);
    if (project) {
      setProject({ ...project });
    }
  }

  function onAssignedToChange(assignedTo: any, projectId: any) {
    if (employeeId && projectId) {
      projectService
        .patchProjectAssignedTo(employeeId, projectId, assignedTo)
        .then((res) => {
          if (res === undefined) {
            console.error("'Patch assigned to' : Response is null");
          } else if (res === false) {
            console.error(
              "'Patch assigned to : Error while updating the object'",
            );
          } else {
            const changingProject = projects.find((x) => x.id === projectId);
            if (changingProject) {
              changingProject.assignedTo = assignedTo;
              setProjects([...projects, changingProject]);
            }

            // loadProjects();
          }
        })
        .catch((ex) => {
          console.error(ex);
          toast.error('An error occured while updating');
        });
    }
  }

  function onStateChange(projectId: any, newState: any) {
    if (employeeId && projectId) {
      projectService
        .patchProjectState(employeeId, projectId, newState)
        .then((res) => {
          // console.log(res);
          if (res === undefined) {
            console.error("'Patch completed' : Response is null");
          } else if (res === false) {
            console.error(
              "'Patch completed : Error while updating the object'",
            );
          } else {
            const changingProject = projects.find((x) => x.id === projectId);
            if (changingProject) {
              changingProject.state = parseInt(newState, 10);
              setProjects([...projects, changingProject]);
            }

            // loadProjects();
          }
        })
        .catch((ex) => {
          console.error(ex);
          toast.error('An error occured while updating');
        });
    }
  }

  function getAssignedToDefaultValue(assignedTo: string): number {
    return parseInt(assignedTo, 10);
  }

  function setTabFilter(value: number) {
    setTab(value);
    setColumnFilters([{ id: 'state', value }]);
  }
}
