import './reminder-menu-component.scss';
import { faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useMemo } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { Dispatch, SetStateAction } from 'react';
import type { Client } from '../../../../services/Users/Client';
import { ReminderFormPopup } from '../../../../pages/Reminder/reminder-menu-form-popup';
import type Reminder from '../../../../services/Reminder/model/Reminder';
import remindersService from '../../../../services/Reminder/ReminderService';
import stringHelper from '../../../../services/Core/Helpers/string-helper';
import reminderWebSocketService from '../../../../services/Reminder/ReminderWebSocketService';

type Props = {
  user: Client;
};

export default function ReminderMenuComponent({ user }: Props) {
  const { t } = useTranslation();
  const [remindersArray, setRemindersArray] = useState<Reminder[]>();
  const [isExtended, setIsExtended] = useState(false); // only for x-small screens

  const [isOpen, setIsOpen] = useState(false);
  const [childIsOpen, setChildIsOpen] = useState(false);
  const getReminders = useMemo(
    () =>
      function getReminders() {
        remindersService
          .getUserReminders()
          .then((res) => setRemindersArray(res))
          .catch(() => toast.error(t('reminder-menu.get-reminder-error')));
      },
    [],
  );
  useEffect(() => {
    reminderWebSocketService.getRefreshReminders(user.id, (remindersArray) => {
      setRemindersArray(remindersArray);
    });
    getReminders();
  }, []);
  return (
    <div id="right-menu" className={`no-print ${isExtended && 'extended'}`}>
      <ReminderFormPopup
        user={user}
        isOpen={isOpen}
        closeAction={() => {
          setIsOpen(false);
          getReminders();
        }}
      />
      <Button
        title={`${t('reminder-menu.create-reminder')}`}
        size="lg"
        variant="warning"
        className="create-reminder-button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
      </Button>
      <Button
        title="extend"
        size="lg"
        variant="outline-warning"
        className="extend-reminder-button"
        onClick={() => {
          setIsExtended(true);
        }}
      >
        <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
      </Button>
      <div
        id="reminder-menu"
        className={`box-shadow no-print${isOpen || childIsOpen ? '' : ' hover-extend'}`}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <h5>{t('reminder-menu.reminders')}</h5>
            <button
              className="btn-close"
              id="collapse-button"
              aria-label="Close"
              onClick={() => {
                setIsExtended(false);
              }}
            ></button>
          </div>
          <div id="reminder-container">
            {remindersArray?.map((reminder) => (
              <ReminderComponent
                key={reminder.id}
                reminder={reminder}
                refreshReminder={getReminders}
                user={user}
                setChildIsOpen={setChildIsOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
type ReminderComponentProps = {
  user: Client;
  reminder: Reminder;
  refreshReminder: () => void;
  setChildIsOpen: Dispatch<SetStateAction<boolean>>;
};

function ReminderComponent({
  refreshReminder,
  user,
  reminder,
  setChildIsOpen,
}: ReminderComponentProps) {
  const [isOpen, setIsOpen] = useState(false);

  const today = new Date();
  let dateString = reminder.date
    ? `${stringHelper.toDayMonthString(
        reminder?.date,
      )}, ${stringHelper.toHourMinuteString(reminder.date)}`
    : '';
  if (reminder.date?.getDate() === today.getDate()) {
    dateString = `${stringHelper.toHourMinuteString(reminder.date)}, today`;
  }
  if (reminder.date && reminder.date?.getFullYear() !== today.getFullYear()) {
    dateString = stringHelper.toDateTimeString(reminder.date);
  }

  return (
    <>
      {isOpen && ( // dont preload
        <ReminderFormPopup
          user={user}
          isOpen={isOpen}
          closeAction={() => {
            setChildIsOpen(false);
            setIsOpen(false);
            refreshReminder();
          }}
          reminder={reminder}
        />
      )}
      <div
        key={reminder.id}
        className={`reminder-${reminder.priority}`}
        onClick={() => {
          remindersService
            .checkReminder(reminder)
            .then(() => refreshReminder())
            .catch(() => {
              toast.error('reminder-menu.check-reminder-error');
            });
        }}
      >
        <div>
          <FormCheck
            checked={!!reminder.finishedDate} // bool conversion
            onChange={() => {
              remindersService
                .checkReminder(reminder)
                .then(() => refreshReminder())
                .catch(() => {
                  toast.error('reminder-menu.check-reminder-error');
                });
            }}
          ></FormCheck>
        </div>
        <div className="content">
          <h6
            onClick={(e) => {
              setIsOpen(true);
              setChildIsOpen(true);
              e.stopPropagation();
            }}
            className={`title ${
              reminder.finishedDate ? 'text-decoration-line-through' : ''
            }`}
          >
            {reminder.title}
          </h6>
          <div
            className={`description ${
              reminder.finishedDate ? 'text-decoration-line-through' : ''
            }`}
          >
            {reminder.description}
          </div>
          <div
            className={`date ${
              reminder.finishedDate ? 'text-decoration-line-through' : ''
            }`}
          >
            {dateString}
          </div>
        </div>
      </div>
    </>
  );
}
