import './edit-quote-pump-page.scss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import type { SortingState } from '@tanstack/react-table';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table';
import { PageTitle } from '../../components/Pages/page-components';
import pumpService from '../../services/Pumps/PumpService';
import LoadingComponent from '../../components/Core/Loading';
import type QuoteKit from '../../services/Quotes/Models/QuoteKit';
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from '../../components/react-table/react-table-component';
import type PumpSearchOption from '../../services/Pumps/Models/PumpSerachOption';
import quoteService from '../../services/Quotes/QuoteService';
import { useAvailVoltPhHz } from '../../hooks/Pump/pump-search-hooks';
import motorService from '../../services/Motors/motor-service';
import ConfiramtionPopup from '../../components/Core/Popup-confirmation-Component';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';

type IParamTypes = {
  quoteKitId: string;
  quoteId: string;
  userId: string;
};

export default function EditQuotePumpPage() {
  const { t } = useTranslation();
  const { quoteKitId, quoteId, userId } = useParams<IParamTypes>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { phases } = useAvailVoltPhHz(userId);
  const [horsepower, setHorsepower] = useState<string[]>([]);
  const [selectedPump, setSelectedPump] = useState<QuoteKit | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<PumpSearchOption[]>(
    [],
  );
  const [options, setOptions] = useState<PumpSearchOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const navigate = useNavigate();

  const columnsOptions: any = useMemo(() => {
    return [
      {
        header: t('pump-list-page.option'),
        accessorKey: 'name',
      },
      {
        header: t('pump-list-page.price'),
        accessorKey: 'price',
      },
    ];
  }, [t]);

  const tableOptions = useReactTable({
    columns: columnsOptions,
    data: options,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    loadPumps();
    loadHorsepower();
  }, []);

  useEffect(() => {
    if (selectedPump) {
      loadOptions();
    }
  }, [selectedPump]);

  const handleOptionSelection = (row: PumpSearchOption) => {
    // Toggle the isChecked state in the options array
    const updatedOptions = options.map((option) =>
      option.id === row.id
        ? { ...option, isChecked: !option.isChecked }
        : option,
    );

    // Update the state with the new options
    setOptions(updatedOptions);

    // Update selectedOptions to include only the checked options
    const updatedSelectedOptions = updatedOptions.filter(
      (option) => option.isChecked,
    );
    setSelectedOptions(updatedSelectedOptions);
  };

  return (
    <div id="edit-quote-pump-page">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Edit Pump',
            active: true,
          },
        ]}
      />
      <PageTitle>
        <div className="page-title wrapper">
          <h1 className="h2">{t('edit-quote-pump-page.title')}</h1>
        </div>
      </PageTitle>
      <div className="form">
        <div className="container">
          <div className="row">
            {/* Left Column */}
            <div className="col-md-5">
              <div className="form-container">
                <label htmlFor="pumpType" className="form-label">
                  {t('edit-quote-pump-page.pump-type')}
                </label>
                <FormControl
                  id="pump-type"
                  value={selectedPump?.pumpType}
                  size="sm"
                  className="form-control active"
                  type=""
                  disabled
                />
              </div>

              <div className="form-container">
                <label htmlFor="flwRate" className="form-label">
                  {t('edit-quote-pump-page.flow')}
                </label>
                <FormControl
                  id="flow-rate"
                  value={selectedPump?.flow}
                  size="sm"
                  className="form-control active"
                  type=""
                  disabled
                />
                <select
                  id="flow-rate-unit-measure"
                  className="form-select form-select-sm"
                  defaultValue={0}
                  disabled
                >
                  <option value={0}>{t('unit-measurement.us-gal')}</option>
                  <option value={1}>{t('unit-measurement.m-per-hour')}</option>
                  <option value={2}>{t('unit-measurement.l-per-sec')}</option>
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="head" className="form-label">
                  {t('edit-quote-pump-page.head')}
                </label>
                <FormControl
                  id="head"
                  value={selectedPump?.head}
                  size="sm"
                  className="form-control active"
                  type=""
                  disabled
                />
                <select
                  id="head-unit-measure"
                  className="form-select form-select-sm"
                  defaultValue={0}
                  disabled
                >
                  <option value={0}>{t('unit-measurement.feet')}</option>
                  <option value={1}>{t('unit-measurement.meter')}</option>
                  <option value={2}>{t('unit-measurement.kpa-sign')}</option>
                  <option value={3}>{t('unit-measurement.psi')}</option>
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="minEff" className="form-label">
                  {`${t('edit-quote-pump-page.minEff')} (%)`}
                </label>
                <FormControl
                  id="minEff"
                  value={
                    selectedPump?.minimumEfficiency
                      ? selectedPump?.minimumEfficiency
                      : 0
                  }
                  size="sm"
                  className="form-control active"
                  type=""
                  disabled
                />
              </div>

              <div className="form-container">
                <label htmlFor="construction" className="form-label">
                  {t('pump-search-page.construction')}
                </label>
                <select
                  id="construction"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.sConstruction || ''}
                  disabled
                >
                  <option value={selectedPump?.sConstruction}>
                    {selectedPump?.sConstruction}
                  </option>
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="glycol" className="form-label">
                  {t('edit-quote-pump-page.glycol')}
                </label>
                <FormControl
                  id="glycol"
                  value={selectedPump?.glycol ? selectedPump.glycol : 0}
                  size="sm"
                  className="form-control active"
                  type=""
                  disabled
                />
              </div>
            </div>

            <div className="col-md-1">{/* Spacer column */}</div>

            {/* Right Column */}
            <div className="col-md-5">
              <div className="form-container">
                <FormLabel htmlFor="rpm" className="form-label">
                  {t('edit-quote-pump-page.rpm')}
                </FormLabel>
                <select
                  id="rpm"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.rpm ? selectedPump?.rpm : 1150}
                >
                  <option value={1150}>1150, 60Hz</option>
                  <option value={1750}>1750, 60Hz</option>
                  <option value={3450}>3450, 60Hz</option>
                  <option value={970}>970, 50Hz</option>
                  <option value={1450}>1450, 50Hz</option>
                  <option value={2900}>2900, 50Hz</option>
                  <option value={0}>Other</option>
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="motorType" className="form-label">
                  {t('pump-search-page.motor-type')}
                </label>
                <select
                  id="motorType"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.motorType}
                  disabled
                >
                  <option value={0}>TEFC</option>
                  <option value={1}>ODP</option>
                </select>
              </div>

              <div className="form-checkbox-container">
                <FormCheckInput
                  id="radio_BHP"
                  name="bhp"
                  type="radio"
                  onChange={() => {
                    // setValue("bhp", "BHP")
                    // setValue("isNol", false)
                  }}
                  checked={selectedPump?.nol === false}
                />
                <FormCheckLabel htmlFor="radio_BHP" children={'BHP'} />
                <FormCheckInput
                  id="radio_NOL"
                  name="nol"
                  type="radio"
                  onChange={() => {
                    // setValue("bhp", "NOL")
                    // setValue("isNol", true)
                  }}
                  checked={selectedPump?.nol === true}
                />
                <FormCheckLabel htmlFor="radio_NOL" children={'NOL'} />
              </div>
              <div className="form-container">
                <label htmlFor="selection" className="form-label">
                  Selection
                </label>

                <select
                  id="hp"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.hp || ''}
                  // onChange={(ev) => setValue("hp", ev.target.value)}
                >
                  <option key={0} value={0}>
                    Automatic
                  </option>
                  {horsepower?.map((hp, i) => {
                    return (
                      <option key={i + 1} value={i + 1}>
                        {hp}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="voltPhHz" className="form-label">
                  Volt/PH/Hz
                </label>
                <select
                  id="voltPhHz"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.phase || ''}
                >
                  {phases.map((phase) => {
                    return (
                      <option key={phase[0]} value={phase[0]}>
                        {phase[1]}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-container">
                <label htmlFor="flangType" className="form-label">
                  Flang Type
                </label>
                <select
                  id="flange-type"
                  className="form-select form-select-sm"
                  defaultValue={selectedPump?.flangeType || ''}
                  disabled
                >
                  <option value={selectedPump?.flangeType}>
                    {selectedPump?.flangeType}
                  </option>
                </select>
              </div>

              <div className="form-container">
                <label className="form-check-label" htmlFor="exclude-motor">
                  {t('edit-quote-pump-page.exclude-motor')}
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="excludeMotor"
                  checked={!selectedPump?.withMotor}
                  disabled
                />
              </div>

              <div className="form-container">
                <label className="form-check-label" htmlFor="premium-eff-motor">
                  {t('edit-quote-pump-page.premium-eff-motor')}
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="premiumEffMotor"
                  checked={selectedPump?.premiumEfficiency}
                />
              </div>

              <div className="form-container">
                <label
                  className="form-check-label"
                  htmlFor="premium-eff-motor-not-usa"
                >
                  {t('edit-quote-pump-page.premium-eff-motor-no-us')}
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="premiumEffMotorNotUsa"
                  checked={selectedPump?.premiumEfficiencyMotorNotNorthAmerican}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-containers-top">
        <button className="btn btn-primary" onClick={handleMfv}>
          {t('edit-quote-pump-page.btn-edit-mfv')}
        </button>
        <button className="btn btn-primary" onClick={handleSd}>
          {t('edit-quote-pump-page.btn-edit-sd')}
        </button>
        <button className="btn btn-primary" onClick={handleVfd}>
          {t('edit-quote-pump-page.btn-edit-vfd')}
        </button>
      </div>
      <div className="pump-options">
        <div className="table-overlay">
          <table className="table table-hover">
            <thead>
              {tableOptions.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  <th></th>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id}>
                        <span {...getTableHeaderSortProps(header.column)}>
                          {header.column.columnDef.header?.toString()}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {options.length === 0 ? (
                <tr>
                  <td
                    colSpan={tableOptions.getHeaderGroups()[0].headers.length}
                    className="text-center"
                  >
                    Loading...
                  </td>
                </tr>
              ) : (
                options.length > 0 &&
                options.map((row: PumpSearchOption, rowIndex: number) => (
                  <tr key={rowIndex} onClick={() => handleOptionSelection(row)}>
                    <td className="align-vertical-center">
                      <input
                        type="checkbox"
                        checked={row.isChecked ?? false}
                        onChange={() => handleOptionSelection(row)}
                        onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                      />
                    </td>

                    {tableOptions
                      .getRowModel()
                      .rows[rowIndex].getVisibleCells()
                      .map((cell, cellIndex, cellsArray) => (
                        <td
                          style={{
                            textAlign:
                              cellIndex === cellsArray.length - 1
                                ? 'center'
                                : 'left',
                            direction: 'ltr',
                          }}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="button-containers">
        <button className="btn btn-primary" onClick={handleSave}>
          {t(`edit-quote-pump-page.save`)}
        </button>
      </div>

      {loading && (
        <div className="loading-overlay">
          <LoadingComponent />{' '}
        </div>
      )}

      {showAlert && (
        <ConfiramtionPopup
          show={showAlert}
          title={'Warn!'}
          message={alertMessage}
          onOk={() => setShowAlert(false)}
        ></ConfiramtionPopup>
      )}
    </div>
  );

  function loadOptions() {
    if (selectedPump && quoteKitId) {
      pumpService
        .getPumpOptions(selectedPump.seriesId, quoteKitId)
        .then((res) => {
          if (res) {
            // console.log(res);
            setOptions(res);
          }
        })
        .catch((error) => console.error(error));
    }
  }
  function loadPumps() {
    setLoading(true);
    if (userId && quoteId && quoteKitId) {
      quoteService
        .getQuoteKit(userId, quoteId, quoteKitId)
        .then((res) => {
          if (res) {
            setSelectedPump(res);
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }
  function loadHorsepower() {
    if (userId) {
      motorService
        .getAvailableHorsePower(userId)
        .then((data) => {
          if (data) {
            setHorsepower(data);
          }
        })
        .catch((error) => console.error(error));
    }
  }
  function handleSave() {
    if (!selectedOptions) {
      setShowAlert(true);
      setAlertMessage(
        t('edit-quote-pump-page.warn-select-option') || 'select an option',
      );
    } else if (selectedPump && quoteId && userId && quoteKitId) {
      pumpService
        .addPumpOptions(selectedOptions, userId, '-1', quoteId, quoteKitId)
        .then((res) => {
          if (res) {
            const activeTabIndex = 1;
            sessionStorage.setItem('activeTabIndex', activeTabIndex.toString());
            navigate(`/Quotes/${userId}/${quoteId}`, { replace: true });
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function handleMfv() {
    navigate(`/Pumps/MFV/${userId}/${quoteId}/${quoteKitId}`);
  }
  function handleSd() {
    navigate(`/Pumps/SD/${userId}/${quoteId}/${quoteKitId}`);
  }
  function handleVfd() {
    navigate(`/Pumps/VFD/${userId}/${quoteId}/${quoteKitId}`);
  }
}
