import type { QuoteProduction } from '../../../services/Quotes/Models/QuoteProduction';

interface Props {
  model: QuoteProduction;
}
const QuoteProductionItem = (props: Props) => {
  return (
    <div className="card m-3 bg-warning" style={{ width: '18rem' }}>
      <div>
        <span className="badge text-dark pb-3">{props.model.historyName}</span>
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <h5>{props.model.quoteItemModel}</h5>
      </div>
      <div>
        {props.model.employeeName ? props.model.employeeName : 'unassigend'}
      </div>
    </div>
  );
};

export default QuoteProductionItem;
