// eslint-disable-next-line import/no-cycle
import BaseApi from '../Core/REST/BaseApi';
import type LoginGmail from './LoginGmail';
import type { Client } from '../Users/Client';

class LoginService extends BaseApi {
  async LoginGmail(loginGmail: LoginGmail, isExtended: boolean) {
    const url = `${this.baseUrl}/Login/Login-Gmail/${isExtended}`;
    return this.post(url, loginGmail);
  }
  async Login(loginCredential: LoginCredential, isExtended: boolean) {
    const url = `${this.baseUrl}/Login/login/${isExtended}`;

    return this.post(url, loginCredential);
  }
  async CreateAccountRequestGmail(
    companyName: string,
    countryGmail: string,
    provinceGmail: string,
    loginGmail: LoginGmail,
  ) {
    const url = `${this.baseUrl}/Login/Create-Account-Request-Gmail/${companyName}/${countryGmail}/${provinceGmail}`;
    return this.post(url, loginGmail);
  }
  async LinkClientIdGmail(loginGmail: LoginGmail) {
    const url = `${this.baseUrl}/Login/Link-Account-Gmail`;
    return this.patch(url, loginGmail);
  }
  async CreateAccountRequest(client: Client) {
    const url = `${this.baseUrl}/Login/0`;
    return this.post(url, client);
  }
  async GetLoginUser(token: string): Promise<Client | undefined> {
    const url = `${this.baseUrl}/Login/${token}`;
    return this.get(url);
  }
  async Logout(token: string): Promise<Client | undefined> {
    const url = `${this.baseUrl}/Login/logout/${token}`;
    return this.post(url, {});
  }
  async SendPasswordChangeRequest(email: string) {
    const url = `${this.baseUrl}/Login/Send-Request/${email}`;
    return this.put(url, {});
  }
  async ChangePassword(changePasswordToken: string, password: string) {
    const url = `${this.baseUrl}/Login/Change-Password`;
    return this.patch(url, {
      ChangePasswordToken: changePasswordToken,
      Password: password,
    });
  }
  /**
   * Only work in debug
   */
  async LoginAdmin() {
    // only work in debug
    const url = `${this.baseUrl}/Login/Login-Admin`;
    // console.log(loginCredential);

    return this.post(url, {});
  }
}
export type LoginCredential = {
  username: string;
  password: string;
};
const loginService = new LoginService();
export default loginService;
