import { useMemo } from 'react';
import { Chart } from 'react-google-charts';
import type StatisticContent from '../../services/Stats/StatisticContent';
import numberHelper from '../../services/Core/Helpers/number-helper';

type Props = {
  content: StatisticContent;
  height?: string;
  width?: string;
};

export default function LineChartComponent(props: Props) {
  const colors: Array<string> = [];
  const data = useMemo(() => {
    const temp: any = [['x']];
    props.content.series[0]?.serieItems.forEach((serie) => {
      if (serie) {
        temp.push([serie.name]);
      }
    });
    props.content.series.forEach((content) => {
      if (!content) return;
      if (content.color) colors.push(content.color);
      temp[0].push(
        `${content.title} (${numberHelper.toNumberFormat(content.total)})`,
      );
      content.serieItems.forEach((serie, index) => {
        if (serie && temp[index + 1]) {
          temp[index + 1].push(serie.value);
        }
      });
    });

    return temp;
  }, [props.content]);

  let options = {
    title: props.content.title,
    hAxis: {
      title: props.content.xAxisTitle,
    },
    vAxis: {
      title: props.content.yAxisTitle,
    },
    legend: { position: 'bottom' },
  };
  if (colors && colors.length) {
    options = { ...options, colors } as any;
  }
  return (
    <Chart
      chartType="LineChart"
      width={props.width}
      height={props.height}
      data={data}
      options={options}
    />
  );
}
