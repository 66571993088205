import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import type StatisticContent from '../../services/Stats/StatisticContent';
import statsService from '../../services/Stats/stats-service';

export function useIncomeStatementQuarter(
  employeeId: string | undefined,
  date: Date | undefined,
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [statementQuarter, setStatementQuarter] = useState<StatisticContent>();

  useEffect(loadStatementQuarter, [employeeId, date]);

  function loadStatementQuarter() {
    if (employeeId && date) {
      statsService
        .getIncomeStatementQuarter(employeeId, date)
        .then((data) => {
          if (data) {
            setStatementQuarter(data);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occured while fetching the data for 'Quarter'");
        })
        .finally(() => setLoading(false));
    }
  }

  return {
    statementQuarter,
    loadingStatementQuarter: loading,
    loadStatementQuarter,
  };
}
