import './FileUploaderComponent.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef, useState } from 'react';

interface IProps {
  onUpload: (file: File | null) => void;
  onChoose: (file: File | null) => void;
  onCancel: () => void;
  onDownload?: () => void;
  show: boolean;
  title?: string | null;
}

export default function FileUploader({
  onChoose,
  onCancel,
  onDownload,
  show,
  title,
}: IProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    onChoose(file);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    onCancel();
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      onChoose(file);
    }
  };
  const handleClickInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (!show) {
    return null;
  }
  return (
    <div id="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          {title || 'Upload a File'}
          <button
            className="btn-close"
            aria-label="Close"
            onClick={handleCancel}
          ></button>
        </div>
        <div
          className={`drop-zone ${isDragging ? 'dragging' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClickInput}
        >
          {!selectedFile && (
            <img
              src={`${window.location.origin}/images/Public/upload.png`}
              alt="Upload Icon"
              className="upload-image"
            />
          )}
          <p>
            {selectedFile
              ? selectedFile.name
              : 'Drag & Drop a file or click to select'}
          </p>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="application/pdf, image/*"
            hidden
          />
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onDownload}>
            Download Short Drawing To PDF
          </button>
        </div>
      </div>
    </div>
  );
}
