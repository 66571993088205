import './Popup.scss';

interface IProps {
  onClose: () => void;
  onOk?: () => void;
  children: string | JSX.Element | JSX.Element[];
  show: boolean;
  title?: string | null;
  okLabel?: string | null;
  closeLabel?: string | null;
}

export default function Popup({
  onClose,
  onOk,
  children,
  show,
  title,
  okLabel,
  closeLabel,
}: IProps) {
  if (!show) {
    return <></>;
  }

  return (
    <div className="popup-window">
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            {title && <h5 className="modal-title">{title}</h5>}
            <button
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          {onOk && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onClose}
              >
                {closeLabel || 'Cancel'}
              </button>
              <button type="button" className="btn btn-primary" onClick={onOk}>
                {okLabel || 'Ok'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface IPropsGetInfo {
  onClose: () => void;
  onOk?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: string | JSX.Element | JSX.Element[];
  show: boolean;
  title?: string | null;
}

export function PopupGetInfo({
  onClose,
  onOk,
  children,
  show,
  title,
}: IPropsGetInfo) {
  if (!show) {
    return <></>;
  }

  return (
    <div className="popup-window-sm">
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            {title && <h5 className="modal-title">{title}</h5>}
            <button
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          {onOk && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={onOk}>
                Ok
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
