import './ProgressBarComponent.scss';

export class ProgressBar {
  title: string = '';
  steps: Array<Step> = [];
}

export class Step {
  title: string = '';
  counter?: number;
  state: number = 0;
  size: string = '';
  descriptions: Array<StepDescription> = [];
}

export class StepDescription {
  name: string = '';
  value: string = '';
  color: string = '';
}

export type ProgressBarProps = {
  progressBar: ProgressBar;
};

export function ProgressBarComponent(props: ProgressBarProps) {
  return (
    <div className="stepper-wrapper">
      {props.progressBar.steps.map((step, i) => {
        return (
          <div
            className={`stepper-item stepper-item-lg ${
              // eslint-disable-next-line no-nested-ternary
              step.state === 1 ? 'active' : step.state === 2 ? 'completed' : ''
            }`}
            key={i}
          >
            <div className="step-counter">{i + 1}</div>
            <div className="step-name">
              {step.title}
              {step.descriptions.map((des, j) => (
                <div className={`step-desciption ${des.color} `} key={j}>
                  {des.name ? des.name : ''} {des.value ? des.value : ''}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
