const logo = `${window.location.origin}/flofab_logo.svg`;

type LogoProps = {
  className?: string;
};

export default function LogoComponent(props: LogoProps) {
  const { className } = props;

  const cssClass = className || 'header-logo';

  return <img className={cssClass} src={logo} title="flofab logo" />;
}
