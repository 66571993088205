import { toast } from 'react-toastify';
import type { ProjectKit } from '../../../services/Projects/Model/Project';
import projectService from '../../../services/Projects/project-service';

type ProjectKitManagerProp = {
  employeeId?: string;
  projectKit: ProjectKit;
  loadProjects: () => void;
};

export function ProjectKitManager(props: ProjectKitManagerProp) {
  const { employeeId, projectKit, loadProjects } = props;

  const id = `project-kit-${projectKit.kitId}`;
  return (
    <div className="form-check">
      <input
        id={id}
        className="form-check-input"
        type="checkbox"
        checked={projectKit.completed}
        onChange={(ev) => onProjectKitChange(ev.target.checked)}
      />
      <label className="form-check-label" htmlFor={id}>
        {projectKit.kitId}
      </label>
    </div>
  );

  function onProjectKitChange(completed: boolean) {
    if (employeeId) {
      if (projectKit.id === 0 && completed) {
        // console.log("in creation");
        createProjectKit(employeeId, projectKit.projectId, projectKit.kitId);
      } else if (projectKit.id !== 0 && completed === false) {
        // console.log("in deletion");
        deleteProjectKit(employeeId, projectKit.id);
      }
    }
  }

  function createProjectKit(
    employeeId: string,
    projectId: number,
    kitId: string,
  ) {
    projectService
      .postProjectKitCompleted(employeeId, projectId, kitId)
      .then((data) => {
        if (data == null || data === false) {
          toast.error(`Failed to changed the kit '${kitId}' to completed`);
        } else {
          loadProjects();
        }
      })
      .catch(() => {
        toast.error(`Failed to changed the kit '${kitId}' to completed`);
      });
  }

  function deleteProjectKit(employeeId: string, projectKitId: number) {
    projectService
      .deleteProjectKitCompleted(employeeId, projectKitId)
      .then((data) => {
        if (data == null || data === false) {
          toast.error(
            `Failed to changed the kit '${projectKit.kitId}' to not completed`,
          );
        } else {
          loadProjects();
        }
      })
      .catch(() => {
        toast.error(
          `Failed to changed the kit '${projectKit.kitId}' to not completed`,
        );
      });
  }
}
