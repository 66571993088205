import { useEffect, useState } from 'react';
import pumpService from '../../services/Pumps/PumpService';
import PumpSearchConfig from '../../services/Pumps/Models/PumpSearchConfig';

export function useAvailPumpConstructions(userId?: string, pumpType?: number) {
  const [state, setState] = useState<Array<string>>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadPumpConstructions = () => {
    if (userId && pumpType && pumpType !== 0) {
      pumpService
        .getPumpAvailableConstructions(userId, pumpType)
        .then((data) => {
          if (data) {
            const constructions = [...data, 'All Constructions'];
            setState(constructions);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(loadPumpConstructions, [userId, pumpType]);

  return { constructions: state, constructionLoading: loading };
}

export function useAvailFlangeTypes(userId?: string, pumpType?: number) {
  const [state, setState] = useState<Array<string>>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadAvailablePumpTypes = () => {
    if (userId && pumpType && pumpType !== 0) {
      pumpService
        .getPumpAvailableFlangeTypes(userId, pumpType)
        .then((data) => {
          // console.log("getPumpAvailableFlangeTypes:" + data)
          // console.log(data);
          if (data) setState(data);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(loadAvailablePumpTypes, [userId, pumpType]);

  return { flangeTypes: state, flangeTypesLoading: loading };
}

export function useAvailVoltPhHz(userId?: string) {
  const [state, setState] = useState<string[][]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const load = () => {
    if (userId) {
      pumpService
        .getPumpAvailableVoltPhHz(userId)
        .then((data) => {
          if (data) {
            setState(data);
          }
        })
        .catch((_) => {})
        .finally(() => setLoading(false));
    }
  };

  useEffect(load, [userId]);

  return { phases: state, PhasesLoading: loading };
}

export function useConfigPumpSearch(userId?: string, pumpType?: number) {
  const [state, setState] = useState<PumpSearchConfig>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadConfigPumpSearch = () => {
    if (userId && pumpType && pumpType !== 0) {
      pumpService
        .getConfigPumpSearch(userId, pumpType)
        .then((data) => {
          if (data) setState(new PumpSearchConfig(data));
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(loadConfigPumpSearch, [userId, pumpType]);

  return { configPumpSearch: state, configPumpSearchBySerieLoading: loading };
}
