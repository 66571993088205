export default class ProductionJob {
  orderDate?: Date = new Date();
  shippingDate?: Date = new Date();
  requestedDate?: Date = new Date();

  status: ProductionJobStatus = ProductionJobStatus.Normal;
  job: string = '';
  jobType: ProductionJobType = ProductionJobType.NoType;
  jobType2?: ProductionJobType = ProductionJobType.NoType;
  quote: number = 0;
  date: Date = new Date();
  items: string = '';
  quantity: number = 0;
  picked: ProductionJobPickedStatus = ProductionJobPickedStatus.NotPicked;
  stages: ProductionJobStage[] = [];
  employees: string[] = [];
  client: string = '';
  progress: number = 0;
  note?: string;
  oldPortalUrl?: string;
  workTime?: number = 0;
  purchaseOrder?: string = '';
  amount?: number = 0;
  prodValue?: number = 0;
  invoices?: string[] = [];
}

export enum ProductionJobStatus {
  Normal = 1,
  Rush,
  TwoWeeks,
  Hold,
  Pending,
  Discard,
  ExpeditedRush,
  Overwrite,
}

export enum ProductionJobStage {
  Picking,
  BackOrder,
  Production,
  Finition,
  Electric,
  QualityControl,
  Packaging,
  ShippingPreparation,
  PartialReadyToShip,
  ReadyToShip,
  PartialShipped,
  Dropship,
  Shipped,
}

export enum ProductionJobPickedStatus {
  NotPicked,
  Partial,
  Full,
}

export enum ProductionJobType {
  Package = 1,
  Pump = 2,
  Welding = 3,
  BuySell = 4,
  Service = 6,
  Electric = 7,
  NoType = 5,
}
