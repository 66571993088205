import { useEffect, useMemo, useState } from 'react';
import type { GoogleChartWrapperChartType } from 'react-google-charts';
import Chart from 'react-google-charts';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePurchasePacToolStats from '../../../hooks/Sales/usePurchasePacTool';

type Props = {
  employeeId?: string;
  defaultYear: number;
  type?: string;
};

export default function ToolPacPurchaseGraphComponent(props: Props) {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [chartType, setChartType] =
    useState<GoogleChartWrapperChartType>('BarChart');
  const { purchasePacTool, purchasePacToolLoading } = usePurchasePacToolStats(
    props.employeeId,
    startDate,
    endDate,
    props.type,
  );

  useEffect(() => {
    if (props.defaultYear) {
      // console.log(props.defaultYear);
      onChangeYear(props.defaultYear);
    }

    if (props.type && props.type != null) {
      setChartType('Bar');
    }
  }, [props]);

  const options = {
    hAxis: {
      title: 'Total costs',
      minValue: 0,
    },
    vAxis: {
      title: 'Accounts',
    },
  };

  const data = useMemo(() => {
    const temp: any[][] = [];

    if (props.type === 'past-years') {
      temp.push(['Year', 'Total']);
    } else {
      temp.push(['Accounts', 'Total', 'Budget']);
    }

    if (purchasePacTool) {
      purchasePacTool.series.forEach((serie) => {
        const arr = [];
        arr.push(serie.title, serie.total);

        if (props.type === 'year' || props.type == null) {
          arr.push(serie.serieItems[0].goal ?? 0);
        }

        temp.push(arr);
      });
    }

    // console.log(temp);
    return temp;
  }, [purchasePacTool]);

  if (purchasePacToolLoading) {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }

  return (
    <div className="card">
      <div className="card-header">
        Détails des achats d'équipements Outil Pac pour l'année{' '}
        {startDate?.getFullYear()}
      </div>
      <div className="card-body">
        {data && (
          <Chart
            chartType={chartType}
            width="100%"
            height="250px"
            data={data}
            options={options}
          />
        )}
      </div>
    </div>
  );

  function onChangeYear(year: number) {
    let startYear = year;
    if (props.type === 'past-years') {
      startYear = year < 2028 ? 2018 : year - 10;
    }

    setStartDate(new Date(startYear, 0, 1));
    setEndDate(new Date(year, 11, 31));
  }
}
