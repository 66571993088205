import type { Quarter } from './Quarter';

export class QuarterReport {
  title: string;
  series: Array<QuarterReportSerie>;

  constructor(title: string, series: Array<QuarterReportSerie>) {
    this.title = title;
    this.series = series;
  }

  public getTotalsPerQuarter(): number[][] {
    const totals = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ];
    this.series.forEach((serie) => {
      serie.reportSerieItems.forEach((item, i) => {
        totals[i][0] += item.budget;
        totals[i][1] += item.total;
      });
    });

    return totals;
  }

  public getTotalPerQuarter(): Array<number> {
    const totals = [0, 0, 0, 0];
    this.series.forEach((serie) => {
      serie.reportSerieItems.forEach((item, i) => {
        totals[i] += item.total;
      });
    });

    return totals;
  }
}

export class QuarterReportSerie {
  name: string;
  reportSerieItems: Array<QuarterReportSerieItem>;

  constructor(name: string, reportSerieItems: Array<QuarterReportSerieItem>) {
    this.name = name;
    this.reportSerieItems = reportSerieItems;
  }
}

export class QuarterReportSerieItem {
  total: number;
  budget: number;
  quarter: Quarter;

  constructor(quarter: Quarter, total?: number, budget?: number) {
    if (total == null) throw new ReferenceError('total undefined');
    if (budget == null) throw new ReferenceError('budget undefined');

    this.total = total;
    this.budget = budget;
    this.quarter = quarter;
  }
}
