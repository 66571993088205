import './vfd-selection.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ConfiramtionPopup from '../../../components/Core/Popup-confirmation-Component';

import type {
  VfdRow,
  VfdSelectionTable,
} from '../../../services/Vfd/Models/VfdSelectionTable';
import vfdService from '../../../services/Vfd/vfd-service';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';

type IParamTypes = {
  userId: string;
  quoteKitId: string;
  quoteId: string;
};

export default function VfdSelectionPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();

  const { userId, quoteId, quoteKitId } = useParams<IParamTypes>();
  const location = useLocation();
  const selectedPump = location.state?.data || [];

  const [vfdSelectionTable, setVfdSelectionTable] =
    useState<VfdSelectionTable>();
  const [selectedVfd, setSelectedVfd] = useState<VfdRow>();
  const [isAddToQuote, setIsAddToQuote] = useState<boolean>(false);

  useEffect(() => {
    if (userId && quoteKitId) {
      loadVfds();
    }
  }, []);

  useEffect(() => {
    if (userId && quoteKitId && isAddToQuote) {
      addToQuote();
    }
  }, [isAddToQuote]);

  return (
    <div id="vfd-selection-page">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Pump Search',
            link: `/Pumps/${userId}/Pump-Search/${quoteId}`,
          },
          {
            text: 'Variable Frequency Drive',
            active: true,
          },
        ]}
      />
      <div className="page-title wrapper">
        <h1 className="h3">{t('vfd-selection-page.title-tb1')}</h1>
      </div>
      <div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th className="td-name-title">VFD</th>
                <th>{t('vfd-selection-page.list-price')}</th>
              </tr>
            </thead>
            <tbody>
              {vfdSelectionTable?.vfds.map((row: VfdRow, rowIndex: number) => (
                <React.Fragment key={rowIndex}>
                  <tr key={rowIndex} className="table-row">
                    <td className="align-vertical-center">
                      <input
                        type="checkbox"
                        checked={row.isChecked ?? false}
                        onChange={() => handleVfdSelection(row)}
                        onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                      />
                    </td>
                    <td className="td-name">{row.name}</td>
                    <td>{row.listPrice}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="expanded-content">
                      {row.name}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="button-containers">
            <button className="btn btn-primary" onClick={handleAddToQuote}>
              {t(`vfd-selection-page.add-to-quote`)}
            </button>
            {selectedPump && (
              <button className="btn btn-primary" onClick={handleSkip}>
                {t(`vfd-selection-page.skip`)}
              </button>
            )}
          </div>
        </div>
      </div>
      {showAlert && (
        <ConfiramtionPopup
          show={showAlert}
          title={'Warn!'}
          message={alertMessage}
          onOk={() => setShowAlert(false)}
          onClose={() => setShowAlert(false)}
        ></ConfiramtionPopup>
      )}
    </div>
  );

  function loadVfds() {
    if (userId && quoteKitId) {
      vfdService
        .getPumpVfds(userId, quoteKitId)
        .then((res) => {
          if (res) {
            setVfdSelectionTable(res);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function addToQuote() {
    if (userId && quoteId && quoteKitId && selectedVfd) {
      vfdService
        .addVFDtoQuote(userId, quoteId, quoteKitId, selectedVfd)
        .then((res) => {
          if (res) {
            toast.success(t(`Vfd-selection-page.success-added`));
            const activeTabIndex = 1;
            sessionStorage.setItem('activeTabIndex', activeTabIndex.toString());
            navigate(`/Quotes/${userId}/${quoteId}`, { replace: true });
          }
        })
        .catch((error) => console.error(error));
    }
  }
  function handleAddToQuote() {
    if (selectedVfd) setIsAddToQuote(true);
    else {
      setShowAlert(true);
      setAlertMessage(
        t('Vfd-selection-page.warn-select-Vfd') ||
          'select a Suction Diffuser valve',
      );
    }
  }
  function handleVfdSelection(vfd: VfdRow) {
    // setSelectedVfd(Vfd);
    setVfdSelectionTable((prevState) => {
      if (!prevState) return { vfds: [] };
      return {
        ...prevState,
        vfds: prevState.vfds.map((row) => ({
          ...row,
          isChecked: row.id === vfd.id,
        })),
      };
    });
    setSelectedVfd(vfd);
  }
  function handleSkip() {}
}
