export function getValueOfObject(accessor: string, obj: any) {
  if (obj) {
    const keys = accessor.split('.');

    let value = obj;
    for (let i = 0; i < keys.length; ++i) {
      if (value) {
        const temp = value[keys[i]];
        // console.log(temp, keys[i]);
        value = temp as any;
      }
    }

    return value;
  }
  return undefined;
}

export function isNull(value: number | null | undefined) {
  return value == null;
}
