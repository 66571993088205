import type { NavigateFunction } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import loginService from './LoginService';

const keyId = 'loggedUser';
export function createSession(employeeId: string | undefined) {
  if (employeeId) sessionStorage.setItem(keyId, employeeId);
  else throw Error('Id is null in createSession');
}
export function deleteSession() {
  sessionStorage.removeItem(keyId);
  localStorage.removeItem(keyId);
}
export function logout(navigate: NavigateFunction) {
  const token = getSessionId();
  deleteSession();
  loginService.Logout(token);
  navigate('/');
  window.location.reload();
}
export function createSessionRememberMe(employeeId: string | undefined) {
  // also put it in localstorage so that the session is still active when the page close
  if (employeeId) {
    sessionStorage.setItem(keyId, employeeId);
    localStorage.setItem(keyId, employeeId);
  } else throw Error('Id is null in createSessionRememberMe');
}
export function getSessionIdStringEmptyIfNull() {
  let employeeId = sessionStorage.getItem(keyId);
  employeeId = employeeId === null ? localStorage.getItem(keyId) : employeeId; // also check is localStorage if the user checked "RememberMe"
  if (employeeId !== null) {
    return employeeId;
  }
  return '';
}
export function getSessionId() {
  let employeeId = sessionStorage.getItem(keyId);
  employeeId = employeeId === null ? localStorage.getItem(keyId) : employeeId; // also check is localStorage is the user checked "RememberMe"
  if (employeeId !== null) {
    return employeeId;
  }
  throw Error('loggedUser is null');
}
export function checkSessionId() {
  let employeeId = sessionStorage.getItem(keyId);
  employeeId = employeeId === null ? localStorage.getItem(keyId) : employeeId; // also check is localStorage is the user checked "RememberMe"
  return employeeId !== null;
}
