import BaseApi from '../Core/REST/BaseApi';
import type { Client } from '../Users/Client';
import type Reminder from './model/Reminder';

class RemindersService extends BaseApi {
  async getUserReminders(): Promise<Array<Reminder> | undefined> {
    const url = `${this.baseUrl}/Reminder/get-user-reminders`;
    const reminderArray = await this.get<Array<Reminder> | undefined>(url);
    if (reminderArray)
      reminderArray.forEach((reminder) => {
        if (reminder.date) reminder.date = new Date(`${reminder.date}Z`);
      });
    return reminderArray;
  }
  async createReminder(reminder: Reminder) {
    const url = `${this.baseUrl}/Reminder/create-reminder`;
    return this.put(url, reminder);
  }
  async updateReminder(reminder: Reminder) {
    const url = `${this.baseUrl}/Reminder/update-reminder`;
    return this.patch(url, reminder);
  }
  async checkReminder(reminder: Reminder) {
    const url = `${this.baseUrl}/Reminder/check-reminder`;
    return this.patch(url, reminder);
  }
  async getRemindableUser(): Promise<Array<Client> | undefined> {
    const url = `${this.baseUrl}/Reminder/get-remindable-users`;
    return this.get<Array<Client> | undefined>(url);
  }
  async GetRemindersAssignedBy(): Promise<Array<Reminder> | undefined> {
    const url = `${this.baseUrl}/Reminder/get-reminders-assigned-by`;
    const reminderArray = await this.get<Array<Reminder> | undefined>(url);
    if (reminderArray)
      reminderArray.forEach((reminder) => {
        if (reminder.date) reminder.date = new Date(`${reminder.date}Z`);
        if (reminder.finishedDate)
          reminder.finishedDate = new Date(`${reminder.finishedDate}Z`);
      });
    return reminderArray;
  }
}

const remindersService = new RemindersService();
export default remindersService;
