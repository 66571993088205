import './users.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash, faX, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import type { AxiosError } from 'axios';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import { Client } from '../../services/Users/Client';
import {
  Page,
  PageDetails,
  PageHeader,
  PageTitle,
} from '../../components/Pages/page-components';
import type { Department } from '../../services/Departments/Department';
import departmentService from '../../services/Departments/departmentService';
import rolesService from '../../services/Roles/RolesService';
import type { Role } from '../../services/Roles/Roles';
import userService from '../../services/Users/UserService';
import type { Customer } from '../../services/Users/Customer';
import CheckAllowedComponent from '../../components/CheckAllowedComponent';
import { CollapseComponent } from '../../components/CollapseComponent';
import {
  CreateNewDepartmentFormPopup,
  DefaultDiscountFormPopup,
  getProvince,
} from './user-form-components';
import defaultDiscountService from '../../services/DefaultDiscount/defaultDiscountService';
import type { DefaultDiscount } from '../../services/DefaultDiscount/DefaultDiscount';
import stringHelper from '../../services/Core/Helpers/string-helper';

type IParamTypes = {
  userId: string;
};
type Props = {
  employee: Client;
};
export default function UserForm({ employee }: Props) {
  const { t } = useTranslation();
  const { userId } = useParams<IParamTypes>();
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState<Client>();
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [title, setTitle] = useState('');
  const [customerArray, setCustomerArray] = useState<Array<Customer>>();
  const navigate = useNavigate();
  const [createDepartment, setCreateDepartment] = useState<boolean>(false);
  const [updateDefaultDiscount, setUpdateDefaultDiscount] =
    useState<boolean>(false);
  const [departmentArray, setDepartmentArray] = useState<Array<Department>>();
  const [roleArray, setRoleArray] = useState<Array<Role>>();
  const [collapsed, setCollapsed] = useState(true);
  const [discountUSA, setDiscountUSA] = useState<DefaultDiscount>();
  const [discountCAN, setDiscountCAN] = useState<DefaultDiscount>();

  const employeeId: string = employee.id.toString();

  useEffect(() => {
    defaultDiscountService
      .getAll(employeeId)
      .then((data) => {
        setDiscountUSA(data?.find((x) => x.country === 'USA'));
        setDiscountCAN(data?.find((x) => x.country === 'CAN'));
      })
      .catch(() => {
        toast.error(t('user-form-page.load-defaultDiscount-error'));
      });
  }, [updateDefaultDiscount]);
  useEffect(() => {
    departmentService
      .getAll()
      .then((data) => setDepartmentArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-department-error'));
      });
    rolesService
      .getAll()
      .then((data) => setRoleArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-role-error'));
      });
  }, []);
  useEffect(() => {
    if (userId) {
      loadUser();
      setTitle(`${t('user-form-page.edit')}`);
    } else {
      setEditing(true);
      setTitle(`${t('user-form-page.create')}`);
      setUser(new Client(-1, '', '', '', false, '', false));
    }
    userService
      .getAllCust(employeeId || '')
      .then((data) => setCustomerArray(data))
      .catch(() => toast.error(t('user-form-page.load-customer-error')));

    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && editing) {
        event.preventDefault();
        submitUser();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
  }, [userId, employeeId]);
  const active = editing ? 'active' : '';
  return (
    <CheckAllowedComponent
      user={employee}
      objectName="alter-user"
      redirect={true}
    >
      <DefaultDiscountFormPopup
        employeeId={employeeId}
        isOpen={updateDefaultDiscount}
        closeAction={() => setUpdateDefaultDiscount(false)}
      />
      <Page id="user-details">
        <PageHeader className="d-block">
          <BreadcrumbComponent
            items={[
              {
                text: t('manage-users-page.manage-users'),
                link: `/ManageUsers/${employeeId}`,
              },
              {
                text: title,
                active: true,
              },
            ]}
          />
        </PageHeader>

        <PageTitle>
          <div className="row text-center">
            <div className="col-auto align-self-center">
              <h1 className="h2">
                {userId && user && (
                  <img
                    className="avatar me-2"
                    title="profil Image"
                    onError={(e) => {
                      e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg`;
                    }}
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/${
                      user.logoPath ? user.logoPath : 'default.jpg'
                    }`}
                  ></img>
                )}
                {title}
              </h1>
            </div>
            <div className="col-auto align-self-center">
              <Button size="sm" onClick={() => submitUser()}>
                {editing ? 'Save' : 'Edit'}
              </Button>
              {userId && (
                <Button
                  size="sm"
                  className="ms-3 btn-outline-primary red"
                  onClick={() => navigate(`/DeleteUser/${userId}/${user?.nom}`)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
            <div className="col-auto align-self-center"></div>
          </div>
        </PageTitle>
        <PageDetails>
          <div className="user-info-container">
            <div className="row">
              <div className="col-12 mb-3">
                <label className="form-label" htmlFor="nom">
                  {t('user.nom')}
                </label>
                <FormControl
                  id="nom"
                  defaultValue={user?.nom}
                  size="sm"
                  className={active}
                  onChange={(ev) => setValue('nom', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="col-12 mb-3">
                <label className="form-label" htmlFor="email">
                  {t('user.email')}
                </label>
                <FormControl
                  id="email"
                  value={user ? user.email : ''}
                  size="sm"
                  className={active}
                  onChange={(ev) => setValue('email', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              {isPasswordEmpty ? (
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="password">
                    {t('user.password')}
                  </label>
                  <FormControl
                    id="password"
                    type="password"
                    defaultValue={user?.password}
                    size="sm"
                    className={active}
                    onChange={(ev) => setValue('password', ev.target.value)}
                    disabled={!editing}
                  />
                </div>
              ) : (
                <EditPasswordForm />
              )}
              <div className="col-12 mb-3">
                <label className="form-label" htmlFor="nomCompagnie">
                  {t('user.nomCompagnie')}
                </label>
                <FormControl
                  id="nomCompagnie"
                  className={active}
                  defaultValue={user?.nomCompagnie}
                  onChange={(ev) => setValue('nomCompagnie', ev.target.value)}
                  size="sm"
                  disabled={!editing}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <label className="form-label" htmlFor="actif">
                  {t('user.actif')}
                </label>
                <FormCheck
                  className={active}
                  disabled={!editing}
                  checked={user?.actif ? user.actif : false}
                  onChange={(_) => setValue('actif', !user?.actif)}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <label className="form-label" htmlFor="employee">
                  {t('user.employee')}
                </label>
                <FormCheck
                  className={active}
                  disabled={!editing}
                  checked={user?.employee ? user.employee : false}
                  onChange={() => {
                    if (user?.employee) {
                      setUser({
                        ...user,
                        employee: false,
                        departmentId: undefined,
                        roleId: undefined,
                      } as Client);
                    } else {
                      setUser({
                        ...user,
                        employee: true,
                        dynacomCustId: undefined,
                      } as Client);
                    }
                  }}
                />
              </div>
              {user?.employee ? (
                <DepartementAndRoleSelectInput />
              ) : (
                <>
                  <DynacomCustomerSelectInput />
                  <CountrySelectInput />
                  {DiscountInputs()}
                </>
              )}
            </div>
          </div>
        </PageDetails>
      </Page>
    </CheckAllowedComponent>
  );
  function validateUser(user: Client) {
    if (user.nom === '') {
      toast.error(t('user-form-page.empty-name'));
      return false;
    }
    if (!stringHelper.RegExpEmailAdress.test(user.email)) {
      const errorMsg =
        t('email-config-table.invalid-email-format') + user.email;
      toast.error(errorMsg);
      return false;
    }
    if (
      !(isPasswordEmpty && !user.password) &&
      !stringHelper.RegExpPassword.test(user.password)
    ) {
      /// /if the password was empty and still is its valid beacause it can be a accout with only email login
      toast.error(t('user-form-page.invalid-password-format'));
      return false;
    }
    /*
    if (user.employee) {
      if (user.departmentId === undefined || user.departmentId === null) {
        toast.error(t("user-form-page.empty-departmentId"));
        return false;
      }
      if (user.roleId === undefined || user.roleId === null) {
        toast.error(t("user-form-page.empty-roleId"));
        return false;
      }
    } */

    return true;
  }
  function submitUser() {
    if (editing) {
      if (
        employeeId !== undefined &&
        user !== undefined &&
        validateUser(user)
      ) {
        userService
          .postUser(employeeId, user)
          .then((data) => {
            // data is 1 if update and inserted Id if create
            if (userId && user.password && isPasswordEmpty)
              // handle the case of a user becoming active after a edit
              savePassword(user.password);
            navigate(`/UserForm/${data === 1 ? userId : data}`);
            loadUser();
            setEditing(false);
          })
          .catch((err: AxiosError) => {
            if (err.response?.status === 409)
              toast.error(t(`user-form-page.${err.response?.data}`));
            else toast.error(t('user-form-page.post-error'));
          });
      }
    } else {
      setEditing(true);
    }
  }

  function loadUser() {
    if (userId) {
      userService
        .getUser(userId)
        .then((data) => {
          if (data) {
            setUser(data);
            setIsPasswordEmpty(!data.password);
          }
        })
        .catch(() => {
          toast.error(t('user-form-page.load-error'));
        });
    }
  }
  function setValue(key: string, value: any) {
    setUser({ ...user, [key]: value } as Client);
  }
  function savePassword(password: string) {
    let sucess = false;
    if (
      employeeId !== undefined &&
      password !== undefined &&
      stringHelper.RegExpPassword.test(password)
    ) {
      userService
        .updatePassword({ ...user, password } as Client)
        .then(() => {
          setValue('password', password);
          sucess = true;
          loadUser();
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 512)
            toast.error(t('user-form-page.name-password-not-unique'));
          else toast.error(t('user-form-page.post-password-error'));
        });
    } else {
      toast.error(t('user-form-page.invalid-password-format'));
    }
    return sucess;
  }
  function EditPasswordForm() {
    const [editingPassword, setEditingPassword] = useState(false);
    const [password, setPassword] = useState('');
    return (
      <div className="col-12 mb-3" id="modify-passwordContainer">
        <label className="form-label" htmlFor="email">
          {t('user.password')}
        </label>
        <Button
          className="ms-3"
          size="sm"
          onClick={() => onTitlePasswordBtnClick()}
        >
          {editingPassword ? 'Save' : 'Edit'}
        </Button>
        {editingPassword && (
          <Button
            className="ms-3 btn-outline-primary red"
            size="sm"
            onClick={() => {
              setEditingPassword(false);
            }}
          >
            <FontAwesomeIcon icon={faX} />
          </Button>
        )}
        <FormControl
          id="password"
          type="password"
          value={editingPassword ? password : '******'}
          size="sm"
          className={editingPassword ? 'active' : ''}
          onChange={(ev) => setPassword(ev.target.value)}
          disabled={!editingPassword}
        />
      </div>
    );
    function onTitlePasswordBtnClick() {
      if (editingPassword) {
        if (savePassword(password)) setEditingPassword(false);
      } else {
        setPassword('');
        setEditingPassword(true);
      }
    }
  }

  function DynacomCustomerSelectInput() {
    let options = customerArray?.map((cust) => ({
      value: cust.inCustId,
      label: cust.name,
      email: cust.email,
    }));
    if (options === undefined || user === undefined) return <div />;
    options = [
      { value: -1, label: t('user-form-page.select-none'), email: '' }, // if selected put null in the database
      ...options,
    ];
    return (
      <>
        <div className="col-12 mb-3">
          <FormLabel htmlFor="dynacomCustId">
            {t('user.dynacomCustId')}
          </FormLabel>
          <Select
            id="dynacomCustId"
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!editing}
            value={options.find((x) => x.value === user.dynacomCustId)}
            options={options}
            onChange={(ev) => {
              setUser({
                ...user,
                dynacomCustId: ev?.value === -1 ? undefined : ev?.value,
              });
            }}
          />
        </div>
      </>
    );
  }
  function CountrySelectInput() {
    const optionsCountry = ['Us', 'Canada'].map((country) => ({
      value: country,
      label: country,
    }));
    const optionsProvince = getProvince(user?.country ? user.country : '')?.map(
      (province) => ({
        value: province.name,
        label: province.name,
      }),
    );
    return (
      <>
        <div className="col-6 mb-3">
          <FormLabel htmlFor="countryId">{t('user.country')}</FormLabel>
          <Select
            id="countryId"
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!editing}
            value={optionsCountry.find((x) => x.value === user?.country)}
            options={optionsCountry}
            onChange={(ev) => setValue('country', ev?.value)}
          />
        </div>
        <div className="col-6 mb-3">
          <FormLabel htmlFor="countryId">{t('user.province')}</FormLabel>
          <Select
            id="countryId"
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!editing}
            value={optionsProvince?.find((x) => x.value === user?.province)}
            options={optionsProvince}
            onChange={(ev) => setValue('province', ev?.value)}
          />
        </div>
      </>
    );
  }
  function DepartementAndRoleSelectInput() {
    let optionsDepartment = departmentArray?.map((department) => ({
      value: department.id,
      label: department.name,
    }));
    const optionsRole = roleArray?.map((role) => ({
      value: role.id,
      label: role.name,
    }));

    if (
      optionsRole === undefined ||
      optionsDepartment === undefined ||
      user === undefined
    )
      return <div />;
    optionsDepartment = [
      ...optionsDepartment,
      { value: -1, label: t('user-form-page.new-department') },
    ];
    return (
      <>
        <div className="mb-2 col-md-6 col-sm-12">
          <FormLabel htmlFor="departmentId">{t('user.department')}</FormLabel>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!editing}
            isSearchable={false}
            id="departmentId"
            value={optionsDepartment.find((x) => x.value === user.departmentId)}
            options={optionsDepartment}
            onChange={(ev) => {
              if (ev?.value === -1) setCreateDepartment(true);
              else setValue('departmentId', ev?.value);
            }}
          />
          <CreateNewDepartmentFormPopup
            createDepartmentAction={(newDepartment: Department, res: any) => {
              newDepartment.id = res.data;
              setDepartmentArray([...(departmentArray || []), newDepartment]);
              setValue('departmentId', res.data);
              setCreateDepartment(false);
            }}
            isOpen={createDepartment}
            closeAction={() => {
              setCreateDepartment(false);
              setValue('departmentId', user?.departmentId);
            }}
          />
        </div>
        <div className="mb-2 col-md-6 col-sm-12">
          <FormLabel htmlFor="roleId">{t('user.role')}</FormLabel>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={!editing}
            isSearchable={false}
            id="roleId"
            value={optionsRole.find((x) => x.value === user.roleId)}
            options={optionsRole}
            onChange={(ev) => {
              setValue('roleId', ev?.value);
            }}
          />
        </div>
      </>
    );
  }
  function DiscountInputs() {
    const discountTypeArray = [
      'sysDisc',
      'itDiscount',
      'hediscount',
      'pumpDiscount',
      'barePumpDiscount',
      'plateAndFrameHeDiscount',
      'customTankDiscount',
      'discount',
    ]; // must be a client proprety

    return (
      <div id="discount-collapse">
        <h5
          className="d-flex justify-content-between"
          onClick={() => setCollapsed(!collapsed)}
        >
          <span>{t('user-form-page.discount')}</span>
          <FontAwesomeIcon size="sm" icon={faChevronDown} />
        </h5>
        <CollapseComponent collapsed={collapsed}>
          <div className="d-flex justify-content-around mb-3">
            <Button
              size="sm"
              disabled={!editing}
              onClick={() =>
                setUser({
                  ...user,
                  sysDisc: discountUSA?.sysDisc,
                  barePumpDiscount: discountUSA?.barePumpDiscount,
                  itDiscount: discountUSA?.itDiscount,
                  pumpDiscount: discountUSA?.pumpDiscount,
                  discount: discountUSA?.discount,
                  hediscount: discountUSA?.heDiscount,
                  customTankDiscount: discountUSA?.customTankDiscount,
                  plateAndFrameHeDiscount: discountUSA?.plateAndFrameHeDiscount,
                } as Client)
              }
            >
              Default USA
            </Button>
            <Button
              size="sm"
              disabled={!editing}
              onClick={() =>
                setUser({
                  ...user,
                  sysDisc: discountCAN?.sysDisc,
                  barePumpDiscount: discountCAN?.barePumpDiscount,
                  itDiscount: discountCAN?.itDiscount,
                  pumpDiscount: discountCAN?.pumpDiscount,
                  discount: discountCAN?.discount,
                  hediscount: discountCAN?.heDiscount,
                  customTankDiscount: discountCAN?.customTankDiscount,
                  plateAndFrameHeDiscount: discountCAN?.plateAndFrameHeDiscount,
                } as Client)
              }
            >
              Default CAN
            </Button>
            <CheckAllowedComponent
              user={employee}
              objectName="change-default-discount"
              redirect={false}
            >
              <Button size="sm" onClick={() => setUpdateDefaultDiscount(true)}>
                Change Default
              </Button>
            </CheckAllowedComponent>
          </div>

          <div className="row">
            {discountTypeArray.map((discountName, index) => (
              <div key={index} className="col-md-6 col-sm-12 mb-3">
                {DiscountInput({ propretyName: discountName })}
              </div>
            ))}
          </div>
        </CollapseComponent>
      </div>
    );
    type DiscountProps = {
      propretyName: string;
    };

    function DiscountInput(props: DiscountProps) {
      const value = (
        user ? user[props.propretyName as keyof Client] : 0
      )?.toString();
      return (
        <>
          <label className="form-label" htmlFor={props.propretyName}>
            {t(`user.discount.${props.propretyName}`)}
          </label>
          <FormControl
            key={props.propretyName}
            id={props.propretyName}
            value={
              value === undefined || Number.isNaN(parseInt(value, 10))
                ? ''
                : value
            }
            type="number"
            min={-100}
            max={100}
            size="sm"
            className={active}
            onBlur={(ev) => {
              if (ev.target.value === '') setValue(props.propretyName, 0);
            }}
            onChange={(ev) => setValue(props.propretyName, ev.target.value)}
            disabled={!editing}
          />
        </>
      );
    }
  }
}
