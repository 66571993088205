import './mfv-selection.scss';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import mfvService from '../../../services/Mfv/mfv-service';
import type { MfvRow } from '../../../services/Mfv/Models/MfvSelectionTable';
import { MfvSelectionTable } from '../../../services/Mfv/Models/MfvSelectionTable';
import ConfiramtionPopup from '../../../components/Core/Popup-confirmation-Component';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';

type IParamTypes = {
  userId: string;
  quoteKitId: string;
  quoteId: string;
};

export default function MfvSelectionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  type TableType = 'secondary' | 'other' | 'mfv';
  const selectedPump = location.state?.data || [];

  const { userId, quoteId, quoteKitId } = useParams<IParamTypes>();
  const [mfvSelectionTable, setMfvSelectionTable] =
    useState<MfvSelectionTable>();
  const [selectedMfv, setSelectedMfv] = useState<MfvRow>();
  const [isAddToQuote, setIsAddToQuote] = useState<boolean>(false);

  useEffect(() => {
    loadmfvs();
  }, []);

  useEffect(() => {
    if (userId && quoteKitId && isAddToQuote) {
      addToQuote();
    }
  }, [isAddToQuote]);
  return (
    <div id="mfv-selection-page">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Pump Search',
            link: `/Pumps/${userId}/Pump-Search/${quoteId}`,
          },
          {
            text: 'MultiFunction Valve',
            active: true,
          },
        ]}
      />
      <div className="page-title wrapper">
        <h1 className="h3">{t('mfv-selection-page.title-tb1')}</h1>
      </div>
      <div>
        <div className="card-body">
          <div className="table-header">
            <span className="sub-description">
              {t('mfv-selection-page.description-tb1')}
            </span>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th className="td-name">MFV</th>
                <th>{t('mfv-selection-page.list-price')}</th>
              </tr>
            </thead>
            <tbody>
              <tr key={1}>
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    checked={mfvSelectionTable?.mfv.isChecked ?? false}
                    onChange={() => {
                      if (mfvSelectionTable) {
                        handleMfvSelection(mfvSelectionTable.mfv, 'mfv');
                      }
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>
                <td className="td-name">{mfvSelectionTable?.mfv.name}</td>
                <td>{mfvSelectionTable?.mfv.listPrice}</td>
              </tr>
            </tbody>
          </table>
          <div className="table-header">
            <span className="sub-description">
              {t('mfv-selection-page.description-tb2')}
            </span>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th className="td-name">MFV</th>
                <th>{t('mfv-selection-page.list-price')}</th>
              </tr>
            </thead>
            <tbody>
              {mfvSelectionTable?.secondaryMfvs.map(
                (row: MfvRow, rowIndex: number) => (
                  <tr key={rowIndex}>
                    <td className="align-vertical-center">
                      <input
                        type="checkbox"
                        checked={row.isChecked ?? false}
                        onChange={() => handleMfvSelection(row, 'secondary')}
                        onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                      />
                    </td>
                    <td className="td-name">{row.name}</td>
                    <td>{row.listPrice}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
          <div className="table-header">
            <span className="sub-description">
              {t('mfv-selection-page.description-tb3')}
            </span>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th className="td-name">MFV</th>
                <th>{t('mfv-selection-page.list-price')}</th>
              </tr>
            </thead>
            <tbody>
              {mfvSelectionTable?.otherMfvs.map(
                (row: MfvRow, rowIndex: number) => (
                  <tr key={rowIndex}>
                    <td className="align-vertical-center">
                      <input
                        type="checkbox"
                        checked={row.isChecked ?? false}
                        onChange={() => handleMfvSelection(row, 'other')}
                        onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                      />
                    </td>
                    <td className="td-name">{row.name}</td>
                    <td>{row.listPrice}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
          <div className="button-containers">
            <button className="btn btn-primary" onClick={handleAddToQuote}>
              {t(`mfv-selection-page.add-to-quote`)}
            </button>
            {selectedPump && (
              <button className="btn btn-primary" onClick={handleSkip}>
                {t(`mfv-selection-page.skip`)}
              </button>
            )}
          </div>
        </div>
      </div>
      {showAlert && (
        <ConfiramtionPopup
          show={showAlert}
          title={'Warn!'}
          message={alertMessage}
          onOk={() => setShowAlert(false)}
          onClose={() => setShowAlert(false)}
        ></ConfiramtionPopup>
      )}
    </div>
  );

  function loadmfvs() {
    if (userId && quoteKitId) {
      mfvService
        .getPumpMfvs(userId, quoteKitId)
        .then((res) => {
          if (res) {
            // console.log(res);
            setMfvSelectionTable(res);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function addToQuote() {
    // console.log(selectedPump)
    if (userId && quoteId && quoteKitId && selectedMfv) {
      mfvService
        .addMFVtoQuote(userId, quoteId, quoteKitId, selectedMfv)
        .then((res) => {
          if (res) {
            toast.success(t(`mfv-selection-page.success-added`));
            if (selectedPump && selectedPump.isSuctionDiffuser) {
              navigate(`/Pumps/SD/${userId}/${quoteId}/${quoteKitId}`, {
                state: { data: selectedPump },
              });
            } else {
              const activeTabIndex = 1;
              sessionStorage.setItem(
                'activeTabIndex',
                activeTabIndex.toString(),
              );
              navigate(`/Quotes/${userId}/${quoteId}`, { replace: true });
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }
  function handleAddToQuote() {
    if (selectedMfv) setIsAddToQuote(true);
    else {
      setShowAlert(true);
      setAlertMessage(
        t('mfv-selection-page.warn-select-mfv') ||
          'select a MultiFunction valve',
      );
    }
  }
  // function handleMfvSelection(mfv: MfvRow) {
  //   setSelectedMfv(mfv);
  // }

  function handleMfvSelection(row: MfvRow, type: TableType) {
    setMfvSelectionTable((prevState) => {
      if (!prevState) return new MfvSelectionTable({});

      switch (type) {
        case 'mfv':
          return {
            ...prevState,
            mfv: { ...prevState.mfv, isChecked: true },
            secondaryMfvs: prevState.secondaryMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: false }
                : { ...r, isChecked: false },
            ),
            otherMfvs: prevState.otherMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: false }
                : { ...r, isChecked: false },
            ),
          };
        case 'secondary':
          return {
            ...prevState,
            mfv: { ...prevState.mfv, isChecked: false },
            secondaryMfvs: prevState.secondaryMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: true }
                : { ...r, isChecked: false },
            ),
            otherMfvs: prevState.otherMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: false }
                : { ...r, isChecked: false },
            ),
          };
        case 'other':
          return {
            ...prevState,
            mfv: { ...prevState.mfv, isChecked: false },
            secondaryMfvs: prevState.secondaryMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: false }
                : { ...r, isChecked: false },
            ),
            otherMfvs: prevState.otherMfvs.map((r) =>
              r.id === row.id
                ? { ...r, isChecked: true }
                : { ...r, isChecked: false },
            ),
          };
        default:
          return prevState;
      }
    });

    setSelectedMfv(row);
  }
  function handleSkip() {
    navigate(`/Pumps/SD/${userId}/${quoteId}/${quoteKitId}`, {
      state: { data: selectedPump },
    });
  }
}
