import BaseApi from '../Core/REST/BaseApi';
import type { MfvRow, MfvSelectionTable } from './Models/MfvSelectionTable';

class MfvService extends BaseApi {
  async getPumpMfvs(
    userId: string,
    kitId: string,
  ): Promise<MfvSelectionTable | undefined> {
    const url = `${this.baseUrl}/MultiFunctionValve/${userId}/get-mfvs/${kitId}`;
    return this.get<MfvSelectionTable | undefined>(url);
  }
  async addMFVtoQuote(
    userId: string,
    quoteId: string,
    kitId: string,
    mfvSelected: MfvRow,
  ): Promise<MfvRow | undefined> {
    const url = `${this.baseUrl}/MultiFunctionValve/${userId}/add-to-quote/${quoteId}/${kitId}`;
    return this.post(url, mfvSelected);
  }
}

const mfvService = new MfvService();
export default mfvService;
