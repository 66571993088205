import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { Button, Col, FormSelect, FormCheck } from 'react-bootstrap';
import type { Client } from '../../services/Users/Client';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import {
  FilterContainerComponent,
  FilterFieldset,
} from '../../components/Forms/Search filters/FilterContainerComponent';
import { DOTS } from '../../services/Core/Helpers/string-helper';
import useColumnFilters from '../../hooks/React table/useColumnFilters';
import useColumnVisibility from '../../hooks/React table/useColumnVisibility';
import Pagination from '../../components/react-table/Pagination';
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from '../../components/react-table/react-table-component';
import { ColumnVisibilityComponent } from '../../components/react-table/column-visibility-component';
import ResetFilterComponent from '../../components/react-table/react-table-header-options';
import {
  Page,
  PageDetails,
  PageHeader,
} from '../../components/Pages/page-components';
import type { Department } from '../../services/Departments/Department';
import type { Customer } from '../../services/Users/Customer';
import type { Role } from '../../services/Roles/Roles';
import CheckAllowedComponent, {
  checkAllowed,
} from '../../components/CheckAllowedComponent';
import {
  CollapseComponent,
  CollapsedIconComponent,
  useCollapse,
} from '../../components/CollapseComponent';
import userService from '../../services/Users/UserService';
import departmentService from '../../services/Departments/departmentService';
import rolesService from '../../services/Roles/RolesService';
import NavTabsComponent from '../../components/nav-tabs-component';

type IParamTypes = {
  employeeId: string;
};
type Props = {
  user: Client;
};

export default function ManageUsersPage({ user }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams<IParamTypes>();
  const [users, setUsers] = useState<Array<Client>>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [customerArray, setCustomerArray] = useState<Array<Customer>>();
  const {
    columnFilters,
    editFilterFn,
    setColumnFilters,
    resetFiltersFn,
    getFilterValueFn,
  } = useColumnFilters(undefined, clearFilters);
  const filterCollapse = useCollapse(true);
  const filterBtnText = t(
    `quotes-page.${filterCollapse.getTitle().toLocaleLowerCase()}-filters-btn`,
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [departmentArray, setDepartmentArray] = useState<Array<Department>>();
  const [roleArray, setRoleArray] = useState<Array<Role>>();
  const pageTitle = t('manage-users-page.manage-users');
  useEffect(() => {
    departmentService
      .getAll()
      .then((data) => setDepartmentArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-department-error'));
      });
    rolesService
      .getAll()
      .then((data) => setRoleArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-role-error'));
      });
  }, []);
  useEffect(() => {
    document.title = pageTitle;
    userService
      .getAllCust(employeeId || '')
      .then((data) => setCustomerArray(data))
      .catch(() => t('manage-users-page.load-customer-error'));
    loadUsers();
  }, [employeeId]);
  const [tab, setTab] = useState<string>('all');
  const columns: any = useMemo(() => {
    return [
      {
        header: t('user.actif'),
        accessorKey: 'actif',
        cell: (props: any) => (
          <CheckAllowedComponent
            user={user}
            objectName="alter-user"
            alt={<span>{props.getValue() ? 'true' : 'false'}</span>}
          >
            <FormCheck
              checked={props.getValue()}
              className="userTableInputContainer"
              onChange={(_) => {
                const client = users.find(
                  (x) => x.id === props.row.original.id,
                );

                if (client === undefined)
                  throw Error('Client not found in onChange');
                client.actif = !props.getValue();
                userService
                  .updateActive(employeeId || '', client)
                  .then(() => {
                    const userId: number = users.findIndex(
                      (user) => user.id === props.row.original.id,
                    );
                    users[userId].actif = !props.getValue();
                    setUsers([...users]);
                  })
                  .catch(() => {
                    toast.error(t('manage-users-page.post-active-error'));
                  });
              }}
            />
          </CheckAllowedComponent>
        ),
      },
      {
        header: t('user.id'),
        accessorKey: 'id',
      },
      {
        header: t('user.nom'),
        accessorKey: 'nom',
      },
      {
        header: t('user.nomCompagnie'),
        accessorKey: 'nomCompagnie',
      },
      {
        header: t('user.email'),
        accessorKey: 'email',
      },
      {
        header: t('user.employee'),
        accessorKey: 'employee',
      },
      {
        header: t('user.country'),
        accessorKey: 'country',
      },
      {
        header: t('user.province'),
        accessorKey: 'province',
      },
      {
        header: t('user.department'),
        accessorKey: 'departmentId',
        cell: (props: any) =>
          departmentArray?.find((e) => e.id === props.getValue())?.name,
      },
      {
        header: t('user.role'),
        accessorKey: 'roleId',
        cell: (props: any) =>
          roleArray?.find((e) => e.id === props.getValue())?.name,
      },
      {
        header: t('user.dynacomCustId'),
        accessorKey: 'dynacomCustId',
        cell: (props: any) =>
          customerArray?.find((e) => e.inCustId === props.getValue())?.name,
      },
    ];
  }, [customerArray, users]);

  const table = useReactTable({
    columns,
    data: users || [],
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { status: false, 'mrt-row-expand': true },
    },
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    autoResetPageIndex: false,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const breadcrumbItems = [{ text: pageTitle, active: true }];
  return (
    <CheckAllowedComponent
      user={user}
      objectName="get-all-user"
      redirect={true}
    >
      <Page className="users-container">
        <PageHeader id="users-page-header" className="d-block">
          <BreadcrumbComponent items={breadcrumbItems} />
          <div className="d-flex">
            <h1 className="h3 mb-2">{pageTitle}</h1>
            <CheckAllowedComponent user={user} objectName="alter-user">
              <Button
                id="new-user-btn"
                variant="outline-primary"
                className="ms-3"
                onClick={() => navigate(`/UserForm`)}
              >
                {t('manage-users-page.new-user')}
              </Button>
            </CheckAllowedComponent>
          </div>
        </PageHeader>
        <section className="quote-filter-section">
          <FilterContainerComponent>
            <FilterFieldset className="px-3 py-0">
              <legend>
                <span
                  className="action-btn me-2 p-0"
                  onClick={filterCollapse.click}
                  title={filterBtnText}
                >
                  <CollapsedIconComponent
                    size="xs"
                    collapsed={filterCollapse.collapsed}
                  />
                  <span className="ms-2">{filterBtnText}</span>
                </span>
              </legend>
              <CollapseComponent collapsed={filterCollapse.collapsed}>
                <PageDetails>
                  <Page className="mb-2">
                    <PageDetails>
                      <FilterContainerComponent className="row">
                        <Col lg={2} md={4} sm={12}>
                          <FilterFieldset>
                            <legend>{t(`user.department`)}:</legend>
                            <FormSelect
                              size="sm"
                              value={getFilterValueFn('departmentId')}
                              onChange={(ev) => {
                                table.resetPageIndex(true);
                                editFilterFn('departmentId', ev.target.value);
                              }}
                            >
                              <option key={DOTS} value={''}>
                                {DOTS}
                              </option>
                              {departmentArray?.map((val, _) => {
                                return (
                                  <option key={val.id} value={val.id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </FormSelect>
                          </FilterFieldset>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                          <FilterFieldset>
                            <legend>{t(`user.role`)}:</legend>
                            <FormSelect
                              size="sm"
                              value={getFilterValueFn('roleId')}
                              onChange={(ev) => {
                                table.resetPageIndex(true);
                                editFilterFn('roleId', ev.target.value);
                              }}
                            >
                              <option key={DOTS} value={''}>
                                {DOTS}
                              </option>
                              {roleArray?.map((val, _) => {
                                return (
                                  <option key={val.id} value={val.id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </FormSelect>
                          </FilterFieldset>
                        </Col>
                      </FilterContainerComponent>
                    </PageDetails>
                  </Page>
                </PageDetails>
              </CollapseComponent>
            </FilterFieldset>
          </FilterContainerComponent>
        </section>

        <PageDetails className="pt-2">
          <div className="row">
            <Col sm={10}>
              <NavTabsComponent
                active={tab}
                navItems={[
                  {
                    key: 'all',
                    onclick: changeFilterTab,
                    text: t('manage-users-page.all'),
                  },
                  {
                    key: 'activeClient',
                    onclick: changeFilterTab,
                    text: t('manage-users-page.active-client'),
                  },
                  {
                    key: 'inactiveClient',
                    onclick: changeFilterTab,
                    text: t('manage-users-page.inactive-client'),
                  },
                  {
                    key: 'activeEmployee',
                    onclick: changeFilterTab,
                    text: t('manage-users-page.active-Employee'),
                  },
                  {
                    key: 'inactiveEmployee',
                    onclick: changeFilterTab,
                    text: t('manage-users-page.inactive-Employee'),
                  },
                ]}
              />
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <input
                  type="search"
                  className="form-control rounded"
                  placeholder={t('common.search') || ''}
                  aria-label={t('common.search') || ''}
                  onChange={(ev) => {
                    setGlobalFilter(ev.target.value);
                    table.resetPageIndex(true);
                  }}
                />
              </div>
            </Col>
          </div>

          <section id="users-table">
            <table className="table table-hover">
              <thead>
                <tr id="goal-table-options" className="table-options">
                  <th
                    colSpan={
                      table.getAllFlatColumns().filter((x) => x.getIsVisible())
                        .length
                    }
                  >
                    <ColumnVisibilityComponent
                      columns={iColumnVisibility}
                      className="mx-1"
                    />
                    <ResetFilterComponent
                      resetFilterFn={resetFiltersFn}
                      className="me-1"
                    />
                  </th>
                </tr>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id}>
                          <span {...getTableHeaderSortProps(header.column)}>
                            {header.column.columnDef.header?.toString()}
                            <ReactTableHeaderOptions header={header} />
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            className="align-vertical-center"
                            key={cell.id}
                            onClick={() => {
                              checkAllowed(user, 'alter-user').then((res) => {
                                if (res && cell.column.id !== 'actif')
                                  navigate(`/UserForm/${row.original.id}`);
                              });
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              reactTable={table}
              siblingCount={2}
              dataName={t('user.users') ?? ''}
              showDataLength
            />
          </section>
        </PageDetails>
      </Page>
    </CheckAllowedComponent>
  );

  function loadUsers() {
    if (employeeId) {
      userService
        .getAll(employeeId)
        .then((data) => {
          if (data) {
            setUsers(data);
          }
        })
        .catch(() => {
          toast.error(t('manage-users-page.load-error'));
        });
    }
  }

  function clearFilters() {
    setTab('all');
    setGlobalFilter('');
  }
  function changeFilterTab(tab: string) {
    setTab(tab);
    table.resetPageIndex(true);

    switch (tab) {
      case 'all': {
        setColumnFilters(
          columnFilters.filter(
            (item) => item.id !== 'employee' && item.id !== 'actif', // remove tab filters
          ),
        );
        setColumnVisibility({});
        break;
      }
      case 'activeClient': {
        setColumnFilters([
          ...columnFilters,
          { id: 'employee', value: false },
          { id: 'actif', value: true },
        ]);
        setColumnVisibility({ departmentId: false, roleId: false });
        break;
      }
      case 'inactiveClient': {
        setColumnFilters([
          ...columnFilters,
          { id: 'employee', value: false },
          { id: 'actif', value: false },
        ]);
        setColumnVisibility({ departmentId: false, roleId: false });
        break;
      }
      case 'activeEmployee': {
        setColumnFilters([
          ...columnFilters,
          { id: 'employee', value: true },
          { id: 'actif', value: true },
        ]);
        setColumnVisibility({
          nomCompagnie: false,
          dynacomCustId: false,
          country: false,
          province: false,
        });
        break;
      }
      case 'inactiveEmployee': {
        setColumnFilters([
          ...columnFilters,
          { id: 'employee', value: true },
          { id: 'actif', value: false },
        ]);
        setColumnVisibility({
          nomCompagnie: false,
          dynacomCustId: false,
          country: false,
          province: false,
        });
        break;
      }
      default:
        break;
    }
  }
  // call after login to have the client object of the connected user in other tables
}
