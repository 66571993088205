export default class PumpSearchConfig {
  id: number;
  seriesId: number;
  flowRate: number;
  head: number;
  construction: number;
  rpm: number;
  motorType: number;
  bhp: number = 0;
  hp: number;
  volt: number;
  flangeType: number;
  clientId: number;

  constructor(data: any) {
    this.id = data.id ?? 0;
    this.seriesId = data.seriesId ?? 0;
    this.flowRate = data.flowRate ?? 0;
    this.head = data.head ?? 0;
    this.construction = data.construction ?? 0;
    this.rpm = data.rpm ?? 0;
    this.motorType = data.motorType ?? 0;
    this.bhp = data.bhp ?? 1;
    this.hp = data.hp ?? 0;
    this.volt = data.volt ?? 0;
    this.flangeType = data.flangeType ?? 0;
    this.clientId = data.clientId ?? 0;
  }

  getConstruction(): string {
    switch (this.construction) {
      case 0:
        return 'Bronze Fitted';
      case 1:
        return 'All Iron';
      case 2:
        return 'All Bronze';
      case 3:
        return 'All S.S. 304';
      case 4:
        return 'All S.S. 316';
      case 5:
        return 'Ductile Iron';
      case 6:
        return 'Iron Body With S.S. 304 Fitted';
      // case -1:
      default:
        return 'All Constructions';
    }
  }

  getFlangeType(): string {
    if (this.flangeType === 0) return '150 lbs';
    if (this.flangeType === 1) return '250 lbs';
    if (this.flangeType === 2) return '10 Pin';
    if (this.flangeType === 3) return '16 Pin';
    return '';
  }

  getRPM(): string | number {
    switch (this.rpm) {
      case 0:
        return 1150;
      case 1:
        return 1750;
      case 2:
        return 3450;
      case 3:
        return -1;
      case 4:
        return 970;
      case 5:
        return 1450;
      case 6:
        return 2900;
      case 7:
        return 'other';
      default:
        return 1150;
    }
  }

  getVolt(): string {
    switch (this.volt) {
      case 0:
        return '575/3/60';
      case 1:
        return '440-460-480/3/60';
      case 2:
        return '380-400/3/60';
      case 3:
        return '208-220-230/3/60';
      case 4:
        return '220-230-240/1/60';
      case 5:
        return '110-115/1/60';
      case 6:
        return '380-400/3/50';
      case 7:
        return '220/230/3/50';
      case 8:
        return '220-230-240/1/50';
      default:
        return '';
    }
  }
}

export function getConfigPumpSearchConstruction(data: PumpSearchConfig) {
  switch (data.construction) {
    case 0:
      return 'Bronze Fitted';
    case 1:
      return 'All Iron';
    case 2:
      return 'All Bronze';
    case 3:
      return 'All S.S. 304';
    case 4:
      return 'All S.S. 316';
    case 5:
      return 'Ductile Iron';
    case 6:
      return 'Iron Body With S.S. 304 Fitted';
    default:
      return 'All Constructions';
  }
}

export function getConfigPumpSearchFlangeType(data: PumpSearchConfig) {
  if (data.flangeType === 0) return '150 lbs';
  if (data.flangeType === 1) return '250 lbs';
  if (data.flangeType === 2) return '10 Pin';
  if (data.flangeType === 3) return '16 Pin';
  return '';
}

export function getConfigPumpSearchRPM(data: PumpSearchConfig) {
  switch (data.rpm) {
    case 0:
      return 1150;
    case 1:
      return 1750;
    case 2:
      return 3450;
    case 3:
      return -1;
    case 4:
      return 970;
    case 5:
      return 1450;
    case 6:
      return 2900;
    case 7:
      return 'other';
    default:
      return 1150;
  }
}
