import { ItemDetails } from '../../Quotes/Models/QuoteItem';

enum OptionObjectType {
  QuotePumpOptions = 0,
  QuoteMultiFunctionValves = 1,
  QuoteSuctionDiffusers = 2,
  QuoteVariableFrequencyDrives = 3,
  BoosterSystemPumpOptions = 4,
  QuoteTankOptionKit = 5,
}
export class Option {
  id: number;
  name: string;

  netPrice: number;
  listPrice: number;

  profit: number;
  totalEach: number;

  optionId: number;
  optionName: string;
  linkedObjectId: number;
  objectType: OptionObjectType;

  items: Array<Item>;
  files: Array<ItemFile>;

  constructor(data: any, items: Array<Item>, files: Array<ItemFile>) {
    this.id = data.id;
    this.name = data.name;
    this.netPrice = data.netPrice;
    this.listPrice = data.listPrice;

    this.profit = data.profit;
    this.totalEach = data.totalEach;

    this.optionId = data.optionId;
    this.optionName = data.optionName;
    this.linkedObjectId = data.linkedObjectId;
    this.objectType = data.objectType;

    this.items = items;
    this.files = files;
  }
}
export enum ItemObjectTypes {
  QuotePumpOptionItems = 0,
  QuoteSuctionDiffuserItems = 1,
  QuoteMultiFunctionValveItems = 2,
  QuoteVariableFrequencyDriveItems = 3,
  QuoteHeatExchangerItems = 4,
  BoosterSystemItem = 5,
  BoosterSystemPumpItem = 6,
  BoosterSystemPumpOptionItems = 7,
  QuoteItem = 8,
  QuoteItemTank = 9,
  QuoteTankOptionItem = 10,
  QuoteSystemMultiFunctionValveItems = 11,
  QuoteSystemSuctionDiffuserItems = 12,
  QuoteSystemVariableFrequencyDrives = 13,
  QuoteSingleItem = 14,
}
export class Item {
  id: number;
  type: string;
  it_code: number;
  it_ocode: string;
  itemDescription: string;
  itemDescriptionToShow: string;
  it_cost: number;
  it_soldPrice: number;
  it_weight: number;
  qty: number;
  note: string;

  linkedObjectId: number;
  objectType: ItemObjectTypes;

  itemDetails: ItemDetails;
  files: Array<ItemFile>;

  constructor(data: any, itemDetails?: ItemDetails, files?: Array<ItemFile>) {
    this.id = data.id;
    this.type = data.type;
    this.it_code = data.it_code;
    this.it_ocode = data.it_ocode;
    this.itemDescription = data.itemDescription;
    this.itemDescriptionToShow = data.itemDescriptionToShow;
    this.it_cost = data.it_cost;
    this.it_soldPrice = data.it_soldPrice;
    this.it_weight = data.it_weight;
    this.qty = data.qty;
    this.note = data.note;

    this.linkedObjectId = data.linkedObjectId;
    this.objectType = data.objectType;

    this.itemDetails = new ItemDetails(itemDetails);

    this.files = files ?? [];
  }
}

export class ItemFile {
  name: string;
  link: string;

  constructor(data: any) {
    this.name = data.name;
    this.link = data.link;
  }
}
