import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useMemo, useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import Chart from 'react-google-charts';
import type { QuotePriority } from '../../services/QuotePriority/Models/QuotePriority';
import quotePriorityService from '../../services/QuotePriority/QuotePriorityService';
import LoadingComponent from '../../components/Core/Loading';
import QuotePriorityTable from '../QuotePriority/quote-priority-table';
import type { Client } from '../../services/Users/Client';
import { GoalTypes } from '../../services/Goals/Goal';
import dateHelper from '../../services/Core/Helpers/date-helper';
import { useGoal } from '../../hooks/Goals/useGoal';
import { useIncomeStatement } from '../../hooks/Sales/useIncomeStatement';
import { useIncomeStatementQuarter } from '../../hooks/Sales/useIncomeStatementQuarter';
import ProductionGoalDiagram from '../Production/production-goal-diagram';
import projectService from '../../services/Projects/project-service';
import type { ToDoAndLateJobs } from '../../services/Projects/Model/Project';
import type StatisticContent from '../../services/Stats/StatisticContent';
import {
  useNumberOfProdJobs,
  useProductionDelays,
  useEmployeeWeeklyHours,
  useClosingRatio,
  useEnginneringData,
  useSalesData,
  useCashflowData,
} from '../../hooks/Production/production-statistics';
import statsService from '../../services/Stats/stats-service';
import stringHelper from '../../services/Core/Helpers/string-helper';
import LineChartComponent from '../../components/Charts/LineChartComponent';
import NavTabsComponent from '../../components/nav-tabs-component';
import type Reminder from '../../services/Reminder/model/Reminder';
import remindersService from '../../services/Reminder/ReminderService';
import ReminderTable from '../Reminder/assigned-reminder-table';
import numberHelper from '../../services/Core/Helpers/number-helper';

type Props = {
  user: Client;
};

export function QuotePriorityPumpComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [priorityPump, setPriorityPump] = useState<Array<QuotePriority>>();
  useEffect(() => {
    quotePriorityService
      .getAllPump()
      .then((res) => setPriorityPump(res))
      .catch(() => {
        toast.error(t('quote-priority-page.get-priority-package-error'));
        setError(`${t('quote-priority-page.get-priority-package-error')}`);
      });
  }, []);
  return (
    <div
      id="quote-priority-card"
      className="card clickable"
      onDoubleClick={() => navigate('../QuotePriority')}
    >
      <div className="card-title m-2 ms-3">
        <div className="d-flex justify-content-around">
          <h5>{t('project-dashboard-page.top-priority-pump')}</h5>
        </div>
      </div>
      <div className="card-body">
        {priorityPump ? (
          <div className="d-flex justify-content-around">
            <QuotePriorityTable
              editing={false}
              setQuotePriorityArray={setPriorityPump}
              quotePriorityArray={priorityPump.slice(0, 5)}
              initialColumnVisibility={{ standerDate: false, name: false }}
            ></QuotePriorityTable>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function QuotePriorityPackageComponent() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [priorityPackage, setPriorityPackage] =
    useState<Array<QuotePriority>>();
  useEffect(() => {
    quotePriorityService
      .getAllPackage()
      .then((res) => setPriorityPackage(res))
      .catch(() => {
        toast.error(t('quote-priority-page.get-priority-package-error'));
        setError(`${t('quote-priority-page.get-priority-package-error')}`);
      });
  }, []);
  return (
    <div
      id="quote-priority-card"
      className="card clickable"
      onDoubleClick={() => navigate('../QuotePriority')}
    >
      <div className="card-title m-2 ms-3">
        <div className="d-flex justify-content-around">
          <h5>{t('project-dashboard-page.top-priority-package')}</h5>
        </div>
      </div>
      <div className="card-body">
        {priorityPackage ? (
          <div className="d-flex justify-content-around">
            <QuotePriorityTable
              editing={false}
              setQuotePriorityArray={setPriorityPackage}
              quotePriorityArray={priorityPackage.slice(0, 5)}
              initialColumnVisibility={{ standerDate: false, name: false }}
            ></QuotePriorityTable>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function QuotePriorityWeldingComponent() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [priorityWelding, setPriorityWelding] =
    useState<Array<QuotePriority>>();
  useEffect(() => {
    quotePriorityService
      .getAllWelding()
      .then((res) => setPriorityWelding(res))
      .catch(() => {
        toast.error(t('quote-priority-page.get-priority-welding-error'));
        setError(`${t('quote-priority-page.get-priority-welding-error')}`);
      });
  }, []);
  return (
    <div
      id="quote-priority-card"
      className="card clickable"
      onDoubleClick={() => navigate('../QuotePriority')}
    >
      <div className="card-title m-2 ms-3">
        <div className="d-flex justify-content-around">
          <h5>{t('project-dashboard-page.top-priority-welding')}</h5>
        </div>
      </div>
      <div className="card-body">
        {priorityWelding ? (
          <div className="d-flex justify-content-around">
            <QuotePriorityTable
              editing={false}
              setQuotePriorityArray={setPriorityWelding}
              quotePriorityArray={priorityWelding.slice(0, 5)}
              initialColumnVisibility={{ standerDate: false, name: false }}
            ></QuotePriorityTable>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function QuotePriorityFinitionComponent() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [priorityFinition, setPriorityFinition] =
    useState<Array<QuotePriority>>();
  useEffect(() => {
    quotePriorityService
      .getAllFinition()
      .then((res) => setPriorityFinition(res))
      .catch(() => {
        toast.error(t('quote-priority-page.get-priority-finition-error'));
        setError(`${t('quote-priority-page.get-priority-finition-error')}`);
      });
  }, []);
  return (
    <div
      id="quote-priority-card"
      className="card clickable"
      onDoubleClick={() => navigate('../QuotePriority')}
    >
      <div className="card-title m-2 ms-3">
        <div className="d-flex justify-content-around">
          <h5>{t('project-dashboard-page.top-priority-finition')}</h5>
        </div>
      </div>
      <div className="card-body">
        {priorityFinition ? (
          <div className="d-flex justify-content-around">
            <QuotePriorityTable
              editing={false}
              setQuotePriorityArray={setPriorityFinition}
              quotePriorityArray={priorityFinition.slice(0, 5)}
              initialColumnVisibility={{ standerDate: false, name: false }}
            ></QuotePriorityTable>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function ProductionGoalComponent({ user }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const employeeId = user.id.toString();
  const [dateFilter] = useState<Date>(dateHelper.getCurrentLastDayDate());

  const startDate = useMemo(
    () => dateHelper.getFirstDayDate(dateFilter),
    [dateFilter],
  );
  const { goal, loadingGoal } = useGoal(
    user.id.toString(),
    GoalTypes.IncomeStatement,
    'monthly',
    dateFilter,
  );

  const { incomeStatement, loadingIncomeStatement } = useIncomeStatement(
    employeeId,
    startDate,
    dateFilter,
  );

  const { statementQuarter, loadingStatementQuarter } =
    useIncomeStatementQuarter(employeeId, dateFilter);
  if (loadingIncomeStatement && loadingStatementQuarter && loadingGoal) {
    return <LoadingComponent />;
  }
  return (
    <div
      id="production-goal-card"
      className="statisctic-card card clickable"
      onDoubleClick={() => navigate('../Production/Goal/717')}
    >
      <div className="card-title m-2 ms-3">
        <h5>{t('income-statement-page.title')}</h5>
      </div>
      <div className="card-body">
        <ProductionGoalDiagram
          incomeStatement={incomeStatement}
          statementQuarter={statementQuarter}
          goal={goal}
        />
      </div>
    </div>
  );
}

export function ProcurementComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [getToDoAndLate, setToDoAndLate] = useState<ToDoAndLateJobs>();
  useEffect(() => {
    projectService
      .getNumberToDoAndLateProject()
      .then((res) => setToDoAndLate(res))
      .catch(() => {
        toast.error(t('project-dashboard-page.get-procurement-error'));
        setError(`${t('project-dashboard-page.get-procurement-error')}`);
      });
  }, []);
  return (
    <div
      id="procurement-card"
      className="card clickable"
      onDoubleClick={() => navigate('../Projects/717')}
    >
      <div className="card-title m-2 ms-3">
        <h5>{t('project-item-state.2')}</h5>
      </div>
      <div className="card-body">
        {getToDoAndLate ? (
          <div className="line-container">
            <div>
              <span>{t('project-dashboard-page.to-do')}</span>
              <span>
                {`${getToDoAndLate.toDoCount} ${t(
                  'production-data-page.jobs',
                )}`}
              </span>
            </div>

            <div>
              <span>{t('project-dashboard-page.late')} </span>
              <span>
                {`${getToDoAndLate.lateCount} ${t(
                  'production-data-page.jobs',
                )}`}
              </span>
            </div>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function ProductionComponent({ user }: Props) {
  const packageDivider = 3; // this number does not mean anything, Marc said that the package delay so divide it by 3
  const { t } = useTranslation();
  const nbOfProdJobs = useNumberOfProdJobs(user.id.toString());
  const productionDelays = useProductionDelays(user.id.toString());
  const productionDelaysData = productionDelays.data?.series;
  const productionDelaysError = productionDelays.error;
  const employeeWeeklyHoursData = useEmployeeWeeklyHours().data?.series;

  const pumpTitle =
    productionDelaysData && employeeWeeklyHoursData
      ? `${productionDelaysData[0].title}: ${
          productionDelaysData[0].total
        } Hours /${employeeWeeklyHoursData[0].title}: ${
          employeeWeeklyHoursData[0].total
        } Hours`
      : '';
  const pumpValue =
    productionDelaysData && employeeWeeklyHoursData
      ? `${
          productionDelaysData[0].total / employeeWeeklyHoursData[0].total
        }`.substring(0, 6)
      : '...';

  const packageTitle =
    productionDelaysData && employeeWeeklyHoursData
      ? `${productionDelaysData[1].title}: ${
          productionDelaysData[1].total
        } Hours /${employeeWeeklyHoursData[1].title}: ${
          employeeWeeklyHoursData[1].total
        } Hours`
      : '';
  const packageReducedValue =
    productionDelaysData && employeeWeeklyHoursData
      ? `${
          productionDelaysData[1].total /
          employeeWeeklyHoursData[1].total /
          packageDivider
        }`.substring(0, 6)
      : '...';
  const packageValue =
    productionDelaysData && employeeWeeklyHoursData
      ? `${
          productionDelaysData[1].total / employeeWeeklyHoursData[1].total
        }`.substring(0, 6)
      : '...';
  const listItem = nbOfProdJobs.data
    ? getProdListItem(nbOfProdJobs.data)
    : undefined;

  return (
    <div id="production-card" className="statisctic-card card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.production')}</h5>
      </div>
      <div className="card-body">
        <h6>{t('production-data-page.delays')}</h6>
        <div className="delay-container">
          <div className="card delay blue">
            <div className="card-body ">
              <h6>{t('project-dashboard-page.pump')}</h6>
              <h4 title={pumpTitle}>{pumpValue}</h4>
              <div style={{ color: 'grey' }}>{t('common.weeks')}</div>
            </div>
          </div>
          <div className="card delay orange">
            <div className="card-body ">
              <h6 style={{ color: 'orange' }}>
                {t('project-dashboard-page.package')}
              </h6>
              <h4 title={packageTitle}>{packageReducedValue}</h4>
              <div style={{ color: 'grey' }}>{t('common.weeks')}</div>
              <div>
                <span style={{ marginRight: 2 }}>{packageValue}</span>
                <span style={{ color: 'grey' }}>custom</span>
              </div>
            </div>
          </div>
        </div>
        <h6>{t('production-data-page.jobs')}</h6>
        {listItem ? (
          <div className="line-container">
            <div>
              <div>{listItem[0].title}</div>
              <div className="box-text">{listItem[0].description}</div>
            </div>
            <div>
              <div>{listItem[1].title}</div>
              <div className="box-text">{listItem[1].description}</div>
            </div>
            <div>
              <div>{listItem[2].title}</div>
              <div className="box-text">{listItem[2].description}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div>{listItem[3].title}</div>
                <div className="box-text">{listItem[3].description}</div>
              </div>
              <div className="d-flex">
                <div>{listItem[4].title}</div>
                <div className="box-text">{listItem[4].description}</div>
              </div>
            </div>
          </div>
        ) : (
          (productionDelaysError ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );

  function getProdListItem(data: StatisticContent) {
    return data.series.map((serie) => {
      return {
        title: t(`stats.${serie.title}`),
        description: serie.total,
      };
    });
  }
}

export function ProjectsComponent() {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [getPercentage, setPercentage] = useState<number>();
  useEffect(() => {
    statsService
      .getPercentageOfOrdersOnTime()
      .then((res) => setPercentage(res))
      .catch(() => {
        toast.error(t('project-dashboard-page.get-project-error'));
        setError(`${t('project-dashboard-page.get-project-error')}`);
      });
  }, []);
  return (
    <div id="projects-card" className="card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.projects')}</h5>
        <div className="d-flex align-items-center">
          <div className="turquoise tablet"></div>
          <div>{t('project-dashboard-page.on-time')}</div>
          <div className="orange tablet"></div>
          <div>{t('project-dashboard-page.late')}</div>
        </div>
      </div>
      <div className="card-body">
        {getPercentage != null || getPercentage !== undefined ? (
          <div>
            <div className="diagramm-container">
              <CircularProgressbar
                value={getPercentage}
                text={`${getPercentage}%`}
                strokeWidth={10}
                circleRatio={0.8}
                styles={buildStyles({
                  rotation: 0.6,
                  textColor: 'black',
                  pathColor: '#64CCC5',
                  trailColor: '#E25E3E',
                  textSize: '24px',
                })}
              />
            </div>
          </div>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function ProductionJobsComponent({ user }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [jobsStatIndex, setJobStatIndex] = useState<number>(7);
  const [jobsStat, setJobStat] = useState<StatisticContent>();
  useEffect(() => {
    loadJobsStatistic();
  }, [jobsStatIndex]);
  return (
    <div
      id="production-jobs-card"
      className="card clickable double-card"
      onDoubleClick={() => navigate(`../Production-Data/${user.id}`)}
    >
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.production-jobs')}</h5>
        <NavTabsComponent
          active={jobsStatIndex}
          navItems={[
            { key: 7, text: 'Week', onclick: () => setJobStatIndex(7) },
            {
              key: 30,
              text: 'Month',
              onclick: () => setJobStatIndex(30),
            },
            {
              key: 365,
              text: 'Year',
              onclick: () => setJobStatIndex(365),
            },
          ]}
        />
      </div>
      <div className="card-body p-0">
        {jobsStat ? (
          <LineChartComponent
            height="215px"
            content={jobsStat}
          ></LineChartComponent>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );

  function loadJobsStatistic() {
    const now = new Date(Date.now());
    const end = stringHelper.toDateString(now);
    const start = stringHelper.toDateString(
      new Date(Date.now() - jobsStatIndex * 86400000),
    );
    statsService
      .getJobsStatisticPumpPackage(start, end)
      .then((data) => {
        if (data) {
          setJobStat(data);
        }
      })
      .catch(() => {
        toast.error(t('project-dashboard-page.get-production-jobs'));
        setError(`${t('project-dashboard-page.get-production-jobs')}`);
      });
  }
}

export function AssignedReminderComponent({ user }: Props) {
  const { t } = useTranslation();
  const [reminderLate, setReminderLate] = useState<Array<Reminder>>();
  const [reminderFinished, setReminderFinished] = useState<Array<Reminder>>();
  const [otherReminder, setOtherReminder] = useState<Array<Reminder>>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const RecentDelayInMillisecond = 1000 * 60 * 60 * 24 * 7; // 7 days
  const refreshReminders = () => {
    remindersService
      .GetRemindersAssignedBy()
      .then((res) => {
        if (res) {
          const date = new Date();
          const tempReminderLate: Array<Reminder> = [];
          const tempReminderFinished: Array<Reminder> = [];
          const tempOtherReminder: Array<Reminder> = [];
          res.forEach((reminder) => {
            if (reminder.finishedDate) {
              if (
                date.getMilliseconds() -
                  reminder.finishedDate.getMilliseconds() <
                RecentDelayInMillisecond
              ) {
                tempReminderFinished.push(reminder);
              }
            } else if (!reminder.date || reminder.date > date) {
              tempOtherReminder.push(reminder);
            } else {
              tempReminderLate.push(reminder);
            }
          });

          setReminderLate(tempReminderLate);
          setReminderFinished(tempReminderFinished);
          setOtherReminder(tempOtherReminder);
        }
        setIsLoading(false);
      })
      .catch(() => {
        toast.error(t('reminder-menu.get-reminder-error'));
        setIsLoading(false);
      });
  };
  useEffect(() => {
    refreshReminders();
  }, []);
  const pageTitle = t('reminder-menu.reminders');
  if (isLoading) <LoadingComponent />;
  return (
    <div
      id="reminder-card"
      className="card clickable"
      onDoubleClick={() => navigate('../Assigned-Reminder-Page/')}
    >
      <div className="card-title m-2 ms-3 d-flex">
        <h5 className="mb-2">{pageTitle}</h5>
        <input
          type="search"
          className="form-control rounded search mr-1"
          placeholder={t('common.search') || ''}
          aria-label={t('common.search') || ''}
          onChange={(ev) => {
            setGlobalFilter(ev.target.value);
          }}
        />
      </div>
      <div className="card-body" id="assigned-reminder-page">
        {reminderLate && reminderLate.length > 0 && (
          <div>
            <h6>{t('reminder-menu.reminder-late')}</h6>
            <ReminderTable
              refreshAction={refreshReminders}
              user={user}
              reminderArray={reminderLate}
              initialColumnVisibility={{ finishedDate: false, id: false }}
              globalFilter={globalFilter}
            ></ReminderTable>
          </div>
        )}
        {reminderFinished && reminderFinished.length > 0 && (
          <div>
            <h6>{t('reminder-menu.reminder-recently-finished')}</h6>
            <ReminderTable
              refreshAction={refreshReminders}
              user={user}
              reminderArray={reminderFinished}
              initialColumnVisibility={{ finishedDate: false, id: false }}
              globalFilter={globalFilter}
            ></ReminderTable>
          </div>
        )}
        {otherReminder && otherReminder.length > 0 && (
          <div>
            <h6>{t('reminder-menu.reminder-other')}</h6>
            <ReminderTable
              refreshAction={refreshReminders}
              user={user}
              reminderArray={otherReminder}
              initialColumnVisibility={{ finishedDate: false, id: false }}
              globalFilter={globalFilter}
            ></ReminderTable>
          </div>
        )}
      </div>
    </div>
  );
}

export function ClosingRatioComponent() {
  const { t } = useTranslation();
  let monthRatioData;
  let fiscalRatioData;
  const closingRatioData = useClosingRatio().data?.series;
  if (closingRatioData) {
    const [soldMonth, soldYear, pendingMonth, pendingYear] = closingRatioData;

    monthRatioData = [
      {
        title: soldMonth.title,
        value: soldMonth.total,
        color: soldMonth.color,
      },
      {
        title: pendingMonth.title,
        value: pendingMonth.total,
        color: pendingMonth.color,
      },
    ];

    fiscalRatioData = [
      {
        title: soldYear.title,
        value: soldYear.total,
        color: soldYear.color,
      },
      {
        title: pendingYear.title,
        value: pendingYear.total,
        color: pendingYear.color,
      },
    ];
  }
  const { error } = useClosingRatio();
  return (
    <div id="closing-ratio-card" className="card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.closing-ratio')}</h5>
      </div>
      <div className="card-body">
        {monthRatioData !== undefined && fiscalRatioData !== undefined ? (
          <>
            <div className="diagramms-container">
              <span>{t('project-dashboard-page.month')}</span>
              <span>{t('project-dashboard-page.fiscal')}</span>
              <div className="diagramm-container">
                <PieChart
                  data={monthRatioData}
                  lengthAngle={-360}
                  labelStyle={{ fill: 'white', fontSize: 15 }}
                  label={({ dataEntry }) =>
                    `${Math.round(dataEntry.percentage)} %`
                  }
                ></PieChart>
              </div>
              <div className="diagramm-container">
                <PieChart
                  data={fiscalRatioData}
                  lengthAngle={-360}
                  labelStyle={{ fill: 'white', fontSize: 15 }}
                  label={({ dataEntry }) =>
                    `${Math.round(dataEntry.percentage)} %`
                  }
                ></PieChart>
              </div>
              <div className="tablet-container">
                <div
                  className="tablet"
                  style={{ backgroundColor: monthRatioData[0].color }}
                ></div>
                <div>
                  {`${t('project-dashboard-page.sold')} ${
                    monthRatioData[0].value
                  }`}
                </div>
                <div
                  className="tablet"
                  style={{ backgroundColor: monthRatioData[1].color }}
                ></div>
                <div>
                  {`${t('project-dashboard-page.pending')} ${
                    monthRatioData[1].value
                  }`}
                </div>
              </div>
              <div className="tablet-container">
                <div
                  className="tablet"
                  style={{ backgroundColor: fiscalRatioData[0].color }}
                ></div>
                <div>
                  {`${t('project-dashboard-page.sold')} ${
                    fiscalRatioData[0].value
                  }`}
                </div>

                <div
                  className="tablet"
                  style={{ backgroundColor: fiscalRatioData[1].color }}
                ></div>
                <div>
                  {`${t('project-dashboard-page.pending')} ${
                    fiscalRatioData[1].value
                  }`}
                </div>
              </div>
            </div>
          </>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function EngineeringComponent() {
  const { t } = useTranslation();
  let enginneringRatioData;
  let total: number = 0;
  const enginneringComponentData = useEnginneringData().data?.series;
  const { error } = useEnginneringData();
  if (enginneringComponentData) {
    const [countSubmitalsWithoutPO, countSubmitalsWithPO, countProdShop] =
      enginneringComponentData;

    enginneringRatioData = [
      {
        title: countSubmitalsWithoutPO.title,
        value: countSubmitalsWithoutPO.total,
        color: countSubmitalsWithoutPO.color,
      },
      {
        title: countSubmitalsWithPO.title,
        value: countSubmitalsWithPO.total,
        color: countSubmitalsWithPO.color,
      },
      {
        title: countProdShop.title,
        value: countProdShop.total,
        color: countProdShop.color,
      },
    ];
    total =
      countSubmitalsWithoutPO.total +
      countSubmitalsWithPO.total +
      countProdShop.total;
  }

  return (
    <div id="enginnering-card" className="card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.engineering')}</h5>
        <div>total: {total}</div>
      </div>
      <div className="card-body">
        {enginneringRatioData ? (
          <>
            <div className="diagramm-container">
              <PieChart
                data={enginneringRatioData}
                labelPosition={84}
                lineWidth={30}
                labelStyle={{ fill: 'white', fontSize: 10 }}
                paddingAngle={2}
                label={({ dataEntry }) => `${dataEntry.value}`}
              ></PieChart>
            </div>
            <div className="tablet-container">
              <div
                className="tablet"
                style={{ backgroundColor: enginneringRatioData[0].color }}
              ></div>
              <div>{t('project-dashboard-page.with-po')}</div>
              <div
                className="tablet"
                style={{ backgroundColor: enginneringRatioData[1].color }}
              ></div>
              <div>{t('project-dashboard-page.without-po')}</div>
              <div
                className="tablet"
                style={{ backgroundColor: enginneringRatioData[2].color }}
              ></div>
              <div>{t('project-dashboard-page.production')}</div>
            </div>
          </>
        ) : (
          (error ?? <LoadingComponent />)
        )}
      </div>
    </div>
  );
}

export function SalesComponent() {
  const { t } = useTranslation();
  const salesData = useSalesData().data?.series;
  const { error } = useSalesData();

  return (
    <div id="sales-card" className="card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.sales')}</h5>
        {salesData && (
          <div className="d-flex align-items-center">
            {salesData.map((dataSerie, i, { length }) => {
              // not the last element
              if (length - 1 === i) return <></>;
              return (
                <div
                  key={dataSerie.title}
                  className="d-flex align-items-center"
                >
                  <div
                    className="tablet"
                    style={{ backgroundColor: dataSerie.color }}
                  ></div>
                  <div className="label">
                    {t(`project-dashboard-page.${dataSerie.title}`)}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="card-body">
        {
          // eslint-disable-next-line no-nested-ternary
          salesData ? (
            <>
              <div>
                <div className="serie-items-container">
                  <h6>{t('common.month')}</h6>
                  <h6>{t('common.year')}</h6>
                </div>
                {salesData.map((dataSerie, i, { length }) => {
                  // last element (the total at the bottom)
                  if (length - 1 === i)
                    return (
                      <div
                        key={dataSerie.title}
                        className="serie-total-container"
                      >
                        <h6 className="m-0">John (USA)</h6>
                        <h6 className="m-0">Monica (CAN)</h6>
                        {dataSerie.serieItems.map((data) => {
                          return (
                            <div
                              key={data.name}
                              className="total-container justify-content-between"
                            >
                              <span>{data.name.substring(0, 3)}</span>
                              <h6 className="m-0">
                                {`$${stringHelper.getNumberToUnit(data.value)}`}
                              </h6>
                            </div>
                          );
                        })}
                      </div>
                    );
                  return (
                    <div key={dataSerie.title}>
                      <div className="serie-items-container">
                        {dataSerie.serieItems.map((data) => {
                          return (
                            <div
                              key={data.name}
                              className="data"
                              style={{ backgroundColor: dataSerie.color }}
                            >
                              {`$${numberHelper.toNumberFormat(
                                data.value,
                                'en-CA',
                              )}`}
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </>
          ) : error ? (
            <span>{error}</span>
          ) : (
            <LoadingComponent />
          )
        }
      </div>
    </div>
  );
}

export function CashflowComponent() {
  const { t } = useTranslation();
  const cashflowData = useCashflowData().data?.series;
  const { error } = useCashflowData();
  let dataCan: Array<any[]> = [];
  let dataUsd: Array<any[]> = [];
  const options = {
    width: 225,
    height: 80,
    titleTextStyle: {
      fontSize: 15,
    },
    bar: { groupWidth: '50%' },
    legend: { position: 'none' },
    annotations: {
      highContrast: false,
      textStyle: {
        fontSize: 12,
        bold: true,
        color: '#000000',
        auraColor: 'none',
      },
    },
  };
  if (cashflowData) {
    dataCan = [
      [
        'Type',
        'Total',
        { role: 'style' },
        {
          sourceColumn: 0,
          role: 'annotation',
          type: 'string',
          calc: 'stringify',
        },
      ],
      [
        cashflowData[0].title[0],
        cashflowData[0].total,
        cashflowData[0].color,
        stringHelper.getNumberToUnit(cashflowData[0].total),
      ],
      [
        cashflowData[1].title[0],
        cashflowData[1].total,
        cashflowData[1].color,
        stringHelper.getNumberToUnit(cashflowData[1].total),
      ],
    ];
    dataUsd = [
      [
        'Type',
        'Total',
        { role: 'style' },
        {
          sourceColumn: 0,
          role: 'annotation',
          type: 'string',
          calc: 'stringify',
        },
      ],
      [
        cashflowData[2].title[0],
        cashflowData[2].total,
        cashflowData[2].color,
        stringHelper.getNumberToUnit(cashflowData[2].total),
      ],
      [
        cashflowData[3].title[0],
        cashflowData[3].total,
        cashflowData[3].color,
        stringHelper.getNumberToUnit(cashflowData[3].total),
      ],
    ];
  }
  return (
    <div id="cashflow-card" className="card">
      <div className="card-title m-2 ms-3">
        <h5>{t('project-dashboard-page.cashflow')}</h5>
        {cashflowData && <div className="d-flex align-items-center"></div>}
      </div>
      <div className="card-body">
        {
          // eslint-disable-next-line no-nested-ternary
          dataUsd && dataCan ? (
            <>
              <h6>CAN</h6>
              <Chart
                chartType="BarChart"
                height="80px"
                data={dataCan}
                options={options}
              />
              <h6>USD</h6>
              <Chart
                chartType="BarChart"
                height="80px"
                data={dataUsd}
                options={options}
              />
            </>
          ) : error ? (
            <span>{error}</span>
          ) : (
            <LoadingComponent />
          )
        }
      </div>
    </div>
  );
}
