import { isNull } from '../../Core/useful-function';

export class QuoteHeatExchanger {
  id: number;
  tag: string;
  description: string;
  qty: number;
  net: number;
  it_ocode: string;
  type: number;
  quoteNum: number;
  cliCusPrice: number;
  modelId: number;
  bs_Id: number;
  service: string;
  manufacturer: string;
  note: string;
  tubeAsmeRating: number;
  shellAsmeRating: number;
  shellMater: number;
  tubeMater: number;
  requiredHeatSurface: number;
  lmtd: number;
  maxLoads: number;
  override: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.tag = data.tag;
    this.description = data.description;
    this.qty = data.qty;
    this.net = data.net;
    this.it_ocode = data.it_ocode;
    this.type = data.type;
    this.quoteNum = data.quoteNum;
    this.cliCusPrice = data.cliCusPrice;
    this.modelId = data.modelId;
    this.bs_Id = data.bS_ID;
    this.service = data.service;
    this.manufacturer = data.manufacturer;
    this.note = data.note;
    this.tubeAsmeRating = data.tubeAsmeRating;
    this.shellAsmeRating = data.shellAsmeRating;
    this.shellMater = data.shellMater;
    this.tubeMater = data.tubeMater;
    this.requiredHeatSurface = data.requiredHeatSurface;
    this.lmtd = data.lMTD;
    this.maxLoads = data.maxLoads;
    this.override = data.override;
  }
}

export class QuoteHeatExchangerOption {
  id: number;
  quoteHeatExchangerId: number;
  heatExchangerOptionId: number;
  netPrice: number;
  listPrice: number;
  cliCusPrice: number;

  constructor(data: any) {
    if (isNull(data.id)) throw new ReferenceError('option undefined');
    if (isNull(data.quoteHeatExchangerId))
      throw new ReferenceError('quote heat exchanger undefined');
    if (isNull(data.heatExchangerOptionId))
      throw new ReferenceError('heat exchanger option undefined');
    if (isNull(data.netPrice)) throw new ReferenceError('net price undefined');
    if (isNull(data.listPrice))
      throw new ReferenceError('list price undefined');
    if (isNull(data.cliCusPrice))
      throw new ReferenceError('cli cus price undefined');

    this.id = data.id;
    this.quoteHeatExchangerId = data.quoteHeatExchangerId;
    this.heatExchangerOptionId = data.heatExchangerOptionId;
    this.netPrice = data.netPrice;
    this.listPrice = data.listPrice;
    this.cliCusPrice = data.cliCusPrice;
  }
}

export class QuoteHeatExchangerItem {
  id: number;
  he_Id: number;
  it_code: number;
  it_ocode: string;
  it_desc: string;
  it_cost: number;
  it_qty: number;

  constructor(data: any) {
    this.id = data.id;
    this.he_Id = data.he_Id;
    this.it_code = data.it_code;
    this.it_ocode = data.it_ocode;
    this.it_desc = data.it_desc;
    this.it_cost = data.it_cost;
    this.it_qty = data.it_qty;
  }
}
