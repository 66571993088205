import './quote-priority-table.scss';

import { Button } from 'react-bootstrap';
import {
  faTrash,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Dispatch, SetStateAction } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Select from 'react-select';

import { useNavigate } from 'react-router-dom';
import type { QuotePriority } from '../../services/QuotePriority/Models/QuotePriority';
import stringHelper from '../../services/Core/Helpers/string-helper';
import type { QuoteExternalFile } from '../../services/Quotes/Models/QuoteExternalFile';
import { getProdStatus } from '../../services/Quotes/Models/QuoteExternalFile';

type TableProps = {
  quotePriorityArray: Array<QuotePriority>;
  setQuotePriorityArray: Dispatch<SetStateAction<QuotePriority[] | undefined>>;
  editing: boolean;
  initialColumnVisibility?: {};
  getQuotePossible?: () => Promise<QuotePriority[] | undefined>;
};
export default function QuotePriorityTable({
  quotePriorityArray,
  setQuotePriorityArray,
  editing,
  initialColumnVisibility,
  getQuotePossible,
}: TableProps) {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState(
    initialColumnVisibility,
  );
  const [optionQuoteId, setOptionQuoteId] = useState<Array<any>>();

  useEffect(() => {
    if (editing && getQuotePossible)
      getQuotePossible()
        .then((possiblePriorities) => {
          if (possiblePriorities !== undefined) {
            setOptionQuoteId(
              possiblePriorities.map((priority: QuotePriority) => ({
                value: priority,
                label:
                  priority.quoteExternalFile &&
                  `${priority.quoteExternalFile.quoteId} ${
                    priority.quoteExternalFile.orderId
                  }-${priority.quoteExternalFile.kitId} ${priority.name}`,
              })),
            );
          }
        })
        .catch((err) => console.log(err));
  }, [editing]);
  const navigate = useNavigate();
  const columns: any = useMemo(() => {
    if (editing && optionQuoteId)
      return [
        {
          header: 'quote-priority-page.jobNumber',
          accessorKey: 'quoteExternalFile.id',
          cell: (props: any) => (
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              isDisabled={!editing}
              value={optionQuoteId.find(
                (x) => x.value.quoteExternalFile.id === props.getValue(),
              )}
              options={optionQuoteId}
              onChange={(priority) => {
                const { position } = props.row.original;
                quotePriorityArray.splice(props.row.index, 1);
                const newPriority = { ...priority.value, position };
                quotePriorityArray = [...quotePriorityArray, newPriority];

                setQuotePriorityArray(
                  quotePriorityArray.sort((a, b) => a.position - b.position),
                );
              }}
            />
          ),
        },
        {
          header: t('quote-priority-page.name'),
          accessorKey: 'name',
        },
        {
          header: t('quote-priority-page.status'),
          cell: (props: any) => (
            <span>
              {getProdStatus(
                props.row.original.quoteExternalFile as QuoteExternalFile,
              )}
            </span>
          ),
        },
        {
          header: t('quote-priority-page.date'),
          accessorKey: 'standerDate',
          cell: (props: any) => (
            <span>
              {stringHelper.toDateString(
                new Date(props.row.original.quoteExternalFile.standerDate),
              )}
            </span>
          ),
        },

        {
          header: t('quote-priority-page.operation'),
          cell: (props: any) => (
            <div>
              <Button
                size="sm"
                onClick={() => {
                  const current = quotePriorityArray.at(
                    props.row.index,
                  )?.position;
                  const above = quotePriorityArray.at(
                    props.row.index - 1,
                  )?.position;
                  if (current && above && props.row.index !== 0) {
                    quotePriorityArray[props.row.index].position = above;
                    quotePriorityArray[props.row.index - 1].position = current;
                    setQuotePriorityArray([
                      ...quotePriorityArray.sort(
                        (a, b) => a.position - b.position,
                      ),
                    ]);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
              <Button
                size="sm"
                className="ms-2"
                onClick={() => {
                  const current = quotePriorityArray.at(
                    props.row.index,
                  )?.position;
                  const below = quotePriorityArray.at(
                    props.row.index + 1,
                  )?.position;
                  if (current && below) {
                    quotePriorityArray[props.row.index].position = below;
                    quotePriorityArray[props.row.index + 1].position = current;
                    setQuotePriorityArray([
                      ...quotePriorityArray.sort(
                        (a, b) => a.position - b.position,
                      ),
                    ]);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
              <Button
                size="sm"
                className="btn-outline-primary red ms-3"
                onClick={() => {
                  if (quotePriorityArray.length === 1) quotePriorityArray = [];
                  quotePriorityArray.splice(props.row.index, 1);
                  setQuotePriorityArray([...quotePriorityArray]);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ),
        },
      ];
    return [
      {
        header: t('quote-priority-page.quoteId'),
        accessorKey: 'quoteExternalFile.quoteId',
        cell: (props: any) => (
          <span>{props.row.original.quoteExternalFile.quoteId}</span>
        ),
      },
      {
        header: t('quote-priority-page.jobNumber'),
        accessorKey: 'quoteExternalFile.orderId',
        cell: (props: any) => (
          <span>
            {`${props.row.original.quoteExternalFile.orderId}-${
              props.row.original.quoteExternalFile.kitId
            }`}
          </span>
        ),
      },
      {
        header: t('quote-priority-page.name'),
        accessorKey: 'name',
      },
      {
        header: t('quote-priority-page.status'),
        accessorKey: 'status',
        cell: (props: any) => (
          <span>
            {getProdStatus(
              props.row.original.quoteExternalFile as QuoteExternalFile,
            )}
          </span>
        ),
      },
      {
        header: t('quote-priority-page.date'),
        accessorKey: 'standerDate',
        cell: (props: any) => (
          <span>
            {stringHelper.toDateString(
              new Date(props.row.original.quoteExternalFile.standerDate),
            )}
          </span>
        ),
      },
    ];
  }, [quotePriorityArray, editing, optionQuoteId]);
  const table = useReactTable({
    columns,
    data: quotePriorityArray,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility,
    },
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <section id="quote-priority-table">
      <table className="table table-hover">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const headerName = header.column.columnDef.header?.toString();
                return (
                  <th key={header.id} className="columnName">
                    <span title={headerName}>{headerName}</span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                onClick={() =>
                  !editing &&
                  row.original.quoteExternalFile &&
                  navigate(
                    `../Quotes/717/${row.original.quoteExternalFile.quoteId}`,
                  )
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {editing && (
            <tr>
              <td>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={!editing}
                  options={optionQuoteId}
                  onChange={(priority) => {
                    let position = quotePriorityArray.at(-1)?.position; // get last position(highest)
                    const newPriority = {
                      ...priority.value,
                      position: position ? ++position : 1,
                    };
                    quotePriorityArray = [...quotePriorityArray, newPriority];

                    setQuotePriorityArray(quotePriorityArray);
                    // optionQuoteId.findIndex((x=>x.))
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
}
