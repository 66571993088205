import BaseApi from '../Core/REST/BaseApi';
import type { Client } from './Client';
import type { Customer } from './Customer';

class UserService extends BaseApi {
  async getMe(employeeId: string): Promise<string | undefined> {
    const url = `${this.baseUrl}/Users/${employeeId}`;
    return this.get<string | undefined>(url);
  }
  async getAll(employeeId: string): Promise<Array<Client> | undefined> {
    const url = `${this.baseUrl}/Users/getAll/${employeeId}`;
    return this.get<Array<Client> | undefined>(url);
  }
  async getUser(userId: string): Promise<Client | undefined> {
    const url = `${this.baseUrl}/Users/Get-User/${userId}`;
    return this.get<Client | undefined>(url);
  }
  async postUser(employeeId: string, client: Client) {
    const url = `${this.baseUrl}/Users/${employeeId}`;
    return this.post(url, client);
  }
  async deleteUser(employeeId: string, userId: string) {
    const url = `${this.baseUrl}/Users/${employeeId}/${userId}`;
    return this.delete(url);
  }
  async updatePassword(client: Client) {
    const url = `${this.baseUrl}/Users/Update-Password/717`;
    return this.patch(url, client);
  }
  async updateActive(employeeId: string, client: Client) {
    const url = `${this.baseUrl}/Users/Update-Active/${employeeId}`;
    return this.patch(url, client);
  }
  async updateSelfPassword(password: string) {
    const url = `${this.baseUrl}/Users/Update-Self-Password`;
    return this.patch(url, { password });
  }
  async updateSelf(client: Client) {
    const url = `${this.baseUrl}/Users/Update-Self`;
    return this.patch(url, client);
  }
  async uploadProfileImage(image: File) {
    const url = `${this.baseUrl}/Users/Upload-Profile-Image`;
    const formData = new FormData();
    formData.append('profileImage', image);
    return this.postFile(url, formData);
  }
  async deleteProfileImage() {
    const url = `${this.baseUrl}/Users/Delete-Profile-Image`;
    return this.delete(url);
  }
  async getAllCust(employeeId: string) {
    const url = `${this.baseUrl}/Users/Get-All-Cust/${employeeId}`;
    return this.get<Array<Customer> | undefined>(url);
  }
  async getAllUnasignedToClientCust(employeeId: string, userId: string) {
    const url = `${this.baseUrl}/Users/Get-All-Unassigned-Cust/${employeeId}/${userId}`;
    return this.get<Array<Customer> | undefined>(url);
  }
  async ClientChangeCountryRequest() {
    const url = `${this.baseUrl}/Users/Client-Change-Country-Request`;
    return this.post(url, {});
  }
}

const userService = new UserService();
export default userService;
