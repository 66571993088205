import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import numberHelper from '../../../services/Core/Helpers/number-helper';
import './rush-program-manager-page.scss';
import quoteService from '../../../services/Quotes/QuoteService';
import type { RushProgramPumpDelays } from '../../../services/Quotes/Models/RushProgramPumpDelays';
import { RushType } from '../../../services/Quotes/Models/RushProgramPumpDelays';
import type { PumpRushRow } from '../../../services/Quotes/Models/QuoteRushDelayPumpInfo';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';

/// *******this page is being used in the old code and remain here to not affect the old code.**********/////
type IParamTypes = {
  userId: string;
  quoteId: string;
};

export const QuoteKitRushProgramTable = () => {
  const { t } = useTranslation();

  const { userId, quoteId } = useParams<IParamTypes>();
  const [rushPrograms, setRushPrograms] = useState<
    Array<RushProgramPumpDelays>
  >([]);
  const [quoteRushPrograms, setQuoteRushPrograms] = useState<
    Array<PumpRushRow>
  >([]);

  useEffect(() => {
    loadDropDownRushPrograms();
    loadQuoteRushPrograms();
  }, [userId, quoteId]);

  function handleEnteredDays(e: any, pump: any) {
    const newArray: Array<PumpRushRow> = quoteRushPrograms.map(
      (quoteRushProgram) => {
        if (pump.quoteKitId === quoteRushProgram.quoteKitId) {
          return { ...quoteRushProgram, nbDays: parseInt(e.target.value, 10) };
        }
        return quoteRushProgram as PumpRushRow;
      },
    );
    setQuoteRushPrograms(newArray);
  }

  function handleEnteredPercent(e: any, pump: any) {
    const newPrecent = e.target.value;
    const newArray: Array<PumpRushRow> = quoteRushPrograms.map(
      (quoteRushProgram) => {
        if (pump.quoteKitId === quoteRushProgram.quoteKitId) {
          return {
            ...quoteRushProgram,
            addedPercent: parseFloat(newPrecent),
            netPrice:
              pump.initialNetPrice * (newPrecent / 100) + pump.initialNetPrice,
          };
        }
        return quoteRushProgram as PumpRushRow;
      },
    );
    setQuoteRushPrograms(newArray);
  }

  function handleSave() {
    console.log(quoteRushPrograms);
    if (userId && quoteId) {
      quoteService
        .insertQuoteRushPrograms(userId, quoteRushPrograms)
        .then((res) => {
          if (res) {
            const activeTabIndex = 1;
            sessionStorage.setItem('activeTabIndex', activeTabIndex.toString());
            window.history.back();
          } else toast.error('Error in fetching data!');
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }

  return (
    <div id="container-rush-program">
      <BreadcrumbComponent
        items={[
          { text: 'Quotes', link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: 'Rush Program',
            active: true,
          },
        ]}
      />
      <div className="page-title wrapper">
        <h3>{t('rush-program-page.card-header')}</h3>
      </div>
      <div className="card-body">
        <table className="table-rush">
          <thead>
            <tr>
              <th>{t('rush-program-page.model')} </th>
              <th>{t('rush-program-page.tag')}</th>
              <th>{t('rush-program-page.net-price')} </th>
              <th>{t('rush-program-page.initial-net-price')} </th>
              <th>{t('rush-program-page.delay')} </th>
              <th>{t('rush-program-page.days')} </th>
              <th>{t('rush-program-page.percentage')} </th>
            </tr>
          </thead>
          <tbody>
            {quoteRushPrograms &&
              quoteRushPrograms.map((pump, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{pump.model}</td>
                  <td>{pump.tag}</td>
                  <td>{numberHelper.toNumberFormat(pump.netPrice)}</td>
                  <td>{numberHelper.toNumberFormat(pump.initialNetPrice)}</td>
                  <td>
                    <select
                      id="rush-program"
                      className="form-select"
                      defaultValue={
                        pump.rushType === RushType.NoRushProgram ||
                        pump.rushType === RushType.PredefinedProgram
                          ? pump.delayId
                          : rushPrograms.find(
                              (program) =>
                                program.rushType === RushType.OthersOption,
                            )?.id
                      }
                      onChange={(ev) =>
                        onRushProgramChange(parseInt(ev.target.value, 10), pump)
                      }
                      onClick={(ev) => {
                        if (
                          pump.delayId !==
                          parseInt((ev.target as HTMLSelectElement).value, 10)
                        )
                          onRushProgramChange(
                            parseInt(
                              (ev.target as HTMLSelectElement).value,
                              10,
                            ),
                            pump,
                          );
                      }}
                    >
                      {rushPrograms.map((program) => {
                        return (
                          <option key={program.id} value={program.id}>
                            {program.description}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td>
                    {pump.editable ? (
                      <input
                        type="number"
                        min="0"
                        className="form-control rounded"
                        defaultValue={pump.nbDays}
                        onChange={(e) => handleEnteredDays(e, pump)}
                      ></input>
                    ) : (
                      pump.nbDays
                    )}
                  </td>

                  <td>
                    {pump.editable ? (
                      <input
                        type="number"
                        min="0"
                        className="form-control rounded"
                        defaultValue={pump.addedPercent}
                        onChange={(e) => handleEnteredPercent(e, pump)}
                      ></input>
                    ) : (
                      pump.addedPercent
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <button className="btn btn-primary" onClick={(_) => handleSave()}>
          Save
        </button>
      </div>
    </div>
  );

  function loadQuoteRushPrograms() {
    if (userId && quoteId) {
      quoteService
        .getAllQuoteRushPrograms(userId, quoteId)
        .then((res) => {
          console.log(res);
          if (res) setQuoteRushPrograms(res);
          else console.error('Error in fetching data!');
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function loadDropDownRushPrograms() {
    if (userId) {
      quoteService
        .getAllRushProgramList(userId)
        .then((res) => {
          if (res) setRushPrograms(res);
          else console.error('Error in fetching data!');
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function onRushProgramChange(rushProgramId: number, pump: PumpRushRow) {
    const selectedRushProgram: RushProgramPumpDelays | undefined =
      rushPrograms.find((program) => program.id === rushProgramId);
    if (selectedRushProgram !== undefined) {
      const newArray: Array<PumpRushRow> = quoteRushPrograms.map(
        (quoteRushProgram) => {
          if (pump.quoteKitId === quoteRushProgram.quoteKitId) {
            if (selectedRushProgram?.rushType === RushType.OthersOption) {
              return {
                ...quoteRushProgram,
                delayId: selectedRushProgram?.id ?? 1,
                description:
                  selectedRushProgram?.description ?? pump.description,
                rushType: selectedRushProgram?.rushType,
                nbDays:
                  pump?.rushType === RushType.NewGeneratedProgram
                    ? pump.nbDays
                    : 0,
                addedPercent:
                  pump?.rushType === RushType.NewGeneratedProgram
                    ? pump.addedPercent
                    : 0,
                editable: true,
              };
            }
            const percent = selectedRushProgram?.addedPercent ?? 0;
            return {
              ...quoteRushProgram,
              delayId: selectedRushProgram?.id ?? 1,
              description: selectedRushProgram?.description ?? pump.description,
              nbDays: selectedRushProgram?.nbDays ?? 0,
              addedPercent: selectedRushProgram?.addedPercent ?? 0,
              rushType: selectedRushProgram?.rushType ?? RushType.NoRushProgram,
              editable: false,
              netPrice:
                pump.initialNetPrice * (percent / 100) + pump.initialNetPrice,
            };
          }
          return quoteRushProgram as PumpRushRow;
        },
      );
      setQuoteRushPrograms(newArray);
    }
  }
};
