import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import type { Client } from '../../services/Users/Client';
import { logout } from '../../services/Login/loginFunction';
import { LanguageSelectorItem } from './LanguageSelector';

type Props = {
  user: Client;
};
export default function UserInformationDropdown({ user }: Props) {
  const navigate = useNavigate();
  // console.log(i18n.languages)
  return (
    <div className="dropdown">
      <a
        type="button"
        id="user-information-dropdown"
        className="nav-link dropdown-toggle text-dark"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {user.nom}
      </a>
      <ul className="dropdown-menu dropdown-menu-lg-end">
        <LanguageSelectorItem></LanguageSelectorItem>
        <div className="dropdown-divider"></div>
        <div title="Sign Out">
          <li>
            <a className="dropdown-item" onClick={() => logout(navigate)}>
              <FontAwesomeIcon icon={faSignOut} />
              <span>Sign out</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}
