export enum RushType {
  NoRushProgram = 0,
  PredefinedProgram = 1,
  OthersOption = 2,
  NewGeneratedProgram = 3,
}
export class RushProgramPumpDelays {
  id: number;
  description: string;
  nbDays: number;
  addedPercent: number;
  rushType: RushType = RushType.NoRushProgram;

  constructor(data: any) {
    this.id = data.id;
    this.description = data.description;
    this.nbDays = data.nb_days;
    this.addedPercent = data.addedPrescent;
    this.rushType = data.rushType;
    this.rushType = data.rushType || RushType.NoRushProgram;
  }
}
