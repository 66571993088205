import BaseApi from '../Core/REST/BaseApi';
import type { EmailConfig } from './EmailConfig';

class EmailService extends BaseApi {
  async getAllEmailConfigs(): Promise<Array<EmailConfig> | undefined> {
    const url = `${this.baseUrl}/sendEmail/getEmailConfiguration`;

    return this.get<Array<EmailConfig> | undefined>(url);
  }

  async postEmailConfig(newEmailConfig: EmailConfig) {
    const url = `${this.baseUrl}/sendEmail/updateEmailConfiguration`;
    // console.log("newEmailConfig", newEmailConfig);
    return this.post(url, newEmailConfig);
  }
}

const emailService = new EmailService();
export default emailService;
