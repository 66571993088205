type CheckboxProps = {
  value: boolean;
  onChange: (event: any) => void;
};

export default function Checkbox({ value, onChange }: CheckboxProps) {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
    </label>
  );
}
