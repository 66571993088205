import './sales-report-page.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../../components/Core/Loading';
import type { CommissionReport } from '../../../services/Sales/Models/CommissionReport';
import salesService from '../../../services/Sales/SalesService';
import QuarterSectionReportComponent from './quarter-section-report-component';
import numberHelper from '../../../services/Core/Helpers/number-helper';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';

type IParamTypes = {
  employeeId: string;
};

export default function SalesReportPage() {
  const { t } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const [commissions, setCommissions] = useState<Array<CommissionReport>>([]);
  const [fiscalDate, setFiscalDate] = useState<number>(0);

  useEffect(() => {
    loadReportData();
  }, [employeeId]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div id="commission-report-section">
      <BreadcrumbComponent
        items={[
          { text: t('sales-report-page.sales') },
          { text: t('sales-report-page.sales-report'), active: true },
        ]}
      />
      <div className="card">
        <div className="card-title ms-3 mt-2">
          <h1 className="h3">
            {t('sales-report-page.title', {
              0: fiscalDate,
              1: fiscalDate + 1,
            })}
          </h1>
        </div>
        <div className="card-body">
          <div className="commissions-grid-container">
            {commissions &&
              commissions.map((commission, index) => {
                return (
                  <table key={index} className="table">
                    <thead>
                      <tr className="bg-lemon-chiffon">
                        <th colSpan={4}>
                          <h5>{commission.territory}</h5>
                        </th>
                      </tr>
                      <tr className="bg-deep-lemon">
                        <th colSpan={2} className="bordered">
                          {t('sales-report-page.annual-goal')}
                        </th>
                        <th colSpan={2} className="bordered">
                          {t('sales-report-page.annual-sales')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-lemon-chiffon">
                        <td colSpan={2} className="bordered">
                          {numberHelper.toCurrencyFormat(
                            commission.goal,
                            commission.currency,
                          )}
                        </td>
                        <td
                          colSpan={2}
                          className={`bordered ${
                            commission.total < commission.goal
                              ? 'text-danger'
                              : 'text-success'
                          }`}
                        >
                          {numberHelper.toCurrencyFormat(
                            commission.total,
                            commission.currency,
                          )}
                        </td>
                      </tr>
                      <tr>
                        {commission.items.map((item, i) => {
                          if (!item) return <></>;
                          return (
                            <td key={i}>
                              <QuarterSectionReportComponent
                                reportItem={item}
                                goal={commission.goal / 4}
                                currency={commission.currency}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );

  function loadReportData() {
    if (employeeId) {
      salesService
        .getCommissionReport(employeeId, new Date(Date.now()))
        .then((data: Array<CommissionReport> | undefined) => {
          if (data) {
            setCommissions(data);
            if (data[0].items[0]?.quarter?.startDate) {
              setFiscalDate(data[0].items[0]?.quarter?.startDate.getFullYear());
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
}
