import ReactDOM from 'react-dom/client';
import './index.scss';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'font-awesome/css/font-awesome.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil, faTrashCan, fas } from '@fortawesome/free-solid-svg-icons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import App from './App';

library.add(fas, faTrashCan, faPencil);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="595208157595-n390hsnbpqmj96qg0a3v23k9t9e7l8v7.apps.googleusercontent.com">
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  </GoogleOAuthProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
