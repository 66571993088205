import BaseApi from '../Core/REST/BaseApi';
import type { SystemTexts } from './BoosterSystem/System/SystemTexts';
import type { SystemTypes } from './BoosterSystem/System/SystemTypes';

class BSystemService extends BaseApi {
  async getBoosterSystemModels(
    nbPump: number,
    type: number,
  ): Promise<Array<SystemTexts> | undefined> {
    const url = `${this.baseUrl}/BoosterSystem/system/get-models/${nbPump}/${type}`;
    return this.get<Array<SystemTexts>>(url);
  }

  async getBoosterSystemTypes(
    nbPump: number,
  ): Promise<Array<SystemTypes> | undefined> {
    const url = `${this.baseUrl}/BoosterSystem/system/get-types/${nbPump}`;
    return this.get<Array<SystemTypes>>(url);
  }
}
const bSystemService = new BSystemService();
export default bSystemService;
