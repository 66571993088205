import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteProduction } from '../../../services/Quotes/Models/QuoteProduction';
import quoteService from '../../../services/Quotes/QuoteService';
import QuoteProductionBar from './quote-production-bar';
import QuoteProductionShowItems from './quote-production-show-items';

interface Props {
  userId: string;
  quoteId: string;
}

const QuoteProductionComponent = (props: Props) => {
  const { t } = useTranslation();
  const [quoteProduction, setQuoteProduction] = useState<
    QuoteProduction[] | undefined
  >([]);

  const [filteredQuoteProduction, setFilteredQuoteProduction] = useState<
    QuoteProduction[] | undefined
  >([]);

  const [jobType, setJobType] = useState('All');

  useEffect(() => {
    quoteService
      .quoteProduction(props.userId, props.quoteId, jobType)
      .then((data) => {
        setQuoteProduction(data);
        setFilteredQuoteProduction(data);
        if (data && data.length > 0) {
          setJobType(data[0].jobType);
        }
      })
      .catch(() => {
        return new Array<QuoteProduction>();
      });
  }, [props.userId, props.quoteId]);

  useEffect(() => {
    setFilteredQuoteProduction(
      quoteProduction?.filter((x) => x.jobType === jobType),
    );
    console.log(filteredQuoteProduction);
  }, [jobType]);

  function changeJobType(jobType: string) {
    setJobType(jobType);
  }

  const bar = Array<string>();

  if (quoteProduction && quoteProduction.length > 0) {
    quoteProduction.forEach((x) => {
      if (bar.indexOf(x.jobType) === -1) {
        bar.push(x.jobType);
      }
    });
  }
  return (
    <div>
      {filteredQuoteProduction && filteredQuoteProduction.length > 0 && (
        <div>
          {bar.length > 1 && (
            <div>
              <QuoteProductionBar
                barContent={bar}
                clickme={changeJobType}
              ></QuoteProductionBar>
            </div>
          )}
          <div>
            <QuoteProductionShowItems
              model={filteredQuoteProduction!}
            ></QuoteProductionShowItems>
          </div>
        </div>
      )}
      {filteredQuoteProduction && filteredQuoteProduction.length === 0 && (
        <div>
          <h5>{t('quote-page.No-Data-Found')}</h5>
        </div>
      )}
    </div>
  );
};

export default QuoteProductionComponent;
