export class Client {
  id: number;
  nom: string;
  nomCompagnie: string;
  password: string;
  actif: boolean;
  email: string;
  employee: boolean;
  departmentId: number | undefined;
  roleId: number | undefined;
  dynacomCustId: number | undefined;
  sysDisc: number | undefined;
  hediscount: number | undefined;
  barePumpDiscount: number | undefined;
  itDiscount: number | undefined;
  pumpDiscount: number | undefined;
  plateAndFrameHeDiscount: number | undefined;
  discount: number | undefined;
  country: string | undefined;
  province: string | undefined;
  logoPath: string | undefined;
  department?: string;
  role?: string;
  constructor(
    id?: number,
    nom?: string,
    nomCompagnie?: string,
    password?: string,
    actif?: boolean,
    email?: string,
    employee?: boolean,
    departmentId?: number,
    roleId?: number,
    dynacomCustId?: number,
    logoPath?: string,
  ) {
    if (id === undefined) throw new ReferenceError('id undefined');
    if (nom === undefined) throw new ReferenceError('nom undefined');
    if (nomCompagnie === undefined)
      throw new ReferenceError('nomCompagnies undefined');
    if (actif === undefined) throw new ReferenceError('actif undefined');
    if (email === undefined) throw new ReferenceError('email undefined');
    if (employee === undefined) throw new ReferenceError('employee undefined');
    if (password === undefined) throw new ReferenceError('password undefined');
    if (password === undefined) throw new ReferenceError('logo path undefined');
    this.id = id;
    this.nom = nom;
    this.nomCompagnie = nomCompagnie;
    this.actif = actif;
    this.email = email;
    this.employee = employee;
    this.departmentId = departmentId;
    this.roleId = roleId;
    this.password = password;
    this.dynacomCustId = dynacomCustId;
    this.logoPath = logoPath;
  }
}
