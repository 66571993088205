import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import {
  AddButton,
  DeleteIconButton,
  EditIconButton,
  SaveIconButton,
} from '../../components/general-button-components';
import {
  getTableHeaderSortProps,
  ReactTableHeaderOptions,
} from '../../components/react-table/react-table-component';

type SystemItemComponentProps = {
  userId?: string;
  quoteId?: string;
  componentId: number;
};

export function SystemItemComponent(props: SystemItemComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, quoteId, componentId } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [editing, setEditing] = useState(false);

  const boosterItems = [
    {
      id: 1,
      name: 'AA-1/4',
      description: '1/4 NPT AA 150# AIR VENT',
      qty: 1,
      stock: 2,
      cost: 200,
    },
    {
      id: 2,
      name: 'AA-1/8',
      description: '1/8 NPT AA 150# AIR VENT',
      qty: 1,
      stock: 70,
      cost: 1.39,
    },
  ];

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const handleAddProductBtnClick = () => {
    navigate(`/SingleItem/${userId}/${quoteId}`);
  };

  const FloatCellRenderer = ({ cell }: { cell: any }) => {
    const value = parseFloat(cell.getValue());
    return Number.isNaN(value) ? value.toFixed(2) : cell.getValue();
  };

  const columnsPumps: any = useMemo(() => {
    return [
      {
        header: t('add-booster-system-page.item-name'),
        accessorKey: 'name',
      },
      {
        header: t('add-booster-system-page.description'),
        accessorKey: 'description',
      },
      {
        header: t('add-booster-system-page.qty'),
        accessorKey: 'qty',
      },
      {
        header: t('add-booster-system-page.stock'),
        accessorKey: 'stock',
      },
      {
        header: t('add-booster-system-page.cost'),
        accessorKey: 'cost',
        cell: FloatCellRenderer,
      },
    ];
  }, [t]);

  const tablePumps = useReactTable({
    columns: columnsPumps,
    data: boosterItems,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  if (componentId === 1) {
    return (
      <div id="system-pump-component">
        <div className="button-container">
          <AddButton
            title={t('add-booster-system-page.add-item') || 'Add Item'}
            btnSize="sm"
            onClick={handleAddProductBtnClick}
          />
        </div>
        <div className="table-header">
          <span className="sub-description">
            Currently added items
            {/* {t("mfv-selection-page.description-tb3")} */}
          </span>
        </div>
        <table className="table table-hover">
          <thead>
            {tablePumps.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th></th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {header.column.columnDef.header?.toString()}
                        <ReactTableHeaderOptions header={header} />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {boosterItems.map((row, rowIndex: number) => (
              <tr
                key={rowIndex}
                // className={selectedPump === row ? "selected-row" : ""}
                // onClick={() => handlePumpSelection(row)}
                onMouseEnter={() => setHoveredRowIndex(rowIndex)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    // checked={selectedPump === row}
                    // onChange={() => handlePumpSelection(row)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>

                {tablePumps
                  .getRowModel()
                  .rows[rowIndex].getVisibleCells()
                  .map((cell) => (
                    <td className="align-vertical-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                <td className="action-buttons-cell">
                  <div
                    className={`action-buttons ${
                      hoveredRowIndex === rowIndex ? 'visible' : ''
                    }`}
                  >
                    {!editing ? (
                      <EditIconButton
                        title="Edit"
                        btnSize="sm"
                        onClick={() => setEditing(true)}
                        className="me-3"
                      />
                    ) : (
                      <SaveIconButton
                        title="Save"
                        btnSize="sm"
                        onClick={() => setEditing(false)}
                        className="me-3"
                      />
                    )}
                    <DeleteIconButton
                      btnSize="sm"
                      title={t('quote-page.delete-quote') || 'Delete Quote'}
                      onClick={() => console.log('delete')}
                      className="me-3"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  if (componentId === 2) {
    return (
      <div id="system-pump-component">
        <div className="button-container">
          <AddButton
            title={t('add-booster-system-page.add-piping') || 'Add Piping'}
            btnSize="sm"
            onClick={handleAddProductBtnClick}
          />
        </div>
        <div className="table-header">
          <span className="sub-description">
            Currently added items
            {/* {t("mfv-selection-page.description-tb3")} */}
          </span>
        </div>
        <table className="table table-hover">
          <thead>
            {tablePumps.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th></th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {header.column.columnDef.header?.toString()}
                        <ReactTableHeaderOptions header={header} />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {boosterItems.map((row, rowIndex: number) => (
              <tr
                key={rowIndex}
                // className={selectedPump === row ? "selected-row" : ""}
                // onClick={() => handlePumpSelection(row)}
                onMouseEnter={() => setHoveredRowIndex(rowIndex)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    // checked={selectedPump === row}
                    // onChange={() => handlePumpSelection(row)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>

                {tablePumps
                  .getRowModel()
                  .rows[rowIndex].getVisibleCells()
                  .map((cell) => (
                    <td className="align-vertical-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                <td className="action-buttons-cell">
                  <div
                    className={`action-buttons ${
                      hoveredRowIndex === rowIndex ? 'visible' : ''
                    }`}
                  >
                    {!editing ? (
                      <EditIconButton
                        title="Edit"
                        btnSize="sm"
                        onClick={() => setEditing(true)}
                        className="me-3"
                      />
                    ) : (
                      <SaveIconButton
                        title="Save"
                        btnSize="sm"
                        onClick={() => setEditing(false)}
                        className="me-3"
                      />
                    )}
                    <DeleteIconButton
                      btnSize="sm"
                      title={t('quote-page.delete-quote') || 'Delete Quote'}
                      onClick={() => console.log('delete')}
                      className="me-3"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  if (componentId === 3) {
    return (
      <div id="system-pump-component">
        <div className="button-container">
          <AddButton
            title={
              t('add-booster-system-page.add-panel') || 'Add Control Panel/VFD'
            }
            btnSize="sm"
            onClick={handleAddProductBtnClick}
          />
        </div>
        <div className="table-header">
          <span className="sub-description">
            Currently added items
            {/* {t("mfv-selection-page.description-tb3")} */}
          </span>
        </div>
        <table className="table table-hover">
          <thead>
            {tablePumps.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th></th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {header.column.columnDef.header?.toString()}
                        <ReactTableHeaderOptions header={header} />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {boosterItems.map((row, rowIndex: number) => (
              <tr
                key={rowIndex}
                // className={selectedPump === row ? "selected-row" : ""}
                // onClick={() => handlePumpSelection(row)}
                onMouseEnter={() => setHoveredRowIndex(rowIndex)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                <td className="align-vertical-center">
                  <input
                    type="checkbox"
                    // checked={selectedPump === row}
                    // onChange={() => handlePumpSelection(row)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                  />
                </td>

                {tablePumps
                  .getRowModel()
                  .rows[rowIndex].getVisibleCells()
                  .map((cell) => (
                    <td className="align-vertical-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                <td className="action-buttons-cell">
                  <div
                    className={`action-buttons ${
                      hoveredRowIndex === rowIndex ? 'visible' : ''
                    }`}
                  >
                    {!editing ? (
                      <EditIconButton
                        title="Edit"
                        btnSize="sm"
                        onClick={() => setEditing(true)}
                        className="me-3"
                      />
                    ) : (
                      <SaveIconButton
                        title="Save"
                        btnSize="sm"
                        onClick={() => setEditing(false)}
                        className="me-3"
                      />
                    )}
                    <DeleteIconButton
                      btnSize="sm"
                      title={t('quote-page.delete-quote') || 'Delete Quote'}
                      onClick={() => console.log('delete')}
                      className="me-3"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}
