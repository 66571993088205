class DateHelper {
  convertTimeStringToDate(time: string): Date {
    const day = '1900-01-01';
    return new Date(`${day}T${time}`);
  }
  toISOLocal(d: Date | undefined): string | undefined {
    if (!d) return undefined;
    const z = (n: any) => `0${n}`.slice(-2);
    const zz = (n: any) => `00${n}`.slice(-3);
    let off = d.getTimezoneOffset();
    const sign = off > 0 ? '-' : '+';
    off = Math.abs(off);

    return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T${z(
      d.getHours(),
    )}:${z(d.getMinutes())}:${z(d.getSeconds())}.${zz(d.getMilliseconds())}${
      sign
    }${z(Math.abs(off / 60))}:${z(off % 60)}`;
  }
  getDifference(date1: Date, date2: Date): string {
    const z = (n: any) => `0${n}`.slice(-2);

    const differenceInMin = (date2.getTime() - date1.getTime()) / 60000;
    const rhours = Math.floor(differenceInMin / 60);
    const minutes = differenceInMin % 60;
    return `${rhours}H${z(minutes)}`;
  }
  getSunday(d: Date): Date {
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -7 : 0); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getFirstDayDate(dt: Date): Date {
    return new Date(dt.getFullYear(), dt.getMonth(), 1);
  }
  getLastDayDate(dt: Date): Date {
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
  }

  getCurrentLastDayDate(): Date {
    const d = new Date(Date.now());
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
  }
  getCurrentFirstDayDate(): Date {
    const d = new Date(Date.now());
    return new Date(d.getFullYear(), d.getMonth(), 1);
  }
  getDatefromString(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }
}

const dateHelper = new DateHelper();
export default dateHelper;
