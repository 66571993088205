import {
  faFileArrowDown,
  faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

type ResetFilterProps = {
  resetFilterFn: () => void;
  show?: boolean;
  filtersLength?: number;
  className?: string;
};

export default function ResetFilterComponent(props: ResetFilterProps) {
  if (!props.show) return <></>;
  return (
    <button
      className={`btn btn-sm btn-outline-primary ${
        props.className && props.className
      }`}
      onClick={props.resetFilterFn}
    >
      <FontAwesomeIcon className="me-1" icon={faFilterCircleXmark} />
      {props.filtersLength && (
        <span className="badge bg-primary rounded-pill">
          {props.filtersLength}
        </span>
      )}
    </button>
  );
}

type ExportDataButtonProps = {
  data: string | object[] | (() => string | object[]);
  filename?: string;
};

export function TableExportDataButton(props: ExportDataButtonProps) {
  const { t } = useTranslation();
  return (
    <CSVLink
      className="btn btn-sm btn-outline-primary"
      data={props.data}
      filename={props.filename}
      onClick={() => {}}
    >
      <FontAwesomeIcon className="primary me-1" icon={faFileArrowDown} />
      {t('react-table.export-data')}
    </CSVLink>
  );
}
