import type { FormEvent } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';
import { Page, PageDetails } from '../../../components/Pages/page-components';
import stringHelper from '../../../services/Core/Helpers/string-helper';
import loginService from '../../../services/Login/LoginService';

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');

  const errorAction = (_: AxiosError) => {
    setValidated(false);
    toast.error(t(`forgot-password-page.error`));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      loginService
        .SendPasswordChangeRequest(email)
        .then(() => setIsSent(true))
        .catch(errorAction);
    }
    setValidated(true);
  };

  return (
    <>
      <Page id="login-page">
        <PageDetails>
          <div className="page-wrapper bg-blue bg-gra-02 font-poppins">
            <div className="wrapper">
              <div className="card card-4">
                <div className="card-body">
                  <h1 className="text-center">
                    {t('forgot-password-page.forgotten-your-password')}
                  </h1>
                  <div className="text-center sm-mb--10 mb-5">
                    {t('forgot-password-page.there-nothing-worry')}
                  </div>
                  {!isSent ? (
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>{t('user.email')}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder={'example@mail.com'}
                            aria-describedby="inputGroupPrepend"
                            pattern={stringHelper.RegExpToString(
                              stringHelper.RegExpEmailAdress,
                            )}
                            required
                            onChange={(ev) => setEmail(ev.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('user.email')}.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Button
                        className="loginFormButton mb-2 "
                        id="loginFormButton"
                        type="submit"
                      >
                        {t('forgot-password-page.send-reset-link')}
                      </Button>
                    </Form>
                  ) : (
                    <div className="text-center fw-bold">
                      {t('forgot-password-page.is-sent-message')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div />
        </PageDetails>
      </Page>
    </>
  );
};
