import BaseApi from '../Core/REST/BaseApi';

class MotorService extends BaseApi {
  async getAvailableHorsePower(userId: string): Promise<string[] | undefined> {
    const url = `${this.baseUrl}/motors/${userId}/Horsepower`;
    return this.get<string[] | undefined>(url);
  }

  async getRPMList(userId: string): Promise<Map<string, number> | undefined> {
    const url = `${this.baseUrl}/motors/${userId}/Revolutions-per-minute-list`;
    return this.get<Map<string, number> | undefined>(url);
  }
}

const motorService = new MotorService();
export default motorService;
