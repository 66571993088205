import BaseApi from '../Core/REST/BaseApi';
import type { DefaultDiscount } from './DefaultDiscount';

class DefaultDiscountService extends BaseApi {
  async getAll(
    employeeId: string,
  ): Promise<Array<DefaultDiscount> | undefined> {
    const url = `${this.baseUrl}/DefaultDiscounts/getAll/${employeeId}`;
    return this.get<Array<DefaultDiscount> | undefined>(url);
  }
  async putDefaultDiscount(
    employeeId: string,
    defaultDiscount: DefaultDiscount,
  ) {
    const url = `${this.baseUrl}/DefaultDiscounts/${employeeId}`;
    return this.put(url, defaultDiscount);
  }
}

const defaultDiscountService = new DefaultDiscountService();
export default defaultDiscountService;
