import './pump-test-sheet-page.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faFloppyDisk,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import type { PumpTestSheetInfo } from '../../../services/Pumps/Models/PumpTestSheet';
import type PumpTestSheet from '../../../services/Pumps/Models/PumpTestSheet';
import { PumpTestSheetTag } from '../../../services/Pumps/Models/PumpTestSheet';
import pumpService from '../../../services/Pumps/PumpService';
import FormFloatingComponent from '../../../components/Forms/from-floating-component';
import TextFloatingBox from '../../../components/text-floating-box';
import mathUtils from '../../../services/Core/Helpers/math-outils';
import LoadingComponent from '../../../components/Core/Loading';
import stringHelper from '../../../services/Core/Helpers/string-helper';
import { useChanges } from '../../../hooks/useChanges';
import pumpExtensions from '../../../services/Pumps/PumpExtensions';
import { getValueOfObject } from '../../../services/Core/useful-function';
import LogoComponent from '../../../components/app/LogoComponent';

type IParamTypes = {
  employeeId: string;
  externalFileId: string;
};

export default function PumpTestSheetPage() {
  const { t } = useTranslation();
  const { employeeId, externalFileId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const [pumpTestSheetModel, setPumpTestSheetModel] = useState<PumpTestSheet>();
  const [testSheet, setTestSheet] = useState({} as PumpTestSheetInfo);
  const [index, setIndex] = useState<number>(0);
  const iChanges = useChanges([index, pumpTestSheetModel], []);

  useEffect(() => {
    if (employeeId && externalFileId) {
      loadSheets(() => setLoading(false));
    }
  }, [externalFileId, employeeId]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div id="pump-test-sheet-page" className="container">
      {employeeId && externalFileId && (
        <div className="card">
          <div className="card-header">
            <div className="flex-fill">
              <button
                className="btn btn-outline-primary"
                onClick={() => onIndexChange(index - 1)}
                disabled={index === 0}
              >
                {t('common.previous')}
              </button>
            </div>
            <div className="flex-fill">
              <button className="btn btn-success" onClick={onNewSheetClick}>
                {t('pumps.common.new-sheet')}
              </button>
            </div>
            <div className="flex-fill">
              <button
                className="btn btn-outline-primary"
                onClick={() => onIndexChange(index + 1)}
                disabled={!canNext()}
              >
                {t('common.next')}
              </button>
            </div>
          </div>

          <div className="card-title title-container">
            <LogoComponent />
            <div className="text-center">
              <h2>{t('pumps.test-sheet-page.title', { i: index + 1 })}</h2>
            </div>
            <div>
              <table id="pump-test-sheet-header-info" className="table">
                <tbody>
                  <tr>
                    <th>{t('common.quote')}</th>
                    <td>:</td>
                    <td>{pumpTestSheetModel?.quoteId}</td>
                  </tr>
                  <tr>
                    <th>{t('common.date')}</th>
                    <td>:</td>
                    <td>{stringHelper.toDateString(testSheet.creationDate)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-body">
            <section id="quote-informations-section" className="info-grid">
              <TextFloatingBox
                id={'client'}
                text={pumpTestSheetModel?.client ?? ''}
                title="Client"
                size="sm"
              />
              <TextFloatingBox
                id={'job'}
                text={pumpTestSheetModel?.job ?? ''}
                title={t('pumps.common.job')}
                size="sm"
              />
              <TextFloatingBox
                id={'employee'}
                text={testSheet.employeeName ?? ''}
                title={t('common.employee')}
                size="sm"
              />
              <TextFloatingBox
                id={'approved-by'}
                text={testSheet.approvedByName ?? ''}
                title={t('pumps.common.approved-by')}
                size="sm"
              />
            </section>

            {/* Tests for the pump with the general pump information */}
            <section id="pump-test-section" className="test-info-grid">
              {/* Pump Info, Motor Info and Phase to Phase Tests */}
              <div id="motor-test-informations">
                <div id="pump-info-section">
                  <h6 className="text-center">Pump Info</h6>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th colSpan={2}>Pressure</th>
                        <th>Flow</th>
                        <th>Imp. dia.</th>
                      </tr>
                    </thead>
                    <tbody className="bordered-td">
                      <tr>
                        <td>
                          <input
                            className="form-control form-control-sm"
                            value={testSheet.pumpModel ?? ''}
                            onChange={(ev) =>
                              setValue('pumpModel', ev.target.value)
                            }
                            disabled={testSheet.isFinish}
                          />
                        </td>
                        <td>
                          <div className="wrapper">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              value={testSheet.pumpHead ?? ''}
                              onChange={(ev) =>
                                setValue('pumpHead', ev.target.value)
                              }
                              disabled={testSheet.isFinish}
                            />
                            <span className="measurement-unit">TDH ft</span>
                          </div>
                        </td>
                        <td>
                          <div className="wrapper">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              defaultValue={mathUtils.getPSIFromTDH(
                                testSheet.pumpHead,
                              )}
                              readOnly
                            />
                            <span className="measurement-unit">psi</span>
                          </div>
                        </td>
                        <td>
                          <div className="wrapper">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              value={testSheet.pumpFlow ?? ''}
                              onChange={(ev) =>
                                setValue('pumpFlow', ev.target.value)
                              }
                              disabled={testSheet.isFinish}
                            />
                            <span className="measurement-unit">usgpm</span>
                          </div>
                        </td>
                        <td>
                          <div className="wrapper">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              value={testSheet.pumpImpDiam ?? ''}
                              onChange={(ev) =>
                                setValue('pumpImpDiam', ev.target.value)
                              }
                              disabled={testSheet.isFinish}
                            />
                            <span className="measurement-unit">in</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div id="motor-info" className="mb-3">
                  <h6 className="text-center">Motor Info</h6>
                  <div className="d-flex">
                    <div className="flex-fill">
                      <FormFloatingComponent
                        id="motorManufacturer"
                        defaultValue={testSheet?.motorManufacturer ?? ''}
                        onchange={(ev: any) =>
                          setValue('motorManufacturer', ev.target.value)
                        }
                        title={t('pumps.test-sheet-page.manifacturer')}
                        readonly={testSheet.isFinish}
                      />
                    </div>
                    <div className="flex-fill">
                      <FormFloatingComponent
                        id="motorCatNo"
                        defaultValue={testSheet.motorCatNo ?? ''}
                        onchange={(ev: any) =>
                          setValue('motorCatNo', ev.target.value)
                        }
                        title={t('pumps.test-sheet-page.motor-cat-no')}
                        readonly={testSheet.isFinish}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <FormFloatingComponent
                      id="motor-hp"
                      defaultValue={testSheet.motorHP ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorHP', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.horsepower')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motor-frame"
                      defaultValue={testSheet?.motorFrame ?? ''}
                      onchange={(ev: any) =>
                        setValue('motorFrame', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.frame')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motor-fla"
                      defaultValue={testSheet?.motorFLA ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorFLA', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-fla')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motorRPM"
                      defaultValue={testSheet?.motorRPM ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorRPM', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-rpm')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motorEff"
                      defaultValue={testSheet?.motorEff ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorEff', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-eff')}
                      readonly={testSheet.isFinish}
                    />
                  </div>

                  <div className="d-flex">
                    <FormFloatingComponent
                      id="motorSF"
                      defaultValue={testSheet?.motorSF ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorSF', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-sf')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motorPF"
                      defaultValue={testSheet?.motorPF ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorPF', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-pf')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motorVolt"
                      defaultValue={testSheet?.motorVolt ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorVolt', ev.target.value)
                      }
                      title={t('common.volt')}
                      readonly={testSheet.isFinish}
                    />
                    <FormFloatingComponent
                      id="motorHz"
                      defaultValue={testSheet?.motorHz ?? ''}
                      type="number"
                      onchange={(ev: any) =>
                        setValue('motorHz', ev.target.value)
                      }
                      title={t('pumps.test-sheet-page.motor-hz')}
                      readonly={testSheet.isFinish}
                    />
                  </div>
                </div>

                <div id="pump-test-value-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th rowSpan={2}>Test</th>
                        <th rowSpan={2}>Flow</th>
                        <th colSpan={2}>Pressure</th>
                        <th colSpan={3}>
                          Amperage Draw
                          <span className="measurement-unit">(A)</span>
                        </th>
                        <th colSpan={3}>Phase to Phase</th>
                        <th rowSpan={2}>BHP</th>
                        <th rowSpan={2}>
                          EFF
                          <span className="measurement-unit">(%)</span>
                        </th>
                      </tr>
                      <tr>
                        <th>ft</th>
                        <th>psi</th>
                        <th>
                          A<sub>1</sub>
                        </th>
                        <th>
                          A<sub>2</sub>
                        </th>
                        <th>
                          A<sub>3</sub>
                        </th>
                        <th>
                          V<sup>1</sup>
                          <sub>2</sub>
                        </th>
                        <th>
                          V<sup>2</sup>
                          <sub>3</sub>
                        </th>
                        <th>
                          V<sup>3</sup>
                          <sub>1</sub>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {testSheet.pumpTestSheetValues.map(
                        (testSheetValues, i) => {
                          return (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>
                                <input
                                  id={`flow-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.flow ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.flow',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`tdh-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.head ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.head',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  defaultValue={mathUtils.getPSIFromTDH(
                                    testSheetValues.head,
                                  )}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  id={`amp1-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.amp1 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.amp1',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`amp2-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.amp2 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.amp2',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`amp3-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.amp3 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.amp3',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`volt1-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.volt1 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.volt1',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`volt2-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.volt2 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.volt2',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`volt3-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.volt3 ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.volt3',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`bhp-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.bhp ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.bhp',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                              <td>
                                <input
                                  id={`eff-value-${i}`}
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={testSheetValues.eff ?? ''}
                                  onChange={(ev) =>
                                    setSheetArrValue(
                                      'pumpTestSheetValues.eff',
                                      ev.target.value,
                                      i,
                                    )
                                  }
                                  disabled={testSheet.isFinish}
                                />
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div id="pump-test-informations">
                {/* Time tests and Tag pump test */}
                <div id="time-test-section" className="mb-2">
                  <h6 className="text-center">Time Test</h6>
                  <div className="time-test-grid">
                    <TextFloatingBox
                      id={'tested-date'}
                      text={stringHelper.toTimeString(testSheet.creationDate)}
                      title="Time"
                    />
                  </div>
                </div>

                <div id="pump-test-tags-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t('pumps.test-sheet-page.pump-tag')}</th>
                        <th>{t('pumps.test-sheet-page.pump-serial')}</th>
                        <th>{t('pumps.test-sheet-page.motor-serial')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testSheet.pumpTestSheetTag.map((tag, i: number) => {
                        // console.log("tag", tag, "index", i);
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                value={tag.pumpTag ?? ''}
                                onChange={(ev) =>
                                  setSheetArrValue(
                                    'pumpTestSheetTag.pumpTag',
                                    ev.target.value,
                                    i,
                                  )
                                }
                                disabled={testSheet.isFinish}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                value={tag.pumpSerial ?? ''}
                                onChange={(ev) =>
                                  setSheetArrValue(
                                    'pumpTestSheetTag.pumpSerial',
                                    ev.target.value,
                                    i,
                                  )
                                }
                                disabled={testSheet.isFinish}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                value={tag.motorSerial ?? ''}
                                onChange={(ev) =>
                                  setSheetArrValue(
                                    'pumpTestSheetTag.motorSerial',
                                    ev.target.value,
                                    i,
                                  )
                                }
                                disabled={testSheet.isFinish}
                              />
                            </td>
                          </tr>
                        );
                      })}
                      {!testSheet.isFinish && (
                        <tr id="new-tag-line-btn">
                          <td className="add-row" colSpan={3}>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={addTagLine}
                            >
                              {t('pumps.test-sheet-page.add-line')}
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <div className="test-hydrostatic-grid">
              <div className="input-group input-group-sm wrapper">
                <span className="input-group-text">
                  {t('pumps.test-sheet-page.hydrostatic-test')}
                </span>
                <input
                  type="text"
                  className="form-control text-center"
                  value={testSheet.hydroTestPSI ?? ''}
                  onChange={(ev) => setValue('hydroTestPSI', ev.target.value)}
                  disabled={testSheet.isFinish}
                />
                <span className="measurement-unit">psi</span>
              </div>
              <div>
                <div className="input-group input-group-sm">
                  <span className="input-group-text">
                    {t('pumps.test-sheet-page.comments')}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={testSheet.comments ?? ''}
                    onChange={(ev) => setValue('comments', ev.target.value)}
                    disabled={testSheet.isFinish}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex text-center">
            <div className="flex-fill">
              <button
                className="btn btn-outline-primary"
                onClick={() => window.print()}
              >
                <FontAwesomeIcon size="xl" className="me-2" icon={faPrint} />
                {t('common.print')}
              </button>
            </div>
            <div className="flex-fill">
              <button
                className="btn btn-outline-primary"
                onClick={onSaveClick}
                disabled={testSheet.isFinish}
              >
                <FontAwesomeIcon
                  size="xl"
                  className="me-2"
                  icon={faFloppyDisk}
                  beatFade={iChanges.hasChanges()}
                />
                {t('common.save')}
                {iChanges.hasChanges() && (
                  <span className="measurement-unit ms-1">
                    {iChanges.changes.length}
                  </span>
                )}
              </button>
            </div>
            <div className="flex-fill">
              {employeeId && testSheet.id && (
                <button
                  className="btn btn-outline-success"
                  onClick={() => onValidateClick(employeeId, testSheet.id)}
                >
                  <FontAwesomeIcon size="xl" className="me-2" icon={faCheck} />
                  Validate
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function loadSheets(success?: Function) {
    if (employeeId && externalFileId) {
      pumpService
        .getPumpTestSheet(externalFileId, employeeId)
        .then((res) => {
          if (res) {
            // console.log(res);
            setPumpTestSheetModel(res);
            setTestSheet(res.sheets[index]);
            if (success !== undefined) success();
          } else {
            console.error('Get sheets response is null');
          }
        })
        .catch((_) => {
          toast.error('Error while fetching the test sheets');
        });
    }
  }

  function onSaveClick() {
    if (employeeId && externalFileId) {
      if (!testSheet.id || testSheet.id === 0) {
        const pumpTestSheetValues = testSheet.pumpTestSheetValues.filter(
          (value) =>
            value.flow ||
            value.head ||
            value.amp1 ||
            value.amp2 ||
            value.amp3 ||
            value.volt1 ||
            value.volt2 ||
            value.volt3 ||
            value.bhp ||
            value.eff,
        );

        const data = {
          ...testSheet,
          pumpTestSheetTag: testSheet.pumpTestSheetTag.filter(
            (tag) => tag.motorSerial || tag.pumpSerial || tag.pumpTag,
          ),
          pumpTestSheetValues,
        };

        pumpService
          .createPumpTestSheet(externalFileId, employeeId, data)
          .then((res) => {
            if (res) {
              loadSheets();
              toast.success('Successfully Saved');
            } else {
              toast.error('Error while creating the new test sheet');
            }
          })
          .catch((_) => {});
      } else {
        const pumpTestSheetValues = testSheet.pumpTestSheetValues.filter(
          (v) =>
            v.id ||
            v.flow ||
            v.head ||
            v.amp1 ||
            v.amp2 ||
            v.amp3 ||
            v.volt1 ||
            v.volt2 ||
            v.volt3 ||
            v.bhp ||
            v.eff,
        );

        const data = {
          ...testSheet,
          pumpTestSheetTag: testSheet.pumpTestSheetTag.filter(
            (tag) =>
              tag.id !== undefined ||
              tag.motorSerial !== undefined ||
              tag.pumpSerial !== undefined ||
              tag.pumpTag !== undefined,
          ),
          pumpTestSheetValues,
        };
        // console.log(data);
        pumpService
          .updatePumpTestSheet(employeeId, data)
          .then((res: any) => {
            // console.log("res", res);
            if (!res?.data) {
              toast.error('An error occured while saving the data.');
            } else {
              loadSheets();
              toast.success('Successfully Saved');
            }
          })
          .catch((_) => {});
      }
    }
  }

  function onValidateClick(employeeId: string, testSheetId: number) {
    pumpService
      .updatePumpTestSheetFinished(employeeId, testSheetId)
      .then((res) => {
        if (res) {
          loadSheets();
        } else {
          toast.error('');
        }
      });
  }

  function onNewSheetClick() {
    if (pumpTestSheetModel) {
      const temp = { ...pumpTestSheetModel } as PumpTestSheet;
      const newSheet = pumpExtensions.generateNewPumpTestSheet(
        employeeId,
        externalFileId,
      );
      temp.sheets.push(newSheet);
      setPumpTestSheetModel(temp);
      setIndex(temp.sheets.length - 1);
      setTestSheet(newSheet);
    }
  }

  function onIndexChange(i: number) {
    setIndex(i);
    if (!pumpTestSheetModel) return;
    setTestSheet({ ...pumpTestSheetModel?.sheets[i] });
  }

  function canNext(): boolean {
    return (
      pumpTestSheetModel?.sheets !== undefined &&
      index < pumpTestSheetModel!.sheets.length - 1
    );
  }

  function setValue(key: string, value: any) {
    // console.log("accessor", key, "value", value);
    if (value === '') value = undefined;
    editChange(key, value);
    setTestSheet({ ...testSheet, [key]: value });
  }

  function setSheetArrValue(accessor: string, val: any, i: number): any {
    // console.log("accessor", accessor, "value", val);
    if (val === '') val = undefined;

    const keys = accessor.split('.');
    editChange(`${keys[0]}.${i}.${keys[1]}`, val);

    const temp = getValueOfObject(keys[0], testSheet);
    if (temp && Array.isArray(temp)) {
      setTestSheet({
        ...testSheet,
        [keys[0]]: temp.map((item, indexValue) => {
          if (indexValue === i) {
            return { ...item, [keys[1]]: val };
          }
          return item;
        }),
      });
    }
  }

  function addTagLine() {
    const temp = { ...testSheet };
    temp.pumpTestSheetTag.push(
      new PumpTestSheetTag({
        sheetId: testSheet.id,
      }),
    );
    setTestSheet(temp);
  }

  function editChange(accessor: string, newValue: any) {
    try {
      const oldVal = getValueOfObject(
        accessor,
        pumpTestSheetModel?.sheets[index],
      );

      if ((oldVal == null && newValue === '') || newValue === oldVal) {
        iChanges.removeAllFn(accessor);
      } else {
        iChanges.addFn(accessor);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
