export default class PumpSearchCriteria {
  pumpType: number = 0;

  flowRate: number = 0;
  flowRateMUnit: number = 0;

  head: number = 0;
  headMUnit: number = 0;

  construction: string = 'All Constructions';
  flangeType: string = '150 lbs';
  glycolPercent: number = 0;

  // Motor Configurations
  hp: number = -1;
  motorType: number = 0;
  bhp: string = 'NOL';
  rpm: Array<number> = [1750];
  voltPhHz?: string;

  isMultiSpeedSelection: boolean = false;
  isPremiumEfficiencyMotorNotNorthAmerican: boolean = false;
  isPremiumEfficiency: boolean = true;
  // Additionnal options
  isBarePump: boolean = false;
  isMultifunctionValve: boolean = false;
  isSuctionDeffuser: boolean = false;
  isVariableFrequencyDrive: boolean = false;
  isShowSystemCurve: boolean = true;
  isNol: boolean = true;
}

export enum HeadUnit {
  Feet = 0,
  Metre = 1,
  KPA = 2,
  PSI = 3,
}
