import './menu-component.scss';
import clsx from 'clsx';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faBars,
  faChartLine,
  faClock,
  faCoins,
  faCube,
  faFileInvoiceDollar,
  faFolderOpen,
  faGear,
  faPenToSquare,
  faReceipt,
  faUsers,
  faCalculator,
  faLock,
  faChevronDown,
  faHashtag,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import React, { createContext, useContext } from 'react';
import type { Client } from '../../../services/Users/Client';
import CheckAllowedComponent from '../../CheckAllowedComponent';

type Props = {
  user: Client;
  standalone?: boolean;
};

const StandaloneContext = createContext(true);

export default function MenuComponent({ user, standalone = true }: Props) {
  const env: string = process.env.REACT_APP_ENV || '';
  const employeeId = user.id.toString();
  const extra = standalone
    ? { id: 'side-menu' }
    : { className: 'offcanvas-page-links' };
  return (
    <div {...extra}>
      <StandaloneContext.Provider value={standalone}>
        {/* <SideMenuItem
        icon={faHouse}
        text={"Home"}
        linkPath={process.env.REACT_APP_HOME_PAGE_URL}
      /> */}

        {env === 'development' && (
          <>
            <SideMenuItem
              icon={faBars}
              text={'Main menu'}
              linkPath={process.env.REACT_APP_MAIN_MENU_URL!}
            />
            <SideMenuItem
              icon={faChartLine}
              text={'Dashboard'}
              linkPath={`/Project-Dashboard`}
            />
            <SideMenuItem
              icon={faPenToSquare}
              text={'Quotes'}
              linkPath={`/Quotes/${employeeId}`}
            />
            <SideMenuItem
              icon={faCube}
              text={'Engineering'}
              linkPath={`https://portal.flofab.com/Main/Production/Pages/DashboardEng.aspx?action=Login&u=flofab&a=yes`}
            />
            <SideMenuItem
              icon={faFolderOpen}
              text={'Projects'}
              linkPath={`/Projects/${employeeId}`}
            />
            <SideMenuItem
              icon={faFileInvoiceDollar}
              text={'Sales'}
              linkPath={`/Orders/${employeeId}`}
            />
            <SideMenuItem
              icon={faClock}
              text={'Production'}
              linkPath={`/Production`}
            >
              <SideMenuItem
                text={'Complete List'}
                linkPath={`/Production/Jobs`}
              />
              <SideMenuItem
                text={'Old Production'}
                linkPath={`https://portal.flofab.com/ASPToASPNET.asp?destpage=/Main/Production/Pages/Orders.aspx?c=7`}
              />
            </SideMenuItem>
            <SideMenuItem
              icon={faReceipt}
              text={'Invoicing'}
              linkPath={`/PageNotFound`}
            />
            <SideMenuItem
              icon={faCalculator}
              text={'PHE Selection'}
              linkPath={`https://soncalc.com/`}
            />
            <SideMenuItem
              icon={faCoins}
              text={'Goal'}
              linkPath={`/Production/Goal/${employeeId}`}
            />
            <SideMenuItem
              icon={faGear}
              text={'Email Config'}
              linkPath={`/EmailConfig/${employeeId}`}
            />
            <SideMenuItem
              icon={faUsers}
              text={'Manage Users'}
              linkPath={`/ManageUsers/${employeeId}`}
            />
            <CheckAllowedComponent user={user} objectName={'manage-permission'}>
              <SideMenuItem
                icon={faLock}
                text={'Manage Permissions'}
                linkPath={`/ManagePermission`}
              />
            </CheckAllowedComponent>
            <SideMenuItem
              icon={faHashtag}
              text={'Part Number'}
              linkPath={`/supplier-part-number-page`}
            />
          </>
        )}

        {env === 'release' && (
          <>
            <SideMenuItem
              icon={faBars}
              text={'Main menu'}
              linkPath={process.env.REACT_APP_MAIN_MENU_URL!}
            />
            {/* not safe to use this is just fo now */}
            <SideMenuItem
              icon={faChartLine}
              text={'Dashboard'}
              linkPath={`/Project-Dashboard`}
            />
            <SideMenuItem
              icon={faPenToSquare}
              text={'Quotes'}
              linkPath={`/Quotes/${employeeId}`}
            />
            <SideMenuItem
              icon={faCube}
              text={'Engineering'}
              linkPath={`https://portal.flofab.com/Main/Production/Pages/DashboardEng.aspx?action=Login&u=flofab&a=yes`}
            />
            <SideMenuItem
              icon={faFolderOpen}
              text={'Projects'}
              linkPath={`/Projects/${employeeId}`}
            />
            <SideMenuItem
              icon={faFileInvoiceDollar}
              text={'Sales'}
              linkPath={`/Orders/${employeeId}`}
            />
            <SideMenuItem
              icon={faClock}
              text={'Production'}
              linkPath={`/Production`}
            >
              <SideMenuItem
                text={'Complete List'}
                linkPath={`/Production/Jobs`}
              />
              <SideMenuItem
                text={'Old Production'}
                linkPath={`https://portal.flofab.com/ASPToASPNET.asp?destpage=/Main/Production/Pages/Orders.aspx?c=7`}
              />
            </SideMenuItem>
            <SideMenuItem
              icon={faReceipt}
              text={'Invoicing'}
              linkPath={`/PageNotFound`}
            />
            <SideMenuItem
              icon={faCalculator}
              text={'PHE Selection'}
              linkPath={`https://soncalc.com/`}
            />
            <SideMenuItem
              icon={faCoins}
              text={'Goal'}
              linkPath={`/Production/Goal/${employeeId}`}
            />
            <SideMenuItem
              icon={faGear}
              text={'Email Config'}
              linkPath={`/EmailConfig/${employeeId}`}
            />
            <SideMenuItem
              icon={faUsers}
              text={'Manage Users'}
              linkPath={`/ManageUsers/${employeeId}`}
            />
            <CheckAllowedComponent user={user} objectName={'manage-permission'}>
              <SideMenuItem
                icon={faLock}
                text={'Manage Permissions'}
                linkPath={`/ManagePermission`}
              />
            </CheckAllowedComponent>
            <SideMenuItem
              icon={faHashtag}
              text={'Part Number'}
              linkPath={`/supplier-part-number-page`}
            />
          </>
        )}
      </StandaloneContext.Provider>
    </div>
  );
}

type SideMenuItemProps = {
  icon?: IconDefinition;
  text: React.ReactNode;
  linkPath: string;
  children?:
    | React.ReactElement<SideMenuItemProps>
    | React.ReactElement<SideMenuItemProps>[];
};

function SideMenuItem(props: SideMenuItemProps) {
  const hasActiveChild = (
    children:
      | React.ReactElement<SideMenuItemProps>
      | React.ReactElement<SideMenuItemProps>[]
      | undefined,
  ): boolean => {
    if (!children) return false;
    if (Array.isArray(children)) {
      return children.some((child) => isActive(child.props.linkPath));
    }
    return isActive(children.props.linkPath);
  };

  const isActive = (linkPath: string | undefined): boolean => {
    const location = useLocation();
    return linkPath === location.pathname;
  };

  const shouldDisplayChildren =
    isActive(props.linkPath) || hasActiveChild(props.children);
  const isExternalLink = props.linkPath.startsWith('http');
  const isStandalone = useContext(StandaloneContext);
  const extras = isStandalone ? {} : { 'data-bs-dismiss': 'offcanvas' };
  return (
    <div className="side-menu-item" {...extras}>
      <Link
        className={clsx({
          active: isActive(props.linkPath),
        })}
        title={`${props.text}`}
        to={props.linkPath}
      >
        <div className="icon-item text-center">
          {props.icon && <FontAwesomeIcon size="lg" icon={props.icon} />}
        </div>
        <div className="text-item">{props.text}</div>
        <div className="icon-item text-center trailing-item">
          {isExternalLink && (
            <FontAwesomeIcon icon={faUpRightFromSquare} size="2xs" />
          )}
          {props.children && shouldDisplayChildren && (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
      </Link>
      {shouldDisplayChildren && props.children}
    </div>
  );
}
