import { useNavigate, useParams } from 'react-router-dom';
import './quote-page.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormSelect,
  ListGroupItem,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faCube,
  faDollar,
  faEllipsis,
  faFileArrowDown,
  faPrint,
  faRefresh,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';
import { PageSectionComponent } from '../../../components/Core/PageSectionComponent/PageSectionComponent';
import {
  QuoteKitComponent,
  QuoteSingleItemComponent,
  QuoteSummaryComponent,
  SystemBoosterComponent,
} from './quote-page-component';
import Quote, { ClientEmail, QuoteCust } from '../../../services/Quotes/Quote';
import quoteService from '../../../services/Quotes/QuoteService';
import stringHelper from '../../../services/Core/Helpers/string-helper';
import type Activity from '../../../services/Activities/Activity';
import {
  ActivityObjectType,
  ActivityType,
} from '../../../services/Activities/Activity';
import {
  ActivityHistoryComponent,
  ActivityManagerComponent,
} from '../../../components/activities/ActivitiesManagerComponent';
import { QuoteHistory } from '../../../services/Quotes/Models/QuoteHistory';
import {
  Page,
  PageDetails,
  PageHeader,
  PageInfo,
  PageTitle,
} from '../../../components/Pages/page-components';
import orderService from '../../../services/Orders/OrderService';
import type { ProgressBar } from '../../../components/Charts/ProgressBarComponent';
import { ProgressBarComponent } from '../../../components/Charts/ProgressBarComponent';
import activityService from '../../../services/Activities/ActivityService';
import activityExtensions from '../../../services/Activities/ActivityExtensions';
import Popup, { PopupGetInfo } from '../../../components/Core/Popup';
import transportService from '../../../services/Transports/TransportServices';
import type ShipVia from '../../../services/Transports/Models/ShipVia';
import { DOTS } from '../../../hooks/usePagination';
import {
  AddButton,
  CopyIconButton,
  CopyToIconButton,
  DeleteProductIconButton,
  RemoveIconButton,
  SelectAllIconButton,
} from '../../../components/general-button-components';
import type { QuoteDownloadStatusTypes } from '../../../services/Quotes/Models/QuoteDownloadStatus';
import { QuoteDownloadStatus } from '../../../services/Quotes/Models/QuoteDownloadStatus';
import { QuoteRelationship } from './quote-relationship-page';
import { CollapseComponent } from '../../../components/CollapseComponent';
import ConfirmationPopup from '../../../components/Core/Popup-confirmation-Component';
import type QuoteKit from '../../../services/Quotes/Models/QuoteKit';
import type { QuoteSingleItem } from '../../../services/SingleItem/Models/QuoteSingleItem';
import type { QuoteHeatExchanger } from '../../../services/HeatExchangers/Models/QuoteHeatExchanger';
import type { BoosterSystem } from '../../../services/Systems/BoosterSystem/BoosterSystem';
import dateHelper from '../../../services/Core/Helpers/date-helper';
import type QuoteSummaryTable from '../../../services/Quotes/Models/QuoteSummaryTable';
import FilesComponent from '../../../components/File/FilesComponent';
import numberHelper from '../../../services/Core/Helpers/number-helper';
import QuoteProductionComponent from './quote-production';
import FileUploader from '../../../components/Core/FileUploaderComponent';
import CircleButton from '../../../components/Buttons/Circle-Icon-Button';

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export default function QuotePage() {
  const [isUploaderVisible, setUploaderVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { t } = useTranslation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [tab, setTab] = useState(0);
  const [editing, setEditing] = useState(true);

  const [quote, setQuote] = useState<Quote>();
  const [quoteSummary, setQuoteSummary] = useState<QuoteSummaryTable>();

  const [, setOriginalQuote] = useState<Quote>();
  const [histories, setHistories] = useState<QuoteHistory[]>();
  const [progressHistory, setProgressHistory] = useState<ProgressBar[]>();
  const [notes, setNotes] = useState<Array<Activity>>([]);

  const [isAddClient, setIsAddClient] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>('');
  const [clientId, setClientId] = useState<number>();
  const [contact, setContact] = useState<ClientEmail>();
  const [clients, setClients] = useState<Array<QuoteCust>>();

  const [shipViaArr, setShipViaArr] = useState<Array<ShipVia>>();
  const [showTAC, setShowTAC] = useState<boolean>(false);

  const [, setShowPrintPreview] = useState<boolean>(false);

  const [showProdCategory, setShowProdCategory] = useState(false);
  const [showRelatedQuote, setShowRelatedQuote] = useState(false);

  const [showCopyTo, setShowCopyTo] = useState(false);
  const [quoteIdCopyTo, setQuoteIdCopyTo] = useState('');

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationQ, setShowConfirmationQ] = useState(false);
  const [showToolBar, setShowToolBar] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedKits, setSelectedKits] = useState<QuoteKit[]>([]);
  const [selectedItems, setSelectedItems] = useState<QuoteSingleItem[]>([]);
  const [selectedHEC, setSelectedHEC] = useState<QuoteHeatExchanger[]>([]);
  const [selectedSystems, setSelectedSystems] = useState<BoosterSystem[]>([]);

  const [selectedStatus, setSelectedStatus] = useState(quote?.status || '');
  useEffect(() => {
    setSelectedStatus(quote?.status || '');
  }, [quote?.status]);

  const [checkboxOptions, setCheckboxOptions] = useState<
    QuoteDownloadStatusTypes[]
  >([]);

  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // more button
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Close the dropdown if the click is outside
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //

  useEffect(() => {
    loadDlStatusTypes();
    if (userId) {
      if (quoteId) {
        loadQuote();
        loadQuoteSummary();
      } else setEditing(true);
    }
  }, [
    userId,
    quoteId,
    selectedHEC,
    selectedItems,
    selectedKits,
    selectedSystems,
  ]);

  useEffect(() => {
    if (quote && quoteSummary) {
      setQuote((prevQuote) => {
        if (!prevQuote) return undefined;

        return {
          ...prevQuote,
          totalNetPrice: quoteSummary.totalQuoteNetPrice,
        };
      });
    }
  }, [quoteSummary]);

  useEffect(() => {
    switch (tab) {
      case 2:
        if (!shipViaArr) loadShipViaList();
        break;
      case 3:
        loadNotes();
        break;
      case 4:
        if (!histories) loadQuoteHistory();
        break;
      case 5:
        if (!progressHistory) loadProgressHistory();
        break;
      default:
        break;
    }
  }, [tab]);

  useEffect(() => {
    const activeTab = sessionStorage.getItem('activeTabIndex');
    if (activeTab === '1') {
      setTab(1);
    } else {
      setTab(0);
    }
    sessionStorage.setItem('activeTabIndex', '0');
  }, []);

  useEffect(() => {
    if (!editing && userId && quoteId && quote) {
      // console.log("Updated Quote:", quote);
      quoteService.UpdateAllOfQuote(parseInt(userId, 10), quote).then((res) => {
        if (!res) return;
        loadQuote();
      });
    }
  }, [editing]);

  const title =
    quoteId !== undefined
      ? t('quote-page.title', { 0: quoteId })
      : t('quote-page.new-quote');

  const contacts = useMemo(() => {
    const client = clients?.find((x) => x.id === clientId);
    return client?.contacts ?? [];
  }, [clientId]);

  const active = editing ? 'active' : '';

  const handleAddProductBtnClick = () => {
    // save data before go to add products
    onEditSaveBtnClick();
    setShowProdCategory(true);
  };
  const handleControlClick = () => {
    setShowRelatedQuote(true);
  };
  const onCloseClick = () => {
    setShowProdCategory(false);
  };
  const onCopyBtnClick = () => {
    const newQuote = new Quote(
      { id: 0 },
      selectedHEC,
      selectedSystems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      [],
    );
    quoteService
      .createCopyOfQuote(userId, newQuote)
      .then((res) => {
        window.open(`/Quotes/${userId}/${res}`);
      })
      .catch((error) => {
        toast.error(t('quote-page.error-copy-quote'), error);
      });
  };

  const onCopyToYourQuoteBtnClick = () => {
    const newQuote = new Quote(
      { id: quoteIdCopyTo },
      selectedHEC,
      selectedSystems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      [],
    );
    quoteService
      .createCopyOfQuote(userId, newQuote)
      .then((res) => {
        window.open(`/Quotes/${userId}/${res}`);
      })
      .catch((error) => {
        toast.error(t('quote-page.error-copy-quote'), error);
      });
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    if (isSelectAll) {
      if (quote?.quoteKits) {
        setSelectedKits([...quote.quoteKits]);
      }
      if (quote?.quoteSingleItems) {
        setSelectedItems([...quote.quoteSingleItems]);
      }
    } else {
      setSelectedHEC([]);
      setSelectedItems([]);
      setSelectedKits([]);
      setSelectedSystems([]);
    }
  };

  const handleDeleteSelected = () => {
    // Logic to delete selected products
    const newQuote = new Quote(
      { id: quoteId },
      selectedHEC,
      selectedSystems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      [],
    );

    quoteService
      .deleteSelectedQuoteItems(userId, newQuote)
      .then((_) => {
        setSelectedHEC([]);
        setSelectedItems([]);
        setSelectedKits([]);
        setSelectedSystems([]);
        setShowConfirmation(false);
        setShowToolBar(false);
      })
      .catch((error) => {
        toast.error(t('quote-page.error-delete-items'), error);
      });
  };

  function handleKitSelect(selectedKit: QuoteKit) {
    setShowToolBar(true);
    if (selectedKits.find((kit) => kit.id === selectedKit.id)) {
      setSelectedKits(
        selectedKits.filter((kit) => !(kit.id === selectedKit.id)),
      );
      if (selectedKits.length === 1 && selectedItems.length === 0)
        setShowToolBar(false);
    } else {
      setSelectedKits([...selectedKits, selectedKit]);
      setShowToolBar(true);
    }
  }

  function handleItemSelect(selectedItem: QuoteSingleItem) {
    if (selectedItems.find((item) => item.id === selectedItem.id)) {
      setSelectedItems(
        selectedItems.filter((item) => !(item.id === selectedItem.id)),
      );
      if (selectedKits.length === 0 && selectedItems.length === 1)
        setShowToolBar(false);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
      setShowToolBar(true);
    }
  }

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    setQuote((prevQuote: Quote | undefined) => {
      if (!prevQuote) {
        return undefined;
      }

      if (isChecked) {
        if (!prevQuote.quoteDownloadStatuses) {
          prevQuote.quoteDownloadStatuses = [];
        }
        prevQuote.quoteDownloadStatuses.push(
          new QuoteDownloadStatus({
            id: 0,
            quoteId: prevQuote.id,
            dlStatusId: index,
          }),
        );
      } else if (prevQuote.quoteDownloadStatuses) {
        prevQuote.quoteDownloadStatuses =
          prevQuote.quoteDownloadStatuses.filter(
            (status) =>
              !(status.quoteId === prevQuote.id && status.dlStatusId === index),
          );
      }
      return prevQuote;
    });
  };

  function handleDeletePump(deletedKitId: number) {
    if (quote) {
      if (quote.quoteKits.find((kit) => kit.id === deletedKitId)) {
        const updatedKits = quote.quoteKits.filter(
          (kit) => kit.id !== deletedKitId,
        );
        setQuote((prevQuote) =>
          prevQuote
            ? {
                ...prevQuote,
                quoteKits: updatedKits,
              }
            : undefined,
        );
        loadQuoteSummary();
      }
    }
  }

  function handleDeleteSingleItem(deletedItemId: number) {
    if (quote) {
      if (quote.quoteSingleItems.find((item) => item.id === deletedItemId)) {
        const updatedItems = quote.quoteSingleItems.filter(
          (item) => item.id !== deletedItemId,
        );
        setQuote((prevQuote) =>
          prevQuote
            ? {
                ...prevQuote,
                quoteSingleItems: updatedItems,
              }
            : undefined,
        );
        loadQuoteSummary();
      }
    }
  }

  const handleUpload = (_file: File | null) => {
    // no need this here
  };
  const handleChoose = (file: File | null) => {
    setSelectedFile(file);
  };
  const handleDownload = () => {
    if (selectedFile) {
      drawingToPdf(true, selectedFile);
    } else {
      drawingToPdf(true, undefined);
    }
  };

  const handleCancel = () => {
    setUploaderVisible(false);
  };

  const handleShowUploader = () => {
    setUploaderVisible(true);
  };

  return (
    <Page id="quote-details">
      <div className="sticky-header">
        <PageHeader className="d-block">
          <BreadcrumbComponent
            items={[
              { text: 'Quotes', link: `/Quotes/${userId}` },
              {
                text: title,
                active: true,
              },
            ]}
          />
        </PageHeader>

        <PageTitle>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex align-items-center">
              <h1 className="h2 me-3">{title}</h1>
              <select
                id="select-dropdown"
                className="status-select"
                value={selectedStatus}
                onChange={(e) => {
                  const newStatus = e.target.value;
                  setSelectedStatus(newStatus);
                  setValue('status', newStatus);
                }}
              >
                <option
                  className="form-select"
                  key={t('quotes.status-1')}
                  value={1}
                >
                  {' '}
                  {t('quotes.status-1')}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-2')}
                  value={2}
                >
                  {' '}
                  {t('quotes.status-2')}{' '}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-0')}
                  value={0}
                >
                  {' '}
                  {t('quotes.status-0')}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-4')}
                  value={4}
                >
                  {' '}
                  {t('quotes.status-4')}{' '}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-5')}
                  value={5}
                >
                  {' '}
                  {t('quotes.status-5')}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-3')}
                  value={3}
                >
                  {' '}
                  {t('quotes.status-3')}{' '}
                </option>
                <option
                  className="form-select"
                  key={t('quotes.status-6')}
                  value={6}
                >
                  {' '}
                  {t('quotes.status-6')}
                </option>
              </select>
              <h6 className="total-price">
                Total:{' '}
                {quote ? numberHelper.toNumberFormat(quote.totalNetPrice) : 0} $
              </h6>
            </div>
            <ConfirmationPopup
              show={showConfirmationQ}
              title={t('quote-page.delete-quote') || 'Delete Quote'}
              message={t('quote-page.warn-delete-quote-message')}
              onDelete={onConfirmClick}
              onClose={() => setShowConfirmationQ(false)}
            />

            <div className="d-flex align-items-center">
              <div className="button-menu-top">
                <ul>
                  <li>
                    <CircleButton
                      color="blue"
                      icon={faRefresh}
                      title="Refresh"
                      onClick={loadQuote}
                    />
                  </li>
                  <li>
                    <CircleButton
                      color="blue"
                      icon={faSave}
                      title="Save"
                      onClick={onEditSaveBtnClick}
                    />
                  </li>
                  <li>
                    <CircleButton
                      color="blue"
                      icon={faPrint}
                      title={t('quote-page.print-preview') || 'PrintPreview'}
                      onClick={loadPrint}
                    />
                  </li>
                  <li>
                    <CircleButton
                      color="blue"
                      icon={faCube}
                      title={t('quote-page.drawing-to-pdf') || 'Drawing to Pdf'}
                      onClick={() => drawingToPdf(false, undefined)}
                    />
                  </li>
                  <li>
                    <CircleButton
                      color="blue"
                      icon={faDollar}
                      title="Recost"
                      onClick={() => alert('not implemented')}
                    />
                  </li>
                  <li style={{ position: 'relative' }}>
                    <CircleButton
                      color="gray"
                      icon={faEllipsis}
                      title="More"
                      onClick={toggleDropdown}
                    />
                    {isOpen && (
                      <div className="dropdown-content" ref={dropdownRef}>
                        <div
                          className="icon-button"
                          onClick={handleShowUploader}
                        >
                          <CircleButton
                            color="blue"
                            icon={faCube}
                            title="Custom Drawing"
                          />
                          Custom Drawing
                        </div>
                        <div
                          className="icon-button"
                          onClick={() => alert('not implemented')}
                        >
                          <CircleButton
                            color="blue"
                            icon={faFileArrowDown}
                            title="Print as Schedule"
                          />
                          Print as Schedule
                        </div>

                        <div
                          className="icon-button"
                          onClick={() => alert('not implemented')}
                        >
                          <CircleButton
                            color="orange"
                            icon={faTrash}
                            title="Delete Quote"
                          />
                          Delete Quote
                        </div>
                      </div>
                    )}
                  </li>
                </ul>

                <FileUploader
                  onUpload={handleUpload}
                  onChoose={handleChoose}
                  onCancel={handleCancel}
                  onDownload={handleDownload}
                  show={isUploaderVisible}
                  title="Upload Your Document"
                />
              </div>
            </div>
          </div>
        </PageTitle>

        <PageSectionComponent
          active={tab}
          onClick={setTab}
          items={[
            { text: t('quote-page.info'), key: 0 },
            { text: t('quote-page.products'), key: 1 },
            { text: t('quote-page.files'), key: 7 },
            { text: t('quote-page.transport'), key: 2 },
            { text: t('quote-page.notes'), key: 3 },
            { text: t('quote-page.production'), key: 6 },
            { text: t('quote-page.history'), key: 4 },
            { text: t('quote-page.quote-progress'), key: 5 },
          ]}
        />
      </div>

      <PageDetails>
        <TabContainerComponent show={tab === 0}>
          <div className="quote-info-container">
            <div className="quote-info-container-left">
              <div className="mb-4">
                <FormLabel htmlFor="client">{t('common.client')}s</FormLabel>
                <button
                  className="btn add-client-btn btn-outline-primary"
                  onClick={() => setIsAddClient(true)}
                >
                  {t('quote-page.add-client')}
                </button>
                <table className="table client-list">
                  <tbody>
                    {quote?.quotesClients.map((client, i) => {
                      const isChecked = client.id === quote.clientListId;
                      const btnTitle =
                        t('common.remove-obj', { 0: client.name }) ?? '';
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              type="radio"
                              name="quote-client"
                              checked={isChecked}
                              onChange={() => onQuoteClientClick(client.id)}
                            />
                          </td>
                          <td>{client.name}</td>
                          <td>
                            {client.email}
                            {client.tel && <div>{client.tel}</div>}
                          </td>
                          <td>
                            {!isChecked && (
                              <RemoveIconButton
                                title={btnTitle}
                                btnSize="sm"
                                iconSize="xs"
                                onClick={() => removeClient(client.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="row align-items-center">
                <div className="mb-2 col-lg-6 col-sm-12">
                  <FormLabel htmlFor="salesman">
                    {t('quote-page.salesman')}
                  </FormLabel>
                  <FormControl id="salesman" size="sm" disabled />
                </div>

                <div className="mb-2 col-lg-6 col-sm-12">
                  <FormLabel htmlFor="owned-by">
                    {t('quote-page.owned-by')}
                  </FormLabel>
                  <FormControl
                    id="owned-by"
                    defaultValue={quote?.ownedBy}
                    size="sm"
                    disabled
                  />
                </div>

                <div className="mb-2 col-lg-6 col-sm-12">
                  <FormLabel htmlFor="jobName">
                    {t('quote-page.job-name')}
                  </FormLabel>
                  <FormControl
                    id="jobName"
                    defaultValue={quote?.jobName}
                    size="sm"
                    className={active}
                    onChange={(ev) => {
                      setValue('jobName', ev.target.value);
                    }}
                    disabled={!editing}
                  />
                </div>

                <div className="mb-2 col-lg-6 col-sm-12">
                  <FormLabel htmlFor="date">{t('common.date')}</FormLabel>
                  <FormControl
                    id="date"
                    defaultValue={
                      quote?.date &&
                      stringHelper.toDateString(new Date(quote.date))
                    }
                    size="sm"
                    type="date"
                    disabled
                  />
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <FormLabel htmlFor="purchase-order">
                    {t('quote-page.po')}
                  </FormLabel>
                  <FormControl
                    id="purchase-order"
                    defaultValue={quote?.po}
                    size="sm"
                    className={active}
                    disabled={!editing}
                    onChange={(ev) => setValue('po', ev.target.value)}
                  />
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <FormLabel htmlFor="purchase-order">
                    {t('quote-page.po-date')}
                  </FormLabel>
                  <input
                    id="po-date"
                    className={`form-control form-control-sm ${active}`}
                    type="date"
                    defaultValue={
                      quote?.poEmailDate instanceof Date
                        ? quote?.poEmailDate.toISOString().split('T')[0]
                        : ''
                    }
                    onChange={(ev) => {
                      setValue('poEmailDate', ev.target.value);
                    }}
                    disabled={!editing}
                  />
                </div>

                <div className="col-12 mb-3">
                  <FormLabel htmlFor="originalQuote">
                    {t('quote-page.original-quote')}
                  </FormLabel>
                  <FormControl
                    id="original-quote"
                    defaultValue={
                      showRelatedQuote
                        ? ''
                        : quote?.originalQuote
                          ? quote?.originalQuote.parentQuoteId
                          : ''
                    }
                    size="sm"
                    className={active}
                    onClick={handleControlClick}
                    disabled={!editing}
                  />
                  {showRelatedQuote && (
                    <Popup
                      title={'Add Relation'}
                      onClose={() => setShowRelatedQuote(false)}
                      show={showRelatedQuote}
                    >
                      <QuoteRelationship />
                    </Popup>
                  )}
                </div>

                <div className="col-12 mb-3">
                  {quote?.originalQuote !== null && quote?.originalQuote ? (
                    <>
                      <div className="col-12 mb-3">
                        <div id="serial-collapse">
                          <h5
                            className="d-flex justify-content-between"
                            onClick={() => setCollapsed(!collapsed)}
                          >
                            <FormLabel>
                              {t('quote-page.quote-serial-numbers')}
                            </FormLabel>
                            <FontAwesomeIcon size="sm" icon={faChevronDown} />
                          </h5>
                          <CollapseComponent collapsed={collapsed}>
                            <div className="row">
                              {quote?.quoteSerialNumbers &&
                                quote?.quoteSerialNumbers.map(
                                  (serial, index) => (
                                    <div
                                      key={index}
                                      className="col-md-6 col-sm-12 mb-3"
                                    >
                                      <FormControl
                                        id={`serials-${index}`}
                                        defaultValue={serial.serialNumber}
                                        size="sm"
                                        disabled={!editing}
                                        onClick={handleControlClick}
                                        className={active}
                                      />
                                    </div>
                                  ),
                                )}
                            </div>
                          </CollapseComponent>
                        </div>
                      </div>
                    </>
                  ) : (
                    quote?.quoteRelations &&
                    quote?.quoteRelations.length > 0 && (
                      <>
                        <div id="related-quote-collapse">
                          <h5
                            className="d-flex justify-content-between"
                            onClick={() => setCollapsed(!collapsed)}
                          >
                            <FormLabel>
                              {t('quote-page.related-quotes')}
                            </FormLabel>
                            <FontAwesomeIcon size="sm" icon={faChevronDown} />
                          </h5>
                          <CollapseComponent collapsed={collapsed}>
                            <div className="row">
                              {quote?.quoteRelations.map((relation, index) => (
                                <div
                                  key={index}
                                  className="col-md-6 col-sm-12 mb-3"
                                >
                                  <a
                                    href="#"
                                    onClick={() => {
                                      window.open(
                                        `/Quotes/${userId}/${relation.childQuoteId}`,
                                      );
                                    }}
                                  >
                                    <FormControl
                                      id={`relatedQuotes-${index}`}
                                      defaultValue={relation.childQuoteId}
                                      size="sm"
                                    />
                                  </a>
                                </div>
                              ))}
                            </div>
                          </CollapseComponent>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>

              <div className="text-center">
                <a className="action-link" onClick={() => setShowTAC(true)}>
                  TERMS & CONDITIONS
                </a>
              </div>
            </div>
            <div className="quote-info-container-right">
              <div className="col-12 mb-3">
                <FormLabel htmlFor="dlStatus">
                  {t('quote-page.dl-status')}
                </FormLabel>
                <div className="dl-status-container">
                  {checkboxOptions?.map((option, index) => (
                    <div className="dl-status-items" key={index}>
                      <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        defaultChecked={quote?.quoteDownloadStatuses?.some(
                          (status) => status.dlStatusId === option.id,
                        )}
                        className={active}
                        onChange={(ev) =>
                          handleCheckboxChange(index + 1, ev.target.checked)
                        }
                        disabled={!editing}
                      />
                      <label className="p-1" htmlFor={`checkbox${index}`}>
                        {option.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12 mb-3">
                <FormLabel htmlFor="remark">{t('quote-page.remark')}</FormLabel>
                <FormControl
                  as="textarea"
                  id="remark"
                  defaultValue={quote?.remark}
                  className={active}
                  onChange={(ev) => setValue('remark', ev.target.value)}
                  disabled={!editing}
                  rows={4}
                />
              </div>
            </div>
          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 1}>
          <div className="quote-products-header">
            <AddButton
              title={t('quote-page.add-product')!}
              btnSize="sm"
              onClick={handleAddProductBtnClick}
            />
            {showToolBar && (
              <div className="row menu-product">
                <div className="col-auto align-self-center">
                  <SelectAllIconButton
                    title={t('common.select-all')!}
                    btnSize="sm"
                    onClick={handleSelectAll}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <CopyIconButton
                    title={t('common.copy')!}
                    btnSize="sm"
                    onClick={onCopyBtnClick}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <CopyToIconButton
                    title={t('common.copy-to-your-quote')!}
                    btnSize="sm"
                    onClick={(_) => setShowCopyTo(true)}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <DeleteProductIconButton
                    title={t('common.delete-selected-Items')!}
                    btnSize="sm"
                    onClick={(_) => setShowConfirmation(true)}
                  />
                </div>
              </div>
            )}
          </div>
          <PopupGetInfo
            title={'Copy to your quote'}
            onClose={() => {
              setShowCopyTo(false);
              setQuoteIdCopyTo('');
            }}
            onOk={onCopyToYourQuoteBtnClick}
            show={showCopyTo}
          >
            <input
              id="copyTo"
              type="number"
              placeholder="Enter a quote number"
              onChange={(e) => setQuoteIdCopyTo(e.target.value)}
            />
          </PopupGetInfo>
          <ConfirmationPopup
            show={showConfirmation}
            title={t('quote-page.delete-quote') || 'Delete Quote'}
            message={t('quote-page.warn-delete-quote-selected-items')}
            onDelete={handleDeleteSelected}
            onClose={() => setShowConfirmation(false)}
          ></ConfirmationPopup>

          <ProductSelectionComponent
            show={showProdCategory}
            onClick={onCloseClick}
            userId={userId}
            quoteId={quoteId}
          />

          <div className="quote-products-container">
            {/* {quote?.quoteHeatExchangers && */}
            {/*  quote.quoteHeatExchangers.map((item, i) => { */}
            {/*    return ( */}
            {/*      <div key={i} className="mb-2"> */}
            {/*        <HeatExchangerComponent */}
            {/*          userId={userId} */}
            {/*          heatExchanger={item} */}
            {/*          pageNumber={i + 1} */}
            {/*          editing={editing} */}
            {/*        /> */}
            {/*      </div> */}
            {/*    ); */}
            {/*  })} */}

            {quote?.boosterSystems.map((system) => {
              return (
                <div className="mb-2">
                  <SystemBoosterComponent
                    userId={userId}
                    quoteId={quoteId}
                    pageNumber={(quote?.quoteHeatExchangers.length ?? 0) + 1}
                    editing={editing}
                    system={system}
                  />
                </div>
              );
            })}

            {quote?.quoteKits.map((quoteKit, i) => {
              return (
                <div
                  key={i + quote.quoteKits.length}
                  className="mb-2"
                  // onDoubleClick={() => handleKitClick(quoteKit)}
                >
                  <QuoteKitComponent
                    userId={userId}
                    quoteId={quoteId}
                    pageNumber={(quote?.quoteKits.length ?? 0) + 1}
                    quoteKit={quoteKit}
                    editing={editing}
                    onSelect={(kit) => handleKitSelect(kit)}
                    isSelected={selectedKits.some(
                      (kit) => kit.id === quoteKit.id,
                    )}
                    onUpdate={(updatedKit) => handleUpdate('kit', updatedKit)}
                    onDelete={handleDeletePump}
                  />
                </div>
              );
            })}

            {quote?.quoteSingleItems.map((quoteSingleItem, i) => {
              return (
                <div key={i + quote.quoteSingleItems.length} className="mb-2">
                  <QuoteSingleItemComponent
                    userId={userId}
                    quoteId={quoteId}
                    pageNumber={(quote?.quoteSingleItems.length ?? 0) + 1}
                    quoteSingleItem={quoteSingleItem}
                    editing={editing}
                    onSelect={(item) => handleItemSelect(item)}
                    isSelected={selectedItems.some(
                      (item) => item.id === quoteSingleItem.id,
                    )}
                    onUpdate={(updatedQuoteSingleItem) =>
                      handleUpdate('singleItem', updatedQuoteSingleItem)
                    }
                    onDelete={handleDeleteSingleItem}
                  />
                </div>
              );
            })}
            {quoteSummary && (
              <QuoteSummaryComponent summaryData={quoteSummary} />
            )}
          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 2}>
          <div className="quote-info-container-left">
            <h4 className="h5">Ship To</h4>
            <div className="row">
              <div className="mb-2 col-12">
                <FormLabel htmlFor="ship-to-info">Info</FormLabel>
                <textarea
                  className={`form-control form-control-sm ${active}`}
                  id="ship-to-info"
                  value={quote?.shipTo}
                  onChange={(ev) => setValue('shipTo', ev.target.value)}
                  disabled={!editing}
                  rows={3}
                />
              </div>

              <div className="mb-3 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Date Requested</FormLabel>
                <FormControl
                  id="date-req"
                  size="sm"
                  type="date"
                  className={active}
                  defaultValue={
                    quote?.datereq &&
                    stringHelper.toDateString(
                      dateHelper.getDatefromString(quote.datereq),
                    )
                  }
                  onChange={(ev) => setValue('datereq', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-3 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Client PO#</FormLabel>
                <FormControl
                  id="client-po"
                  size="sm"
                  className={active}
                  value={quote?.shiptopo}
                  onChange={(ev) => setValue('shiptopo', ev.target.value)}
                  disabled={!editing}
                />
              </div>

              <hr className="mt-2 mb-3" />

              {shipViaArr && (
                <div className="mb-2 col-md-6 col-sm-12">
                  <FormLabel htmlFor="ship-via">Ship via</FormLabel>
                  {!editing ? (
                    <span className="form-control form-control-sm">
                      {quote?.shipVia}
                    </span>
                  ) : (
                    <FormSelect
                      id="ship-via"
                      size="sm"
                      defaultValue={quote?.shipVia ?? shipViaArr[0].name}
                      onChange={(ev) => setValue('shipVia', ev.target.value)}
                      disabled={!editing}
                      className={`form-select-sm ${editing ? 'active' : ''}`}
                    >
                      {shipViaArr?.map((shipVia, i) => {
                        return (
                          <option key={i} value={shipVia.name}>
                            {shipVia.name}
                          </option>
                        );
                      })}
                      <option key={'Others'} value={'Others'}>
                        Others
                      </option>
                    </FormSelect>
                  )}
                </div>
              )}
              {quote?.shipVia === DOTS && (
                <div className="mb-2 col-md-6 col-sm-12">
                  <FormLabel htmlFor="ship-via-other">Other</FormLabel>
                  <FormControl
                    id="ship-via-other"
                    size="sm"
                    className={active}
                    onChange={(ev) => setValue('shipVia', ev.target.value)}
                    disabled={!editing}
                  />
                </div>
              )}
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Account</FormLabel>
                <FormControl
                  id="ship-acc"
                  size="sm"
                  className={active}
                  value={quote?.shipAcc}
                  onChange={(ev) => setValue('shipAcc', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Broker</FormLabel>
                <FormControl
                  id="broker"
                  size="sm"
                  className={active}
                  value={quote?.broker}
                  onChange={(ev) => setValue('broker', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Broker Tel</FormLabel>
                <FormControl
                  id="broker-ph"
                  size="sm"
                  className={active}
                  value={quote?.brokerph}
                  onChange={(ev) => setValue('brokerph', ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-12">
                <FormLabel htmlFor="ship-note">Ship Note</FormLabel>
                <textarea
                  className={`form-control form-control-sm ${active}`}
                  id="ship-note"
                  value={quote?.shipnote}
                  onChange={(ev) => setValue('shipnote', ev.target.value)}
                  disabled={!editing}
                  rows={4}
                />
              </div>
            </div>
          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 3}>
          {quote && (
            <ActivityManagerComponent
              userId={userId}
              objectId={quote?.id}
              objectType={ActivityObjectType.Quotes}
              activities={notes}
              loadActivities={loadNotes}
            />
          )}
        </TabContainerComponent>

        <TabContainerComponent show={tab === 4}>
          <Page>
            {histories && histories.length > 0 ? (
              <PageDetails>
                {histories.map((history, i) => {
                  return (
                    <ActivityHistoryComponent
                      key={i}
                      username={history.username}
                      date={history.date}
                      description={history.description}
                    />
                  );
                })}
              </PageDetails>
            ) : (
              <PageTitle>
                <h5>No data found</h5>
              </PageTitle>
            )}
          </Page>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 5}>
          <Page>
            {progressHistory && progressHistory.length !== 0 ? (
              <PageInfo>
                {progressHistory.map((progressBar, i) => {
                  return (
                    <Page className="page-border mb-2" key={i}>
                      <PageTitle>
                        <h6>{progressBar.title}</h6>
                      </PageTitle>
                      <PageDetails>
                        <ProgressBarComponent progressBar={progressBar} />
                      </PageDetails>
                    </Page>
                  );
                })}
              </PageInfo>
            ) : (
              <PageTitle>
                <h5>No data found</h5>
              </PageTitle>
            )}
          </Page>
        </TabContainerComponent>
        <TabContainerComponent show={tab === 6}>
          <Page>
            <PageInfo>
              <QuoteProductionComponent
                userId={userId!}
                quoteId={quoteId!}
              ></QuoteProductionComponent>
            </PageInfo>
          </Page>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 7}>
          <FilesComponent quoteId={quoteId!} />
        </TabContainerComponent>
      </PageDetails>

      <Popup title={'Add Client'} onClose={onAddClientClose} show={isAddClient}>
        <div className="container">
          <Row>
            <div className="col-auto">
              <FormLabel htmlFor="addClientSearch">Client Name:</FormLabel>
            </div>
            <div className="col-auto">
              <FormControl
                id="addClientSearch"
                className="border"
                size="sm"
                value={clientName}
                onChange={(ev) => setClientName(ev.target.value)}
              />
            </div>
            <div className="col-auto">
              <Button size="sm" onClick={loadClientContact}>
                {t('common.search')}
              </Button>
            </div>
          </Row>
          {clients && (
            <>
              <hr />
              <Row className="m-3">
                <div className="col-lg-6 col-md-12 item-list">
                  <h5 className="h6">{t('common.client')}s :</h5>
                  <div className="list-group">
                    {clients?.map((client, i) => {
                      return (
                        <ListGroupItem
                          key={i}
                          variant="action"
                          onClick={() => setClientId(client.id)}
                          active={client.id === clientId}
                        >
                          {client.name} {client.city}
                        </ListGroupItem>
                      );
                    })}
                  </div>
                </div>
                {clientId && (
                  <div className="col-lg-6 col-md-12 item-list">
                    <h5 className="h6">Contacts :</h5>
                    <div className="list-group">
                      {contacts?.map((clientEmail, i) => {
                        return (
                          <ListGroupItem
                            key={i}
                            variant="action"
                            onClick={() => setContact(clientEmail)}
                            active={
                              clientEmail.contactId === contact?.contactId
                            }
                          >
                            {clientEmail.name}{' '}
                            {clientEmail.email !== 'none' && clientEmail.email}
                          </ListGroupItem>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Row>
              <hr />
              {contact && (
                <div className="text-center mt-1">
                  <Button size="sm" onClick={addClient}>
                    Add client
                  </Button>
                  <span className="ms-2">{contact.name}</span>
                </div>
              )}
            </>
          )}
        </div>
      </Popup>

      <Popup
        title={'TERMS & CONDITIONS'}
        onClose={() => setShowTAC(false)}
        show={showTAC}
      >
        <div className="terms-conditions-container">
          <p>{quote?.note}</p>
        </div>
      </Popup>
    </Page>
  );

  function onEditSaveBtnClick() {
    if (editing) {
      // Handle save
      if (userId && quote) {
        quoteService
          .UpdateAllOfQuote(parseInt(userId, 10), quote)
          .then((res) => {
            if (!res) return;
            loadQuote();
          });
      }
    } else {
      setEditing(true);
      setOriginalQuote({ ...quote } as Quote);
    }
  }

  //  function updateReminder() {
  //     let hasChanges =
  //       quote?.reminderEnable !== originalQuote?.reminderEnable ||
  //       quote?.reminder !== originalQuote?.reminder;

  //     if (userId && quoteId && quote && hasChanges) {
  //       // console.log(quote.reminder, quote.reminderEnable);
  //       quoteService
  //         .updateQuoteReminder(
  //           userId,
  //           quoteId,
  //           quote.reminderEnable,
  //           stringHelper.toDateString(quote.reminder)
  //         )
  //         .then((res) => {
  //           if (res) {
  //             loadQuote();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     }
  //   }

  function loadQuote() {
    if (userId && quoteId) {
      quoteService
        .getQuote(userId, quoteId)
        .then((data) => {
          if (data) {
            setQuote(data);
          }
        })
        .catch(() => {});
    }
  }
  function loadQuoteSummary() {
    if (userId && quoteId) {
      quoteService
        .getQuoteSummary(userId, quoteId)
        .then((data) => {
          if (data) {
            setQuoteSummary(data);
          }
        })
        .catch(() => {});
    }
  }

  function loadDlStatusTypes() {
    if (userId) {
      quoteService
        .getAllQuoteDlStatusTypes(userId)
        .then((data) => {
          if (data) {
            setCheckboxOptions(data);
          }
        })
        .catch(() => {});
    }
  }

  function loadNotes() {
    if (userId && quoteId) {
      const objectType = ActivityObjectType.Quotes;
      const activityType = ActivityType.Comment;
      activityService
        .getActivities(userId, quoteId, objectType, activityType)
        .then((data) => {
          if (data) setNotes(activityExtensions.buildActivities(data));
        })
        .catch(() => {
          return new Array<Activity>();
        });
    }
  }

  function loadQuoteHistory() {
    if (userId && quoteId) {
      quoteService
        .getQuoteHistory(userId, quoteId)
        .then((data) => {
          // console.log("history", data);
          if (data) {
            setHistories(data.map((history) => new QuoteHistory(history)));
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }

  function loadProgressHistory() {
    if (userId && quoteId) {
      orderService
        .getOrderHistoryProgress(userId, quoteId)
        .then((res) => {
          // console.log(res);
          if (res === undefined) {
            console.error(t('quote-page.error-progress-history-undefined'));
          } else {
            setProgressHistory(res);
          }
        })
        .catch((error) => {
          toast.error(t('quote-page.error-progress-history'), error);
        });
    }
  }

  function loadClientContact() {
    if (userId && clientName) {
      quoteService
        .getQuoteClients(userId, clientName)
        .then((data) => {
          if (data) {
            setClients(
              data.map((customer) => {
                const contacts = customer.contacts.map(
                  (c) => new ClientEmail(c),
                );
                return new QuoteCust(customer, contacts);
              }),
            );
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function loadShipViaList() {
    transportService
      .getAllShipVia()
      .then((data) => {
        if (data) {
          setShipViaArr(data);
        }
      })
      .catch((error) => console.error(error));
  }

  function onAddClientClose() {
    setIsAddClient(false);
    setClientId(undefined);
    setClientName('');
    setClients(undefined);
    setContact(undefined);
  }

  function addClient() {
    if (userId && quoteId && contact?.contactId && contact.clientId) {
      quoteService
        .addQuoteClient(userId, quoteId, contact.clientId, contact.contactId)
        .then((res) => {
          if (!res) {
            console.error(t('quote-page.error-add-client'));
          } else {
            loadQuote();
            onAddClientClose();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function onQuoteClientClick(id: number) {
    if (userId && quoteId && quote?.client !== id) {
      quoteService
        .updateQuotesClient(userId, quoteId, id)
        .then((res) => {
          if (!res) return;
          loadQuote();
        })
        .catch((error) => console.error(error));
    }
  }

  function removeClient(id: number) {
    if (userId && quoteId) {
      quoteService
        .deleteQuoteClient(userId, quoteId, id)
        .then((res) => {
          if (!res) return;
          loadQuote();
        })
        .catch((error) => console.error(error));
    }
  }

  function setValue(key: string, value: any) {
    setQuote((prevQuote) => ({ ...prevQuote, [key]: value }) as Quote);
  }

  function loadPrint() {
    setShowPrintPreview(true);
    window.open(`/Quotes/${userId}/${quoteId}/print`);
  }

  function drawingToPdf(_isShort: boolean, _file: File | undefined) {
    if (quoteId) {
      quoteService
        .drawingToPdf(quoteId)
        .then((blob) => {
          setUploaderVisible(false);
          if (blob) {
            if (blob.size > 0) {
              // Create a File object
              const pdfFile = new File(
                [blob],
                `Drawings-Quote-${quoteId}.pdf`,
                {
                  type: 'application/pdf',
                },
              );

              // Create a URL and open the PDF in a new tab
              const fileUrl = window.URL.createObjectURL(pdfFile);
              window.open(fileUrl, '_blank');

              // Download
              const link = document.createElement('a');
              link.href = fileUrl;
              link.setAttribute('download', pdfFile.name);
              document.body.appendChild(link);
              link.click();
              link.remove();
            } else {
              console.error(t('quote-page.error-blob-empty'));
            }
          } else {
            console.error(t('quote-page.error-blob-fetch-failed'));
          }
        })
        .catch((error) => console.error('Error fetching PDF:', error));
    }
  }

  function onConfirmClick() {
    if (userId && quoteId) {
      quoteService
        .deleteQuote(userId, quoteId)
        .then((_) => {
          setShowConfirmationQ(false);
          localStorage.setItem('activeTabIndex', '1');
          window.location.href = `/Quotes/${userId}`;
        })
        .catch((error) => console.error(error));
    }
  }

  function handleUpdate(type: string, updatedItem: any) {
    setQuote((prevQuote) => {
      if (prevQuote) {
        const updatedQuote = { ...prevQuote };

        switch (type) {
          case 'kit':
            updatedQuote.quoteKits = updatedQuote.quoteKits.map((kit) =>
              kit.id === updatedItem.id ? updatedItem : kit,
            );
            break;
          case 'hxchanger':
            updatedQuote.quoteHeatExchangers =
              updatedQuote.quoteHeatExchangers.map((hx) =>
                hx.id === updatedItem.id ? updatedItem : hx,
              );
            break;
          case 'singleItem':
            updatedQuote.quoteSingleItems = updatedQuote.quoteSingleItems.map(
              (item) => (item.id === updatedItem.id ? updatedItem : item),
            );
            break;
          // Add cases for other types (tank, system, etc.)
          default:
            break;
        }

        return updatedQuote;
      }
      return new Quote(
        {
          id: 0,
          jobName: '',
          client: 0,
          clientListId: 0,
          user: 0,
          date: new Date(),
          po: '',
          hasRel: false,
          isRMA: false,
          toOrder: false,
          reminderEnable: false,
          note: '',
          originalQuote: undefined,
        },
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      );
    });
  }
}

type TabContainerProp = {
  show?: boolean;
  children?: React.ReactNode;
};
function TabContainerComponent(props: TabContainerProp) {
  const { show, children } = props;
  return <>{show && children}</>;
}

interface ProductSelectionProps {
  show: boolean;
  onClick: () => void;
  userId?: string;
  quoteId?: string;
}
function ProductSelectionComponent({
  show,
  onClick,
  userId,
  quoteId,
}: ProductSelectionProps) {
  const navigate = useNavigate();
  const products = [
    {
      name: 'Pumps',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/pump.svg`,
    },
    {
      name: 'Item',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/air.svg`,
    },
    {
      name: 'Storage Buffer Tank',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/storage-buffer-tank.svg`,
    },
    {
      name: 'System',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/system.svg`,
    },
    {
      name: 'Misc Kits',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/misc.svg`,
    },
    {
      name: 'Heat Exch. PHE',
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/heat-exchanger.svg`,
    },
  ];

  return (
    <Modal show={show} onHide={onClick} id="product-selection" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add a product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="product-grid">
          {products.map((product, index) => (
            <div
              key={index}
              className="product-item"
              onClick={() => onAddCatClick(product)}
            >
              <div className="image-container">
                <img src={product.image} alt={product.name} />
              </div>
              <div className="button-container">
                <Button
                  key={product.name}
                  onClick={() => onAddCatClick(product)}
                >
                  {product.name}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function onAddCatClick(product: any) {
    if (product.name === 'Item' && userId && quoteId) {
      navigate(`/SingleItem/${userId}/${quoteId}`);
    }
    if (product.name === 'Pumps' && userId && quoteId) {
      navigate(`/Pumps/${userId}/Pump-Search/${quoteId}`);
    }
  }
}
