import BaseApi from '../Core/REST/BaseApi';
import type { Goal, GoalType } from './Goal';
import stringHelper from '../Core/Helpers/string-helper';
import goalExtension from './goal-extensions';

class GoalsService extends BaseApi {
  async getGoal(employeeId: string, goalId: string): Promise<Goal | undefined> {
    return this.get<Goal | undefined>(
      `${this.baseUrl}/Goals/${employeeId}/${goalId}`,
    ).then((data) => {
      if (data) {
        // console.log(data);
        return goalExtension.toGoal(data);
      }
      return undefined;
    });
  }

  async getGoalByType(
    employeeId: string,
    goalTypeId: number,
    goalName: string,
    date: Date,
  ): Promise<Goal | undefined> {
    const dateString = stringHelper.toDateString(date);
    return this.get<Goal | undefined>(
      `${this.baseUrl}/Goals/Type/${goalTypeId}/${employeeId}?name=${goalName}&date=${dateString}`,
    ).then((data) => {
      if (data) {
        // console.log(data);
        return goalExtension.toGoal(data);
      }
      return undefined;
    });
  }

  async getAllGoal(employeeId: string): Promise<Array<Goal> | undefined> {
    return this.get<Array<Goal> | undefined>(
      `${this.baseUrl}/Goals/${employeeId}`,
    ).then((data) => {
      if (data) {
        // console.log(data);
        return data.map((goal) => {
          return goalExtension.toGoal(goal);
        });
      }
      return undefined;
    });
  }

  async getGoalTypes(employeeId: string): Promise<Array<GoalType> | undefined> {
    return this.get<Array<GoalType> | undefined>(
      `${this.baseUrl}/Goals/Types/${employeeId}`,
    );
  }

  async postGoal(employeeId: string, data: Goal): Promise<string | undefined> {
    return this.post<Goal, string | undefined>(
      `${this.baseUrl}/Goals/${employeeId}`,
      data,
    );
  }

  async putGoal(employeeId: string, data: Goal): Promise<boolean | undefined> {
    return this.put(`${this.baseUrl}/Goals/${employeeId}`, data);
  }
}

const goalsService = new GoalsService();
export default goalsService;
