import BaseApi from '../Core/REST/BaseApi';
import type PumpCouplingAlignmentSheet from './Models/PumpCouplingAlignmentSheet';
import pumpExtensions from './PumpExtensions';
import PumpTestSheet, {
  PumpTestSheetInfo,
  PumpTestSheetTag,
  PumpTestSheetValue,
} from './Models/PumpTestSheet';
import type PumpSearchConfig from './Models/PumpSearchConfig';
import type { PumpType } from './Models/PumpType';
import type PumpSearchCriteria from './Models/PumpSearchCriteria';
import type PumpSearchPump from './Models/PumpSearchPump';
import type PumpSearchOption from './Models/PumpSerachOption';
import type { Pump } from './Models/Pump';
import type PumpCurvePageModel from './Models/PumpCurvePageModel';

class PumpService extends BaseApi {
  async getPumpTypes(userId: string): Promise<PumpType[][] | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/Pump-Types`;
    return this.get<PumpType[][] | undefined>(url);
  }

  async getPumpAvailableConstructions(
    userId: string,
    pumpTypeId: number,
  ): Promise<string[] | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/Available-Construction/${pumpTypeId}`;
    return this.get<string[] | undefined>(url);
  }

  async getPumpAvailableFlangeTypes(
    userId: string,
    pumpTypeId: number,
  ): Promise<string[] | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/Available-Flange-Types/${pumpTypeId}`;
    return this.get<string[] | undefined>(url);
  }

  async getPumpAvailableVoltPhHz(
    userId: string,
  ): Promise<string[][] | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/Available-Volt-PH-Hz`;
    return this.get<string[][]>(url);
  }

  async getConfigPumpSearch(
    userId: string,
    pumpTypeId: number,
  ): Promise<PumpSearchConfig | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/Config-Pump-search/${pumpTypeId}`;
    return this.get<PumpSearchConfig | undefined>(url);
  }

  async getPumpAlignmentSheets(
    externalFileId: string,
    employeeId: string,
  ): Promise<PumpCouplingAlignmentSheet[] | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/employee/${employeeId}`;
    return this.get<PumpCouplingAlignmentSheet[] | undefined>(url);
  }

  async createAlignmentSheet(
    externalFileId: string,
    employeeId: string,
    serialNumber: string,
  ): Promise<string | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/employee/${employeeId}/${serialNumber}`;
    return this.post(url, {});
  }

  async saveAlignmentSheet(
    employeeId: string,
    data: any,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/employee/${employeeId}/alignment-sheet/${data.id}`;
    return this.put(url, data);
  }
  async updatePumpAlignmentSheetFinished(
    employeeId: string,
    id: number,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/employee/${employeeId}/alignment-sheet/${id}`;
    return this.patch(url, {});
  }
  async getPumpTestSheet(
    externalFileId: string,
    employeeId: string,
  ): Promise<PumpTestSheet | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/${employeeId}/pump-test-sheets`;
    return this.get<PumpTestSheet>(url).then((data) => {
      // console.log(data);
      if (data) {
        let sheets: Array<PumpTestSheetInfo> = [];
        if (data.sheets.length === 0) {
          sheets = [
            pumpExtensions.generateNewPumpTestSheet(employeeId, externalFileId),
          ];
        } else {
          sheets = data.sheets.map((sheet) => {
            const { id } = sheet;
            let pumpSheetTags: Array<PumpTestSheetTag> = [];
            let pumpSheetValues: Array<PumpTestSheetValue> = [];

            if (sheet.pumpTestSheetTag.length === 0) {
              pumpSheetTags.push(new PumpTestSheetTag({ sheetId: id }));
            } else {
              pumpSheetTags = sheet.pumpTestSheetTag.map(
                (tag) => new PumpTestSheetTag(tag),
              );
            }

            if (sheet.pumpTestSheetValues.length === 0) {
              pumpSheetValues = pumpExtensions.generatePumpTestSheetValues(id);
            } else {
              pumpSheetValues = sheet.pumpTestSheetValues.map((value) => {
                return new PumpTestSheetValue(value);
              });

              if (sheet.pumpTestSheetValues.length < 5) {
                pumpSheetValues = pumpSheetValues.concat(
                  pumpExtensions.generatePumpTestSheetValues(
                    id,
                    sheet.pumpTestSheetValues.length,
                  ),
                );
              }
            }

            return new PumpTestSheetInfo(sheet, pumpSheetTags, pumpSheetValues);
          });
        }
        return new PumpTestSheet(data, sheets);
      }
      return undefined;
    });
  }

  async createPumpTestSheet(
    externalFileId: string,
    employeeId: string,
    data: any,
  ): Promise<number | undefined> {
    const url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/${employeeId}/pump-test-sheets`;
    return this.post(url, data);
  }

  async updatePumpTestSheet(
    employeeId: string,
    data: any,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/pumps/${employeeId}/pump-test-sheets/${data.id}`;
    return this.put(url, data);
  }

  async updatePumpTestSheetFinished(
    employeeId: string,
    id: number,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/pumps/${employeeId}/pump-test-sheets/${id}`;
    return this.patch(url, {});
  }
  async getPumps(
    searchCriteria: PumpSearchCriteria,
    userId: string,
  ): Promise<Array<PumpSearchPump> | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/load-pumps`;
    return this.post(url, searchCriteria);
  }
  async getPumpOptions(
    seriesId: number,
    quoteKitId: string | number,
  ): Promise<Array<PumpSearchOption> | undefined> {
    const url = `${this.baseUrl}/pumps/${seriesId}/${quoteKitId}/load-options`;
    return this.get(url);
  }
  async addPumpAndOption(
    selectedPump: PumpSearchPump,
    userId: string,
    systemId: string,
  ): Promise<number | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/add-pump-to-quote/${systemId}`;
    return this.post(url, selectedPump);
  }
  async addPumpOptions(
    selectedPumpOption: Array<PumpSearchOption>,
    userId: string,
    systemId: string,
    quoteId: string,
    quoteKitId: string,
  ): Promise<number | undefined> {
    const url = `${this.baseUrl}/pumps/${userId}/add-pump-options-to-quote/${quoteId}/${quoteKitId}/${systemId}`;
    return this.post(url, selectedPumpOption);
  }
  // gneerate a new curve by user input
  async generatePumpCurve(
    selectedPump: PumpCurvePageModel,
  ): Promise<PumpCurvePageModel | undefined> {
    const url = `${this.baseUrl}/pumps/generate-pump-curve`;
    return this.post(url, selectedPump);
  }
  // for when you open from curve button of quotekit
  async fetchQuoteKitCurve(
    quoteId: string,
    quoteKitId: string | number,
  ): Promise<PumpCurvePageModel | undefined> {
    const url = `${this.baseUrl}/pumps/${quoteId}/${quoteKitId}/Fetch-QuoteKit-Curve`;
    return this.get(url);
  }
  // for when you open from curve button of system pump
  async fetchSystemPumpCurve(
    quoteId: string,
    systemPumpId: string | number,
  ): Promise<PumpCurvePageModel | undefined> {
    const url = `${this.baseUrl}/pumps/${quoteId}/${systemPumpId}/Fetch-System-Pump-Curve`;
    return this.get(url);
  }
  async getAllPumps(): Promise<Pump[] | undefined> {
    const url = `${this.baseUrl}/pumps/get-all-pumps`;
    return this.get<Pump[] | undefined>(url);
  }
  async getPumpDrawing(
    quoteId: string,
    kitId: number,
  ): Promise<Blob | undefined> {
    const url = `${this.baseUrl}/pumps/${quoteId}/${kitId}/Pump-Drawing`;
    return this.getPdf<Blob | undefined>(url);
  }
}
const pumpService = new PumpService();
export default pumpService;
