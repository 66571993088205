import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="header">
        <h1>{t('not-found-component.not-found')}</h1>
      </div>
      <h5>{t('not-found-component.details')}</h5>
      <br />
      <Link to={process.env.REACT_APP_HOME_PAGE_URL!}>
        <button className="btn btn-outline-primary">
          {t('not-found-component.main-page')}
        </button>
      </Link>
    </>
  );
};
