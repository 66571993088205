import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './orders-page.scss';
import { useNavigate, useParams } from 'react-router-dom';
import NavTabsComponent from '../../components/nav-tabs-component';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import OpenOrdersTable from './open-orders-table';
import WebQuotesTable from './web-quotes-table';
import CustOrdersTable from './customer-orders-table';

type IParamTypes = {
  employeeId: string;
};

export default function OrdersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams<IParamTypes>();

  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    if (t) {
      document.title = t('orders-page.title');
    }
  }, [t]);
  return (
    <div className="m-2">
      <BreadcrumbComponent
        items={[{ text: t('orders-page.title'), active: true }]}
      />
      <div className="page-title wrapper">
        <h1 className="h3">{t('orders-page.title')}</h1>
        <div className="wrapper-item">
          <button
            className="btn btn-outline-primary"
            onClick={() => navigate(`/Sales/report/${employeeId}`)}
          >
            Sales report
          </button>
        </div>
      </div>

      <NavTabsComponent
        active={tab}
        navItems={[
          { key: 0, onclick: setTab, text: `Open Orders` },
          { key: 1, onclick: setTab, text: `Web Quotes` },
          { key: 2, onclick: setTab, text: `Cust Orders` },
        ]}
      />
      <div className="card" style={{ borderTop: 0 }}>
        <div className="card-body">
          {/* {tab === 0 ? <OpenOrdersTable /> : <WebQuotesTable />} */}

          {
            // eslint-disable-next-line no-nested-ternary
            tab === 0 ? (
              <OpenOrdersTable />
            ) : tab === 1 ? (
              <WebQuotesTable />
            ) : (
              <CustOrdersTable />
            )
          }
        </div>
      </div>
    </div>
  );
}
