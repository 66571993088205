import { ItemDetails } from '../../Quotes/Models/QuoteItem';
import { Item, ItemFile, Option } from './Item';

class ItemExtensions {
  public generateItem(data: any) {
    const details = new ItemDetails(data.itemDetails);
    let files: ItemFile[] = [];
    if (data.files) {
      files = data.files.map((file: any) => new ItemFile(file));
    }
    return new Item(data, details, files);
  }

  public generateOption(data: any) {
    let items: Item[] = [];
    if (data.items) {
      items = data.items.map((item: any) => this.generateItem(item));
    }

    let files: ItemFile[] = [];
    if (data.files) {
      files = data.files.map((file: any) => new ItemFile(file));
    }

    return new Option(data, items, files);
  }
}
const itemExtensions = new ItemExtensions();
export default itemExtensions;
