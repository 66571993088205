import './supplier-part-number-page.scss';
import { Col } from 'react-bootstrap';
import Select from 'react-select';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { listPartNumber } from './SupplierPartNumberListItem';
import type { SupplierPartNumberCodeValue } from './SupplierPartNumberListItem';
import { Page } from '../../components/Pages/page-components';

export default function SupplierPartNumberPage() {
  const [selectedCodeArray, setSeletedCodeArray] = useState(
    new Array<string>(),
  ); // the key is the code item and the value is the code value
  return (
    <Page id="supplier-part-number-page">
      <div className="card mt-2">
        <div className="card-header">
          <h2 className="text-center">Supplier Part Number</h2>
        </div>
        <div className="card-body">
          <form>
            {listPartNumber.map((partNumberCodeItem) => (
              <Col sm={12} className="textInputContainer">
                <label htmlFor="PictureBeforePackaging">
                  {`${partNumberCodeItem.codeItem}. ${
                    partNumberCodeItem.description
                  }`}
                </label>
                <OptionSelectInput
                  arrayCodeValue={partNumberCodeItem.codeValues}
                  id={partNumberCodeItem.codeItem}
                  selectedCodeArray={selectedCodeArray}
                  setSeletedCodeArray={setSeletedCodeArray}
                />
              </Col>
            ))}
          </form>
          <h5 className="text-end">
            Code: {getSupplierPartNumber(selectedCodeArray)}
          </h5>
        </div>
      </div>
    </Page>
  );
}
type OptionSelectInputProps = {
  arrayCodeValue: Array<SupplierPartNumberCodeValue> | undefined;
  id: number;
  selectedCodeArray: string[];
  setSeletedCodeArray: Dispatch<SetStateAction<string[]>>;
};
function OptionSelectInput({
  arrayCodeValue,
  id,
  selectedCodeArray,
  setSeletedCodeArray,
}: OptionSelectInputProps) {
  const options = arrayCodeValue?.map((codeValue) => ({
    value: codeValue.codeValue,
    label: (
      <span>
        <b>{`${codeValue.codeValue}: `}</b>
        {codeValue.desciption}
      </span>
    ),
  }));

  if (options === undefined) return <div />;
  return (
    <>
      <Select
        id="usedPumpId"
        className="react-select-container"
        options={options}
        onChange={(ev) => {
          if (ev?.value) {
            selectedCodeArray[id] = ev.value;
            setSeletedCodeArray([...selectedCodeArray]);
          }
        }}
      />
    </>
  );
}
function getSupplierPartNumber(selectedCodeArray: Array<string>) {
  let partNumberString = '';
  selectedCodeArray.forEach((code) => {
    if (code !== undefined) partNumberString += `.${code}`;
  });
  return partNumberString.slice(1);
}
