import type { Item, Option } from '../../Core/Items/Item';

export class BoosterSystemPump {
  id: number;
  bS_ID: number;
  pumpId: number;
  nol: boolean;
  model: string;
  tag: string;
  qty: number;
  netPrice: number;
  cost: number;
  glycol: number;
  flow: number;
  head: number;
  odp: number;
  pumpType: string;
  construction: number;
  sConstruction: string;
  diam: number;
  manufacturer: string;
  motorManufacturer: string;
  rpm: number;
  phase: string;
  hp: number;
  ansi125: boolean;
  weight: number;
  suct: number;
  disc: number;
  motorId: number;
  withMotor: boolean;
  it_code: number;

  flangePin: boolean;
  flangeType: string;
  simplex: boolean;

  premiumEfficiency: boolean;
  premiumEfficiencyMotorNotNorthAmerican: boolean;

  pumpTypeImage: string;
  discharge: string;
  suction: string;

  hasItems: boolean;
  hasOptions: boolean;

  items: Array<Item>;
  options: Array<Option>;

  public getProfit(): number {
    return ((this.netPrice - this.cost) / this.netPrice) * 100;
  }

  constructor(data: any, items?: Array<Item>, options?: Array<Option>) {
    this.id = data.id;
    this.bS_ID = data.bS_ID;
    this.pumpId = data.pumpId;
    this.nol = data.nol;
    this.model = data.model;
    this.tag = data.tag;
    this.qty = data.qty;
    this.netPrice = data.netPrice;
    this.cost = data.cost;
    this.glycol = data.glycol;
    this.flow = data.flow;
    this.head = data.head;
    this.odp = data.odp;
    this.pumpType = data.pumpType;
    this.construction = data.construction;
    this.sConstruction = data.sConstruction;
    this.diam = data.diam;
    this.manufacturer = data.manufacturer;
    this.motorManufacturer = data.motorManufacturer;
    this.rpm = data.rpm;
    this.phase = data.phase;
    this.hp = data.hp;
    this.ansi125 = data.ansi125;
    this.flangePin = data.flangePin;
    this.flangeType = data.flangeType;
    this.simplex = data.simplex;
    this.weight = data.weight;
    this.suct = data.suct;
    this.disc = data.disc;
    this.withMotor = data.withMotor;
    this.premiumEfficiency = data.premiumEfficiency ?? false;
    this.premiumEfficiencyMotorNotNorthAmerican =
      data.premiumEfficiencyMotorNotNorthAmerican ?? false;

    this.motorId = data.motorId;
    this.it_code = data.it_code;

    this.pumpTypeImage = data.pumpTypeImage;
    this.suction = data.suction;
    this.discharge = data.discharge;

    this.hasItems = data.hasItems;
    this.hasOptions = data.hasOptions;
    this.items = items ?? [];
    this.options = options ?? [];
  }
}
