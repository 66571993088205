import './statistic-cards.scss';

type Props = {
  text: string;
  description?: string | JSX.Element | JSX.Element[];
};

export default function StatisticCardInfo(props: Props) {
  return (
    <div className="card stat-card-info">
      <div className="card-title">
        <h6>{props.text}</h6>
      </div>
      {props.description && (
        <div className="card-text text-center">{props.description}</div>
      )}
    </div>
  );
}

type StatCardListProps = {
  title: string;
  description?: string;
  listItems: Array<ListGroupItem | undefined>;
  children?: string | JSX.Element | JSX.Element[];
};

export class ListGroupItem {
  title: string;
  description: string | number;
  constructor(title: string, description: string | number) {
    this.title = title;
    this.description = description;
  }
}

export function StatisticCardList(props: StatCardListProps) {
  return (
    <div className="card stat-card-list">
      <div className="card-title">
        <h6>{props.title}</h6>
      </div>
      {props.listItems && (
        <ul className="list-group list-group-flush">
          {props.listItems.map((item, i) => {
            if (!item) return <></>;
            return (
              <li key={i} className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>{item.title}</span>
                  <span>{item.description}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {props.children && (
        <div className="card-body text-center">{props.children}</div>
      )}
    </div>
  );
}
