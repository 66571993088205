import BaseApi from '../Core/REST/BaseApi';
import type { HydrostaticTestReport } from './Models/HydrostaticTestReport';
import dateHelper from '../Core/Helpers/date-helper';

class HydrostaticTestReportService extends BaseApi {
  async GetHydrostaticTestReport(
    externalFileId: string,
  ): Promise<HydrostaticTestReport | undefined> {
    const url = `${this.baseUrl}/HydrostaticTestReport/${externalFileId}`;
    const hydrostaticTestReport: HydrostaticTestReport | undefined =
      await this.get(url);
    if (hydrostaticTestReport) {
      if (hydrostaticTestReport.creationDate)
        hydrostaticTestReport.creationDate = new Date(
          hydrostaticTestReport.creationDate,
        );
      if (hydrostaticTestReport.testDate)
        hydrostaticTestReport.testDate = new Date(
          hydrostaticTestReport.testDate,
        );

      if (hydrostaticTestReport.startTime)
        hydrostaticTestReport.startTime = new Date(
          hydrostaticTestReport.startTime,
        );
      if (hydrostaticTestReport.endTime)
        hydrostaticTestReport.endTime = new Date(hydrostaticTestReport.endTime);
    }
    return hydrostaticTestReport;
  }
  async SaveHydrostaticTestReport(
    hydrostaticTestReport: HydrostaticTestReport,
    employeeId: number,
  ) {
    const url = `${this.baseUrl}/HydrostaticTestReport/Save-Hydrostatic-Test-Report/${employeeId}`;
    return this.put(url, {
      ...hydrostaticTestReport,
      startTime: dateHelper.toISOLocal(hydrostaticTestReport.startTime),
      endTime: dateHelper.toISOLocal(hydrostaticTestReport.endTime),
    });
  }
  async ValidateHydrostaticTestReport(
    hydrostaticTestReport: HydrostaticTestReport,
    employeeId: number,
  ) {
    const url = `${this.baseUrl}/HydrostaticTestReport/Validate-Hydrostatic-Test-Report/${employeeId}`;
    return this.put(url, {
      ...hydrostaticTestReport,
      startTime: dateHelper.toISOLocal(hydrostaticTestReport.startTime),
      endTime: dateHelper.toISOLocal(hydrostaticTestReport.endTime),
    });
  }
}

const hydrostaticTestReportService = new HydrostaticTestReportService();
export default hydrostaticTestReportService;
