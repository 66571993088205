import './stock-prod-page.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import salesService from '../../../services/Sales/SalesService';
import stringHelper from '../../../services/Core/Helpers/string-helper';
import type { QuarterReport } from '../../../services/Sales/Models/QuarterReport';
import numberHelper from '../../../services/Core/Helpers/number-helper';
import BudgetToolPacPurchaseComponent from './stock-prod-components';

type IParamTypes = {
  employeeId: string;
};
export default function StockProdPage() {
  const { employeeId } = useParams<IParamTypes>();

  const [quarterReport, setQuarterReport] = useState<QuarterReport>();
  const [budgetToolPacDate] = useState<Date>(new Date(Date.now()));

  useEffect(loadBudgetToolPac, [employeeId]);

  const { acctBudgetPercentages, totalBudgets } = useMemo(() => {
    const arr: number[] = [];
    let total = 0;
    quarterReport?.series.forEach((serie) => {
      let totalBudget = 0;
      serie.reportSerieItems.forEach((item) => {
        totalBudget += item.budget;
        total += item.budget;
      });
      arr.push(totalBudget);
    });
    return { acctBudgetPercentages: arr, totalBudgets: total };
  }, [quarterReport]);

  return (
    <div id="budget-tool-pac-page" className="m-3">
      <div className="row">
        <div className="col-12">
          <h1 className="h3">Stock Prod</h1>
        </div>
        <div className="col-lg-6 col-sm-12 mb-2">
          <BudgetToolPacPurchaseComponent
            defaultYear={new Date(Date.now()).getFullYear()}
            employeeId={employeeId}
          />
        </div>
        <div className="col-lg-6 col-sm-12 mb-2">
          <BudgetToolPacPurchaseComponent
            defaultYear={new Date(Date.now()).getFullYear()}
            employeeId={employeeId}
            type="past-years"
          />
        </div>
        <div className="col-12">
          <table className="table">
            <thead>
              <tr className="border border-dark">
                <th rowSpan={2}></th>
                <th className="border border-dark" rowSpan={2}>
                  %
                </th>
                <th className="border-start border-dark" colSpan={2}>
                  Q1
                </th>
                <th className="border-start border-dark" colSpan={2}>
                  Q2
                </th>
                <th className="border-start border-dark" colSpan={2}>
                  Q3
                </th>
                <th className="border-start border-dark" colSpan={2}>
                  Q4
                </th>
              </tr>
              <tr className="border-start border-dark">
                <th className="">Budget</th>
                <th className="border-start">Actual</th>
                <th className="border-start border-dark">Budget</th>
                <th className="border-start">Actual</th>
                <th className="border-start border-dark">Budget</th>
                <th className="border-start">Actual</th>
                <th className="border-start border-dark">Budget</th>
                <th className="border-start">Actual</th>
              </tr>
            </thead>
            <tbody>
              {quarterReport?.series.map((serie, i) => {
                return (
                  <tr key={i}>
                    <th>{serie.name}</th>
                    <td>
                      {numberHelper.toNumberFormat(
                        (acctBudgetPercentages[i] / totalBudgets) * 100,
                      )}
                      <span className="ms-1">%</span>
                    </td>
                    {serie.reportSerieItems.map((serieItem) => {
                      const budget =
                        serieItem.budget > 0
                          ? `${numberHelper.toNumberFormat(serieItem.budget)} $`
                          : '-';
                      const total =
                        serieItem.total > 0
                          ? `${numberHelper.toNumberFormat(serieItem.total)} $`
                          : '';
                      return (
                        <>
                          <td className="budget">{budget}</td>
                          <td className="border-start">{total}</td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
              <tr style={{ backgroundColor: 'lightblue' }}>
                <th>Totaux</th>
                <td>
                  100<span className="ms-1">%</span>
                </td>
                {quarterReport?.getTotalsPerQuarter().map((arr) => {
                  const budget = arr[0];
                  const total = arr[1];
                  const color = budget < total ? 'red' : 'green';
                  return (
                    <>
                      <td>{numberHelper.toNumberFormat(budget)}</td>
                      <td style={{ color }}>
                        {total > 0 ? numberHelper.toNumberFormat(total) : '-'}
                      </td>
                    </>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  function loadBudgetToolPac() {
    if (employeeId) {
      const date = stringHelper.toDateString(budgetToolPacDate);
      salesService.getPurchaseBudgetPacTool(employeeId, date).then((data) => {
        if (data) {
          setQuarterReport(data);
        }
      });
    }
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  function calculateBudgetPercentage(totalBudget: number): string {
    let totalBudgets = 0;
    quarterReport?.series.forEach((serie) => {
      serie.reportSerieItems.forEach((item) => {
        totalBudgets += item.budget;
      });
    });

    return `${(totalBudget / totalBudgets) * 100} %`;
  }
}
