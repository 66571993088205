import './production-list-page.scss';
import type { HTMLAttributes } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ColumnFiltersState,
  SortingState,
  ColumnFilter,
  FilterFn,
  Row,
  RowData,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStickyNote,
  faBolt,
  faCalendarAlt,
  faPauseCircle,
  faHourglassHalf,
  faTrashAlt,
  faRocket,
  faExclamationTriangle,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import type { TFunction } from 'i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import Pagination from '../../components/react-table/Pagination';
import type ProductionJob from '../../services/Production/Models/ProductionJob';
import {
  ProductionJobStatus,
  ProductionJobStage,
  ProductionJobPickedStatus,
  ProductionJobType,
} from '../../services/Production/Models/ProductionJob';
import productionService from '../../services/Production/ProductionService';
import { SelectComponent } from '../../components/Forms/select-component';
import { checkAllowed } from '../../components/CheckAllowedComponent';
import type { Client } from '../../services/Users/Client';
import {
  getTableHeaderSortProps,
  ReactTableHeaderOptions,
} from '../../components/react-table/react-table-component';
import { SearchBarComponent } from '../../components/Forms/SearchBarComponent';
import stringHelper from '../../services/Core/Helpers/string-helper';
import NavTabsComponent from '../../components/nav-tabs-component';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import LoadingComponent from '../../components/Core/Loading';
import numberHelper from '../../services/Core/Helpers/number-helper';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    getRowProps: (row: Row<TData>) => HTMLAttributes<HTMLTableRowElement>;
  }
}

const StatusIcons = {
  [ProductionJobStatus.Normal]: faThumbsUp,
  [ProductionJobStatus.Rush]: faBolt,
  [ProductionJobStatus.TwoWeeks]: faCalendarAlt,
  [ProductionJobStatus.Hold]: faPauseCircle,
  [ProductionJobStatus.Pending]: faHourglassHalf,
  [ProductionJobStatus.Discard]: faTrashAlt,
  [ProductionJobStatus.ExpeditedRush]: faRocket,
  [ProductionJobStatus.Overwrite]: faExclamationTriangle,
};

const columnHelper = createColumnHelper<ProductionJob>();

const headerFn = (t: TFunction) => (data: any) =>
  t(`production-page.table.${data.column.id}`);
const currencyCellFn = (data: any) => (
  <div className="text-end">
    {numberHelper.toCurrencyFormat(data.getValue(), 'CAD')}
  </div>
);

const statusFilterFn: FilterFn<any> = (
  row,
  columnId,
  filterValue: ProductionJobStatus,
) => {
  const cellValue = row.getValue(columnId) as ProductionJobStatus;
  return cellValue === filterValue;
};

const columnsFn = (
  t: TFunction<'translation', undefined>,
  showExtraColumns: boolean = false,
) => [
  columnHelper.accessor('status', {
    header: headerFn(t),
    cell: (data) => {
      const status = data.getValue();
      const title = stringHelper.camelCaseToTitle(ProductionJobStatus[status]);
      const icon = StatusIcons[status];

      return icon ? <FontAwesomeIcon icon={icon} title={title} /> : title;
    },
    filterFn: statusFilterFn,
  }),
  columnHelper.accessor('job', {
    header: headerFn(t),
    cell: (data) => data.getValue(),
  }),
  columnHelper.accessor('quote', {
    header: headerFn(t),
    cell: (data) => data.renderValue(),
  }),
  columnHelper.accessor('date', {
    header: headerFn(t),
    cell: (data) => {
      const order = data.row.original;
      const now = new Date();
      let className = 'text-body';
      if (order.status !== ProductionJobStatus.Pending && order.shippingDate) {
        const shippingDate = new Date(order.shippingDate);
        if (shippingDate < now) {
          className = 'text-danger fw-bold';
        } else if (
          order.stages.some(
            (stage) => stage > ProductionJobStage.ShippingPreparation,
          ) &&
          shippingDate > now
        ) {
          className = 'text-success fw-bold';
        }
      }
      return (
        <div className={className}>
          {order.orderDate && (
            <div style={{ textAlign: 'right' }}>
              Ordered:{' '}
              <span style={{ fontFamily: 'monospace' }}>
                {stringHelper.toDateString(new Date(order.orderDate))}
              </span>
            </div>
          )}
          {order.shippingDate && (
            <div style={{ textAlign: 'right' }}>
              Shipping:{' '}
              <span style={{ fontFamily: 'monospace' }}>
                {stringHelper.toDateString(new Date(order.shippingDate))}
              </span>
            </div>
          )}
          {order.requestedDate && (
            <div style={{ textAlign: 'right' }}>
              Requested:{' '}
              <span style={{ fontFamily: 'monospace' }}>
                {stringHelper.toDateString(new Date(order.requestedDate))}
              </span>
            </div>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor('items', {
    header: headerFn(t),
    cell: (data) => data.getValue(),
  }),
  columnHelper.accessor('quantity', {
    header: headerFn(t),
    cell: (data) => data.renderValue(),
  }),
  columnHelper.accessor('picked', {
    header: headerFn(t),
    cell: (data) =>
      stringHelper.camelCaseToTitle(ProductionJobPickedStatus[data.getValue()]),
  }),
  columnHelper.accessor('stages', {
    header: headerFn(t),
    cell: (data) =>
      data
        .getValue()
        .map((stage) =>
          stringHelper.camelCaseToTitle(ProductionJobStage[stage]),
        )
        .join(' & '),
    filterFn: 'arrIncludes',
  }),
  columnHelper.accessor('employees', {
    header: headerFn(t),
    cell: (data) => data.getValue().join(', '),
  }),
  ...(showExtraColumns
    ? [
        columnHelper.accessor('workTime', {
          header: headerFn(t),
          cell: (data) => data.getValue(),
        }),
        columnHelper.accessor('purchaseOrder', {
          header: headerFn(t),
          cell: (data) => data.getValue(),
        }),
      ]
    : []),
  columnHelper.accessor('client', {
    header: headerFn(t),
    cell: (data) => data.getValue(),
  }),
  ...(showExtraColumns
    ? [
        columnHelper.accessor('amount', {
          header: headerFn(t),
          cell: currencyCellFn,
        }),
      ]
    : []),
  columnHelper.accessor('progress', {
    header: headerFn(t),
    cell: (data) => {
      const progressValue = data.getValue() * 100;
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100%' }}
        >
          <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
              value={progressValue}
              text={`${progressValue}%`}
            />
          </div>
        </div>
      );
    },
  }),
  ...(showExtraColumns
    ? [
        columnHelper.accessor('prodValue', {
          header: headerFn(t),
          cell: currencyCellFn,
        }),
        columnHelper.accessor('invoices', {
          header: headerFn(t),
          cell: (data) => (
            <span style={{ whiteSpace: 'pre-line' }}>
              {data.getValue()!.join('\n')}
            </span>
          ),
        }),
      ]
    : []),
  columnHelper.accessor('note', {
    header: headerFn(t),
    cell: (data) => {
      const note = data.getValue();
      if (note) {
        return <FontAwesomeIcon icon={faStickyNote} title={note} />;
      }
      return <></>;
    },
    enableSorting: false,
  }),
];

type Props = {
  user: Client;
};

export default function ProductionJobs({ user }: Props) {
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);

  const [productionJobs, setProductionJobs] = useState<Array<ProductionJob>>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);

  // Whether to show the WorkTime, PO, Amount, ProdValue and Invoices columns, which should only be visible
  // to users with the get-production-job-extras permission.
  const [showExtraColumns, setShowExtraColumns] = useState(false);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columns, setColumns] = useState(columnsFn(t));

  const breadcrumbItems = [
    {
      text: t('production-page.title'),
      link: `/Production`,
    },
    { text: t('production-page.list'), active: true },
  ];

  useEffect(() => {
    document.title = t('production-page.title');
    setTabFilter(ProductionJobStage.Picking);
    loadProductionJobs();
  }, [t]);

  useEffect(() => {
    checkAllowed(user, 'get-production-job-extras').then((res) => {
      setShowExtraColumns(res);
    });
  }, [user]);

  useEffect(() => {
    setColumns(columnsFn(t, showExtraColumns));
  }, [showExtraColumns, t]);

  const table = useReactTable({
    data: productionJobs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      getRowProps: (
        row: Row<ProductionJob>,
      ): React.HTMLAttributes<HTMLTableRowElement> => ({
        style: {
          background: getJobTypeColor(row.original.jobType),
        },
        onClick: () => handleRowClick(row.original),
      }),
    },
    state: {
      columnFilters,
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const getFilterValueFn = (key: string) => {
    const result = columnFilters.find(({ id }) => id === key);
    return result?.value;
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div id="production-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <h2>{t('production-page.title')}</h2>
      <div className="m-3"></div>
      <div className="row">
        <div className="col-lg-12">
          <NavTabsComponent
            active={tab}
            navItems={Object.values(ProductionJobStage)
              .filter((v): v is ProductionJobStage => typeof v === 'number')
              .map((stage: ProductionJobStage) => {
                const items = productionJobs.filter((item) =>
                  item.stages.includes(stage),
                );
                const html = (
                  <>
                    {t(
                      `common.stages.${ProductionJobStage[stage].toLowerCase()}`,
                    )}{' '}
                    <span className="badge bg-secondary">{items.length}</span>
                  </>
                );
                return {
                  key: stage,
                  onclick: () => setTabFilter(stage),
                  html,
                };
              })}
          />
        </div>
      </div>

      <div className="card" style={{ borderTop: 0 }}>
        <div className="card-body">
          <div id="filters">
            <div className="row mb-2 align-items-end">
              <div className="col-lg-2 col-sm-12">
                <fieldset className="border rounded-3 p-1">
                  <legend className="float-none w-auto px-3">Status:</legend>
                  <SelectComponent
                    className="form-select-sm"
                    defaultValue={getFilterValueFn('status')}
                    onChange={(value) => {
                      value = Number(value);
                      editFilterFn(
                        'status',
                        Number.isNaN(value) ? undefined : value,
                      );
                    }}
                    options={[
                      { text: 'All', value: undefined },
                      ...Object.values(ProductionJobStatus)
                        .filter((v) => !Number.isNaN(Number(v)))
                        .map((status: any) => ({
                          text: stringHelper.camelCaseToTitle(
                            ProductionJobStatus[status],
                          ),
                          value: status,
                        })),
                    ]}
                  />
                </fieldset>
              </div>
              <div className="col" />
              <div className="col-lg-3 col-sm-12">
                <SearchBarComponent
                  value={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
          <div className="table-parent">
            <table className="table table-hover">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <span {...getTableHeaderSortProps(header.column)}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} {...table.options.meta?.getRowProps(row)}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination reactTable={table} siblingCount={2} />
      </div>
    </div>
  );

  function loadProductionJobs() {
    productionService
      .getJobs()
      .then((res) => {
        if (!res) {
          console.error('Get production jobs response is null');
        } else {
          setProductionJobs(res);
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error("'Load Production Jobs' : Error while fetching data");
      });
  }

  function setTabFilter(value: ProductionJobStage) {
    setTab(value);
    setColumnFilters([{ id: 'stages', value }]);
  }

  function editFilterFn(columnId: string, value?: any) {
    const newFilters: Array<ColumnFilter> = columnFilters.filter(
      ({ id }) => id !== columnId,
    );

    if (value != null) {
      newFilters.push({ id: columnId, value });
    }

    setColumnFilters(newFilters);
  }

  function handleRowClick(order: ProductionJob) {
    if (order.oldPortalUrl) {
      window.open(order.oldPortalUrl, '_blank');
    } else {
      console.error('No URL found for the order');
    }
  }
}

function getJobTypeColor(jobType: ProductionJobType): string {
  switch (jobType) {
    case ProductionJobType.Package:
      return 'rgba(0,176,240,0.35)';
    case ProductionJobType.Pump:
      return 'rgba(0,176,80,0.35)';
    case ProductionJobType.Welding:
      return 'rgba(255,255,0,0.35)';
    case ProductionJobType.BuySell:
      return 'rgba(208,208,208,0.35)';
    case ProductionJobType.Service:
      return 'rgba(255,153,255,0.35)';
    case ProductionJobType.Electric:
      return 'rgba(255,192,0,0.35)';
    default:
      return 'white';
  }
}
