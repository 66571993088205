import React from 'react';
import './buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CircleButtonProps {
  color?: string;
  icon: IconProp;
  text?: string;
  title?: string;
  onClick?: () => void;
}

const CircleButton: React.FC<CircleButtonProps> = ({
  color = '#00A9FF',
  icon,
  text,
  title,
  onClick,
}) => {
  const buttonColor = (() => {
    if (!color) return '';
    switch (color) {
      case 'blue':
        return '#00A9FF';
      case 'gray':
        return '#C1C7CD';
      case 'orange':
        return '#FF9B50';
      default:
        return '#00A9FF';
    }
  })();

  return (
    <button
      className={`circle-button ${!text ? 'icon-only' : ''}`}
      style={{ backgroundColor: buttonColor }}
      title={title}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
      {text && <span className="button-text">{text}</span>}
    </button>
  );
};

export default CircleButton;
