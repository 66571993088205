import './manage-Permission.scss';
import { Col, FormControl } from 'react-bootstrap';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { Permission } from '../../services/Permissions/Permission';
import { PermissionValue } from '../../services/Permissions/Permission';
import Popup from '../../components/Core/Popup';
import permissionService from '../../services/Permissions/PermissionService';
import departmentService from '../../services/Departments/departmentService';
import rolesService from '../../services/Roles/RolesService';
import type { Department } from '../../services/Departments/Department';
import type { Role } from '../../services/Roles/Roles';
import type { Client } from '../../services/Users/Client';
import { ColumnVisibilityComponent } from '../../components/react-table/column-visibility-component';
import { DOTS } from '../../services/Core/Helpers/string-helper';
import {
  Page,
  PageDetails,
  PageHeader,
} from '../../components/Pages/page-components';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import useColumnVisibility from '../../hooks/React table/useColumnVisibility';
import CheckAllowedComponent from '../../components/CheckAllowedComponent';

type Props = {
  user: Client;
};
export default function ManagePermissionPage({ user }: Props) {
  const [permissions, setPermissions] = useState<Array<Permission> | undefined>(
    [],
  );
  const [departmentArray, setDepartmentArray] = useState<Array<Department>>();
  const [roleArray, setRoleArray] = useState<Array<Role>>();
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [changeDescriptionPermission, setChangeDescriptionPermission] =
    useState<Permission>();
  const pageTitle = t('manage-permission-page.manage-permissions');
  useEffect(() => {
    departmentService
      .getAll()
      .then((data) => setDepartmentArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-department-error'));
      });
    rolesService
      .getAll()
      .then((data) => setRoleArray(data))
      .catch(() => {
        toast.error(t('user-form-page.load-role-error'));
      });
    permissionService.GetAll().then((res) => {
      setPermissions(res);
    });
  }, []);

  const data = useMemo(
    () =>
      permissions?.map((perm) => {
        let obj: any = {
          id: perm.id,
          permissionName: perm.permissionName,
          description: perm.description,
        };
        departmentArray?.forEach(
          // eslint-disable-next-line no-return-assign
          (dep) =>
            (obj = {
              ...obj,
              [dep.id]: perm.permissionValues.find(
                (x) => x.departmentId === dep.id,
              )?.roleId,
            }),
        );
        return obj;
      }),
    [permissions, departmentArray, roleArray],
  );
  const columns: any = useMemo(() => {
    let columns: any = departmentArray
      ? departmentArray.map((dep) => ({
          header: dep.name,
          accessorKey: `${dep.id}`,
          cell: (props: any) => (
            <FormControl
              as="select"
              defaultValue={props.getValue()}
              size="sm"
              onChange={(ev) =>
                handlePermissionSubmit(
                  props.row.original.id,
                  dep.id,
                  ev.target.value,
                )
              }
            >
              <option key={DOTS} value={''}>
                {DOTS}
              </option>
              {roleArray?.map((val) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </FormControl>
          ),
        }))
      : [{ header: 'notLoaded' }];
    columns = [
      {
        header: 'permission',
        accessorKey: 'permissionName',
        enableHiding: false,
        cell: (props: any) => (
          <div
            onClick={() => setChangeDescriptionPermission(props.row.original)}
            title={props.row.original.description}
          >
            {props.getValue()}
          </div>
        ),
      },
      ...columns,
    ];

    return columns;
  }, [data, departmentArray]);
  // console.log(columns);
  const table = useReactTable({
    columns,
    data: data || [],
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { status: false, 'mrt-row-expand': true },
    },
    state: {
      globalFilter,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });
  const iColumnVisibility = useColumnVisibility(table, columnVisibility);
  const breadcrumbItems = [{ text: pageTitle, active: true }];
  return (
    <CheckAllowedComponent
      user={user}
      objectName="manage-permission"
      redirect={true}
    >
      <ChangePermissionDescriptionPopup
        permission={changeDescriptionPermission}
        closeAction={() => setChangeDescriptionPermission(undefined)}
      />
      <Page id="permission-page">
        <PageHeader id="users-page-header" className="d-block">
          <BreadcrumbComponent items={breadcrumbItems} />
          <div className="d-flex">
            <h1 className="h3 mb-2">{pageTitle}</h1>
          </div>
        </PageHeader>

        <PageDetails className="pt-2">
          <div className="row mb-3">
            <Col sm={2}>
              <div className="input-group">
                <input
                  type="search"
                  className="form-control rounded"
                  placeholder={t('common.search') || ''}
                  aria-label={t('common.search') || ''}
                  onChange={(ev) => {
                    setGlobalFilter(ev.target.value);
                    table.resetPageIndex(true);
                  }}
                />
              </div>
            </Col>
            <Col sm={1} className="d-flex flex-wrap align-content-center">
              <ColumnVisibilityComponent
                columns={iColumnVisibility}
                className="mx-1"
              />
            </Col>
          </div>

          <section id="permission-Table">
            <table className="table table-hover">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const headerName =
                        header.column.columnDef.header?.toString();
                      return (
                        <th key={header.id} className="columnName">
                          <span title={headerName}>{headerName}</span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </PageDetails>
      </Page>
    </CheckAllowedComponent>
  );
  function handlePermissionSubmit(
    permissionKeyId: number,
    departmentId: number,
    roleId: string,
  ) {
    if (parseInt(roleId, 10))
      // value of DOTS
      permissionService
        .Post(
          new PermissionValue(
            permissionKeyId,
            departmentId,
            parseInt(roleId, 10),
          ),
        )
        .catch(() => t('manage-permission-page.post-error'));
    else {
      permissionService
        .Delete(permissionKeyId, departmentId)
        .catch(() => t('manage-permission-page.delete-error'));
    }
  }
}
type ChangePermissionDescriptionProps = {
  permission: Permission | undefined;
  closeAction: () => void;
};
export function ChangePermissionDescriptionPopup({
  permission,
  closeAction,
}: ChangePermissionDescriptionProps) {
  // console.log(permission);
  const { t } = useTranslation();
  const [description, setDescription] = useState(permission?.description ?? '');
  useEffect(() => setDescription(permission?.description ?? ''), [permission]);
  return (
    <Popup
      onClose={closeAction}
      onOk={() => {
        if (permission !== undefined) {
          permission.description = description;
          permissionService
            .UpdateDescription(permission)
            .then(() => closeAction())
            .catch(() => {
              toast.error(
                t('manage-permission-page.update-permission-description-error'),
              );
            });
        }
      }}
      title="Change permission description"
      show={permission !== undefined}
    >
      <label className="form-label">
        <h4>{permission?.permissionName}</h4>
      </label>
      <div className="row">
        <label className="form-label" htmlFor={'description'}>
          {t(`manage-permission-page.description`)}
        </label>
        <textarea
          id="description"
          defaultValue={permission?.description}
          onChange={(ev) => setDescription(ev.target.value)}
        />
      </div>
    </Popup>
  );
}
