class MathUtils {
  getPSIFromTDH(n: number | undefined) {
    if (n) return Math.round((n / 2.31) * 100) / 100;
    return undefined;
  }

  feetToMeter(val: number) {
    return val / 3.281;
  }

  inchToMillimeter(val: number) {
    return val * 25.4;
  }
  poundsToKilo(val: number) {
    const unit = 0.45359237;
    const kilo = val * unit;
    return kilo;
  }
  gpmtToCubisMetre(val: number) {
    const unit = 0.2271;
    const cubismetre = val * unit;
    return cubismetre;
  }
  gpmtToLitreSec(val: number) {
    const unit = 3.785;
    const litreSec = (val * unit) / 60.0;
    return litreSec;
  }
  hpToKilowatts(val: number) {
    const unit = 0.746;
    return val * unit;
  }
}

const mathUtils = new MathUtils();
export default mathUtils;
