import BaseApi from '../Core/REST/BaseApi';
import type { Role } from './Roles';

class RolesService extends BaseApi {
  async getAll(): Promise<Array<Role> | undefined> {
    const url = `${this.baseUrl}/Roles/getAll`;
    return this.get<Array<Role> | undefined>(url);
  }
}

const rolesService = new RolesService();
export default rolesService;
