import { useEffect, useState } from 'react';
import { PumpType } from '../../services/Pumps/Models/PumpType';
import pumpService from '../../services/Pumps/PumpService';

export default function usePumpTypes(userId: string | undefined) {
  const [pumpTypes, setPumpTypes] = useState<Array<PumpType[]>>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(loadPumpTypes, [userId]);

  function loadPumpTypes() {
    if (userId) {
      pumpService
        .getPumpTypes(userId)
        .then((data) => {
          if (data) {
            const tempPumpTypes: Array<PumpType[]> = data.map((pumpTypeArr) => {
              return pumpTypeArr.map((pumpType) => new PumpType(pumpType));
            });

            setPumpTypes(tempPumpTypes);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  }

  return { pumpTypes, pumpTypesLoading: loading };
}
