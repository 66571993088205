import type { Item, Option } from '../Core/Items/Item';
import itemExtensions from '../Core/Items/ItemExtensions';
import BaseApi from '../Core/REST/BaseApi';
import type { QuoteHeatExchangerOption } from '../HeatExchangers/Models/QuoteHeatExchanger';
import { QuoteHeatExchanger } from '../HeatExchangers/Models/QuoteHeatExchanger';
import type QuoteTableModel from '../Orders/Models/QuoteTableModel';
import { BoosterSystem } from '../Systems/BoosterSystem/BoosterSystem';
import type { BoosterSystemPump } from '../Systems/BoosterSystem/BoosterSystemPump';
import type { QuoteHistory } from './Models/QuoteHistory';
import type { QuoteCust } from './Quote';
import Quote, { QuotesClient } from './Quote';
import QuoteKit, { QuoteKitCost } from './Models/QuoteKit';
import type { PumpRushRow } from './Models/QuoteRushDelayPumpInfo';
import { QuotesRelationship } from './Models/QuotesRelationship';
import { QuotesSerialNumber } from './Models/QuotesSerialNumber';
import type { RushProgramPumpDelays } from './Models/RushProgramPumpDelays';
import { QuoteSingleItem } from '../SingleItem/Models/QuoteSingleItem';
import type { QuoteDownloadStatusTypes } from './Models/QuoteDownloadStatus';
import { QuoteDownloadStatus } from './Models/QuoteDownloadStatus';
import type { PrintModel } from '../Print/Models/PrintModel';
import type QuoteSumamryTable from './Models/QuoteSummaryTable';
import type { QuoteProduction } from './Models/QuoteProduction';
import type { QuoteExternalFile } from './Models/QuoteExternalFile';

class QuoteService extends BaseApi {
  async getAllQuotes(
    userId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number,
    withNetPrice: boolean = false,
  ): Promise<Array<QuoteTableModel> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}`;
    const params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);
    if (withNetPrice) params.push(`withTotal=${withNetPrice}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += `?${param}`;
      } else {
        url += `&${param}`;
      }
    });

    return this.get<Array<QuoteTableModel>>(url);
  }

  async getQuote(userId: string, quoteId: string): Promise<Quote | undefined> {
    const url = `${this.baseUrl}/Quotes/quote/${quoteId}`;
    return this.get<Quote | undefined>(url).then((data) => {
      if (data) {
        // console.log(data)
        const heatExchangers = data.quoteHeatExchangers.map((heatExchanger) => {
          return new QuoteHeatExchanger(heatExchanger);
        });

        const systems = data.boosterSystems.map(
          (val) => new BoosterSystem(val),
        );
        const quoteKits = data.quoteKits.map((val) => new QuoteKit(val));
        let quoteClients: Array<QuotesClient> = [];
        if (data.quotesClients) {
          quoteClients = data.quotesClients.map(
            (item) => new QuotesClient(item),
          );
        }

        const quoteSingleItems = data.quoteSingleItems.map((val) => {
          if (val.itemDetails) {
            return new QuoteSingleItem(val, val.itemDetails);
          }
          return val;
        });

        let quoteRelations: Array<QuotesRelationship> = [];
        if (data.quoteRelations) {
          quoteRelations = data.quoteRelations.map(
            (quoteRel) => new QuotesRelationship(quoteRel),
          );
        }

        let quoteSerials: Array<QuotesSerialNumber> = [];
        if (data.quoteSerialNumbers) {
          quoteSerials = data.quoteSerialNumbers.map(
            (serial) => new QuotesSerialNumber(serial),
          );
        }

        const quoteDownloadStatuses = data.quoteDownloadStatuses.map(
          (dlStatus) => new QuoteDownloadStatus(dlStatus),
        );

        return new Quote(
          data,
          heatExchangers,
          systems,
          quoteKits,
          quoteSingleItems,
          quoteClients,
          quoteRelations,
          quoteSerials,
          quoteDownloadStatuses,
        );
      }
      return undefined;
    });
  }

  async getQuoteHistory(
    userId: string,
    quoteId: string,
  ): Promise<QuoteHistory[] | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/History`;
    return this.get<QuoteHistory[] | undefined>(url);
  }

  async getQuoteClients(userId: string, clientName: string) {
    return this.get<Array<QuoteCust> | undefined>(
      `${this.baseUrl}/Quotes/${userId}/Clients?clientName=${clientName}`,
    );
  }

  async addQuoteClient(
    userId: string,
    quoteId: string,
    clientId: number,
    contactId: number,
  ): Promise<boolean | undefined> {
    return this.post(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?clientId=${clientId}&contactId=${contactId}`,
      {},
    );
  }

  async updateQuotesClient(
    userId: string,
    quoteId: string,
    quotesClientId: number,
  ) {
    return this.patch(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?id=${quotesClientId}`,
      {},
    );
  }

  async deleteQuoteClient(
    userId: string,
    quoteId: string,
    quotesClientId: number,
  ) {
    return this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?id=${quotesClientId}`,
    );
  }

  async updateQuote(userId: string, quoteId: string, data: any) {
    return this.put<any>(`${this.baseUrl}/Quotes/${userId}/${quoteId}}`, data);
  }

  async updateQuoteReminder(
    userId: string,
    quoteId: string,
    reminderEnabled: boolean,
    reminder?: string,
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}}/Reminder?reminderEnable=${reminderEnabled}`;
    if (reminder != null) url += `&date=${reminder}`;
    return this.patch(url, {});
  }

  // **** ...Quote Items... **** //
  // ** .. Heat Exchangers .. **//
  async getHEModel(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number,
  ): Promise<undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Model`;
    return this.get(url);
  }

  async getAllHEItems(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number,
  ): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Items`;
    return this.get<Array<Item>>(url);
  }

  async getAllHEIOptions(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number,
  ): Promise<Array<QuoteHeatExchangerOption> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Options`;
    return this.get<Array<QuoteHeatExchangerOption>>(url);
  }

  // ** .. QuoteKit .. **//
  async getQuoteKit(
    userId: string,
    quoteId: string | number,
    quoteKitId: string,
  ): Promise<QuoteKit | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}`;
    return this.get<QuoteKit>(url).then((data) => {
      if (data) {
        return new QuoteKit(data);
      }
      return undefined;
    });
  }

  async getAllQuoteKitCost(
    userId: string,
    quoteId: string | number,
    quoteKitId: number,
  ): Promise<QuoteKitCost | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Cost`;
    return this.get<QuoteKitCost>(url).then((data) => {
      if (data) {
        return new QuoteKitCost(data);
      }
      return undefined;
    });
  }

  async getQuoteKitAndOptionsNetPrice(
    userId: string,
    quoteId: string | number,
    quoteKitId: number,
  ): Promise<number | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Total-Price`;
    return this.get<number | undefined>(url);
  }

  async getAllQKitQuoteItems(
    userId: string,
    quoteId: string | number,
    quoteKitId: number,
  ): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Quote-Items`;
    return this.get<Array<Item>>(url).then((data) => {
      if (data) {
        return data.map((item: Item) => itemExtensions.generateItem(item));
      }
      return undefined;
    });
  }

  async getQuoteKitOptions(
    userId: string,
    quoteId: string | number,
    quoteKitId: number,
  ): Promise<Array<Option> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Options`;
    return this.get<Array<Option>>(url).then((data) => {
      if (data) {
        return data.map((option) => {
          return itemExtensions.generateOption(option);
        });
      }
      return undefined;
    });
  }

  // ** .. Systems .. **//
  async getSystemItems(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
  ): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Items`;
    return this.get<Array<Item>>(url).then((data) => {
      if (data) {
        return data.map((item: Item) => itemExtensions.generateItem(item));
      }
      return undefined;
    });
  }

  async getSystemPumps(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
  ): Promise<Array<BoosterSystemPump> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps`;
    return this.get<Array<BoosterSystemPump>>(url);
  }

  async getSystemPumpItems(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
    bs_PumpId: number,
  ): Promise<Array<Item> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps/${bs_PumpId}/Items`;
    return this.get<Array<Item>>(url);
  }

  async getSystemPumpOptions(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
    bs_PumpId: number,
  ): Promise<Array<Option> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps/${bs_PumpId}/Options`;
    return this.get<Array<Option>>(url);
  }
  /** ***** * */

  async getAllQuoteRushPrograms(
    userId: string,
    quoteId: string,
  ): Promise<Array<PumpRushRow> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Rush-Manager`;
    return this.get<Array<PumpRushRow> | undefined>(url);
  }
  async getAllRushProgramList(
    userId: string,
  ): Promise<Array<RushProgramPumpDelays> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/Rush-Program-List`;
    return this.get<Array<RushProgramPumpDelays> | undefined>(url);
  }
  async insertQuoteRushPrograms(userId: string, pumps: Array<PumpRushRow>) {
    const url = `${this.baseUrl}/Quotes/${userId}/Quote-Rush-Manager`;
    try {
      return await this.post(url, pumps);
    } catch (error) {
      console.error('Error during insertion:', error);
      throw error;
    }
  }

  // ** .. QuoteRelations .. **//
  async getQuoteRelations(
    userId: string,
    quoteId: string,
  ): Promise<Array<QuotesRelationship> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Relation`;
    return this.get<Array<QuotesRelationship> | undefined>(url);
  }
  async getOriginalQuote(
    userId: string,
    quoteId: string,
  ): Promise<QuotesRelationship | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original`;
    return this.get<QuotesRelationship | undefined>(url);
  }
  async addQuoteOriginal(
    userId: string,
    quoteId: string,
    parentQuoteId: number,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original`;
    return this.post(url, parentQuoteId);
  }
  async deleteQuoteOriginal(
    userId: string,
    quoteId: string,
    relationId: number,
  ) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original?id=${relationId}`,
    );
  }

  // ** .. QuotesSerialNumber .. **//
  async getQuoteSerialNumbers(
    userId: string,
    quoteId: string,
  ): Promise<Array<QuotesSerialNumber> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Serial-Number`;
    return this.get<Array<QuotesSerialNumber> | undefined>(url);
  }
  async addQuoteSerialNumber(
    userId: string,
    serialNumber: QuotesSerialNumber,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/Quote-Serial-Number`;
    return this.post(url, serialNumber);
  }
  async deleteQuoteSerialNumber(
    userId: string,
    quoteId: string,
    serialId: number,
  ) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Serial-Number?id=${serialId}`,
    );
  }

  // async updateQuoteInfo(userId: number, quote: Quote): Promise<boolean | undefined>{
  //   let url = `${this.baseUrl}/Quotes/${userId}/Update-Quote-Info`;
  //   return this.patch(url, quote);
  // }
  async UpdateAllOfQuote(
    userId: number,
    quote: Quote,
  ): Promise<boolean | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/Update-Quote`;
    return this.patch(url, quote);
  }
  async getAllQuoteDlStatusTypes(
    userId: string,
  ): Promise<Array<QuoteDownloadStatusTypes> | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/Download-Status`;
    return this.get<Array<QuoteDownloadStatusTypes> | undefined>(url);
  }
  async createNewQuote(userId: string | undefined) {
    const url = `${this.baseUrl}/Quotes/${userId}/Create-New-Quote`;
    return this.post(url, userId);
  }
  async createCopyOfQuote(userId: string | undefined, quote: Quote) {
    const url = `${this.baseUrl}/Quotes/${userId}/Create-Copy`;
    return this.post(url, quote);
  }

  async getTermsAndConditions(
    userId: string,
    id: number,
  ): Promise<string | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${id}/Terms`;
    return this.get<string | undefined>(url);
  }

  async getPrintPreviewData(
    userId: string,
    quoteId: string,
  ): Promise<PrintModel | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Print-Preview`;
    return this.get<PrintModel | undefined>(url);
  }
  async deleteQuote(userId: string, quoteId: string) {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}`;
    return this.delete(url);
  }
  async deleteSelectedQuoteItems(userId: string | undefined, quote: Quote) {
    const url = `${this.baseUrl}/Quotes/${userId}/delete`;
    return this.deleteAll(url, quote);
  }
  async getAllQuotesInProduction(): Promise<
    Array<QuoteTableModel> | undefined
  > {
    const url = `${this.baseUrl}/Quotes/get-all-quotes-in-production`;
    return this.get<Array<QuoteTableModel>>(url);
  }
  async deleteQuoteKit(userId: string, quoteId: string, kitId: number) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-kit?kitId=${kitId}`,
    );
  }
  async deleteQuoteKitOption(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpOptionId: number,
  ) {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Option?id=${quotePumpOptionId}`;
    return this.delete(url);
  }
  async deleteQuoteKitMfv(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpMfvId: number,
  ) {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Mfv?id=${quotePumpMfvId}`;
    return this.delete(url);
  }
  async deleteQuoteKitSd(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpSdId: number,
  ) {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Sd?id=${quotePumpSdId}`;
    return this.delete(url);
  }
  async deleteQuoteKitVfd(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpVfdId: number,
  ) {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Vfd?id=${quotePumpVfdId}`;
    return this.delete(url);
  }
  async getQuoteSummary(
    userId: string,
    quoteId: string,
  ): Promise<QuoteSumamryTable | undefined> {
    const url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Summary`;
    return this.get<QuoteSumamryTable | undefined>(url);
  }
  // async drawingToPdf(
  //   quoteId: string,
  //   isShort: boolean,
  // ): Promise<Blob | undefined> {
  //   let url = `${this.baseUrl}/Quotes/${quoteId}/${isShort}/Drawing-To-Pdf`;
  //   return await this.getPdf<Blob | undefined>(url);
  // }
  async drawingToPdf(quoteId: string): Promise<Blob | undefined> {
    const url = `${this.baseUrl}/Quotes/${quoteId}/Drawing-To-Pdf`;
    return this.getPdf<Blob | undefined>(url);
  }

  async quoteProduction(
    userId: string,
    quoteId: string,
    jobType: string,
  ): Promise<Array<QuoteProduction> | undefined> {
    const url = `${this.baseUrl}/QuoteExternalFile/${userId}/Production/${quoteId}/${jobType}`;
    return this.get<Array<QuoteProduction> | undefined>(url);
  }

  async getFiles(quoteId: string): Promise<QuoteExternalFile[] | undefined> {
    const url = `${this.baseUrl}/Quotes/${quoteId}/files`;
    return this.get<QuoteExternalFile[] | undefined>(url);
  }
}
const quoteService = new QuoteService();
export default quoteService;
