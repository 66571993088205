/* eslint-disable no-nested-ternary */
import './pump-curve-page.scss';
import { useEffect, useState } from 'react';
import { FormControl, FormLabel, FormSelect } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, PageDetails } from '../../../components/Pages/page-components';
import type PumpSearchPump from '../../../services/Pumps/Models/PumpSearchPump';
import pumpService from '../../../services/Pumps/PumpService';
import LoadingComponent from '../../../components/Core/Loading';
import BreadcrumbComponent from '../../../components/Core/BreadcrumbComponent';
import {
  FilterContainerComponent,
  FilterFieldset,
} from '../../../components/Forms/Search filters/FilterContainerComponent';
import {
  CollapseComponent,
  CollapsedIconComponent,
  useCollapse,
} from '../../../components/CollapseComponent';
import type PumpCurvePageModel from '../../../services/Pumps/Models/PumpCurvePageModel';

type IParamTypes = {
  userId: string;
};
interface BreadcrumbItem {
  text: string;
  link?: string;
  active?: boolean;
}

export default function PumpCurvePage() {
  const { t } = useTranslation();
  const filterCollapse = useCollapse(true);

  const location = useLocation();
  const [quoteId, setQuotId] = useState<string | null>(null);
  // const [systemId, setSystemId] = useState<string | null>(null);
  const [searchPump, setSearchPump] = useState<PumpSearchPump>();
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
  const { userId } = useParams<IParamTypes>();
  const [pumpModel, setPumpModel] = useState<PumpCurvePageModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const quoteKitIdFromUrl = queryParams.get('quoteKitId');
    setQuotId(queryParams.get('quoteId'));
    const systemPumpIdFromUrl = queryParams.get('systemPumpId');
    const searchPumpFromState = location.state?.searchPump;
    // Set the relevant state based on the available parameters
    if (quoteKitIdFromUrl) {
      setValue('quoteKitId', quoteKitIdFromUrl);
      setValue('quoteId', quoteId);
    } else if (systemPumpIdFromUrl) {
      setValue('systemPumpId', systemPumpIdFromUrl);
      setValue('quoteId', quoteId);
    } else if (searchPumpFromState) {
      setSearchPump(searchPumpFromState);
    }
  }, [location]);

  function setValue(key: string, value: any) {
    setPumpModel(
      (prevpump) => ({ ...prevpump, [key]: value }) as PumpCurvePageModel,
    );
  }

  const fetchData = () => {
    let items: BreadcrumbItem[] = [...breadcrumbItems];
    if (pumpModel?.quoteKitId) {
      items = [
        { text: 'Quotes', link: `/Quotes/${userId}` },
        { text: `Quote ${quoteId}`, link: `/Quotes/${userId}/${quoteId}` },
        { text: 'Pump Curve', active: true },
      ];
      fetchQuoteKitData();
    } else if (pumpModel?.systemPumpId) {
      items = [
        { text: 'Quotes', link: `/Quotes/${userId}` },
        { text: `Quote ${quoteId}`, link: `/Quotes/${userId}/${quoteId}` },
        { text: 'Pump Curve', active: true },
      ];
      fetchSystemPumpData();
    } else if (searchPump) {
      items = [
        { text: 'Quotes', link: `/Quotes/${userId}` },
        { text: `Quote ${quoteId}`, link: `/Quotes/${userId}/${quoteId}` },
        {
          text: `Pump Search`,
          link: `/Pumps/${userId}/Pump-Search/${quoteId}`,
        },
        { text: 'Pump Curve', active: true },
      ];
      fetchPumpSearchPumpData(searchPump);
    }
    setBreadcrumbItems(items);
  };
  useEffect(() => {
    fetchData();
  }, [pumpModel, searchPump]);

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    if (value === '0') {
      setValue('forceDiam', -1);
    } else if (value === '1') {
      setValue('head', -1);
    } else if (value === '2') {
      setValue('flowRate', -1);
    }
  };

  return (
    <div id="pump-curve-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <section className="quote-filter-section">
        <FilterContainerComponent>
          <FilterFieldset className="px-3 py-0">
            <legend>
              <a
                className="action-btn me-2 p-0"
                onClick={filterCollapse.click}
                title={'Search'}
              >
                <CollapsedIconComponent
                  size="xs"
                  collapsed={filterCollapse.collapsed}
                />
                <span className="ms-2">{t('common.search')}</span>
              </a>
            </legend>
            <CollapseComponent collapsed={filterCollapse.collapsed}>
              <PageDetails>
                <Page className="mb-2">
                  <PageDetails>
                    <FilterContainerComponent className="row">
                      <div className="form">
                        <div className="row">
                          <div className="form-group flex-container">
                            <div className="form-container">
                              <label htmlFor="glycol" className="form-label">
                                {t('pump-curve-page.glycol')}
                              </label>
                              <div className="inline-container">
                                <FormControl
                                  id="glycol1"
                                  value={
                                    pumpModel?.glycolPercent
                                      ? pumpModel.glycolPercent
                                      : 0
                                  }
                                  size="sm"
                                  className="form-control active"
                                  onChange={(ev) =>
                                    setValue('glycolPercent', ev.target.value)
                                  }
                                />
                                <span className="form-label"> %at </span>
                                <FormControl
                                  id="glycol2"
                                  defaultValue={140}
                                  size="sm"
                                  className="form-control active"
                                  disabled
                                />
                                <span className="label"> F </span>
                              </div>
                            </div>
                            <div className="form-container">
                              <FormLabel htmlFor="rpm" className="form-label">
                                {t('pump-curve-page.rpm')}
                              </FormLabel>
                              <FormSelect
                                id="rpm"
                                defaultValue={
                                  pumpModel?.rpm ? pumpModel?.rpm : 1150
                                }
                                size="sm"
                                className="form-control active"
                                onChange={(ev) =>
                                  setValue('rpm', ev.target.value)
                                }
                              >
                                <option value={1150}>1150, 60Hz</option>
                                <option value={1750}>1750, 60Hz</option>
                                <option value={3450}>3450, 60Hz</option>
                                <option value={970}>970, 50Hz</option>
                                <option value={1450}>1450, 50Hz</option>
                                <option value={2900}>2900, 50Hz</option>
                                <option value={0}>Other</option>
                              </FormSelect>
                            </div>
                          </div>
                          <div className="form-group flex-container">
                            <div className="form-container">
                              <FormLabel
                                htmlFor="systemCurve"
                                className="form-label"
                              >
                                {t('pump-curve-page.system-curve')}
                              </FormLabel>
                              <FormSelect
                                id="systemCurve"
                                defaultValue={1}
                                size="sm"
                                className="form-control active"
                                onChange={(ev) =>
                                  setValue('systemCurve', ev.target.value)
                                }
                              >
                                <option value={0}>
                                  Do not show system curve
                                </option>
                                <option value={1}>
                                  Show automatically generated system curve
                                </option>
                                <option value={2}>
                                  Let me enter system curve conditions
                                </option>
                              </FormSelect>
                            </div>
                            <div className="form-container">
                              <FormLabel
                                htmlFor="parallelPumps"
                                className="form-label"
                              >
                                {t('pump-curve-page.parallel-pumps')}
                              </FormLabel>
                              <FormSelect
                                id="parallelPumps"
                                defaultValue={1}
                                size="sm"
                                className="form-control active"
                                onChange={(ev) =>
                                  setValue('parallel', ev.target.value)
                                }
                              >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                              </FormSelect>
                            </div>
                          </div>
                          <div className="form-group flex-container">
                            <div className="form-container">
                              <FormLabel htmlFor="unit" className="form-label">
                                {t('pump-curve-page.unit')}
                              </FormLabel>
                              <FormSelect
                                id="unit"
                                defaultValue={0}
                                size="sm"
                                className="form-control active"
                                onChange={handleSelectChange}
                              >
                                <option value={0}>Diameter</option>
                                <option value={1}>Ft</option>
                                <option value={2}>GPM</option>
                              </FormSelect>
                            </div>
                          </div>
                        </div>

                        <div className="button-containers">
                          <button
                            className="btn btn-primary"
                            onClick={() => handleRegenarteCurve()}
                          >
                            {t('pump-curve-page.regenerate')}
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleAdvanceSearch()}
                          >
                            {t('pump-curve-page.advance-search')}
                          </button>
                        </div>
                      </div>
                    </FilterContainerComponent>
                  </PageDetails>
                </Page>
              </PageDetails>
            </CollapseComponent>
          </FilterFieldset>
        </FilterContainerComponent>
      </section>

      <div className="page-load">
        <div className="pump-info">
          <div className="labels-row">
            <label htmlFor="model" className="label-row">
              {t('pump-curve-page.model')}:
            </label>
            <label htmlFor="type" className="label-row">
              {t('pump-curve-page.type')}:
            </label>
            <label htmlFor="motor" className="label-row">
              {t('pump-curve-page.motor')}:
            </label>
          </div>
          <div className="values-row">
            <label className="label-value">{pumpModel?.modelName}</label>
            <label className="label-value">{pumpModel?.pumpType}</label>
            <label className="label-value">
              {`${pumpModel ? (pumpModel.nol ? 'NOL' : 'BHP') : ''} - 
  ${pumpModel ? (pumpModel.motorType === 1 ? 'ODP' : 'TEFC') : ''} - 
  ${pumpModel ? pumpModel.rpm : ''} RPM`}
            </label>
          </div>

          <div className="labels-row">
            <label htmlFor="gpm" className="label-row">
              {t('pump-curve-page.gpm')}:
            </label>
            <label htmlFor="head" className="label-row">
              {t('pump-curve-page.head')}:
            </label>
            <label htmlFor="diam" className="label-row">
              {t('pump-curve-page.diam')}:
            </label>
            <label htmlFor="bhp" className="label-row">
              {t('pump-curve-page.bhp')}:
            </label>
            <label htmlFor="nol" className="label-row">
              {t('pump-curve-page.nol')}:
            </label>
            <label htmlFor="npshr" className="label-row">
              {t('pump-curve-page.npshr')}:
            </label>
            <label htmlFor="eff" className="label-row">
              {t('pump-curve-page.eff')}:
            </label>
          </div>
          <div className="values-row">
            <label className="label-value">{pumpModel?.flowRate}</label>
            <label className="label-value">{pumpModel?.head} ft</label>
            <label className="label-value">{pumpModel?.diam}'</label>
            <label className="label-value">{pumpModel?.bhp} hp</label>
            <label className="label-value">{pumpModel?.nol} hp</label>
            <label className="label-value">
              {pumpModel?.npshr ? `${pumpModel.npshr} ft` : ''}
            </label>
            <label className="label-value">
              {`${pumpModel?.efficiencyPercent ?? ''} %`}
            </label>
          </div>
        </div>
      </div>
      <div className="pump-curve">
        <div className="curve-header">
          <h5>{t('pump-curve-page.curve-title')}</h5>
        </div>
        <div className="curve-img">
          {pumpModel?.curvePath && (
            <img src={`/images/Curves/${pumpModel?.curvePath}`} />
          )}
        </div>

        <div className="footer-container">
          <table className="quote-info">
            <tbody>
              <tr>
                <td>
                  <strong>{t('common.job-name')}:</strong>
                </td>
                <td>{pumpModel?.jobName}</td>
                <td>
                  <strong>{t('common.job-name')}:</strong>
                </td>
                <td>{pumpModel?.tag}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('common.client')}:</strong>
                </td>
                <td>{pumpModel?.client}</td>
                <td>
                  <strong>{t('common.date')}:</strong>
                </td>
                <td>{pumpModel?.date}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('common.engineer')}:</strong>
                </td>
                <td>{pumpModel?.engineer}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {loading && <LoadingComponent />}
    </div>
  );

  function fetchQuoteKitData() {
    if (pumpModel?.quoteKitId && quoteId) {
      setLoading(true);
      pumpService
        .fetchQuoteKitCurve(quoteId, pumpModel?.quoteKitId)
        .then((res) => {
          if (res) {
            setPumpModel(res);
            setLoading(false);
          }
        })
        .catch((error) => {
          alert(t('curve-page.error-fetch-quotekit') + error);
          setLoading(false);
        });
    }
  }
  function fetchSystemPumpData() {
    console.log(pumpModel?.systemPumpId);
    if (pumpModel?.systemPumpId && quoteId) {
      console.log('here');
      setLoading(true);
      pumpService
        .fetchSystemPumpCurve(quoteId, pumpModel?.systemPumpId)
        .then((res) => {
          if (res) {
            setPumpModel(res);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          alert(t('curve-page.error-fetch-system') + error);
        });
    }
  }
  function fetchPumpSearchPumpData(searchPump: PumpSearchPump) {
    setPumpModel(
      (prevState) =>
        ({
          ...prevState,
          flowRate: searchPump.flowRate,
          head: searchPump.head,
          modelId: searchPump.modelId,
          modelName: searchPump.modelName,
          pumpType: searchPump.seriesName,
          diam: Number(searchPump.impellerDiameter),
          flangeType: searchPump.flangeType,
          constructionId: searchPump.constructionId,
          motorId: searchPump.motorId,
          motorType: searchPump.motorType,
          bhp: searchPump.bhp,
          nol: searchPump.nol,
          rpm: searchPump.rpm,
          npshr: searchPump.npshr,
          efficiencyPercent: searchPump.efficiencyPercent,
          curvePath: searchPump.curvePath,
        }) as PumpCurvePageModel,
    );
  }

  function handleRegenarteCurve() {
    filterCollapse.collapsed = false;
    if (pumpModel) {
      setLoading(true);
      pumpService
        .generatePumpCurve(pumpModel)
        .then((res) => {
          if (res) {
            setPumpModel(res);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          alert(t('curve-page.error-regenerate-curve'));
        });
    }
  }
  function handleAdvanceSearch() {
    window.location.href = 'https://flofab.portal.intelliquip.com';
  }
}
