export class MfvSelectionTable {
  mfv: MfvRow;
  secondaryMfvs: Array<MfvRow> = [];
  otherMfvs: Array<MfvRow> = [];

  constructor(data: any) {
    this.mfv = data.mfvs;
    this.secondaryMfvs = data.secondaryMfvs;
    this.otherMfvs = data.otherMfvs;
  }
}
export class MfvRow {
  id: number;
  name: string;
  listPrice: number;
  isChecked: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.listPrice = data.listPrice;
    this.isChecked = data.isChecked;
  }
}
