import { Chart } from 'react-google-charts';

interface Props {
  data: [string, number | string][];
  options: {
    title: string;
    pieHole: number;
    is3D: boolean;
  };
}

const DonutChartComponent = (props: Props) => {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={props.data}
      options={props.options}
    />
  );
};

export default DonutChartComponent;
