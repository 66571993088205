import type { QuoteProduction } from '../../../services/Quotes/Models/QuoteProduction';
import QuoteProductionItem from './quote-production-item';

interface Props {
  model: QuoteProduction[];
}
const QuoteProductionShowItems = (props: Props) => {
  return (
    <div className="row">
      {props.model.map((item, index) => (
        <QuoteProductionItem key={index} model={item}></QuoteProductionItem>
      ))}
    </div>
  );
};

export default QuoteProductionShowItems;
