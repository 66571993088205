import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Col } from 'react-bootstrap';
import CsvExport from '../../components/CSVExportDataAsync';
import useColumnVisibility from '../../hooks/React table/useColumnVisibility';
import Pagination from '../../components/react-table/Pagination';
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from '../../components/react-table/react-table-component';
import { ColumnVisibilityComponent } from '../../components/react-table/column-visibility-component';

import {
  Page,
  PageDetails,
  PageHeader,
} from '../../components/Pages/page-components';
import nonConformityReportService from '../../services/NonConformity/NonConformityReportService';
import BreadcrumbComponent from '../../components/Core/BreadcrumbComponent';
import type { NonConformityReport } from '../../services/NonConformity/Models/NonConformityReport';
import stringHelper from '../../services/Core/Helpers/string-helper';

type IParamTypes = {
  employeeId: string;
};

export default function NonConformityReportTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams<IParamTypes>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [nonConformityReportArray, setNonConformityReportArray] =
    useState<Array<NonConformityReport>>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const pageTitle = t(
    'non-conformity-report-page.record-of-non-conformity-reports',
  );
  useEffect(() => {
    document.title = pageTitle;
    nonConformityReportService
      .getAllNonConformityReport()
      .then((data) => setNonConformityReportArray(data))
      .catch(() => t('non-conformity-report-page.non-confomity-report'));
  }, [employeeId, pageTitle, t]);
  const columns: any = useMemo(() => {
    return [
      {
        header: t('non-conformity-report-page.id'),
        accessorKey: 'id',
      },
      {
        header: t('non-conformity-report-page.provenance'),
        id: 'provenance',
        cell: (props: any) => {
          return <>{getProvenanceText(props.row.original, t)}</>;
        },
      },
      {
        header: t('non-conformity-report-page.responsibility'),
        cell: (props: any) => {
          return <>{getResponsibilityText(props.row.original, t)}</>;
        },
      },
      {
        header: t('non-conformity-report-page.description'),
        accessorKey: 'description',
      },
      {
        header: t('non-conformity-report-page.creation-date'),
        accessorKey: 'creationDate',
        cell: (props: any) => (
          <>{props.getValue() && (props.getValue() as Date).toDateString()}</>
        ),
      },
      {
        header: t('non-conformity-report-page.close-date'),
        accessorKey: 'closeDate',
        cell: (props: any) => (
          <>{props.getValue() && (props.getValue() as Date).toDateString()}</>
        ),
      },
      {
        header: t('non-conformity-report-page.complain-cost'),
        accessorKey: 'costAssociatedWithTheComplain',
      },
      {
        header: 'Job Number',
        accessorKey: 'orderId',
        cell: (props: any) => (
          <>
            {props.getValue() &&
              `${props.getValue()}-${props.row.original?.kitId}`}
          </>
        ),
      },
    ];
  }, [t]);

  const table = useReactTable({
    columns,
    data: nonConformityReportArray || [],
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { status: false, 'mrt-row-expand': true },
    },
    state: {
      globalFilter,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    autoResetPageIndex: false,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const breadcrumbItems = [{ text: pageTitle, active: true }];
  return (
    <Page className="users-container">
      <PageHeader id="users-page-header" className="d-block">
        <BreadcrumbComponent items={breadcrumbItems} />
        <div className="d-flex">
          <h1 className="h3 mb-2">{pageTitle}</h1>
          <Button
            id="new-user-btn"
            variant="outline-primary"
            className="ms-3"
            onClick={() =>
              navigate(`/non-conformity-report-form/${employeeId}`)
            }
          >
            {t('non-conformity-report-page.new-non-conformity-report')}
          </Button>
        </div>
      </PageHeader>

      <PageDetails className="pt-2">
        <div className="row mb-3">
          <Col sm={2}>
            <div className="input-group">
              <input
                type="search"
                className="form-control rounded"
                placeholder={t('common.search') || ''}
                aria-label={t('common.search') || ''}
                onChange={(ev) => {
                  setGlobalFilter(ev.target.value);
                  table.resetPageIndex(true);
                }}
              />
            </div>
          </Col>
        </div>

        <section id="users-table">
          <table className="table table-hover">
            <thead>
              <tr id="goal-table-options" className="table-options">
                <th
                  colSpan={
                    table.getAllFlatColumns().filter((x) => x.getIsVisible())
                      .length
                  }
                >
                  <ColumnVisibilityComponent
                    columns={iColumnVisibility}
                    className="mx-1"
                  />
                  <CsvExport
                    url={getCSVDownloadLink()}
                    label={
                      <>
                        <FontAwesomeIcon
                          className="primary me-1"
                          icon={faFileArrowDown}
                        />
                        {t('react-table.export-data')}
                      </>
                    }
                    transform={transformToExportData}
                  ></CsvExport>
                </th>
              </tr>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id}>
                        <span {...getTableHeaderSortProps(header.column)}>
                          {header.column.columnDef.header?.toString()}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          className="align-vertical-center"
                          key={cell.id}
                          onClick={() => {
                            navigate(
                              `/non-conformity-report-form/${employeeId}/${row.original.id}`,
                            );
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            reactTable={table}
            siblingCount={2}
            dataName={
              t('non-conformity-report-page.non-conformity-report') ?? ''
            }
            showDataLength
          />
        </section>
      </PageDetails>
    </Page>
  );
  function transformToExportData(data: Array<NonConformityReport>) {
    return data.map((model) => {
      const creationDate =
        // eslint-disable-next-line no-nested-ternary
        model.creationDate !== null
          ? model.creationDate instanceof Date
            ? model.creationDate
            : new Date(model.creationDate as any)
          : undefined;
      const closeDate =
        // eslint-disable-next-line no-nested-ternary
        model.closeDate !== null
          ? model.closeDate instanceof Date
            ? model.closeDate
            : new Date(model.closeDate as any)
          : undefined;

      return {
        'Non Conformity Report': model.id,
        Provenance: getProvenanceText(model, t),
        Responsibility: getResponsibilityText(model, t),
        Description: model.description,
        'Creation Date': stringHelper.toDateString(creationDate),
        'Closed Date': stringHelper.toDateString(closeDate),
        'Cost Associated with the Complain':
          model.costAssociatedWithTheComplain,
        'Job Number': `${model.orderId}-${model.kitId}`,
      };
    });
  }
}
function getCSVDownloadLink() {
  return `${nonConformityReportService.baseUrl}/NonConformityReport/getAll`;
}

function getProvenanceText(
  nonConfomityReport: NonConformityReport | undefined,
  t: any,
) {
  let provenanceText =
    (nonConfomityReport?.isProvenanceClient
      ? `${t('non-conformity-report-page.client')}, `
      : '') +
    (nonConfomityReport?.isProvenanceInternal
      ? `${t('non-conformity-report-page.internal')}, `
      : '') +
    (nonConfomityReport?.isProvenanceSupplier
      ? `${t('non-conformity-report-page.supplier')}, `
      : '');
  provenanceText = provenanceText.slice(0, -2);
  return provenanceText;
}
function getResponsibilityText(
  nonConfomityReport: NonConformityReport | undefined,
  t: any,
) {
  let responsibilityText =
    (nonConfomityReport?.isEmployeeResponsibility
      ? `${t('non-conformity-report-page.employee')}, `
      : '') +
    (nonConfomityReport?.isClientResponsibility
      ? `${t('non-conformity-report-page.client')}, `
      : '') +
    (nonConfomityReport?.isOtherResponsibility
      ? `${t('non-conformity-report-page.other')}, `
      : '') +
    (nonConfomityReport?.isAdminResponsibility
      ? `${t('non-conformity-report-page.admin')}, `
      : '') +
    (nonConfomityReport?.isProductionResponsibility
      ? `${t('non-conformity-report-page.production')}, `
      : '') +
    (nonConfomityReport?.isConceptionResponsibility
      ? `${t('non-conformity-report-page.conception')}, `
      : '') +
    (nonConfomityReport?.isSupplierResponsibility
      ? `${t('non-conformity-report-page.supplier')}, `
      : '');
  responsibilityText = responsibilityText.slice(0, -2);
  return responsibilityText;
}
