import stringHelper from '../Core/Helpers/string-helper';
import BaseApi from '../Core/REST/BaseApi';
import type StatisticContent from './StatisticContent';

class StatsService extends BaseApi {
  async getProductionOrderTypeQuantities(
    employeeId: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/production/orders-type-count`,
    );
  }

  async getJobsStatistic(
    employeeId: string,
    start: string,
    end: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/production/jobs-stats?start=${start}&end=${end}`,
    );
  }
  async getJobsStatisticPumpPackage(
    start: string,
    end: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/admin/production/jobs-stats-pump-package?start=${start}&end=${end}`,
    );
  }

  async getJobTypes(
    start: string,
    end: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/admin/production/jobs-types?start=${start}&end=${end}`,
    );
  }

  async getNbProductionJobs(
    employeeId: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/production/number-of-jobs`,
    );
  }

  async getTodayProduction(
    employeeId: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/production/today`,
    );
  }

  async getProductionDelays(
    employeeId: string,
  ): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/delays`,
    );
  }

  async getIncomeStatement(
    employeeId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<StatisticContent | undefined> {
    const start = stringHelper.toDateString(startDate);
    const end = stringHelper.toDateString(endDate);
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/income-statement?start=${start}&end=${end}`,
    );
  }

  async getIncomeStatementQuarter(
    employeeId: string,
    date: Date,
  ): Promise<StatisticContent | undefined> {
    const dateString = stringHelper.toDateString(date);
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/${employeeId}/admin/income-statement/quarter?date=${dateString}`,
    );
  }
  async getPercentageOfOrdersOnTime(): Promise<number | undefined> {
    const url = `${this.baseUrl}/stats/get-percentage-of-orders-on-time`;
    return this.get<number>(url);
  }
  async getEmployeeWeeklyHours(): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/get-employee-weekly-hours`,
    );
  }
  async getClosingRatio(): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/get-closing-ratio`,
    );
  }
  async getEngineeringData(): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/get-engineering-data`,
    );
  }
  async getSalesData(): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/get-sales-data`,
    );
  }
  async getCashflowData(): Promise<StatisticContent | undefined> {
    return this.get<StatisticContent | undefined>(
      `${this.baseUrl}/stats/get-cashflow-data`,
    );
  }
}

const statsService = new StatsService();
export default statsService;
