export default class PumpTestSheet {
  quoteId: number = 0;
  client: string = '';
  job: string = '';
  sheets: Array<PumpTestSheetInfo> = [];

  pumpModel: string = '';

  constructor(data: any, sheets: Array<PumpTestSheetInfo>) {
    this.quoteId = data.quoteId;
    this.client = data.client;
    this.job = data.job;

    this.sheets = sheets;

    this.pumpModel = data.pumpModel;
  }
}

export class PumpTestSheetInfo {
  id: number;
  quoteExternalFileId: number;
  creationDate: Date;
  employeId: number;
  employeeName: string;
  pumpModel: string;
  pumpHead: number;
  pumpFlow: number;
  pumpImpDiam: number;
  motorManufacturer: string;
  motorCatNo: string;
  motorHP: number;
  motorFrame: string;
  motorFLA: number;
  motorRPM: number;
  motorEff: number;
  motorSF: number;
  motorPF: number;
  motorVolt: number;
  motorHz: number;

  hydroTestPSI: number;
  comments: string;
  dateTimeTested: Date = new Date();
  isFinish: boolean;
  approvedBy: number;
  approvedByName: string;

  pumpTestSheetTag: Array<PumpTestSheetTag> = [];
  pumpTestSheetValues: Array<PumpTestSheetValue> = [];

  constructor(
    data: any,
    pumpTestSheetTag: Array<PumpTestSheetTag>,
    pumpTestSheetValues: Array<PumpTestSheetValue>,
  ) {
    this.id = data.id;
    this.quoteExternalFileId = data.quoteExternalFileId;

    if (data.creationDate) this.creationDate = new Date(data.creationDate);
    else this.creationDate = new Date(Date.now());

    this.employeId = data.employeId;
    this.employeeName = data.employeeName;

    this.pumpModel = data.pumpModel;
    this.pumpHead = data.pumpHead;
    this.pumpFlow = data.pumpFlow;
    this.pumpImpDiam = data.pumpImpDiam;

    this.motorManufacturer = data.motorManufacturer;
    this.motorCatNo = data.motorCatNo;
    this.motorHP = data.motorHP;
    this.motorFrame = data.motorFrame;
    this.motorFLA = data.motorFLA;
    this.motorRPM = data.motorRPM;
    this.motorEff = data.motorEff;
    this.motorSF = data.motorSF;
    this.motorPF = data.motorPF;
    this.motorVolt = data.motorVolt;
    this.motorHz = data.motorHz;

    this.hydroTestPSI = data.hydroTestPSI;
    this.comments = data.comments;
    this.dateTimeTested = data.dateTimeTested;
    this.isFinish = data.isFinish;
    this.approvedBy = data.approvedBy;
    this.approvedByName = data.approvedByName;

    this.pumpTestSheetTag = pumpTestSheetTag;
    this.pumpTestSheetValues = pumpTestSheetValues;
  }
}

export class PumpTestSheetTag {
  id?: number;
  sheetId?: number = 0;
  pumpTag?: string = '';
  pumpSerial?: string = '';
  motorSerial?: string = '';

  constructor(data: any) {
    this.id = data.id;
    this.sheetId = data.sheetId;
    this.pumpTag = data.pumpTag;
    this.pumpSerial = data.pumpSerial;
    this.motorSerial = data.motorSerial;
  }
}

export class PumpTestSheetValue {
  id?: number;
  sheetId: number;
  flow?: number;
  head?: number;
  amp1?: number;
  amp2?: number;
  amp3?: number;
  volt1?: number;
  volt2?: number;
  volt3?: number;
  bhp?: number;
  eff?: number;

  constructor(data: any) {
    this.id = data.id;
    this.sheetId = data.sheetId;
    this.flow = data.flow;
    this.head = data.head;
    this.amp1 = data.amp1;
    this.amp2 = data.amp2;
    this.amp3 = data.amp3;
    this.volt1 = data.volt1;
    this.volt2 = data.volt2;
    this.volt3 = data.volt3;
    this.bhp = data.bhp;
    this.eff = data.eff;
  }
}
