import { useEffect, useState } from 'react';
import salesService from '../../services/Sales/SalesService';
import stringHelper from '../../services/Core/Helpers/string-helper';
import type StatisticContent from '../../services/Stats/StatisticContent';

export default function usePurchasePacToolStats(
  employeeId?: string,
  start?: Date,
  end?: Date,
  type?: string,
) {
  const [state, setState] = useState<StatisticContent>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = () => {
    if (employeeId && start && end) {
      const s = stringHelper.toDateString(start);
      const e = stringHelper.toDateString(end);

      salesService
        .getPurchasePacToolStats(employeeId, s, e, type ?? 'year')
        .then((data) => {
          if (data) {
            setState(data);
          }
        })
        .catch((_) => {})
        .finally(() => setLoading(false));
    }
  };

  useEffect(loadData, [employeeId, start, end]);

  return { purchasePacTool: state, purchasePacToolLoading: loading };
}
