import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import type { Client } from '../services/Users/Client';
import permissionService from '../services/Permissions/PermissionService';
import type { Permission } from '../services/Permissions/Permission';

type Props = {
  children: React.ReactNode;
  user: Client;
  objectName: string;
  redirect?: boolean;
  alt?: JSX.Element;
};
let permissionKeyArray: Array<Permission> | undefined;

export async function checkAllowed(user: Client, objectName: string) {
  if (permissionKeyArray === undefined) {
    permissionKeyArray = await permissionService.GetAll();
    // while (permissionKeyArray===undefined);//wait for permission to be in cache
  }
  const permissionValueArray = permissionKeyArray?.find(
    (x) => x.permissionName === objectName,
  )?.permissionValues;
  if (permissionValueArray === undefined) {
    throw `Permission undefined ${objectName}`;
  }
  return (
    permissionValueArray.find(
      (x) =>
        x.departmentId === user.departmentId &&
        user.roleId &&
        x.roleId >= user.roleId,
    ) !== undefined || user.roleId === 1
  ); // 1 is the admin role
}
export default function CheckAllowedComponent(props: Props) {
  const navigate = useNavigate();
  const [isAllowed, setIsAllowed] = useState(false);
  useEffect(() => {
    checkAllowed(props.user, props.objectName).then((res) => {
      setIsAllowed(res);
      if (permissionKeyArray && !res && props.redirect) {
        navigate('../PageNotFound');
      }
    });
  }, [permissionKeyArray]);

  return <>{isAllowed ? props.children : props.alt}</>;
}
