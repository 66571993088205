import './dashboard-project.scss';
import { useTranslation } from 'react-i18next';
import type { Client } from '../../services/Users/Client';
import {
  Page,
  PageDetails,
  PageHeader,
} from '../../components/Pages/page-components';
import CheckAllowedComponent from '../../components/CheckAllowedComponent';
import {
  QuotePriorityPackageComponent,
  QuotePriorityPumpComponent,
  QuotePriorityWeldingComponent,
  QuotePriorityFinitionComponent,
  ProductionGoalComponent,
  ProcurementComponent,
  ProductionComponent,
  ProjectsComponent,
  ProductionJobsComponent,
  AssignedReminderComponent,
  ClosingRatioComponent,
  EngineeringComponent,
  SalesComponent,
  CashflowComponent,
} from './projects-components';

type Props = {
  user: Client;
};
function DashboardProject({ user }: Props) {
  const { t } = useTranslation();
  return (
    <Page id="project-dashboard-page">
      <PageHeader id="users-page-header" className="d-block">
        <div className="d-flex">
          <h1 className="h2 mb-2">
            {`${t('common.hello')} `} <b>{user.nom} !</b>
          </h1>
        </div>
      </PageHeader>
      <PageDetails>
        <div className="card-grid">
          <CheckAllowedComponent
            objectName="get-quote-priority-pump"
            user={user}
          >
            <QuotePriorityPumpComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent
            objectName="get-quote-priority-package"
            user={user}
          >
            <QuotePriorityPackageComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent
            objectName="get-quote-priority-pump"
            user={user}
          >
            <QuotePriorityWeldingComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent
            objectName="get-quote-priority-package"
            user={user}
          >
            <QuotePriorityFinitionComponent />
          </CheckAllowedComponent>

          <CheckAllowedComponent objectName="get-production-goal" user={user}>
            <ProductionGoalComponent user={user} />
          </CheckAllowedComponent>

          <CheckAllowedComponent
            objectName="get-to-do-late-project"
            user={user}
          >
            <ProcurementComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent objectName="get-production-job" user={user}>
            <ProductionComponent user={user} />
          </CheckAllowedComponent>

          <CheckAllowedComponent
            objectName="get-project-percentage"
            user={user}
          >
            <ProjectsComponent />
          </CheckAllowedComponent>

          <CheckAllowedComponent objectName="get-production-job" user={user}>
            <ProductionJobsComponent user={user} />
          </CheckAllowedComponent>
          <CheckAllowedComponent
            objectName="get-reminder-assigned-by"
            user={user}
          >
            <AssignedReminderComponent user={user} />
          </CheckAllowedComponent>
          <CheckAllowedComponent objectName="get-closing-ratio" user={user}>
            <ClosingRatioComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent objectName="get-engineering-data" user={user}>
            <EngineeringComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent objectName="get-sales-data" user={user}>
            <SalesComponent />
          </CheckAllowedComponent>
          <CheckAllowedComponent objectName="get-cashflow-data" user={user}>
            <CashflowComponent />
          </CheckAllowedComponent>
        </div>
      </PageDetails>
    </Page>
  );
}
export default DashboardProject;
