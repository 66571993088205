import './column-visibility-component.scss';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

type ColumnVisibilityProps = {
  columns: Array<IColumnVisibilityItem>;
  className?: string;
};

export type IColumnVisibility = Array<IColumnVisibilityItem>;

export type IColumnVisibilityItem = {
  titleTranslationKey: string;
  isVisible: boolean;
  onClick: () => (event: unknown) => void;
};

export function ColumnVisibilityComponent(props: ColumnVisibilityProps) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [columns, setColumns] = useState<IColumnVisibility>([]);

  const wrapperRef = useRef(null);
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideClick: () => onColumnVisibilityClick(false),
  });

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns);
    }
  }, [props]);

  return (
    <div ref={wrapperRef} className="column-visibility d-inline-block">
      <button
        className={`btn btn-sm btn-outline-primary ${
          props.className && props.className
        }`}
        title="Column Visibility"
        type="button"
        onClick={() => onColumnVisibilityClick()}
      >
        <FontAwesomeIcon icon={faListCheck} />
      </button>
      {visible && (
        <div className="column-visibility-items">
          {columns.map((column, i) => {
            const id = `column-visibility-check-${i}`;
            return (
              <div key={i} onClick={column.onClick()}>
                <input
                  id={id}
                  className="form-check-input me-1"
                  type="checkbox"
                  checked={column.isVisible}
                  onChange={column.onClick()}
                />
                <label className="form-check-label" htmlFor={id}>
                  {t(column.titleTranslationKey)}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  function onColumnVisibilityClick(newValue: boolean = !visible) {
    setVisible(newValue);
  }
}
