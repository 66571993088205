import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import { AddButton } from '../../components/general-button-components';
import {
  getTableHeaderSortProps,
  ReactTableHeaderOptions,
} from '../../components/react-table/react-table-component';

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export const SystemPumpComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const handleAddProductBtnClick = () => {
    navigate(`/Pumps/${userId}/Pump-Search/${quoteId}`);
  };

  const FloatCellRenderer = ({ cell }: { cell: any }) => {
    const value = parseFloat(cell.getValue());
    return !Number.isNaN(value) ? value.toFixed(2) : cell.getValue();
  };

  const boosterPumps = [
    {
      tag: 'P001',
      modelName: 'ModelX100',
      suct: '6 inch',
      disc: '4 inch',
      flow: 1200, // in GPM (gallons per minute)
      head: 350, // in feet
      bhp: 50, // brake horsepower
      rpm: 1750, // revolutions per minute
      phase: 3,
      flangeType: 'ANSI 150',
      isPremEff: true, // premium efficiency
      qty: 2,
    },
    {
      tag: 'P002',
      modelName: 'ModelX200',
      suct: '8 inch',
      disc: '6 inch',
      flow: 1500,
      head: 400,
      bhp: 75,
      rpm: 1800,
      phase: 3,
      flangeType: 'ANSI 300',
      isPremEff: false,
      qty: 1,
    },
  ];

  const columnsPumps: any = useMemo(() => {
    return [
      {
        header: t('add-booster-system-page.tag'),
        accessorKey: 'tag',
      },
      {
        header: t('add-booster-system-page.model'),
        accessorKey: 'modelName',
      },
      {
        header: t('add-booster-system-page.suct'),
        accessorKey: 'suct',
      },
      {
        header: t('add-booster-system-page.disc'),
        accessorKey: 'disc',
      },
      {
        header: t('add-booster-system-page.flow'),
        accessorKey: 'flow',
      },
      {
        header: t('add-booster-system-page.head'),
        accessorKey: 'head',
      },
      {
        header: t('add-booster-system-page.hp'),
        accessorKey: 'bhp',
        cell: FloatCellRenderer,
      },
      {
        header: t('add-booster-system-page.rpm'),
        accessorKey: 'rpm',
        cell: FloatCellRenderer,
      },
      {
        header: t('add-booster-system-page.phase'),
        accessorKey: 'phase',
        cell: FloatCellRenderer,
      },
      {
        header: t('add-booster-system-page.flange-type'),
        accessorKey: 'flangeType',
      },
      {
        header: t('add-booster-system-page.prem-eff'),
        accessorKey: 'isPremEff',
      },
      {
        header: t('add-booster-system-page.qty'),
        accessorKey: 'qty',
      },
    ];
  }, [t]);

  const tablePumps = useReactTable({
    columns: columnsPumps,
    data: boosterPumps,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div id="system-pump-component">
      <div>
        <span className="form-label">Requeired Number of Pumps: </span>
        <span className="form-label"> 2</span>
        <div className="button-container">
          <AddButton
            title={t('add-booster-system-page.add-pump') || 'Add Pump'}
            btnSize="sm"
            onClick={handleAddProductBtnClick}
          />
        </div>
      </div>
      <div className="table-header">
        <span className="sub-description">
          Currently added pumps
          {/* {t("mfv-selection-page.description-tb3")} */}
        </span>
      </div>
      <table className="table table-hover">
        <thead>
          {tablePumps.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th></th>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    <span {...getTableHeaderSortProps(header.column)}>
                      {header.column.columnDef.header?.toString()}
                      <ReactTableHeaderOptions header={header} />
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {boosterPumps.map((row, rowIndex: number) => (
            <tr
              key={rowIndex}
              // className={selectedPump === row ? "selected-row" : ""}
              // onClick={() => handlePumpSelection(row)}
            >
              <td className="align-vertical-center">
                <input
                  type="checkbox"
                  // checked={selectedPump === row}
                  // onChange={() => handlePumpSelection(row)}
                  onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                />
              </td>

              {tablePumps
                .getRowModel()
                .rows[rowIndex].getVisibleCells()
                .map((cell) => (
                  <td className="align-vertical-center" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
