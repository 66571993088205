import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

type Props = {
  collapsed?: boolean;
  title: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
  showSquareIndicator?: boolean;
};

export default function CollapseListComponent(props: Props) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (props.collapsed == null) return;
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  const onClick = () => {
    // console.log(props.title, collapsed);
    setCollapsed(!collapsed);
  };
  return (
    <>
      <a className="btn" role="button" onClick={onClick}>
        <FontAwesomeIcon
          className="me-2"
          icon={collapsed === false ? faSquareMinus : faSquarePlus}
        />
        {props.title}
      </a>
      {collapsed === false && <>{props.children}</>}
    </>
  );
}
