import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import type {
  ColumnFiltersState,
  VisibilityState,
  ColumnDef,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import type { QuoteExternalFile } from '../../services/Quotes/Models/QuoteExternalFile';
import { FileType } from '../../services/Quotes/Models/QuoteExternalFile';
import quoteService from '../../services/Quotes/QuoteService';
import productionService from '../../services/Production/ProductionService';
import stringHelper from '../../services/Core/Helpers/string-helper';
import type { NavItem } from '../nav-tabs-component';
import NavTabsComponent from '../nav-tabs-component';
import { Page } from '../Pages/page-components';
import {
  getTableHeaderSortProps,
  ReactTableHeaderOptions,
} from '../react-table/react-table-component';
import Checkbox from '../Checkbox';
import ConfiramtionPopup from '../Core/Popup-confirmation-Component';
import './files-component.scss';

interface FilesComponentProps {
  quoteId: string;
}

export default function FilesComponent({ quoteId }: FilesComponentProps) {
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [files, setFiles] = useState<QuoteExternalFile[]>([]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const [deleting, setDeleting] = useState<number | null>(null);
  const [tab, setTab] = useState<number>(-1);

  useEffect(() => {
    quoteService
      .getFiles(quoteId)
      .then((res) => {
        if (!res) console.error('Get quote files response is null');
        else setFiles(res);
      })
      .catch(() => {
        toast.error("'Load Quote Files': Error while fetching data");
      });
  }, []);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    type: false,
    kitId: false,
  });
  const columnHelper = createColumnHelper<QuoteExternalFile>();
  const columns: ColumnDef<QuoteExternalFile, any>[] = [
    {
      id: 'select-col',
      header: ({ table }) => (
        <Checkbox
          value={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          value={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    },
    columnHelper.accessor('type', {
      header: 'Type',
      filterFn: 'equals',
    }),
    columnHelper.accessor('kitId', {
      header: 'Kit',
    }),
    columnHelper.accessor('filename', {
      header: t('quote-page.filename')!,
      cell: (data) => (
        <a
          href={`https://portal.flofab.com/flofabVD/quoteExternalFiles/${quoteId}/${data.row.original.path}`}
          target="_blank"
        >
          {data.getValue()}
        </a>
      ),
    }),
    columnHelper.accessor('uploadDate', {
      header: 'Date',
      cell: (data) => (
        <>
          {stringHelper.toDateString(
            new Date(data.getValue()! as unknown as string),
          )}
        </>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <div className="action-col">
          {/* TODO: Not possible due to CORS
          <a
            href={`https://portal.flofab.com/flofabVD/quoteExternalFiles/${quoteId}/${row.original.path}`}
            download={row.original.filename}
          >
            <FontAwesomeIcon className="Round-fa-Button" icon={faDownload} />
          </a> */}
          <a
            onClick={() => {
              setDeleting(row.original.id!);
            }}
            title={t('common.delete')!}
          >
            <FontAwesomeIcon className="Round-fa-Button" icon={faTrash} />
          </a>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: files,
    columns,
    state: {
      columnVisibility,
      columnFilters,
      rowSelection,
    },
    getRowId: (row) => row.id?.toString() ?? '',
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
  });

  // display the kit column only in Production and All tab
  useEffect(() => {
    table
      .getColumn('kitId')!
      .toggleVisibility(tab === -1 || tab === FileType.Production);
  }, [tab, table]);

  const navTabItems = useMemo(() => {
    const tmp: NavItem[] = Object.values(FileType)
      .filter(
        (v): v is FileType => typeof v === 'number' && v !== FileType.Unknown,
      )
      .map((type) => {
        const items = files.filter((item) => item.type! === type);
        if (items.length <= 0) return null;

        const html = (
          <>
            {t(`quote-page.filetype.${FileType[type].toLowerCase()}`)}
            <span className="badge bg-secondary">{items.length}</span>
          </>
        );
        return {
          key: type,
          onclick: setTabFilter,
          html,
        };
      })
      .filter((item) => item)
      .map((item) => item!);
    tmp.unshift({
      key: -1,
      html: (
        <>
          {t(`quote-page.filetype.all`)}
          <span className="badge bg-secondary">{files.length}</span>
        </>
      ),
      onclick: () => {
        setTabFilter(-1);
        setColumnFilters([]);
      },
    });
    return tmp;
  }, [files, t]);
  return (
    <Page id="files">
      {files.length === 0 ? (
        t('common.no-data')!
      ) : (
        <>
          <NavTabsComponent active={tab} navItems={navTabItems} />
          <div className="table-parent">
            <table className="table table-hover">
              <thead>
                <tr className="table-options">
                  <th
                    colSpan={
                      table.getAllFlatColumns().filter((x) => x.getIsVisible())
                        .length
                    }
                  >
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={exportFiles}
                    >
                      <FontAwesomeIcon
                        className="primary me-1 mx-1"
                        icon={faFileArrowDown}
                      />
                      {t('react-table.export-data')}
                    </button>
                    {/* <button
                  className="btn btn-sm btn-outline-primary mx-1"
                  onClick={addFile}
                >
                  <FontAwesomeIcon className="primary me-1" icon={faPlus} />
                  {t('react-table.add')}
                </button> */}
                  </th>
                </tr>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <span {...getTableHeaderSortProps(header.column)}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} {...table.options.meta?.getRowProps(row)}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {deleting !== null && (
        <ConfiramtionPopup
          show={deleting !== null}
          title={t('quote-page.delete-file')}
          message={t('quote-page.warn-delete-file-message')}
          onDelete={() => deleteFile(deleting!)}
          onClose={() => setDeleting(null)}
        />
      )}
    </Page>
  );

  function setTabFilter(value: FileType) {
    setTab(value);
    if (value.valueOf() === -1) return;
    setColumnFilters([{ id: 'type', value }]);
  }

  function exportFiles() {
    const items = files.filter((file) =>
      Object.prototype.hasOwnProperty.call(rowSelection, file.id!),
    );
    if (items.length === 0) {
      toast.error(t('quote-page.error-export-select-file')!);
      return;
    }
    items.forEach((file) => {
      saveAs(
        `https://portal.flofab.com/flofabVD/quoteExternalFiles/${quoteId}/${file.path}`,
      );
    });
    /* TODO: Not possible due to CORS
    const zip = new JSZip();
    files
      .filter((file) =>
        Object.prototype.hasOwnProperty.call(rowSelection, file.id!),
      )
      .forEach((file) => {
        fetch(
          `https://portal.flofab.com/flofabVD/quoteExternalFiles/${quoteId}/${file.path}`,
        )
          .then((res) => res.blob())
          .then((blob) => zip.file(file.filename!, blob));
      });
    zip
      .generateAsync({ type: 'blob' })
      .then((archive) => saveAs(archive, `${quoteId}-export.zip`)); */
  }

  function deleteFile(id: number) {
    productionService
      .deleteFile(id)
      .catch((error) => {
        toast.error('An error occured while deleting file.');
        console.error(error);
      })
      .then((_) => {
        setFiles(files.filter((file) => file.id !== id));
        setDeleting(null);
      })
      .catch((error) => {
        toast.error(t('quote-page.error-delete-file'), error);
      });
  }
}
